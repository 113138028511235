import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { AttachmentService } from 'src/app/project/shared/services/attachment.service';

@Component({
  selector: 'avs-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent {
  fileName: string = '';
  file: any;
  fileBlob: any;
  constructor(public dialogRef: MatDialogRef<FileUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project,
    private attachmentService: AttachmentService
  ) { }

  public onCloseFileUploaderClicked(): void {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    this.file = (event.target as HTMLInputElement).files;
    if (this.file) {
      this.fileName = this.file[0].name;
      this.fileName = this.fileName.replace(/^@"^[\w\-. ]+$"/, '').substring(0, 50);
    }
  }


  public onUploadFileClicked(): void {
    let file = this.file[0];
    this.attachmentService.uploadAttachment(file, this.data.id, this.fileName).subscribe(
      x => { this.dialogRef.close({ message: 'file uploaded' }) }
    )
  }
}
