<!-- <table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8" style="height: 500px; overflow: scroll;">


  <ng-container matColumnDef="measuredAt">
    <th mat-header-cell *matHeaderCellDef> Zeitpunkt </th>
    <td mat-cell *matCellDef="let element"> {{element.measuredAt}} </td>
  </ng-container>

  <ng-container matColumnDef="pkwTrafficVolume">
    <th mat-header-cell *matHeaderCellDef> Anzahl PKW </th>
    <td mat-cell *matCellDef="let element"> {{element.pkwTrafficVolume}} </td>
  </ng-container>

  <ng-container matColumnDef="lkwTrafficVolume">
    <th mat-header-cell *matHeaderCellDef> Anzahl LKW </th>
    <td mat-cell *matCellDef="let element"> {{element.lkwTrafficVolume}} </td>
  </ng-container>

  <ng-container matColumnDef="kfzTrafficVolume">
    <th mat-header-cell *matHeaderCellDef> Anzahl KFZ </th>
    <td mat-cell *matCellDef="let element"> {{element.kfzTrafficVolume}} </td>
  </ng-container>

  <ng-container matColumnDef="pkwAverageSpeed">
    <th mat-header-cell *matHeaderCellDef> Durchschnittsgeschwindigkeit PKW in km/h </th>
    <td mat-cell *matCellDef="let element"> {{element.pkwAverageSpeed}} </td>
  </ng-container>

  <ng-container matColumnDef="lkwAverageSpeed">
    <th mat-header-cell *matHeaderCellDef> Durchschnittsgeschiwndigkeit LKW in km/h </th>
    <td mat-cell *matCellDef="let element"> {{element.lkwAverageSpeed}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
 -->

<!-- 
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
</mat-form-field> -->

<div class="mat-elevation-z8 table-container position-relative" style="z-index: 0;">
  <div class="position-absolute" style=" top: 0px; right: 0px; z-index: 99999;">

    <mat-menu #menuDownload="matMenu" xPosition="before">
      <button mat-menu-item (click)="downloadMQReport('csv')">
        <mat-icon>download</mat-icon>
        <span>CSV</span>
      </button>
      <button mat-menu-item (click)="downloadMQReport('ascii')">
        <mat-icon>download</mat-icon>
        <span>ASCII</span>
      </button>
      <button mat-menu-item  (click)="downloadMQReport('pdf')">
        <mat-icon>download</mat-icon>
        <span>PDF</span>
      </button>
    </mat-menu>
  </div>
  <table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8">


    <ng-container matColumnDef="measuredAt">
      <th mat-header-cell *matHeaderCellDef> Zeitpunkt </th>
      <td mat-cell *matCellDef="let element"> {{element.measuredAt | date: 'dd.MM.yyyy - H:mm':
        offset(element.measuredAt)}} </td>
    </ng-container>

    <ng-container matColumnDef="pkwTrafficVolume">
      <th mat-header-cell *matHeaderCellDef> Anzahl PKW </th>
      <td mat-cell *matCellDef="let element"> {{element.pkwTrafficVolume}} </td>
    </ng-container>

    <ng-container matColumnDef="lkwTrafficVolume">
      <th mat-header-cell *matHeaderCellDef> Anzahl LKW </th>
      <td mat-cell *matCellDef="let element"> {{element.lkwTrafficVolume}} </td>
    </ng-container>

    <ng-container matColumnDef="kfzTrafficVolume">
      <th mat-header-cell *matHeaderCellDef> Anzahl KFZ </th>
      <td mat-cell *matCellDef="let element"> {{element.kfzTrafficVolume}} </td>
    </ng-container>

    <ng-container matColumnDef="pkwAverageSpeed">
      <th mat-header-cell *matHeaderCellDef> Durchschnittsgeschwindigkeit PKW in km/h </th>
      <td mat-cell *matCellDef="let element"> {{element.pkwAverageSpeed}} </td>
    </ng-container>

    <ng-container matColumnDef="lkwAverageSpeed">
      <th mat-header-cell *matHeaderCellDef> Durchschnittsgeschwindigkeit LKW in km/h </th>
      <td mat-cell *matCellDef="let element"> {{element.lkwAverageSpeed}} </td>
    </ng-container>

    <ng-container matColumnDef="actions" >
      <th mat-header-cell *matHeaderCellDef >
        <button mat-icon-button [matMenuTriggerFor]="menuDownload" aria-label="Download menu">
          <mat-icon>download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!--     <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr> -->
  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
</div>