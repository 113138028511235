import {Component, Input} from '@angular/core';

@Component({
  selector: 'avs-json-data-viewer',
  templateUrl: './json-data-viewer.component.html',
  styleUrls: ['./json-data-viewer.component.css']
})
export class JsonDataViewerComponent {
  @Input() json: any = "";

  public getObjectPairs(obj: any): { key: string, value: any }[] {
    return Object.keys(obj).map(key => ({ key, value: obj[key] }));
  }

  public isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }

}
