<div class="wrapper" style="overflow: hidden; overflow-y: hidden; overflow-x: hidden; margin-top: 24px;">
    <div class="card shadow rounded-0 showcase w-100 h-100 mx-5">
        <img src="../../../assets/logo/avs_symbol.svg" class="bg-image">
        <h1 class="text-danger w-100 bg-img-title ms-4" style="font-size: 30px;
        font-weight: 500;
        line-height: 1.4;">Impressum</h1>
        <div class="h-100 w-100 d-flex flex-column  bg-img-txt ms-4" style="margin-top: 24px;">

            <span> Verantwortlich für die Domain:</span>
            <a href="https://new-portal.avs-digital.de" target="_blank"> https://new-portal.avs-digital.de</a>

            <span style="margin-top: 32px; font-weight: bold;"> AVS Verkehrssicherung GmbH</span>
            <span> Burghof 1 ∙ 51491 Overath</span>
            <br>
            <span style="margin-top: 24px; font-weight: bold;"> Niederlassung Senden</span>
            <span> Daimlerstr. 37 ∙ 48308 Senden</span>
            <br>
            <span style="margin-top: 24px;"> USt-IdNr.: DE121974312</span>
            <span> Amtsgericht Köln HRB 46046</span>
            <br>
            <span style="margin-top: 32px;"><b> Geschäftsführer</b> </span>
              <span> Elena Vasileva </span> 
              <span>  Andreas Schwingeler </span>
              <span>  Sebastian Minnich</span>
              <span>  Thomas Gerding</span>
              <span style="margin-top: 32px;"><b> Kontakt</b> </span>
            <a href="mailto:opz@avs.eu"> opz@avs.eu</a>
            <br>
            <span style="margin-top: 48px;"> Redaktionell verantwortlich:</span>
            <br>
            <span style="margin-top: 16px;"> Thomas Gerding</span>
        </div>

    </div>
</div>