import {Component} from '@angular/core';
import {SelectInput} from "../../shared/dropdown/shared/select-input.class";

@Component({
  selector: 'avs-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent {
  public isFooterMenuOpen: boolean = false;
  public footerMenuSelectInput: SelectInput[] = [
    {name: 'Impressum'},
    {name: 'Datenschutz'},
    {name: 'AGB'},
    {name: 'Cookie-Einstellungen'},
    {name: 'FAQ', routerLink:'/faq'}
  ];
  public isMouseOverIcon = false;

  public onFooterMenuButtonClicked(): void {
    this.isFooterMenuOpen = !this.isFooterMenuOpen;
  }

  public onMenuListItemClicked(): void {
    this.isFooterMenuOpen = false;
  }
}
