import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'avs-project-time-switch-add-modal-wrapper',
  templateUrl: './project-time-switch-add-modal-wrapper.component.html',
  styleUrls: ['./project-time-switch-add-modal-wrapper.component.css']
})
export class ProjectTimeSwitchAddModalWrapperComponent implements OnInit {
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public aq: boolean = true;
  public isFlasher: boolean = true;
  public objIsOneTime: boolean = false;
  public objIsTouched: boolean = false;
  public objIsWithBlinkerTimer: boolean = false;

  mainForm = this._formBuilder.group({
    timerSwitchForm: this._formBuilder.group({
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      priority: 0,
      tlS_Code: 0,
      tlS_Function: 0,
      tlS_Time: 0,
      starttime: "08:00:00",
      endtime: "16:00:00",
      startdate: { year: "", month: "", day: "" },
      enddate: { year: "", month: "", day: "" },
      vs: 1,
      trafic: 1
    })
  })

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectTimeSwitchAddModalWrapperComponent>,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService) { }

  ngOnInit(): void {
    if (this.data.project) {
      this.getAllImages(this.data.project.id);
    }
    if (this.data.aq) { this.aq = true }
    else this.aq = false;
    this.isFlasher = this.data.flasher
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  public onCancelBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSubmitBtnClicked(): void {
    let startTime = this.mainForm.value.timerSwitchForm!.starttime!;
    let endTime = this.mainForm.value.timerSwitchForm!.endtime!;
    let startTimeParts = startTime.split(":", 3);
    let endTimeParts = endTime.split(":", 3);
    if (this.aq) {
      this.closeDialogAq(startTimeParts, endTimeParts)
    }
    if (!this.aq) {
      this.closeDialogMq(startTimeParts, endTimeParts)
    }
  }

  public getIsOneTime(newValue: boolean) {
    this.objIsOneTime = newValue
  }
  
  public getIsWithBlinkerTimer(newValue: boolean) {
    this.objIsWithBlinkerTimer = newValue
  }

  public getIsTouched(newValue: boolean) {
    this.objIsTouched = newValue
  }

  private getAllImages(projectId: number): void {
    this.projectService.getAllRouteStationImages(projectId).subscribe(images => {
      this.images = images;
      this.allImages = images;
    });
  }

  private closeDialogAq(startTimeParts: string[], endTimeParts: string[]) {
    if (this.objIsOneTime) {
      this.dialogRef.close({
        aq: this.aq,
        one_time: this.objIsOneTime,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        yearOn: this.mainForm.value.timerSwitchForm!.startdate?.year,
        monthOn: this.mainForm.value.timerSwitchForm!.startdate?.month,
        dayOn: this.mainForm.value.timerSwitchForm!.startdate?.day,
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        yearOff: this.mainForm.value.timerSwitchForm!.enddate?.year,
        monthOff: this.mainForm.value.timerSwitchForm!.enddate?.month,
        dayOff: this.mainForm.value.timerSwitchForm!.enddate?.day,
        priority: this.mainForm.value.timerSwitchForm!.priority,
        tlS_Code: this.mainForm.value.timerSwitchForm!.tlS_Code,
        tlS_Function: this.mainForm.value.timerSwitchForm!.tlS_Function,
        tlS_Time: this.mainForm.value.timerSwitchForm!.tlS_Time,
        createBlinkerTimer: this.objIsWithBlinkerTimer
      });
    }
    else {
      this.dialogRef.close({
        aq: this.aq,
        one_time: this.objIsOneTime,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        monday: this.mainForm.value.timerSwitchForm!.monday,
        tuesday: this.mainForm.value.timerSwitchForm!.tuesday,
        wednesday: this.mainForm.value.timerSwitchForm!.wednesday,
        thursday: this.mainForm.value.timerSwitchForm!.thursday,
        friday: this.mainForm.value.timerSwitchForm!.friday,
        saturday: this.mainForm.value.timerSwitchForm!.saturday,
        sunday: this.mainForm.value.timerSwitchForm!.sunday,
        priority: this.mainForm.value.timerSwitchForm!.priority,
        tlS_Code: this.mainForm.value.timerSwitchForm!.tlS_Code,
        tlS_Function: this.mainForm.value.timerSwitchForm!.tlS_Function,
        tlS_Time: this.mainForm.value.timerSwitchForm!.tlS_Time,
        createBlinkerTimer: this.objIsWithBlinkerTimer
      });
    }
  }

  private closeDialogMq(startTimeParts: string[], endTimeParts: string[]) {
    if (this.objIsOneTime) {
      this.dialogRef.close({
        aq: this.aq,
        one_time: this.objIsOneTime,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        yearOn: this.mainForm.value.timerSwitchForm!.startdate?.year,
        monthOn: this.mainForm.value.timerSwitchForm!.startdate?.month,
        dayOn: this.mainForm.value.timerSwitchForm!.startdate?.day,
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        yearOff: this.mainForm.value.timerSwitchForm!.enddate?.year,
        monthOff: this.mainForm.value.timerSwitchForm!.enddate?.month,
        dayOff: this.mainForm.value.timerSwitchForm!.enddate?.day,
        priority: this.mainForm.value.timerSwitchForm!.priority,
        trafic: this.mainForm.value.timerSwitchForm!.trafic,
        vs: this.mainForm.value.timerSwitchForm!.vs,
      });
    }
    else {
      this.dialogRef.close({
        aq: this.aq,
        one_time: this.objIsOneTime,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        priority: this.mainForm.value.timerSwitchForm!.priority,
        monday: this.mainForm.value.timerSwitchForm!.monday,
        tuesday: this.mainForm.value.timerSwitchForm!.tuesday,
        wednesday: this.mainForm.value.timerSwitchForm!.wednesday,
        thursday: this.mainForm.value.timerSwitchForm!.thursday,
        friday: this.mainForm.value.timerSwitchForm!.friday,
        saturday: this.mainForm.value.timerSwitchForm!.saturday,
        sunday: this.mainForm.value.timerSwitchForm!.sunday,
        trafic: this.mainForm.value.timerSwitchForm!.trafic,
        vs: this.mainForm.value.timerSwitchForm!.vs,
      });
    }
  }
}
