import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DeviceDataService } from "../shared/services/device-data.service";
import { ProjectRouteStationWithParam } from "../../project/shared/interfaces/project-route-station.class";
import { GroupService } from "../../project/shared/services/group.service";
import { ProjectService } from "../../project/shared/services/project.service";
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';

import { Subscription } from 'rxjs';
import { RouteStationService } from 'src/app/project/shared/services/route-station.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { RouteStationNote } from 'src/app/project/shared/interfaces/route-station-note';


@Component({
  selector: 'avs-device-notes',
  templateUrl: './device-notes.component.html',
  styleUrls: ['./device-notes.component.css']
})
export class DeviceNotesComponent implements OnInit, OnDestroy {
  public routeStationWithDeviceParam?: ProjectRouteStationWithParam;
  public note: string = '';
  private subscriptionCollection: Subscription[] = [];
  public latestNote: RouteStationNote | undefined;
  private projectRouteStationsWithParamSubscription?: Subscription;
  public isNoteChanged: boolean = false;
  constructor(private readonly deviceDataService: DeviceDataService,
    private readonly groupService: GroupService,
    private routeStationService: RouteStationService,
    private alertService: AlertService,
    private readonly projectService: ProjectService) {
    this.projectRouteStationsWithParamSubscription = deviceDataService.routeStationWithDeviceParam.subscribe(station => {
      this.routeStationWithDeviceParam = station;
      this.getNote();
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.projectRouteStationsWithParamSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }
  @HostListener('document:keypress', ['$event'])
  public onKeyDownCreateSingleNote(event: KeyboardEvent): void {
    this.isNoteChanged = true;
  }

  public onSaveSingleNotes(): void {
    this.routeStationService.postRouteStationNote(this.note, this.routeStationWithDeviceParam?.routeStation!.id!).subscribe((note) => {
      this.alertService.alert('Notiz gespeichert', AlertTypes.success);
      this.routeStationWithDeviceParam?.routeStation?.routeStationNotes.push(note);
      this.getNote();
      this.isNoteChanged = false;
      this.note = '';
    })
  }


  public onEditSingleNote(id: number) {
    this.routeStationService.editRouteStationNote(this.latestNote?.note!, id).subscribe((note) => {
      this.alertService.alert('Notiz geändert', AlertTypes.success);
      if (this.latestNote) this.latestNote.noteChanged = false;
      this.latestNote = note;
    })
  }
  onChangeLatestNote(newValue: string, note: RouteStationNote) {
    this.latestNote!.note = newValue;
    note.noteChanged = true;
  }
  private getNote() {
    if (this.routeStationWithDeviceParam?.routeStation) {
      let latestNoteId = Math.max(...(this.routeStationWithDeviceParam.routeStation?.routeStationNotes.map(note => note.id!) ?? []));
      this.latestNote = this.routeStationWithDeviceParam.routeStation.routeStationNotes.find(note => note.id === latestNoteId);
    }
  }
}
