<ng-container *ngIf="routeStation; else noActiveRouteStation">
    <div class="mt-3">
        <div class="card shadow rounded-0 p-3">
            <p class="fw-semibold text-secondary">Bilder</p>
            <div class="row avs-project-images-container">
                <ng-container *ngFor="let image of unassignedImages">
                    <div (click)="onUnassignedProjectImageClicked(image)" class="mb-3 avs-project-images">
                        <div class="d-flex justify-content-center">
                            <img [src]="environment.apiAddress + image.path">
                        </div>
                        <div class="small text-center">Stellcode: {{image.stellcode}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <div class="card shadow rounded-0 p-3">
            <div class="d-flex justify-content-between">
                <p class="fw-semibold text-secondary">Gesetzte Bilder</p>
                <img (click)="onGenerateAnimatedImageClicked()" class="avs-icon-add mb-3" src="assets/icons/images.svg"
                    alt="Wechselbild erstellen">
            </div>

            <div class="row avs-project-images-container">
                <ng-container *ngFor="let image of stationImages">
                    <div (click)="onProjectImageClicked(image)" class="mb-3 avs-project-images">
                        <div class="d-flex justify-content-center ">
                            <div class="position-relative">
                                <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
                                <img *ngIf="image.isAnimated" src="assets/icons/shuffle.svg" alt="animatedImage"
                                    class="position-absolute bg-white rounded-5 p-1 "
                                    style="top: 1px; right: 2px; height: 20px">
                            </div>

                        </div>
                        <div class="small text-center">Stellcode: {{image.stellcode}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noActiveRouteStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
</ng-template>