<ng-container *ngIf="deviceParameter">
    <h6 class="text-secondary">Digitale Outputs</h6>
    <div class="row mt-4">

        <mat-slide-toggle *ngIf="deviceParameter.io[0] !== undefined" class="mb-3"
            [checked]="deviceParameter!.io[0].status === 'high'"
            (change)="onDigitalOutputsToggleValueChange(6, $event.checked)" color="primary" style="display: none;">
            Digital 1 Output
        </mat-slide-toggle>
        <mat-slide-toggle *ngIf="deviceParameter!.io[1] !== undefined" class="mb-3"
            [checked]="deviceParameter!.io[1].status === 'high'"
            (change)="onDigitalOutputsToggleValueChange(7, $event.checked)" color="primary" [disabled]="">
            Digital 2 Output
        </mat-slide-toggle>
        <mat-slide-toggle *ngIf="deviceParameter!.io[2] !== undefined" class="mb-3"
            [checked]="deviceParameter!.io[2].status === 'high'"
            (change)="onDigitalOutputsToggleValueChange(8, $event.checked)" color="primary" [disabled]="">
            Radar
        </mat-slide-toggle>
        <mat-slide-toggle *ngIf="deviceParameter!.io[3] !== undefined" class="mb-3"
            [checked]="deviceParameter!.io[3].status === 'high'"
            (change)="onDigitalOutputsToggleValueChange(9, $event.checked)" color="primary" [disabled]="">
            Anna
        </mat-slide-toggle>
    </div>
    <div *ngIf="deviceParameter.io[0] === undefined " class="text-danger ms-2 ">
        Keine Outputs vorhanden </div>
</ng-container>