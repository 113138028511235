import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import {
  DataEndChannelTypeEnum,
  getDataEndChannelTypeSelect,
  getDataEndChannelTypeString
} from "../../shared/enums/data-end-channel-type.enum";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { DataEndChannelService } from "../../shared/services/data-end-channel.service";
import { DisplayService } from "../../../device/shared/services/display.service";
import { RadarService } from "../../../device/shared/services/radar.service";

@Component({
  selector: 'avs-data-end-channel-edit-modal',
  templateUrl: './data-end-channel-edit-modal.component.html',
  styleUrls: ['./data-end-channel-edit-modal.component.css']
})
export class DataEndChannelEditModalComponent implements OnInit {
  public isDataEndChannelTypeOpen: boolean = false;
  public isDeviceTypeOpen: boolean = false;
  public dataEndChannelTypeSelect: SelectInput[] = getDataEndChannelTypeSelect();
  public deviceTypeSelect: SelectInput[] = [];
  public deviceType?: SelectInput;
  public displaySelect: SelectInput[] = [];
  public radarSelect: SelectInput[] = [];
  protected readonly getDataEndChannelTypeString = getDataEndChannelTypeString;
  protected readonly DataEndChannelTypeEnum = DataEndChannelTypeEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DataEndChannel,
    private dialogRef: MatDialogRef<DataEndChannel>,
    private alertService: AlertService,
    private dataEndChannelService: DataEndChannelService,
    private displayService: DisplayService,
    private radarService: RadarService) {
  }

  public ngOnInit(): void {
    this.getDeviceTypesForSelect();
    this.getDeviceConnection();
  }

  public onDataEndChannelTypeSelectClicked(): void {
    this.isDataEndChannelTypeOpen = !this.isDataEndChannelTypeOpen;
    this.isDeviceTypeOpen = false;
  }

  public onDeviceTypeSelectClicked(): void {
    this.isDeviceTypeOpen = !this.isDeviceTypeOpen;
  }

  public onDataEndChannelTypeSelectItemClicked(item: SelectInput): void {
    if (item.id !== undefined) {
      this.data.dataEndChannelType = +(item.id);
      this.isDataEndChannelTypeOpen = false;
      this.deviceType = undefined;
      switch (item.id) {
        case 0:
          this.data.fg = 6;
          break;
        case 1:
          this.data.fg = 4;
          break;
        case 2:
          this.data.fg = 4;
          break;
        case 3:
          this.data.fg = 4;
          break;
        case 4:
          this.data.fg = 1;
          break;
        case 5:
          this.data.fg = 4;
          break;
      }  /* wieso weshalb warum? */ /* sollte aber noch editierbar sein */
    }
  }


  public onFGChange(newValue: string): void {
    this.data.fg = Number(newValue);

  }

  public onDeviceItemClicked(item: SelectInput): void {
    this.deviceType = item;
    this.isDeviceTypeOpen = false;
  }

  public onCancelBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSaveBtnClicked(): void {
    if (this.data.dataEndChannelType === DataEndChannelTypeEnum.RADAR || this.data.dataEndChannelType === DataEndChannelTypeEnum.LED ||
      this.data.dataEndChannelType === DataEndChannelTypeEnum.PW) {
      if (!this.deviceType) {
        this.alertService.alert('Kein Gerät ausgewählt', AlertTypes.warning);
        return;
      }
    }
    this.dataEndChannelService.editDataEndChannel(this.data.id, this.data).subscribe(endChannel => {
      this.alertService.alert('Datenendkanal bearbeitet', AlertTypes.info);
      if (this.data.dataEndChannelType === DataEndChannelTypeEnum.RADAR && this.deviceType?.id !== undefined) {
        this.dataEndChannelService.setRadarType(endChannel.id, this.deviceType.id).subscribe();
      } else if ((this.data.dataEndChannelType === DataEndChannelTypeEnum.PW ||
        this.data.dataEndChannelType === DataEndChannelTypeEnum.LED) && this.deviceType?.id !== undefined) {
        this.dataEndChannelService.setDisplayType(endChannel.id, this.deviceType.id).subscribe();
      }
      this.dialogRef.close(true);
    });
  }

  private getDeviceTypesForSelect(): void {
    this.displayService.getDisplayTypes().subscribe(displays => {
      for (let display of displays) {
        let select = {
          name: display.name,
          id: display.id
        } as SelectInput;
        this.displaySelect.push(select);
      }
    });
    this.radarService.getRadarTypes().subscribe(radare => {
      for (let radar of radare) {
        let select = {
          name: radar.name,
          id: radar.id
        } as SelectInput;
        this.radarSelect.push(select);
      }
    });
  }

  private getDeviceConnection(): void {
    switch (this.data.dataEndChannelType) {
      case DataEndChannelTypeEnum.Unknown:
        return;
      case DataEndChannelTypeEnum.CLUSTER:
        return;
      case DataEndChannelTypeEnum.BLINKER:
        return;
      case DataEndChannelTypeEnum.RADAR:
        this.dataEndChannelService.getDataEndChannelConection(this.data.id).subscribe(connection => {
          this.deviceType = this.radarSelect.find(d => d.id === connection.id);
        });
        break;
      case DataEndChannelTypeEnum.LED:
        this.dataEndChannelService.getDataEndChannelConection(this.data.id).subscribe(connection => {
          this.deviceType = this.displaySelect.find(d => d.id === connection.id);
        });
        break;
      case DataEndChannelTypeEnum.PW:
        this.dataEndChannelService.getDataEndChannelConection(this.data.id).subscribe(connection => {
          this.deviceType = this.displaySelect.find(d => d.id === connection.id);
        });
        break;
    }
  }
}
