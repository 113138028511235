<ng-container *ngIf="routeStationWithDeviceParam ; else noRouteStation"><!-- && projectImages.length > 0 -->
    <div class="row">
        <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-5 col-sm-7">
            <div class="card rounded-0 shadow p-3 mt-3 avs-project-station-card">
                <avs-project-route-station [routeStation]="routeStationWithDeviceParam" [images]="projectImages" [isModalOpen]="true">
                </avs-project-route-station>
            </div>
        </div>

        <div class="col-xxl-8 col-xl-7 col-lg-6 col-md-7 col-sm-5 position-relative">
            <div class="card rounded-0 shadow p-3 mt-3">
                <avs-json-data-viewer [json]="routeStationWithDeviceParam"></avs-json-data-viewer>
            </div>
            <img *ngIf="!routeStationWithDeviceParam.routeStation.groupId" src="assets/icons/exclamation-triangle.svg" class="avs-icon-arrow-right mt-5 position-absolute"
                alt="icon-arrow-right" height="62" style="top: 15px; right: 75px; cursor: pointer" accordion
                matTooltip="Datensatz unvollständig">
        </div>
    </div>
</ng-container>
<ng-template #noRouteStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
</ng-template>