<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">{{data.title}}</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg"
                alt="close-modal-icon" (click)="onCloseModalClicked()">
    </div>
    <div *ngIf="data.image" class="mt-1">
        <div class="d-flex justify-content-center">
          <img [src]="environment.apiAddress + data.image.path">
        </div>
        <div class="mt-2 text-center">Stellcode: {{data.image.stellcode}}</div>
    </div>
    <div class="d-flex justify-content-between mt-5 border-top pt-3">
        <button *ngIf="data.button_1" class="btn btn-sm btn-outline-danger rounded-0" (click)="onButton1Clicked()">
            {{data.button_1}}
        </button>
        <button *ngIf="data.button_2" class="btn btn-sm btn-outline-success rounded-0" (click)="onButton2Clicked()">
            {{data.button_2}}
        </button>
    </div>
</mat-dialog-content>
