import {Component} from '@angular/core';

@Component({
  selector: 'avs-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {


}
