import {Component, OnInit} from '@angular/core';
import {ProjectRouteStation} from "../../project/shared/interfaces/project-route-station.interface";
import {RouteStationService} from "../../project/shared/services/route-station.service";
import {DeviceDataService} from "../shared/services/device-data.service";

@Component({
  selector: 'avs-device-menu',
  templateUrl: './device-menu.component.html',
  styleUrls: ['./device-menu.component.css']
})
export class DeviceMenuComponent implements OnInit {
  public routeStations: ProjectRouteStation[] | undefined;
  public activeRouteStation: ProjectRouteStation | undefined;
  public searchInput: string = "";
  public filteredStations: ProjectRouteStation[] = [];

  constructor(private readonly deviceDataService: DeviceDataService,
              private routeStationService: RouteStationService) {
    deviceDataService.routeStations.subscribe(stations => {
      this.routeStations = stations;
    });
    deviceDataService.activeRouteStation.subscribe(activeStation => {
      this.activeRouteStation = activeStation;
    });
  }

  ngOnInit() {
    this.getRouteStations();
  }

  public onSearchInputChanged(event: string): void {
    this.searchInput = event;
    this.filterRouteStations();
  }

  public onRouteStationClicked(routeStation: ProjectRouteStation): void {
    if (this.activeRouteStation === routeStation) {
      this.deviceDataService.updateActiveRouteStation(undefined);
    } else {
      this.deviceDataService.updateActiveRouteStation(routeStation);
    }
  }

  private getRouteStations(): void {
    this.routeStationService.getRouteStations().subscribe(stations => {
      this.deviceDataService.updateRouteStations(stations);
    });
  }

  private filterRouteStations(): void {
    if (this.routeStations) {
      this.filteredStations = this.routeStations.filter(station =>
        station.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        station.samId.toString().includes(this.searchInput.toLowerCase())
      );
    }
  }
}
