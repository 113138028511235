import { Component, Input } from '@angular/core';
import { DeviceParameter } from 'src/app/device/shared/interfaces/device-parameter.interface';
import { ProjectRouteStation } from '../../shared/interfaces/project-route-station.interface';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { Subscription } from 'rxjs';
import { DataEndChannel } from '../../shared/interfaces/data-end-channel.interface';
import { ProjectDataService } from '../../shared/services/project-data.service';

@Component({
  selector: 'avs-test-area-smart-micro',
  templateUrl: './test-area-smart-micro.component.html',
  styleUrls: ['./test-area-smart-micro.component.css']
})
export class TestAreaSmartMicroComponent {
  @Input() deviceParameter?: DeviceParameter;
  @Input() activeStation?: ProjectRouteStation;
  public dataEndChannelsSubscription?: Subscription;
  public isKanalDropdownOpen: boolean = false;
  public selectedKanal: SelectInput = { name: 'Kanal', id: 0 }
  public selectKanalInput: SelectInput[] = [];
  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }];
  public selectedFunction: SelectInput = { name: "Funktion" };
public isFunctionSelected: boolean = false;

  constructor(private deviceService: DeviceService,
    private alertService: AlertService,
    public projectDataService: ProjectDataService
  ) {
    this.dataEndChannelsSubscription = projectDataService.dataEndChannels.subscribe(endChannels => {
      this.getKanalSelect(endChannels)

    });
  }

  public onChannelDropdownSyncStellcodeValueChange(selectItem: SelectInput): void {
    this.selectedKanal = selectItem;
    this.isKanalDropdownOpen = false
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    this.isFunctionSelected = true;

  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onSaveBtnClicked(): void {
    this.deviceService.learnSmartMicro(this.activeStation?.id!, 11, this.selectedFunction.id as number).subscribe(() => {
      this.alertService.alert("Erfolgreich gespeichert", AlertTypes.success)
    })
  }

  private getKanalSelect(endChannels: DataEndChannel[]): void {
    this.selectKanalInput = [];
    for (let filteredChannel of endChannels.filter(e => e.localbusAddress === 11)) {
      let selectInput = {
        name: 'Kanal ' + filteredChannel.de,
        id: filteredChannel.de,
        dataEndChannelType: filteredChannel.dataEndChannelType
      } as SelectInput
      this.selectKanalInput.push(selectInput);
    }
  }
}
