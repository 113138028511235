<h5 class="text-secondary p-2 ms-1 me-1 border-2 border-bottom text-center">Datenendkanal hinzufügen</h5>
<div class="p-2">
    <div class="d-flex">
        <div class="w-100">
            <div class="ms-2 mb-1 pe-4">Datenendkanal Typ<span class="text-danger ms-1">*</span></div>
            <div [ngClass]="isDataEndChannelTypeOpen ? 'border-danger' : ''" class="border d-flex justify-content-between pb-1"
                 role="button" (click)="onDataEndChannelTypeSelectClicked()">
                <div [ngClass]="!dataEndChannel.dataEndChannelType ? 'ms-2 p-1 text-secondary' : 'ms-2 p-1'">
                    {{getDataEndChannelTypeString(dataEndChannel.dataEndChannelType)}}
                </div>
                <img *ngIf="!isDataEndChannelTypeOpen" class="me-2" src="assets/icons/caret-down.svg" alt="caret-down open select">
                <img *ngIf="isDataEndChannelTypeOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
            </div>
            <avs-dropdown *ngIf="isDataEndChannelTypeOpen"
                          [isHeaderActive]="false"
                          (onItemClicked)="onDataEndChannelTypeSelectItemClicked($event)"
                          [selectInput]="dataEndChannelTypeSelect"
                          [activeItem]="{name: getDataEndChannelTypeString(dataEndChannel.dataEndChannelType), id: dataEndChannel.dataEndChannelType}">
            </avs-dropdown>
        </div>
        <avs-text-input class="ms-2 w-25"
                        label="fg"
                        [value]="dataEndChannel.fg"
                        [disabled]="true">
        </avs-text-input>
    </div>
    <div *ngIf="dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.PW || dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.LED">
        <div [ngClass]="isDeviceTypeOpen ? 'border-danger' : ''" class="border d-flex justify-content-between mt-2"
             role="button" (click)="onDeviceTypeSelectClicked()">
            <div [ngClass]="deviceType ? 'ms-2 p-1' : 'ms-2 p-1 text-secondary'">
                {{deviceType ? deviceType.name : 'auswählen...'}}
            </div>
            <img *ngIf="!isDeviceTypeOpen" class="me-2" src="assets/icons/caret-down.svg" alt="caret-down open select">
            <img *ngIf="isDeviceTypeOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
        </div>
        <avs-dropdown *ngIf="isDeviceTypeOpen" (onItemClicked)="onDeviceItemClicked($event)"
                      [isHeaderActive]="false"
                      [selectInput]="displaySelect"
                      [activeItem]="deviceType">
        </avs-dropdown>
    </div>
    <div *ngIf="dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.RADAR">
        <div [ngClass]="isDeviceTypeOpen ? 'border-danger' : ''" class="border d-flex justify-content-between mt-2"
             role="button" (click)="onDeviceTypeSelectClicked()">
            <div [ngClass]="deviceType ? 'ms-2 p-1' : 'ms-2 p-1 text-secondary'">
                {{deviceType ? deviceType.name : 'auswählen...'}}
            </div>
            <img *ngIf="!isDeviceTypeOpen" class="me-2" src="assets/icons/caret-down.svg" alt="caret-down open select">
            <img *ngIf="isDeviceTypeOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
        </div>
        <avs-dropdown *ngIf="isDeviceTypeOpen" (onItemClicked)="onDeviceItemClicked($event)"
                      [isHeaderActive]="false"
                      [selectInput]="radarSelect"
                      [activeItem]="deviceType">
        </avs-dropdown>
    </div>
    <div class="text-center mt-4">
        <span class="avs-more-settings" (click)="onExpandedSettingsClicked()">
            {{isExpandedSettingsOpen ? 'Einstellungen einklappen' : 'weitere Einstellungen'}}</span>
    </div>
    <div *ngIf="isExpandedSettingsOpen">
        <div class="mt-1" >
            <avs-text-input label="Datenendkanal"
                            [value]="dataEndChannel.de"
                            (valueChange)="dataEndChannel.de = +$event">
            </avs-text-input>
        </div>
        <div class="mt-3">
            <avs-text-input label="Localbus Adresse"
                            [value]="dataEndChannel.localbusAddress"
                            (valueChange)="dataEndChannel.localbusAddress = +$event">
            </avs-text-input>
        </div>
        <div class="mt-3 mb-2">
            <avs-text-input label="Eakanal-Nr."
                            [value]="dataEndChannel.eakanalnr"
                            (valueChange)="dataEndChannel.eakanalnr = +$event">
            </avs-text-input>
        </div>
    </div>
</div>
<div class="p-2 ms-1 me-1 border-2 border-top">
    <div class="d-flex justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" type="button"
                (click)="onCancelBtnClicked()">Abbrechen</button>
        <button class="btn btn-sm btn-outline-success rounded-0"
                type="button" (click)="onSaveBtnClicked()">Speichern</button>
    </div>
</div>
