import {JwtTokenService} from '../../core/shared/jwt-token.service';
import {UserDataResponse} from '../shared/interfaces/login-data-response.interface';
import {LoginData} from '../shared/interfaces/login-data.interface';
import {Component} from '@angular/core';
import {LoginService} from '../shared/services/login.service';
import {Router} from '@angular/router';
import {AlertService} from "../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../shared/alert/shared/alert-types";
import {UserDataService} from "../shared/services/user-data.service";

@Component({
  selector: 'avs-login-overview',
  templateUrl: './login-overview.component.html',
  styleUrls: ['./login-overview.component.css']
})
export class LoginOverviewComponent {
  public loginData: LoginData = {email: "", password: ""};
  public isLoading: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private jwtTokenService: JwtTokenService,
    private alertService: AlertService,
    private readonly userDataService: UserDataService) {}

  public onLoginButtonClicked(): void {
    this.isLoading = true;
    this.loginService.login(this.loginData).subscribe((userData: UserDataResponse) => {
      this.jwtTokenService.setJwtToken(userData.jwtToken);
      this.userDataService.updateUserData(userData);
      this.isLoading = false;
      this.alertService.alert('Erfolgreich angemeldet', AlertTypes.success);
      this.router.navigate(['/']);
    },() => {
      this.isLoading = false;
      this.alertService.alert('Daten sind falsch!', AlertTypes.error)
    });
  }

  public onPasswordForgotClicked(): void {
    if (this.loginData.email) {
      this.loginService.forgotPassword(this.loginData.email).subscribe(result => {
      });
    } else {
      this.alertService.alert('Bitte geben Sie Ihre E-Mail ein!', AlertTypes.error)
    }
  }
}

