import { ManualControl } from "src/app/project/shared/interfaces/manual-control.interface"; 
import { ProjectRouteStationWithParam } from "src/app/project/shared/interfaces/project-route-station.class"; 

export class SchemaLine {
    areaA: SchemaLineWithPosition[];
    areaB: SchemaLineWithPosition[];

    constructor(areaA: SchemaLineWithPosition[], areaB: SchemaLineWithPosition[]) {
        this.areaA = areaA;
        this.areaB = areaB;
    }
}

export interface SchemaLineWithPosition {
    station: ProjectRouteStationWithParam[] | undefined;
    x: number;
    isActive: boolean;
    line: number[];
    colorArr: string[];
    piktogramm?: string,
    isHidden?: boolean, /* dann müsste aber eine station vorhanden sein, oder ich setze die auf undefined */
   /*  manualControls?: ManualControl[] | undefined;  *//*  */
}


/* 

Entweder ich packe in jedes scenario separate lines 


oder ich gebe in die modular route station ein nullable manualcontrol mit rein, welches dann quasi die einstellung mit dem manual control überschreibt

das geht aber nicht, da ich in den modular route stations mehrere dataendchannels habe...

dann muss ich also

ein array von manualcontrols an die avs modular route station geben und dann für jeden de kanal diese entscheidung treffen




*/