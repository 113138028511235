export interface ProjectMetaData {
  name: string;
  timeSlotFrom: string;
  timeSlotTo: string;
  notes: string;
  isActiveChange: boolean;
}

export enum ProjectMetaDataTypes {
  name,
  timeSlotFrom,
  timeSlotTo,
  notes,
  isActive
}
