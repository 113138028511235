import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/shared/http.service';
import { MQResult } from '../interfaces/mqresult.interface';

@Injectable({
  providedIn: 'root'
})
export class MQResultService {

  constructor(private readonly httpService: HttpService) { }

  public getMQResult(filter: MQResult): Observable<MQResult> {
    return this.httpService.post<MQResult>('MQResult/GetByFilter', filter);
    /* Group_ID: + Count sollten zum Filtern verwendet werden ansonsten overload; ohne body funktioniert es gar nicht */
  }

  public getMQResultByGroupId(groupId: number): Observable<MQResult[]> {
    return this.httpService.get<MQResult[]>('MQResult/GetLastResultsByGroupId/' + groupId);
  }
}