import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Fg1interface } from './interfaces/fg1interface';
import { Fg4interface } from './interfaces/fg4interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { ImageDataService } from 'src/app/project/shared/services/image-data.service';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { ProjectService } from 'src/app/project/shared/services/project.service';

interface Image {
  value: number;
  viewValue: string;
}

interface Funktion {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'avs-data-end-channel-settings-dialog',
  templateUrl: './data-end-channel-settings-dialog.component.html',
  styleUrls: ['./data-end-channel-settings-dialog.component.css']
})
export class DataEndChannelSettingsDialogComponent implements OnInit {
  public jsonObj?: any;
  public fg1Obj?: Fg1interface;
  public fg4Obj?: Fg4interface;
  public guid?: string;
  public type?: string;
  public projectImages?: ProjectImageData[];
  public projectId?: number;
  public information: string = ''
  public functions: Funktion[] = [
    { value: 0, viewValue: 'Aus' },
    { value: 1, viewValue: 'An' },
    { value: 2, viewValue: 'Blinkend' },
  ];
  public images: Image[] = [{ value: 61, viewValue: "Blinker(61)" }];

  constructor(
    private dialogRef: MatDialogRef<DataEndChannelSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService
  ) { }

  public ngOnInit(): void {
    this.jsonObj = JSON.parse(this.data.data)
    if (this.data.fg === 1) { this.fg1Obj = this.jsonObj?.EngineParameter }
    if (this.data.fg === 4) { this.fg4Obj = this.jsonObj?.EngineParameter }

    this.guid = this.data.guid;
    this.type = this.data.type;
    this.projectId = this.data.projectId;
    if (this.type === 'LED-Anzeige' || this.type === 'Prismenwender') this.projectService.getAllRouteStationImagesWithStationId(this.projectId!, this.guid!).subscribe(images => { this.projectImages = images; this.filterImagesByGuid() });
  }

  public checkForImages() {
    let danger = this.projectImages?.find((element: ProjectImageData) => { return element.stellcode === this.fg4Obj?.DangerCode });
    let free = this.projectImages?.find((element: ProjectImageData) => { return element.stellcode === this.fg4Obj?.FreeCode });
    let jam = this.projectImages?.find((element: ProjectImageData) => { return element.stellcode === this.fg4Obj?.JamCode });

    if (danger === undefined || free === undefined || jam === undefined) {
      this.information = 'Bilderkatalog unvollständig, bitte prüfen'
    }
  }

  private filterImagesByGuid() {
    this.images = [];
    this.projectImages?.forEach(element => this.images.push({ value: element.stellcode, viewValue: String(element.stellcode) }))
    this.checkForImages()
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onSubmitClick(): void {
    if (this.data.fg === 1)
      this.dialogRef.close({ settings: this.fg1Obj, fg: 1, type: this.type }); /* oder sogar type mitgeben?  fg wieder löschen?*/
    if (this.data.fg === 4)
      this.dialogRef.close({ settings: this.fg4Obj, fg: 4, type: this.type });
  }

  dangerChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.DangerTime! = 0;
    }
  }

  freeChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.FreeTime = 0;
    }
  }
  jamChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.JamTime = 0;
    }
  }
  v100ChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.V100Time = 0;
    }
  }
  v120ChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.V120Time = 0;
    }
  }
  v60ChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.V60Time = 0;
    }
  }
  v80ChangeFn(event: any) {
    if (this.fg4Obj) {
      this.fg4Obj.V80Time = 0;
    }
  }

}
