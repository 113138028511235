export enum LocalBusTypeEnum {

    TSS_Schild = 1,
    Chainzone = 2,
    ASIM_ADEC_VIAFALCON = 3,
    /* EMPTY SLOT */
    DynaRoads = 5,
    STM32_IO_App = 6,
    RUT_GPS = 7,
    Prismenwender = 8,
    Swarco_Anhänger = 9,
    Solar_Regler_App = 10,
    SmartMicro = 11,
}


export function getLocalBusTypeString(type: LocalBusTypeEnum): string {
    switch (type) {
        case LocalBusTypeEnum.TSS_Schild:
            return 'TSS';
        case LocalBusTypeEnum.Chainzone:
            return 'Chainzone';
        case LocalBusTypeEnum.ASIM_ADEC_VIAFALCON:
            return 'ASIM/ADEC/VIAFALCON';
        case LocalBusTypeEnum.SmartMicro:
            return 'SmartMicro';
        case LocalBusTypeEnum.DynaRoads:
            return 'DnyaRoads';
        case LocalBusTypeEnum.STM32_IO_App:
            return 'STM';
        case LocalBusTypeEnum.RUT_GPS:
            return 'GPS';
        case LocalBusTypeEnum.Prismenwender:
            return 'Prismenwender';
        case LocalBusTypeEnum.Swarco_Anhänger:
            return 'Swarco Anhänger';
        case LocalBusTypeEnum.Solar_Regler_App:
            return 'Solar Regler';
        default:
            return 'Unbekannt';
    }
}
