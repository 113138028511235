import { Component, OnInit } from '@angular/core';
import { AlertTypes } from "../../shared/alert/shared/alert-types";
import { AlertService } from "../../shared/alert/shared/alert.service";
import { CompanyData } from "../../company/shared/interfaces/company-data.interface";
import { CreateUser } from "../shared/interfaces/create-user.interface";
import { UserService } from "../shared/services/user.service";
import { CompanyService } from "../../company/shared/services/company.service";
import { SelectInput } from "../../shared/dropdown/shared/select-input.class";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleData } from "../shared/interfaces/role-data.interface";
import { UserDataResponse } from "../shared/interfaces/login-data-response.interface";
import { EditUser } from "../shared/interfaces/edit-user.interface";
import { UserDataTypes } from "../shared/enums/user-data-types.enum";
import { UserProjects } from "../shared/interfaces/user-projects.interface";
import { ModalService } from "../../shared/modal/shared/modal.service";
import { MatDialog } from "@angular/material/dialog";
import { UserProjectAddModalComponent } from "../user-project-add-modal/user-project-add-modal.component";


@Component({
  selector: 'avs-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {
  public isCompanyDropdownOpen: boolean = false;
  public isRoleDropdownOpen: boolean = false;
  public isCardChanged: boolean = false;
  public companySelectInput: SelectInput[] = [];
  public selectedCompany?: SelectInput;
  public roleSelectInput: SelectInput[] = [];
  public selectedRole: RoleData | undefined;
  public userId: number | undefined;
  public assignedProjects: UserProjects[] | undefined;
  public userData: CreateUser = {
    title: "", firstName: "", lastName: "", password: "", confirmPassword: "",
    email: "", company: 0
  } as CreateUser
  private roleData: RoleData[] = [];
  private assignedRole: number = 0;
  private editUserData: EditUser | undefined;
  private companies: CompanyData[] = [];


  protected readonly UserDataTypes = UserDataTypes;

  constructor(private alertService: AlertService,
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    public dialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.getData();
  }

  public onBackIconClicked(): void {
    this.router.navigate(['user/']);
  }

  public onCompanyDropdownValueChange(newValue: SelectInput): void {
    this.selectedCompany = newValue;
    if (newValue.id) {
      this.userData.company = +newValue.id;
    }
    this.isCompanyDropdownOpen = false;
    this.isCardChanged = true;
  }

  public onRoleDropdownValueChange(newValue: SelectInput): void {
    this.selectedRole = this.roleData.find((i => i.name === newValue.name && i.id === newValue.id))
    this.isRoleDropdownOpen = false;
    this.isCardChanged = true;
  }

  public onCompanyDataValueChange(newValue: any, type: UserDataTypes): void {
    switch (type) {
      case UserDataTypes.title:
        this.userData.title = newValue;
        break;
      case UserDataTypes.firstName:
        this.userData.firstName = newValue;
        break;
      case UserDataTypes.lastName:
        this.userData.lastName = newValue;
        break;
      case UserDataTypes.password:
        this.userData.password = newValue;
        break;
      case UserDataTypes.confirmPassword:
        this.userData.confirmPassword = newValue;
        break;
      case UserDataTypes.email:
        this.userData.email = newValue;
        break;
    }
    this.isCardChanged = true;
  }

  public onCompanyDropdownClicked(): void {
    this.isCompanyDropdownOpen = !this.isCompanyDropdownOpen;
  }

  public onRoleDropdownClicked(): void {
    this.isRoleDropdownOpen = !this.isRoleDropdownOpen;
  }

  public onSaveCardBtnClicked(): void {
    if (this.userId) {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  public onAddProjectBtnClicked(): void {
    this.addProjects();
  }

  public onDeleteBtnClicked(projectId: number): void {
    this.deleteProject(projectId);
  }

  private deleteProject(projectId: number): void {
    this.modalService.openDialog('Das Projekt vom Benutzer entfernen?', 'Nein', 'Ja')
      .subscribe(() => {
        if (this.userId) {
          this.userService.editUserProject(this.userId, [], [projectId])
            .subscribe(() => {
              this.getProjectsAssignedToUser();
            })
        }
      });
  }

  private addProjects(): void {
    const dialogRef = this.dialog.open(UserProjectAddModalComponent, { data: this.assignedProjects });
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.userId) {
        this.userService.editUserProject(this.userId, [result.id], [])
          .subscribe(projects => {
            this.getProjectsAssignedToUser();
          })
      }
    });
  }

  private updateUser(): void {
    this.setUpdateUserData();
    if (!this.validUserData()) {
      return;
    }

    if (this.editUserData) {
      this.userService.editUserData(this.userId, this.editUserData).subscribe(updatedUser => {
        this.userService.assignRoleToUser(updatedUser.id, this.assignedRole).subscribe(() => {
          this.alertService.alert('Die Änderungen wurden erfolgreich gespeichert', AlertTypes.success);
          this.isCardChanged = false;
          this.router.navigate(['user/']);
        })
      })
    }
  }

  private setUpdateUserData() {
    this.assignedRole = this.selectedRole ? this.selectedRole.id : 2;

    this.editUserData = {
      firstName: this.userData.firstName,
      lastName: this.userData.lastName,
      title: this.userData.title,
      email: this.userData.email,
      newPassword: this.userData.password,
      company: this.userData.company
    } as EditUser
  }
  /* old password leerer string -> newPassword */
  private getUserId(): void {
    this.activatedRoute.params.subscribe(params => {
      this.userId = params['id'];
    });
  }

  private getUserData(): void {
    if (this.userId) {
      this.userService.getUserById(this.userId).subscribe(userData => {
        this.setCurrentUserData(userData);
      });
    }
  }

  private setCurrentUserData(userData: UserDataResponse): void {
    this.selectedCompany = this.companySelectInput.find(i => i.id === userData.company);

    this.userData.firstName = userData.firstName;
    this.userData.lastName = userData.lastName;
    this.userData.title = userData.title;
    this.userData.email = userData.email;
    this.selectedRole = this.roleData.find((i => i.id === userData.role));
    if (this.selectedCompany)
      this.onCompanyDropdownValueChange({ name: this.selectedCompany.name, id: this.selectedCompany.id })
  }

  private getCompanyData(): void {
    this.companyService.getCompanys().subscribe(companyData => {
      this.companies = companyData;
      this.setCompanySelectInput(companyData);
      this.getUserId();
      if (this.userId) {
        this.getUserData();
        this.getProjectsAssignedToUser();
      }
    });
  }

  private getAllRoles() {
    let roleData = [{ name: 'Admin', id: 1 },
    { name: 'User', id: 2 },
    { name: 'Extern', id: 3 },
    { name: 'Monteur', id: 4 },
    { name: 'AdminReadOnly', id: 5 },
    { name: 'UserReadOnly', id: 7 },
    ]
    this.setRoleSelectInput(roleData);

  }

  private getProjectsAssignedToUser(): void {
    this.userService.getProjectsAssignedToUser(this.userId).subscribe(assignedProjects => {
      this.assignedProjects = assignedProjects;
    })
  }

  private setCompanySelectInput(companyData: CompanyData[]): void {
    this.companySelectInput = [];
    for (let company of companyData) {
      let selectInput = {
        name: company.companyName,
        id: company.id
      } as SelectInput;
      this.companySelectInput.push(selectInput);
    }
  }

  private setRoleSelectInput(roleData: RoleData[]): void {
    this.roleData = roleData;
    for (let role of this.roleData) {
      let selectInput = {
        name: role.name,
        id: role.id
      } as SelectInput;
      this.roleSelectInput.push(selectInput);
    }
  }

  /*  private checkPasswordLength(): void {
       if (this.userData.password.length <= 6) {
         this.alertService.alert("Das Passwort sollte mindestens 6 Zeichen besitzen.", AlertTypes.error);
       }
   } */

  private validatePassword(): boolean {
    if (this.userData.password === this.userData.confirmPassword) {
      return true;
    } else {
      this.alertService.alert("Die Passwörter stimmen nicht überein.", AlertTypes.error);
      return false;
    }
  }

  private createUser(): void {
    this.assignedRole = this.selectedRole ? this.selectedRole.id : 2;
    if (!this.validUserData()) {
      return;
    }

    this.userService.createUser(this.userData).subscribe(newUser => {
      this.userService.assignRoleToUser(newUser.id, this.assignedRole).subscribe((x) => {
        this.alertService.alert('Der Benutzer wurde erfolgreich erstellt', AlertTypes.success);
        this.isCardChanged = false;
        this.userId = x.id;
      })
    })
  }

  private validUserData(): boolean {
    if (!this.userData.firstName || !this.userData.lastName || !this.userData.title || !this.userData.email || !this.userData.company) {
      this.alertService.alert('Es müssen alle Felder ausgefüllt werden', AlertTypes.error);
      return false;
    } else {
      return true;
    }
  }

  private getData(): void {
    this.getCompanyData();
    this.getAllRoles()

  }
}
