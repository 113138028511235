import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";

export enum EvaCommandEnum {
    Unknown,
    EVA_ON,
    EVA_OFF,
    EVA_RESET,
    RUT_REBOOT
}

export function getEvaCommandString(type: EvaCommandEnum): string {
    switch (type) {
        case EvaCommandEnum.EVA_ON:
            return 'Eva einschalten';
        case EvaCommandEnum.EVA_OFF:
            return 'Eva ausschalten';
        case EvaCommandEnum.EVA_RESET:
            return 'Eva-Reset';
        case EvaCommandEnum.RUT_REBOOT:
            return 'Router-Reboot';
        case EvaCommandEnum.Unknown:
            return 'Sonstiges';
        default:
            return 'Unbekannt';
    }
}

export function getEvaCommandSelect(): SelectInput[] {
    return [
        { name: getEvaCommandString(EvaCommandEnum.EVA_ON), id: 1 },
        { name: getEvaCommandString(EvaCommandEnum.EVA_OFF), id: 2 },
        { name: getEvaCommandString(EvaCommandEnum.EVA_RESET), id: 3 },
        { name: getEvaCommandString(EvaCommandEnum.RUT_REBOOT), id: 4 },
        { name: getEvaCommandString(EvaCommandEnum.Unknown), id: 0 },
    ];
}