<ng-container *ngIf="routeStationWithDeviceParam ; else noRouteStation"><!-- && projectImages.length > 0 -->
    <div class="row">
        <div>


            <div>
                <div class="card rounded-0 shadow mt-4 p-2" *ngIf="routeStationWithDeviceParam.routeStation.routeStationNotes.length === 0">
                    <div class="fw-semibold">RouteStation Notizen zu: <span
                            class="text-danger">{{routeStationWithDeviceParam.routeStation.name}}</span></div>
                    <div class="p-3">
                        <textarea [(ngModel)]="note" class="avs-textarea-notes w-100 h-auto form-control rounded-0"
                            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                            placeholder="Keine Notizen..." (keydown)="onKeyDownCreateSingleNote($event)">
                        </textarea>
                    </div>
                    <div class="actionbar">
                        <button class="btn btn-outline-success rounded-0 me-3" type="button"
                            (click)="onSaveSingleNotes()" *ngIf="note !== ''">Speichern</button>
                    </div>
                </div>
                <div>
                    <div class="card rounded-0 shadow mt-4 p-2" *ngIf="latestNote">
                        <div class="d-flex justify-content-between">
                            <div class="fw-semibold">RouteStation Notizen zu: <span
                                    class="text-danger">{{routeStationWithDeviceParam.routeStation.name}}</span></div>
                            <div class="d-flex justify-content-end  ">
                                <span *ngIf="latestNote.createdAt === latestNote.lastChangedAt">
                                    Erstellt am: {{ latestNote.createdAt | date: 'dd.MM.yyyy - H:mm'}}</span>
                                <span *ngIf="latestNote.createdAt !== latestNote.lastChangedAt">
                                    Bearbeitet am: {{latestNote.lastChangedAt | date: 'dd.MM.yyyy - H:mm'}}</span>
                            </div>
                        </div>
                        <div class="p-3">
                            <textarea [ngModel]="latestNote.note"
                                class="avs-textarea-notes w-100 h-auto form-control rounded-0"
                                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                                placeholder="Keine Notizen..." (ngModelChange)="onChangeLatestNote($event, latestNote)">

        </textarea>
                        </div>
                        <div class="actionbar">
                            <button *ngIf="latestNote.noteChanged" class="btn btn-outline-success rounded-0 me-3"
                                type="button" (click)="onEditSingleNote(latestNote.id!)">Bearbeiten</button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noRouteStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
</ng-template>