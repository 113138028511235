<div [class]="classItem">
  <label *ngIf="label" class="form-label ms-2 mb-1" [class]="classInputLabel">
    {{label}}
    <span *ngIf="required" class="text-danger">*</span>
  </label>

  <span class="col-1 d-flex align-items-center text-danger"> 
    <div *ngIf="min">
      <span *ngIf="input.value < min">
        Wert zu niedrig
      </span>
    </div>
    <div *ngIf="max">
      <span *ngIf="input.value > max">
        Wert zu hoch
      </span>
    </div>
  </span>

  <input [placeholder]="placeholder" class="form-control rounded-0" [ngClass]="disabled ? 'text-secondary' : ''" [type]="type" (change)="onValueChange($event)"
    [required]="required" [disabled]="disabled" #input="ngModel"
    [ngModel]="isDateString ? (value | date:'dd.MM.YYYY') : value" [class]="classInputLabel + classInputOnly" [min]="min"
    [max]="max">
  <span class="col-1 d-flex align-items-center " *ngIf="measurement !== ''"> {{ measurement }}

  </span>
  <span *ngIf="!input.control.valid && input.touched && !disabled" class="text-danger">
    <span *ngIf="label"> "{{label}}" ist ein Pflichtfeld! </span>
    <span *ngIf="!label"> "Das ist ein Pflichtfeld! </span>
  </span>

</div>