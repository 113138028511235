import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectGroup } from 'src/app/project/shared/interfaces/project-group.interface';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'avs-group-stellcode-delete-dialog',
  templateUrl: './group-stellcode-delete-dialog.component.html',
  styleUrls: ['./group-stellcode-delete-dialog.component.css']
})

export class GroupStellcodeDeleteDialogComponent implements OnInit {

  public activeProject?: Project;
  public isGroupDropdownOpen: boolean = false;
  public groupSelect: SelectInput[] = [];
  public selectedGroup: SelectInput = { name: "Gruppe", id: 0 };
  public selectedImage?: ProjectImageData;
  public allImages: ProjectImageData[] = [];
  public filteredImages: ProjectImageData[] = [];
  public assignedImages: ProjectImageData[] = [];
  public images: ProjectImageData[] = [];
  protected readonly environment = environment;
  constructor(private dialogRef: MatDialogRef<GroupStellcodeDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public ngOnInit(): void {
    this.images = this.data.assignedImages;
    this.allImages = this.data.assignedImages;
    this.activeProject = this.data.data;
    this.getGroupSelect();
    this.rotateImagesPaths(this.images);
  }
  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) {
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]);
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
  }

  public getPathByImageId(imageId: number) {
    if (this.images) {
      let imageObject = this.allImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }
  public getGroupSelect() {
    this.activeProject!.groups.forEach((element: ProjectGroup) => {
      this.groupSelect?.push({ name: element.name, id: element.id })
    })
  }

  public onGroupDropdownClicked(): void {
    this.isGroupDropdownOpen = !this.isGroupDropdownOpen;
  }

  public onGroupItemClicked(item: SelectInput): void {
    this.isGroupDropdownOpen = false;
    this.selectedGroup.id = item.id;
    this.selectedGroup.name = item.name
  }

  public onProjectImageClicked(image: ProjectImageData) {
    if (this.images.length > 1) { this.allImages = this.images }
    if (this.images) {
      if (this.images.length > 1) {
        this.selectedImage = image
        this.images = [];
        this.images.push(image)
      }
      else if (this.images.length === 1) {
        this.images = this.allImages
      }
    }
  }

  public onSubmitModalClicked(): void {
    this.dialogRef.close({ group: this.selectedGroup, image: this.selectedImage });
  }

  public onCancelClicked(): void {
    this.dialogRef.close();
  }
}
