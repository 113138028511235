import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStation } from "../../project/shared/interfaces/project-route-station.interface";
import { DeviceDataService } from "../shared/services/device-data.service";
import { DataEndChannel } from "../../project/shared/interfaces/data-end-channel.interface";
import { RouteStationService } from "../../project/shared/services/route-station.service";
import { ProjectGroup } from "../../project/shared/interfaces/project-group.interface";
import { GroupService } from "../../project/shared/services/group.service";
import { DataEndChannelError } from "../../project/shared/interfaces/data-end-channel-error.interface";
import { DeviceService } from "../shared/services/device.service";
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css']
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  public activeRouteStation: ProjectRouteStation | undefined;
  public activeGroup: ProjectGroup | undefined;
  public dataEndChannel: DataEndChannel[] = [];
  public dataEndChannelErrors: DataEndChannelError[] = [];

  private subscriptionCollection: Subscription[] = [];
  private routeStationSubscription?: Subscription;
  private dataEndChannelSubscription?: Subscription;
  private dataEndChannelErrorsSubscription?: Subscription;


  constructor(private deviceDataService: DeviceDataService,
    private stationService: RouteStationService,
    private groupService: GroupService,
    private deviceService: DeviceService,
    private alertService: AlertService) {
    this.routeStationSubscription = deviceDataService.activeRouteStation.subscribe(station => {
      this.activeRouteStation = station;
      if(station === undefined) return;
      if (station?.groupId !== null) {
        this.getGroupByStation(station!)
      }
      else {
        this.activeGroup = undefined;
      }
    });
    this.dataEndChannelSubscription = deviceDataService.dataEndChannelDevice.subscribe(dataEndChannel => {
      this.dataEndChannel = dataEndChannel;
    });
    this.dataEndChannelErrorsSubscription = deviceDataService.dataEndChannelErrors.subscribe(channelErrors => {
      this.dataEndChannelErrors = channelErrors;
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.routeStationSubscription!);
    this.subscriptionCollection.push(this.dataEndChannelSubscription!);
    this.subscriptionCollection.push(this.dataEndChannelErrorsSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onRefreshDataEndChannel(): void {
    if (this.activeRouteStation) {
      this.stationService.getDataEndChannels(this.activeRouteStation.id).subscribe(dataEndChannel => {
        this.deviceDataService.updateDataEndChannelDevice(dataEndChannel);
      });
    }
  }

  public onRefreshDataEndChannelErrors(): void {
    if (this.activeRouteStation) {
      this.deviceService.getAllChannelErrors(this.activeRouteStation.samId).subscribe(channelErrors => {
        this.deviceDataService.updateDataEndChannelErrorDevice(channelErrors);
      });
    }
  }

  private getGroupByStation(station: ProjectRouteStation): void { /* am besten sollte man in der UI anzeigen, dass es keine groupId gibt, und deswegen nicht alle parameter geladen werden können? */
    if (station.groupId === null || station.groupId === undefined || station === undefined) return;
    this.groupService.getProjectGroup(station.groupId).subscribe(group => {
      this.activeGroup = group;
    }/* ,
      (err) => { this.alertService.alert("RouteStation besitzt keine Gruppenzuweisung", AlertTypes.error) } */);
  };

}
