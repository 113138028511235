import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ProjectDataService } from '../shared/services/project-data.service';
import { Subscription } from 'rxjs';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { Project } from '../shared/interfaces/project.interface';
import { SchemaRouteStationModalComponent } from '../project-schema/schema-route-station-modal/schema-route-station-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { ProjectRouteStationWithParam } from '../shared/interfaces/project-route-station.class';
import { environment } from 'src/environments/environment';
import { ImageDataService } from '../shared/services/image-data.service';
import { ProjectImageData } from '../shared/interfaces/project-image-data.interface';
import { ProjectMarkerObject } from './shared/interfaces/project-marker-object';
import { GroupMarkerObject } from './shared/interfaces/group-marker-object';
import { RouteStationMarkerObject } from './shared/interfaces/route-station-marker-object';
import { Location } from '@angular/common';
const loader: Loader = new Loader({
  apiKey: "AIzaSyBcos3aEaS6CkhPm_2YPTrbv3f1XUH6Hf0",
  version: "weekly"
});
@Component({
  selector: 'avs-project-googlemaps',
  templateUrl: './project-googlemaps.component.html',
  styleUrls: ['./project-googlemaps.component.css']
})
export class ProjectGooglemapsComponent implements OnInit/*,  OnChanges */ { /* Brauche onChanges nicht mehr, wenn das alles in diese component gehandelt wird */

  public map?: google.maps.Map;
  public center: google.maps.LatLngLiteral = { lat: 51.030431, lng: 7.2036781 };
  public activeProject: Project | undefined;
  public activeProjects: Project[] = [];
  public projects: Project[] = [];
  public projectGroups: ProjectGroup[] = [];
  public allGroups: ProjectGroup[] = [];
  /* public groupsRouteStations: ProjectRouteStation[] = []; */
  public groupsRouteStationsWithParams: ProjectRouteStationWithParam[] | undefined;
  public filteredProjects: Project[] = [];
  public activeGroup: ProjectGroup | undefined;
  public projectPins?: ProjectMarkerObject[] = [];
  public projectMarkers: google.maps.marker.AdvancedMarkerElement[] = []; /* activeProjectMarkers and deactivatedProjectMarkers? */
  public groupPins?: GroupMarkerObject[];
  public groupMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public routeStationPins?: RouteStationMarkerObject[];
  public routeStationMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  public activeRouteStation: ProjectRouteStation | undefined;
/*   public routeStationsWithParms: ProjectRouteStationWithParam[] | undefined;
 */  public projectRouteStations: ProjectRouteStationWithParam[] | undefined;
  public projectImagesSubscription?: Subscription;
  public projectsSubscription?: Subscription;
  public groupsSubscription?: Subscription;
  public routeStationsSubscription?: Subscription;
  public routeStationsWithParamSubscription?: Subscription;
  public projectRouteStationsWithParamSubscription?: Subscription;
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public projectClusterer?: MarkerClusterer
  public apiAddress: string = environment.apiAddress;

  constructor(private readonly projectDataService: ProjectDataService,
    private dialog: MatDialog, private imageDataService: ImageDataService,
    private location: Location
  ) {
    this.projectsSubscription = projectDataService.projects.subscribe(projects => {
      console.log(projects)
      if (projects === undefined) { /*  EMPTY */ }
      else {

        this.destroyGroupMarkers();
        this.destroyRouteStationMarkers();
        this.createProjectPins(projects);
        this.projects = projects; this.filteredProjects = this.projects.filter(p => p.isActive);
        this.activeProjects = this.projects.filter(p => p.isActive);
      }
    });
    this.groupsSubscription = projectDataService.projectGroups.subscribe(groups => {
      console.log(groups, this.projectPins, this.projectMarkers)
      if (this.projectPins?.length !== this.projectMarkers.length) this.setProjectMarkers();
      if (groups.length === 0 && this.projectPins!.length > 0) { this.showProjectClusterer(true) }
      if (groups.length === 0 && this.projectPins?.length === 0) { this.createProjectPins(this.projects) }

      this.projectGroups = groups;
      if (this.routeStationMarkers.length > 0) this.destroyRouteStationMarkers();

      if (groups.length === 0) {
        this.destroyGroupMarkers();

      }
      if (groups.length > 0) {
        this.generateGroupPins(groups);
        this.showGroupBackButton(true);
      }
    });
    this.projectRouteStationsWithParamSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(routeStations => {
      console.log(routeStations)
      this.destroyRouteStationMarkers();
      if (routeStations === undefined) { this.generateGroupPins(this.projectGroups); this.showGroupBackButton(true); this.showRouteStationBackButton(false); return }
      this.groupsRouteStationsWithParams = routeStations;
      if (routeStations) /* Wenn die geladen sind, erst das zeichnen des RS-Layers starten */
        if (routeStations.length > 0) {
          this.destroyGroupMarkers();
          this.showGroupBackButton(false);
          this.generateRouteStationPins(routeStations);
          this.showRouteStationBackButton(true);
        }
    });
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.images = images;
      this.allImages = images;
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.initMap();
    }, 500);
  }

  private createProjectPins(projects: Project[]): void {
    console.log(projects)
    this.projectPins = [];
    this.projects.forEach(project => {
      this.allGroups.push(...project.groups);
    }
    )
    projects.forEach(project => {

      this.projectPins!.push({
        lat: project.latitude,
        lng: project.longitude,
        title: project.name,
        content: 'content',
        active: project.isActive,
        id: project.id
      });
    })

  }

  public generateGroupPins(groups: ProjectGroup[]): void {
    this.destroyGroupMarkers();
    this.destroyProjectMarkers();

    this.groupPins = [];
    let itemsProcessed = 0;
    groups.forEach(group => {

      this.groupPins!.push({
        lat: group.latitude,
        lng: group.longitude,
        title: group.name,
        content: 'content',
        active: group.isActive,
        id: group.id,
        street: group.routeName
      })
      itemsProcessed++;
      if (itemsProcessed === groups.length) {
        this.setGroupMarker(this.groupPins!, 'group');
      }
    }
    )
    if (groups.length > 0) {
      let projectId = groups[0].projectId;
      let project = this.projects.find(p => p.id === projectId);
      let bounds = new google.maps.LatLngBounds();
      groups.forEach(g => { bounds.extend({ lat: g.latitude, lng: g.longitude }) });
      this.map?.fitBounds(bounds);

    }
    else {
      this.map?.setZoom(8);
      this.map?.setCenter(this.center);
    }
  }
  public generateRouteStationPins(routeStations: ProjectRouteStationWithParam[]): void {
    this.showGroupBackButton(false);
    this.routeStationPins = [];
    let itemsProcessed = 0;
    routeStations.forEach(routeStation => {
      console.log(routeStation)
      this.routeStationPins!.push({
        lat: routeStation.routeStation.latitude,
        lng: routeStation.routeStation.longitude,
        title: routeStation.routeStation.name,
        content: 'content',
        id: routeStation.routeStation.samId,
        stellcode: routeStation?.deviceParameter.led.stellcode,
        name: routeStation.routeStation.name,
        blinker: routeStation.deviceParameter.blinker?.indicator,
        indicatorLed: routeStation.deviceParameter.led.indicator
      })
      itemsProcessed++;
      if (itemsProcessed === routeStations.length) {
        this.setrouteStationMarkers(this.routeStationPins!, 'routeStation');
      }
    }
    )
    let bounds = new google.maps.LatLngBounds();
    routeStations.forEach(rs => { bounds.extend({ lat: rs.routeStation.latitude, lng: rs.routeStation.longitude }) });
    /* this.map?.setZoom(16); */
    this.map?.fitBounds(bounds);

  }



  public destroyGroupMarkers(): void {
    this.groupMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public destroyProjectMarkers(): void {
    this.projectMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public destroyRouteStationMarkers(): void {
    this.routeStationMarkers.forEach(marker => {
      marker.map = null;
    })
  }

  public getAllGroups() {
    this.allGroups = [];
    this.projects.forEach(project => {
      this.allGroups.push(...project.groups);
    })
  }

  async initMap() {
    /*  if (this.projectPins) { */
    loader.load().then(async () => {
      /* cleaner? */
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
      const center = { lat: 37.43238031167444, lng: -122.16795397128632 };
      this.map = new Map(document.getElementById("map") as HTMLElement, {
        zoom: 10,
        center: this.center,
        mapId: "avs-map",
      });
      if (this.projectPins!.length > 0) {
        this.setProjectMarkers();
      }
      this.addGroupBackButton();
      this.addRouteStationBackButton();
    })
  }

  enterProject(markerView: any, property: any) {
    let project = this.projects.find(p => p.id === property.id);
    if (this.activeProject === project) {
      if (this.activeGroup !== undefined) { this.projectDataService.updateActiveGroup(undefined); return }
      this.projectDataService.updateActiveProject(undefined);
    } else {
      this.projectDataService.updateActiveProject(project);
      this.map?.setCenter({ lat: project!.latitude, lng: project!.longitude });
      this.map?.setZoom(13);
    }
  }

  enterGroup(markerView: any, property: any) {
    let group = this.projectGroups.find(g => g.id === property.id);
    if (this.activeGroup === group) {
      this.projectDataService.updateActiveGroup(undefined);
    } else {
      this.projectDataService.updateActiveGroup(group);
      this.map?.setCenter({ lat: group!.latitude, lng: group!.longitude });
      this.map?.setZoom(16);
    }

  }

  enterRouteStation(markerView: any, property: any) {
    let routeStation = this.groupsRouteStationsWithParams!.find(rs => rs.routeStation.samId === property.id);



    if (this.activeRouteStation === routeStation) {
      this.projectDataService.updateActiveRouteStation(undefined);
    } else {
      this.projectDataService.updateActiveRouteStation(routeStation?.routeStation);
      /*  let routeStationWithDevice = this.projectRouteStations?.find(rs => rs.routeStation.samId === routeStation!.samId); */
      this.dialog.open(SchemaRouteStationModalComponent, { data: routeStation }).afterClosed().subscribe(
        result => {
          /*   this.projectDataService.updateActiveGroup(this.activeGroup); */

          /*    if (result && result.navigate) {
               this.navigateToRouteStation(station)
             } */
        });

    }
  }

  toggleHighlight(markerView: any, property: any) {
    if (markerView.content.classList.contains("highlight")) {
      markerView.content.classList.remove("highlight");
      markerView.zIndex = null;
    } else {
      markerView.content.classList.add("highlight");
      markerView.zIndex = 1;
    }
  }



  showProjectClusterer(bool: boolean) {
    console.log('showProjectClusterer', bool)
    if (bool) {
      if (this.projectPins?.length === this.projectMarkers.length) {

        this.projectMarkers.forEach(marker => { /* prüfen ob pins und marker selbe length haben, ansonsten erstmal die marker aus den projecten erstellen? */
          marker.map = this.map
        })
        this.projectClusterer?.addMarkers(this.projectMarkers);
      }
      else this.createProjectPins(this.projects)
    }
    else {
      this.projectClusterer?.removeMarkers(this.projectMarkers);
      this.projectMarkers.forEach(marker => {
        marker.map = null
      })
    }
  }

  setProjectMarkers() {
    if (this.map === undefined) return;

    this.projectMarkers = this.projectPins!.map((pin, i) => {
      const AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        content: this.buildContent(pin),
        position: { lat: pin.lat, lng: pin.lng },
        title: pin.title,

      });
      AdvancedMarkerElement.addListener("click", () => {
        this.enterProject(AdvancedMarkerElement, pin);
      });

      return AdvancedMarkerElement;
    });
    this.projectClusterer = new MarkerClusterer({ markers: this.projectMarkers, map: this.map });
  }

  public addGroupBackButton() {
    const backButton = document.createElement("button"); /* wird dann jedes mal ein neuer hinzugefügt */
    backButton.classList.add("group-button");
    backButton.innerHTML = "g Zurück";
    backButton.addEventListener("click", () => {
      this.destroyGroupMarkers();
      this.showProjectClusterer(true);
      this.showGroupBackButton(false);
      let bounds = new google.maps.LatLngBounds();
      this.projects.forEach(p => { bounds.extend({ lat: p.latitude, lng: p.longitude }) });
      this.map?.fitBounds(bounds);
    });
    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(backButton);
  }

  public addRouteStationBackButton() {
    const backButton = document.createElement("button"); /* wird dann jedes mal ein neuer hinzugefügt */
    backButton.classList.add("route-station-button");
    backButton.innerHTML = " r Zurück";
    backButton.addEventListener("click", () => {
      this.destroyRouteStationMarkers();
      this.generateGroupPins(this.projectGroups);
      this.showRouteStationBackButton(false);
      this.showGroupBackButton(true);
      let bounds = new google.maps.LatLngBounds();
      this.projectGroups.forEach(g => { bounds.extend({ lat: g.latitude, lng: g.longitude }) });
      this.map?.fitBounds(bounds);
    });
    this.map?.controls[google.maps.ControlPosition.TOP_LEFT].push(backButton);
  }

  public showGroupBackButton(bool: boolean) {
    if (bool) {
      let groupbutton = document.querySelector('.group-button') as HTMLElement;
      groupbutton.style.display = 'flex'
    }
    else {
      let groupbutton = document.querySelector('.group-button') as HTMLElement;
      groupbutton.style.display = 'none'
    }
  }

  public showRouteStationBackButton(bool: boolean) {
    if (bool) {
      let routeStationButton = document.querySelector('.route-station-button') as HTMLElement;
      routeStationButton.style.display = 'flex'
    }
    else {
      let routeStationButton = document.querySelector('.route-station-button') as HTMLElement;
      routeStationButton.style.display = 'none'
    }
  }

  setGroupMarker(pins: GroupMarkerObject[], markerType: string) {
    if (this.map === undefined) return;



    this.projectClusterer?.clearMarkers();
    for (let i = 0; i < pins.length; i++) {

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: pins[i].lat, lng: pins[i].lng },
        map: this.map,
        content: this.buildGroupContent(pins[i])

      });
      marker.addListener("click", () => {
        this.enterGroup(marker, pins[i]);
      });

      this.groupMarkers.push(marker);
    }
  }

  setrouteStationMarkers(pins: RouteStationMarkerObject[], markerType: string) {
    for (let i = 0; i < pins.length; i++) {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: pins[i].lat, lng: pins[i].lng },
        map: this.map,
        content: this.buildRouteStationContent(pins[i])
      });
      marker.addListener("click", () => {
        this.enterRouteStation(marker, pins[i]);
      });
      this.routeStationMarkers.push(marker);
    }
  }

  public getPathByStellcode(stellcode: number) {
    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.stellcode == stellcode)
      return imageObject?.path as string
    }
    else return
  }


  buildContent(property: ProjectMarkerObject) {
    console.log(property.groups, this.allGroups)
    property.groups = this.allGroups.filter(g => g.projectId === property.id);
    console.log(property.groups)
    let groupContent = this.groupContentInProject(property);
    const content = document.createElement("div");
    content.classList.add("property");
    if (property.active) {
      /* TODO: Gruppen auflisten ggf. Shortcut zu notizen */
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-folder-tree" title=" ${property.title}"></i>
        <span class="fa-sr-only"> ${property.title}</span>
         <div class="details">   ${property.title} </div>
    </div>
   
    <div class="group-container">
    ${groupContent}
    </div>
          `;
      return content;
    }
    else
      content.innerHTML = `
  <div class="icon">
      <i aria-hidden="true" class="fa fa-icon fa-folder-tree deactivated" title=" ${property.title}"></i>
      <span class="fa-sr-only"> ${property.title}</span>
        <div class="details">
    ${property.title}
  </div>
  </div>
  <div class="group-container">
    ${groupContent}
    </div>
        `;
    return content;
  }

  groupContentInProject(property: ProjectMarkerObject): string {
    console.log(property)
    let htmlContent = '';
    property.groups?.forEach(group => {
      htmlContent += `<span> ${group.name} </span> `
    })
    if (property.groups?.length === 0) { return '<span> Keine Gruppen </span>' }
    else return htmlContent
  }

  buildGroupContent(property: GroupMarkerObject) {
    let routeStationCount = this.routeStationcountInGroup(property)
    /* TODO: Anzahl der Geräte innerhalb der Gruppe, Straße siehe GroupSettings */


    const content = document.createElement("div");
    content.classList.add("property");
    if (property.active) {
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-folder" title="folder"></i>
        <span class="fa-sr-only">folder</span>
            <div class="details">
      ${property.title}
    </div>
         
     </div>
   <span class="group-container"> ${property.street} </span>
      <div class="group-container">
   
    ${routeStationCount}
    </div>

          `;
      return content;
    }
    else
      content.innerHTML = `
  <div class="icon">
      <i aria-hidden="true" class="fa fa-icon fa-folder deactivated" title="folder"></i>
      <span class="fa-sr-only">folder</span>
        <div class="details">
    ${property.title}
  </div>
        
   </div>
 <span class="group-container"> ${property.street} </span>
    <div class="group-container">

    ${routeStationCount}
    </div>

        `;
    return content;
  }

  public routeStationcountInGroup(property: GroupMarkerObject): string {
    let htmlContent = '';
    let routeStations = this.projectGroups.find(g => g.id === property.id)?.routeStations;
    if (routeStations) {
      if (routeStations.length > 0) {
        htmlContent += `<span> Anzahl RouteStations: ${routeStations.length}  </span> `
        return htmlContent
      }
      else return `<span> Anzahl RouteStations: ${routeStations.length}  </span> `
    }
    else return `<span> Anzahl RouteStations: 0  </span> `

  }
  buildRouteStationContent(property: RouteStationMarkerObject) {
    let path = this.getPathByStellcode(property.stellcode!);
    let fullPath = this.apiAddress + path;
    if (property.indicatorLed === 'ausschalten') {
      fullPath = location.origin + '/assets/led_default.svg'
    }
    const content = document.createElement("div");
    content.classList.add("property");
    if (path === undefined) {
      content.innerHTML = `
    <div class="icon">
        <i aria-hidden="true" class="fa fa-icon fa-map-pin" title="map-pin"></i>
        <span class="fa-sr-only">map-pin</span>
    </div>
    <div class="details">
      ${property.title}
    </div>
          `;
      return content;
    }
    else
      if (property.blinker === 'ausschalten') {
        content.innerHTML = `
<div class="details">
   <span class="text-center"> ${property.name} </span>
   
   </div>
   <span class="text-center group-container"> SAM ID: ${property.id} </span>
   <div class="d-flex" style="margin-bottom: -16px">
      <div class=" rounded-5 border border-black"
                    style="background-color: white; height: 14px; width: 14px; margin-right: 12px;padding: 10px;">

                </div>
                      <div class="rounded-5 border border-black"
                    style="background-color: white; height: 14px; width: 14px; padding: 10px;">

                </div>
                </div>
      <img style="height: 60px" src="${fullPath}">
   
        `;
        return content;
      }
    if (property.blinker === 'einschalten') {
      content.innerHTML = `
<div class="details">
   <span class="text-center"> ${property.name} </span>
   
   </div>
   <span class="text-center group-container"> SAM ID: ${property.id} </span>
   <div class="d-flex" style="margin-bottom: -16px">
      <div class=" rounded-5 border border-black"
                    style="background-color: yellow; height: 14px; width: 14px; margin-right: 12px; padding: 10px;">

                </div>
                      <div class="rounded-5 border border-black"
                    style="background-color: yellow; height: 14px; width: 14px; padding: 10px;">

                </div>
                </div>
      <img style="height: 60px" src="${fullPath}">

    `;
      return content;
    }
    if (property.blinker === null) {
      content.innerHTML = `
        <div class="details">
   <span class="text-center"> ${property.name} </span>
   
   </div>
   <span class="text-center group-container"> SAM ID: ${property.id} </span>
  
      <img style="height: 60px" src="${fullPath}">
  `}
    return content;
  }
}

/* TO
 
 
wenn blinker vorhanden und an = gelb,
wenn blinker vorhanden und aus = grau,
nicht vorhande = nicht anzeigen
  
Image Content zu Ende bauen */

/* was ist mit wechselbildern?
beim gruppen wehcsel müssen alle routeStations entfernt und dann neu gesetzt werden
 
*/

