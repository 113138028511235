import {ProjectRouteStation} from "./project-route-station.interface";
import {DeviceParameter} from "../../../device/shared/interfaces/device-parameter.interface";

export class ProjectRouteStationWithParam {
  public routeStation: ProjectRouteStation;
  public deviceParameter: DeviceParameter;

  constructor(routeStation: ProjectRouteStation, deviceParameter: DeviceParameter) {
    this.routeStation = routeStation;
    this.deviceParameter = deviceParameter;
  }
}
