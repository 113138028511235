import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'avs-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  protected readonly environment = environment;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModalComponent>) { }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public onButton1Clicked(): void {
    this.data.btn1 = true;
    this.dialogRef.close(false);
  }

  public onButton2Clicked(): void {
    this.data.btn2 = true;
    this.dialogRef.close(true);
  }
}
