import { HttpService } from '../../../core/shared/http.service';
import { Injectable } from "@angular/core";
import { Project } from "../interfaces/project.interface";
import { Observable } from "rxjs";
import { ProjectGroup } from "../interfaces/project-group.interface";
import { ProjectImageData } from "../interfaces/project-image-data.interface";
import { HttpContentTypes } from 'src/app/core/shared/http-content-types.enum';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private readonly httpService: HttpService) { }

  public getProjects(): Observable<Project[]> {
    return this.httpService.get<Project[]>('Project');
  }

  public createNewProject(newProject: Project): Observable<Project> {
    return this.httpService.post<Project>('Project', newProject);
  }

  public editProject(projectId: number, project: Project): Observable<Project> {
    return this.httpService.put('Project/' + projectId, project);
  }

  public getProjectGroups(projectId: number): Observable<ProjectGroup[]> {
    return this.httpService.get<ProjectGroup[]>('Project/' + projectId + '/Group');
  }

  public getAllUnassignedRouteStationImages(projectId: number): Observable<ProjectImageData[]> {
    return this.httpService.get<ProjectImageData[]>('Project/' + projectId + '/RouteStation/All/Image/Unassigned');
  }

  public getAllUnassignedRouteStationImagesWithStationId(projectId: number, stationId: string): Observable<ProjectImageData[]> {
    return this.httpService.get<ProjectImageData[]>('Project/' + projectId + '/RouteStation/' + stationId + '/Image/Unassigned');
  }

  public getAllRouteStationImages(projectId: number): Observable<ProjectImageData[]> { /* müsste getAllProjectImages heißen! */
    return this.httpService.get<ProjectImageData[]>('Project/' + projectId + '/RouteStation/All/Image');
  }

  public getAllRouteStationImagesWithStationId(projectId: number, stationId: string): Observable<ProjectImageData[]> {
    return this.httpService.get<ProjectImageData[]>('Project/' + projectId + '/RouteStation/' + stationId + '/Image')
  }

  public setImageForRouteStation(projectId: number, stationId: string, imageId: number, data: { path: any } = { path: null }): Observable<any> {
    return this.httpService.post('Project/' + projectId + '/RouteStation/' + stationId + '/Image/' + imageId + '/Existing', data);
  }

  public setImageForAllRouteStations(projectId: number, imageId: number, data: { path: any } = { path: null }): Observable<any> {
    return this.httpService.post('Project/' + projectId + '/RouteStation/All/Image/' + imageId, data);
  }

  public uploadCustomImageForProject(projectId: number, imageId: number, data: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', data)

    return this.httpService.post('Project/' + projectId + '/RouteStation/All/Image/' + imageId, formData, true, HttpContentTypes.formData);
  }

  public deleteImageForRouteStation(projectRouteStationImageId: number): Observable<any> {
    return this.httpService.delete('Project/RouteStation/Image/' + projectRouteStationImageId);
  }

  public getProjectsAssignedUsers(projectId: number): Observable<EditUser[]> {
    return this.httpService.get<EditUser[]>('Project/' + projectId + '/AssignedUsers')
  }

  public getProjectsAttachments(projectId: number): Observable<any> {
    return this.httpService.get('Project/' + projectId + '/Attachment');
  }

}
