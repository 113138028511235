export class ChartData {
  item1: {
    name: string,
    value: number
  }
  item2: {
    name: string,
    value: number
  }
  item3?: {
    name: string,
    value: number
  }
  item4?: {
    name: string,
    value: number
  }

  constructor(item1: {name: string, value: number}, item2: {name: string, value: number}, item3?: {name: string, value: number}, item4?: {name: string, value: number}) {
    this.item1 = item1;
    this.item2 = item2;
    this.item3 = item3;
    this.item4 = item4;
  }
}
