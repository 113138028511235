import {Injectable} from "@angular/core";
import {HttpService} from "../../../core/shared/http.service";
import {Observable} from "rxjs";
import {RadarType} from "../interfaces/radar-type.interface";

@Injectable({
    providedIn: 'root'
})
export class RadarService {
    constructor(private httpService: HttpService) {
    }

    public getRadarTypes(): Observable<RadarType[]> {
        return this.httpService.get<RadarType[]>('Radar');
    }
}