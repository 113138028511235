<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">
        
        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/person-circle-black.svg"
                alt="question-circle Icon"> Account
        </h1>



        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie Logge ich mich ein ? </h4> <button (click)="onShowAccountLogin()"> <img
                            *ngIf="!isShowAccountLogin" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowAccountLogin" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowAccountLogin">
                Zum Öffnen des AVS-Frontend Portal geben sie im Browser folgende Webadresse ein: <br>
                <a href="https://dev-nuxt.avs-digital.de/">https://dev-nuxt.avs-digital.de/</a><br>Im geöffneten
                Fenster
                (siehe Abbildung 1) können Sie sich
                anschließend
                mit der zugewiesenen E-Mail-Adresse und dem Passwort anmelden.<br>
                Die Anmeldedaten werden Ihnen per E-Mail übermittelt.
                Sie werden zur Übersichts-Seite des Portals weitergeleitet.
                <br>
                <br>
                <img style="height: 350px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 1.png"
                    alt="Referenz zu Abbildung 1">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4  mb-4">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie verwalte ich meine Daten ? </h4> <button (click)="onShowAccountData()">
                        <img *ngIf="!isShowAccountData" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowAccountData" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowAccountData"> In der oberen Rechten ecke im Portal sehen Sie ein
                Benutzer
                Logo, siehe Abbildung 11. <br>
                Da haben Sie die Möglichkeit sich aus ihren Konto auszuloggen und die Benutzer Einstellungen zu
                verwalten/ändern.
                <br>
                <br>
                <img style="height: 150px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 11.png"
                    alt="Referenz zu Abbildung 11">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4  mb-4">
            <div class="ms-3 d-flex justify-content-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie logge ich mich aus ? </h4> <button (click)="onShowAccountLogout()"> <img
                            *ngIf="!isShowAccountLogOut" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowAccountLogOut" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowAccountLogOut">In der oberen Rechten ecke sehen Sie im Portal ein
                Benutzer
                Logo, siehe Abbildung 11. <br>
                Da haben Sie die Möglichkeit sich aus ihren Konto auszuloggen und die Benutzer Einstellungen zu
                verwalten/ändern.
                <br>
                <br>
                <img style="height: 150px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 11.png"
                    alt="Referenz zu Abbildung 11">
            </div>
        </div>


    </div>
</div>