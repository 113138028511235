import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { LisaMessage } from '../../project-lisamessage/interfaces/lisa-message.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LisaMessagesService {

  constructor(private httpService: HttpService) { }

  public getAllLisaMessages(): Observable<LisaMessage[]> {
    return this.httpService.get<LisaMessage[]>('LisaMessage');
  }
}