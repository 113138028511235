import { Component } from '@angular/core';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { ProjectDataService } from '../shared/services/project-data.service';
import { MatDialog } from "@angular/material/dialog";
import { ProjectSettingsCreateModalComponent } from "../project-settings/project-settings-create-modal/project-settings-create-modal.component";
import { ProjectService } from "../shared/services/project.service";
import { SelectInput } from "../../shared/dropdown/shared/select-input.class";
import { MapHandlerService } from '../project-map/shared/map-handler.service';

@Component({
  selector: 'avs-project-menu',
  templateUrl: './project-menu.component.html',
  styleUrls: ['./project-menu.component.css']
})
export class ProjectMenuComponent {
  public projects: Project[] = [];
  public projectGroups: ProjectGroup[] = [];
  public projectRouteStations: ProjectRouteStation[] = [];
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public activeRouteStation: ProjectRouteStation | undefined;
  public filteredProjects: Project[] = [];
  public activeProjects: Project[] = [];
  public searchInput: string = "";
  public selectedProjectFilter: SelectInput = { name: "Aktive Projekte", id: 2 };
  public projectFilter: SelectInput[] = [{ name: "Alle", id: 1 }, { name: "Aktive Projekte", id: 2 }];
  public isDropdownOpen: boolean = false;

  constructor(private readonly projectDataService: ProjectDataService,
    private readonly projectService: ProjectService,
    private dialog: MatDialog,
    private mapHandler: MapHandlerService) {
    projectDataService.projects.subscribe(projects => {


/* ins die karte einbauen */
      this.projects = projects; this.filteredProjects = this.projects.filter(p => p.isActive);
      this.activeProjects = this.projects.filter(p => p.isActive);
    });





    projectDataService.projectGroups.subscribe(groups => {
      this.projectGroups = groups;
    });
    projectDataService.projectsRouteStations.subscribe(routeStations => {
      this.projectRouteStations = routeStations;
    });
    projectDataService.activeProject.subscribe(activeProject => { this.activeProject = activeProject });
    projectDataService.activeGroup.subscribe(activeGroup => { this.activeGroup = activeGroup });
    projectDataService.activeRouteStation.subscribe(routeStation => { this.activeRouteStation = routeStation });
  }

  public onProjectFolderClicked(project: Project): void {
    if (this.activeProject === project) {
      if (this.activeGroup !== undefined) { this.projectDataService.updateActiveGroup(undefined); return }
      this.projectDataService.updateActiveProject(undefined);
      this.mapHandler.removeProjectLayer(2);
      this.mapHandler.removeGroupLayer(1);
      this.mapHandler.removeRouteStationLayer(1);
    } else {
      this.projectDataService.updateActiveProject(project);
      this.mapHandler.removeProjectLayer(1);
    }
    this.resizeMap();
  }

  public onProjectGroupClicked(group: ProjectGroup): void {
    console.log(group);
    if (this.activeGroup === group) {
      if (this.activeRouteStation !== undefined) { this.projectDataService.updateActiveRouteStation(undefined); return }
      this.projectDataService.updateActiveGroup(undefined);
      this.mapHandler.removeGroupLayer(2);
      this.mapHandler.removeRouteStationLayer(1);

    } else {
      this.projectDataService.updateActiveGroup(group);
      this.mapHandler.removeGroupLayer(1);
    }
    this.resizeMap();
  }

  public onProjectRouteStationClicked(routeStation: ProjectRouteStation): void {
    if (this.activeRouteStation === routeStation) {
      this.projectDataService.updateActiveRouteStation(undefined);
      this.mapHandler.removeRouteStationLayer(2);
    } else {
      this.projectDataService.updateActiveRouteStation(routeStation);
      this.mapHandler.removeRouteStationLayer(1);
    }
    this.resizeMap();
  }

  public onProjectSelectChange(event: SelectInput): void {
    this.selectedProjectFilter = event;
    this.isDropdownOpen = false;
    this.searchInProjects();
  }

  public onSearchInputChanged(event: string): void {
    this.searchInput = event;
    this.searchInProjects();
  }

  public onAddProjectBtnClicked(): void {
    const dialogRef = this.dialog.open(ProjectSettingsCreateModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.projectService.getProjects().subscribe(projects => {
        this.projectDataService.updateProjects(projects);
      })
    });
  }

  public onDropdownClicked(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private searchInProjects(): void {
    if (this.selectedProjectFilter.id === 1) {
      this.filteredProjects = this.projects.filter(p =>
        p.name.toLowerCase().includes(this.searchInput.toLowerCase())
        || p.groups.find(g => g.name.toLowerCase().includes(this.searchInput.toLowerCase())));
    }
    else {
      this.filteredProjects = this.activeProjects.filter(p =>
        p.name.toLowerCase().includes(this.searchInput.toLowerCase())
        || p.groups.find(g => g.name.toLowerCase().includes(this.searchInput.toLowerCase())));
    }
  }

  private resizeMap(): void {
    this.mapHandler.initResize();
  }
}
