import {Component, Inject} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {AlertTypes} from "./shared/alert-types";

@Component({
  selector: 'avs-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  protected readonly AlertTypes = AlertTypes;
}
