import { Component, Input, OnChanges } from '@angular/core';
import { DeviceParameter } from "../../../device/shared/interfaces/device-parameter.interface";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { DeviceService } from "../../../device/shared/services/device.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { ImageService } from "../../shared/services/image.service";
import { ProjectGroup } from "../../shared/interfaces/project-group.interface";
import { GroupService } from "../../shared/services/group.service";
import { EmnifyService } from "../../../device/shared/services/emnify.service";
import { getEvaCommandSelect } from '../../shared/enums/eva-command.enum';

@Component({
  selector: 'avs-test-area-additional-settings',
  templateUrl: './test-area-additional-settings.component.html',
  styleUrls: ['./test-area-additional-settings.component.css']
})
export class TestAreaAdditionalSettingsComponent implements OnChanges {
  @Input() deviceParameter?: DeviceParameter;
  @Input() activeStation?: ProjectRouteStation;
  public isSyncStellcodeOpen: boolean = false;
  public selectedSyncStellcode: SelectInput = { name: 'Kanal', id: 0 }
  public channelSyncStellcodeSettings: SelectInput[] = [{ name: 'Kanal 1', id: 1 }, { name: 'Kanal 2', id: 2 }, { name: 'Kanal 3', id: 3 }, { name: 'Kanal 4', id: 4 },
  { name: 'Kanal 5', id: 5 }, { name: 'Kanal 6', id: 6 }, { name: 'Kanal 7', id: 7 }, { name: 'Kanal 8', id: 8 }, { name: 'Kanal 9', id: 9 }, { name: 'Kanal 10', id: 10 }];
  public router: boolean = false;

  public isEvaCommandDropdownOpen: boolean = false;
  public evaCommandSelect: SelectInput[] = getEvaCommandSelect();
  public selectedEvaCommand: SelectInput = { name: "Eva", id: 0 };



  constructor(private deviceService: DeviceService,
    private alertService: AlertService,
    private imageService: ImageService,
    private groupService: GroupService,
    private emnifyService: EmnifyService) { }

  /*  public ngOnChanges(): void {
     if (!this.activeStation) { return; }
     this.emnifyService.getRutOnlineStatus(this.activeStation.id).subscribe(RutState => {
       if (RutState) {
         this.router = RutState.online;
       }
     });
   } */

  public ngOnChanges(): void {
    if (!this.activeStation) { return; }
    if (this.deviceParameter?.online) {
      this.emnifyService.getRutOnlineStatus(this.activeStation.id).subscribe(RutState => {
        if (RutState) {
          this.router = RutState.online;
        }
      }, (err) => { this.alertService.alert("Router ist nicht online", AlertTypes.error) }
      );
    }
  }

  public onRefreshSolardataClicked(): void {
    this.deviceService.refreshDataFromChannels(this.activeStation?.id!).subscribe(data => this.alertService.alert("Solar Daten wurden aktualisiert", AlertTypes.info))
  }

  public onResetEvaClicked(): void {
    if (!this.activeStation) { return; }
    this.deviceService.resetDevice(this.activeStation.id, 0).subscribe(() => {
      this.alertService.alert("Eva Reset wurde ausgeführt", AlertTypes.info);
    });
  }

  public onUploadImagesClicked(): void {
    this.groupService.getProjectGroup(this.activeStation!.groupId).subscribe(group => {
      this.imageService.uploadImageToEva(this.activeStation!.id, group.projectId).subscribe(data => {
        this.alertService.alert("Die Bilder wurde erfolgreich hochgeladen.", AlertTypes.info)
      });
    });
  }

  public onChannelDropdownSyncStellcodeValueChange(selectItem: SelectInput): void {
    this.selectedSyncStellcode = selectItem;
    this.isSyncStellcodeOpen = false
  }

  public onevaCommandSStellcodeValueChange(selectItem: SelectInput): void {
    this.selectedEvaCommand = selectItem;
    this.selectedEvaCommand.id = selectItem.id
    this.isEvaCommandDropdownOpen = false
  }


  public onSyncStellcodeBtnClicked(): void {
    this.imageService.syncStellcodes(this.selectedSyncStellcode.id, this.activeStation!.samId).subscribe(() => {
      this.alertService.alert("Synchroniesierung wurde angestoßen; dies kann einige Zeit in Anspruch nehmen", AlertTypes.info);
      this.selectedSyncStellcode = { name: 'Kanal', id: 0 };
    });
  }

  public onEvaCommandBtnClicked(command: any): void {
    let num: number = parseInt(command)
    this.emnifyService.EmnifySendSmsToEva(this.activeStation?.id!, num).subscribe(x => console.log(x))
  }
}
