import { JwtTokenService } from './jwt-token.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { AlertService } from "../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../shared/alert/shared/alert-types";
import { HttpContentTypes } from "./http-content-types.enum";
import { environment } from "../../../environments/environment";
import { HttpHeaders } from '@angular/common/http';
import { ParamsType } from './api-base-actions';
import { tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public apiAddress: string = environment.apiAddress;

  constructor(private readonly http: HttpClient,
    private readonly jwtTokenService: JwtTokenService,
    private readonly router: Router,
    private readonly alertService: AlertService) {
  }

  public get<T>(url: string, isJwtTokenRequired: boolean = true): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      this.http.get<any>(this.apiAddress + url, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        }, error => {

        /*   if (error.error.title !== 'Not Found') {  */observer.error(this.getApiResponseErrorMessage(error))/* ; }
          else return  */

        });
    }) as Observable<T>;
  }

  private getAuthorization() {
    let token = this.jwtTokenService.getJwtToken();
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return headers;
  }

  private createParams(params?: ParamsType) {
    let httpParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.append(key, value);
      });
    }
    return httpParams;
  }

  public post<T>(url: string, data: any, isJwtTokenRequired: boolean = true,
    contentType: HttpContentTypes = HttpContentTypes.json): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired, contentType);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      this.http.post<any>(this.apiAddress + url, data, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        },
        error => {
          observer.error(this.getApiResponseErrorMessage(error));
        });
    }) as Observable<T>;
  }

  public put<T>(url: string, data: any, isJwtTokenRequired: boolean = true): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      this.http.put<any>(this.apiAddress + url, data, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        }, error => {
          observer.error(this.getApiResponseErrorMessage(error));
        });
    }) as Observable<T>;
  }

  public patch<T>(url: string, data: any, isJwtTokenRequired: boolean = true): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      this.http.patch<any>(this.apiAddress + url, data, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        }, error => {
          observer.error(this.getApiResponseErrorMessage(error));
        });
    }) as Observable<T>;
  }

  public head<T>(url: string, isJwtTokenRequired: boolean = true): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      return this.http.head<any>(this.apiAddress + url, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        }, error => {
          observer.error(error);
        });
    }) as Observable<T>;
  }

  public delete<T>(url: string, isJwtTokenRequired: boolean = true): Observable<T> {
    let headers = this.getHeaders(isJwtTokenRequired);
    if (!headers) {
      return new Observable<T>(observer => {
        observer.error('Kein JWT-Token im local Storage');
      });
    }

    return new Observable<T>(observer => {
      this.http.delete<any>(this.apiAddress + url, { headers: headers }).subscribe(
        resp => {
          observer.next(resp);
        }, error => {
          observer.error(this.getApiResponseErrorMessage(error));
        })
    }) as Observable<T>;
  }

  private getHeaders(isJwtTokenRequired: boolean, contentType: HttpContentTypes = HttpContentTypes.json): any {
    let token = this.jwtTokenService.getJwtToken();
    if (!isJwtTokenRequired) {
      return { 'Content-Type': 'application/json' };
    }
    if (token) {
      switch (contentType) {
        case HttpContentTypes.json:
          return { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
        case HttpContentTypes.formData:
          return { 'Authorization': `Bearer ${token}` };
        default:
          return { 'Authorization': `Bearer ${token}` };
      }
    }
  }

  private getApiResponseErrorMessage(resp: any): string {
    if (resp.error === null || resp.error === undefined) { return '' }
    if (resp.error.title === 'Not Found' || resp.error.message === 'No RouteStation found') { return '' } /* wird umgestellt */
    /*  if(resp.error.title === 'Not Found') { return '' }  */
    switch (resp.status) {
      case 401:
        this.alertService.alert('Ihre Sitzung ist abgelaufen', AlertTypes.error);
        this.router.navigateByUrl('login');
        return 'Unauthorized | Ihre Sitzung ist abgelaufen';
      case 404:
        /*   if (resp.error.title === 'Not Found' || resp.error.message === 'No RouteStation found') { return '' }
          else */
        this.alertService.alert('Die angeforderte Ressource wurde nicht gefunden', AlertTypes.error);
        return 'Not Found | Die angeforderte Ressource wurde nicht gefunden';
      case 500:
        this.alertService.alert('Internal Server Error / Code: 500', AlertTypes.error);
        return 'Internal Server Error';
      case undefined:
        return 'und'
      default:
        this.alertService.alert('Fehler: ' + resp.message, AlertTypes.error); /* TODO: prüfen ob needed, aber errorHandler wird eh angepasst */
        return 'Fehler: ' + resp.message;
    }
  }

  /* Downloads */
  public getBlob(url: string, params?: ParamsType) {
    return this.http
      .get(this.apiAddress + url, { headers: this.getAuthorization(), responseType: 'blob', params: this.createParams(params) }) /* komischerweise steht der responseType nicht im header ist aber relevant, ggf nur für den service?? */
      .pipe(tap((x) => this.getApiResponseErrorMessage(x)));
  }

  public postBlob(url: string, body: any, params?: ParamsType) {
    return this.http
      .post(this.apiAddress + url, body, { headers: this.getAuthorization(), responseType: 'blob', params: this.createParams(params) }) /* komischerweise steht der responseType nicht im header ist aber relevant, ggf nur für den service?? */
      .pipe(tap((x) => this.getApiResponseErrorMessage(x)));
  }
}
