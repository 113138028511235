import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {checkAccessAuthorization} from "./role.guard";
import {UserDataService} from "./services/user-data.service";
import {UserDataResponse} from "./interfaces/login-data-response.interface";

@Directive({
    selector: '[canAccess]'
})
export class CanAccessDirective {
    private userData?: UserDataResponse

    constructor(private templateRef: TemplateRef<any>,
                private readonly viewContainerRef: ViewContainerRef,
                private readonly userDataService: UserDataService) {
        userDataService.userData.subscribe(userData => {
            this.userData = userData;
        });
    }

    @Input() set canAccess(releasLevel: number[]) {
        if (!this.userData) {
            this.viewContainerRef.clear();
            return;
        }
        if(checkAccessAuthorization(releasLevel, this.userData.role)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }
}