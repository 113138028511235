<nav class="navbar bg-body-tertiary shadow">
  <div class="d-flex">
    <a class="navbar-brand ms-5 me-5" routerLink="/dashboard">
      <img src="assets/logo/avs.svg" alt="AVS-Portal Logo" height="35">
    </a>
    <a *canAccess="[1,5]" class="avs-nav-link mt-4 ms-5 d-none d-lg-block d-xl-block" aria-current="page" role="button"
      routerLink='/dashboard' routerLinkActive="active">Dashboard</a>
    <a class="avs-nav-link mt-4 ms-3 d-none d-lg-block d-xl-block" aria-current="page" role="button"
      routerLink='/project' routerLinkActive="active">Projekte</a>
    <a *canAccess="[1,5]" class="avs-nav-link mt-4 ms-3 d-none d-lg-block d-xl-block" aria-current="page" role="button"
      routerLink="/device" routerLinkActive="active">Geräte</a>
    <a class="avs-nav-link mt-4 ms-3 d-none d-lg-block d-xl-block" aria-current="page" role="button"
      routerLink="/report" routerLinkActive="active">Berichte</a>
    <a *canAccess="[1,5]" class="avs-nav-link mt-4 ms-3 d-none d-lg-block d-xl-block" aria-current="page" role="button"
       routerLink="user" routerLinkActive="active">Benutzerverwaltung</a>
    <a *canAccess="[1,5]" class="avs-nav-link mt-4 ms-3 d-none d-lg-block d-xl-block" aria-current="page" role="button"
       routerLink="company" routerLinkActive="active">Firmenverwaltung</a>
    <div class="avs-navbar-menu d-block d-lg-none d-xl-none">
      <img *ngIf="!isMenuOpen" class="avs-menu-icon p-1" height="38" src="../../../assets/icons/list.svg"
        (click)="onMenuButtonClicked()" alt="menu icon button">
      <avs-dropdown
          *ngIf="isMenuOpen"
          [title]="'Seite auswählen'"
          [selectInput]="menuSelectInput"
          (onCloseBtnClicked)="onMenuCloseButtonClicked()"
          (onItemClicked)="onMenuListItemClicked()">
      </avs-dropdown>
    </div>
    <div class="avs-navbar-user-div">
      <img class="avs-navbar-user" src="../../../assets/icons/person-circle.svg" alt="account circle"
        (click)="onAccountCircleClicked()" *ngIf="!isAccountSelectOpen">
      <avs-dropdown
          *ngIf="isAccountSelectOpen"
          [title]="user? user.title + ' ' + user.lastName :''"
          [selectInput]="userSelectInput"
          (onCloseBtnClicked)="onUserSelectCloseButtonClicked()"
          (onItemClicked)="onUserSelectItemClicked($event)">
      </avs-dropdown>
    </div>
  </div>
  <div class="container-fluid avs-underline-navbar"></div>
</nav>
