import { Component, OnDestroy, OnInit } from '@angular/core';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectDataService } from '../shared/services/project-data.service';
import { ProjectService } from '../shared/services/project.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectTimeSwitchAddModalWrapperComponent } from './project-time-switch-add-modal/project-time-switch-add-modal-wrapper/project-time-switch-add-modal-wrapper.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProjectTimeSwitchInterface } from './shared/interfaces/project-time-switch.interface';
import { getPrioritySelect } from './shared/enum/priority.enum';
import { TimerSwitchService } from '../shared/services/timer-switch.service';
import { AddTimeSwitchComponent } from './project-time-switch-add-modal/add-time-switch/add-time-switch.component';
import { ProjectTimeSwitchBody } from './shared/interfaces/project-time-switch-body';
import { ProjectImageData } from '../shared/interfaces/project-image-data.interface';
import { getVerkehrsstufeSelect } from '../shared/enums/verkehrsstufe';
import { getVerkehrszustandSelect } from '../shared/enums/verkehrszustand';
import { ModalService } from 'src/app/shared/modal/shared/modal.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { CheckboxDropdownItem } from 'src/app/shared/checkbox-dropdown/shared/checkbox-dropdown-item.interface';
import { ImageDataService } from '../shared/services/image-data.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ProjectTimeSwitchEditModalWrapperComponent } from './project-time-switch-edit-modal-wrapper/project-time-switch-edit-modal-wrapper.component';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'avs-project-time-switch',
  templateUrl: './project-time-switch.component.html',
  styleUrls: ['./project-time-switch.component.css']
})
export class ProjectTimeSwitchComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public routeStations: ProjectRouteStation[] = [];
  public ProjectGroupSelectInput: CheckboxDropdownItem[] = [];
  public isCheckboxDropdownOpen: boolean = false;
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00"
  public AQs: ProjectTimeSwitchInterface[] = [];
  public MQs: ProjectTimeSwitchInterface[] = [];
  public Blinker: ProjectTimeSwitchInterface[] = [];
  public timerSwitches: ProjectTimeSwitchBody[] = [];
  public priorityNames = getPrioritySelect();
  public trafficNames = getVerkehrsstufeSelect();
  public betterVsName = getVerkehrszustandSelect();
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public assignmentGuids: CheckboxDropdownItem[] = []
  public guidList: string[] = [];
  public apiAddress: string = environment.apiAddress;

  public subscriptionCollection: Subscription[] = [];
  public activeProjectSubscription?: Subscription;
  public activeGroupSubscription?: Subscription;
  public routeStationsSubscription?: Subscription;
  public projectImagesSubscription?: Subscription;

  constructor(
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private timerService: TimerSwitchService,
    private modalService: ModalService,
    private imageDataService: ImageDataService,
  ) {
    this.activeProjectSubscription = this.projectDataService.activeProject.subscribe((project) => {
      this.activeProject = project;
      if (project) this.getAllTimeSwitches();
    });
    this.activeGroupSubscription = this.projectDataService.activeGroup.subscribe((group) => {
      if (group !== this.activeGroup) { this.guidList = [];; }
      this.activeGroup = group;
    });
    this.routeStationsSubscription = projectDataService.projectsRouteStations.subscribe(routeStations => {
      this.routeStations = routeStations;
      this.routeSationAsCheckboxItem();
    });
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.images = images;
      this.allImages = images;
    });
  }

  public ngOnInit(): void {

    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeGroupSubscription!);
    this.subscriptionCollection.push(this.routeStationsSubscription!);
    this.subscriptionCollection.push(this.projectImagesSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public assignmentListener(checkboxList: any) {
    this.assignmentGuids = checkboxList;
    this.guidList = [];
    this.assignmentGuids.forEach(x => { if (x.checked) { this.guidList.push(x.guid!) } })
  }

  public getPathByStellcodeId(stellcode: number) {
    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.stellcode == stellcode)
      return imageObject?.path as string
    }
    else return
  }

  public onDeleteAQTimerSwitchClicked(aq: ProjectTimeSwitchInterface, timerSwitchId: number) {
    this.timerService.deleteAQTimer(timerSwitchId, aq.id!).subscribe(x => {
      this.alertService.alert('Der AQ-Timer wurde gelöscht', AlertTypes.info);
      let timerswitchById = this.timerSwitches.find(x => x.id === timerSwitchId);
      timerswitchById?.aqTimers?.splice(timerswitchById.aqTimers.findIndex(e => e.id === aq.id), 1)
    })
  }

  public onEditTimerSwitchWrapperClicked(ts: ProjectTimeSwitchBody) {
    const dialogRef = this.dialog.open(AddTimeSwitchComponent, { data: ts });
    dialogRef.afterClosed().subscribe((x: any) => {
      if (x) { /* methode von create und edit zusammenfassen; sowie toTwoDigits() ggf. eine utils erschaffen */
        let startTimeParts = x.starttime.split(":", 3);
        let endTimeParts = x.endtime.split(":", 3);
        const startEvent = new Date(x.startDate.year, x.startDate.month - 1, x.startDate.day, startTimeParts[0], startTimeParts[1], startTimeParts[2], 0) /* monat fängt bei 0 an... */
        const endEvent = new Date(x.endDate.year, x.endDate.month - 1, x.endDate.day, endTimeParts[0], endTimeParts[1], endTimeParts[2], 0)
        let datestringStart = startEvent.toISOString() as unknown
        let datestringEnd = endEvent.toISOString() as unknown
        let data = { start: datestringStart as Date, stop: datestringEnd as Date, name: x.name, projectId: this.activeProject?.id!, id: x.id }
        this.timerService.updateTimerSwitch(data).subscribe(x => this.getAllTimeSwitches())
      }
    })
  }

  public onEditTimerSwitchConfigClicked(aq: ProjectTimeSwitchInterface, type: string) {
    const dialogRef = this.dialog.open(ProjectTimeSwitchEditModalWrapperComponent, { data: { data: aq, type: type, projectId: this.activeProject?.id } });
    dialogRef.afterClosed().subscribe((x: any) => {
      if (x) {
        if (type === 'aq') {
          this.editAqTimer(x)
          this.alertService.alert('Der AQ-Timer wurde bearbeitet', AlertTypes.info);
        }
        if (type === 'mq') {
          this.editMqTimer(x)
          this.alertService.alert('Der MQ-Timer wurde bearbeitet', AlertTypes.info);
        }
        if (type === 'flasher') {
          this.editBlinkerTimer(x)
          this.alertService.alert('Der Blinker-Timer wurde bearbeitet', AlertTypes.info);
        }
      }
    })
  }

  public onDeleteMQTimerSwitchClicked(mq: ProjectTimeSwitchInterface, timerSwitchId: number) {
    this.timerService.deleteMQTimer(timerSwitchId, mq.id!).subscribe(x => {
      this.alertService.alert('Der MQ-Timer wurde gelöscht', AlertTypes.info);
      let timerswitchById = this.timerSwitches.find(x => x.id === timerSwitchId);
      timerswitchById?.mqTimers?.splice(timerswitchById.mqTimers.findIndex(e => e.id === mq.id), 1)
    })
  }

  public onDeleteBlinkerTimerSwitchClicked(blinker: ProjectTimeSwitchInterface, timerSwitchId: number) {
    this.timerService.deleteBlinkerTimer(timerSwitchId, blinker.id!).subscribe(x => {
      this.alertService.alert('Der Blinker-Timer wurde gelöscht', AlertTypes.info);
      let timerswitchById = this.timerSwitches.find(x => x.id === timerSwitchId);
      timerswitchById?.blinkerTimers?.splice(timerswitchById.blinkerTimers.findIndex(e => e.id === blinker.id), 1)
    })
  }

  public onEnterStartTimeChange(newValue: any): void {
    this.starttime = newValue;
  }

  public onEnterEndTimeChange(newValue: any): void {
    this.endtime = newValue;
  }

  public onStartDateSelect(newValue: NgbDate): void {
    this.startDate = newValue;
  }
  public onEndDateSelect(newValue: NgbDate): void {
    this.endDate = newValue;
  }

  lastSunday(year: number, month: number) {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);
    return date;
  }

  isCEST(d: Date): boolean {

    let yearString = d as unknown as number;
    let startCET: Date = this.lastSunday(yearString, 3);
    let endCET: Date = this.lastSunday(yearString, 10);

    return !(startCET < d && d < endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }

  public onAddTimeSwitchWrapperBtnClick() {
    const dialogRef = this.dialog.open(AddTimeSwitchComponent);
    dialogRef.afterClosed().subscribe((x) => {
      if (x !== undefined) {
        let startTimeParts = x.starttime.split(":", 3);
        let endTimeParts = x.endtime.split(":", 3);
        const startEvent = new Date(x.startDate.year, x.startDate.month - 1, x.startDate.day, startTimeParts[0], startTimeParts[1], startTimeParts[2], 0) /* monat fängt bei 0 an... */
        const endEvent = new Date(x.endDate.year, x.endDate.month - 1, x.endDate.day, endTimeParts[0], endTimeParts[1], endTimeParts[2], 0)
        let datestringStart = startEvent.toISOString() as unknown
        let datestringEnd = endEvent.toISOString() as unknown
        let data = { start: datestringStart as Date, stop: datestringEnd as Date, name: x.name, projectId: this.activeProject?.id! }
        this.timerService.createTimerSwitch(data).subscribe(data => this.getAllTimeSwitches())
      }
    });
  }

  public changeEnableStatus(ts: ProjectTimeSwitchBody): void {
    ts.enabled = !ts.enabled
    this.timerService.updateTimerSwitch(ts).subscribe(x => {
      if (ts.enabled) {
        this.alertService.alert('Die Zeitschaltuhr wurde aktiviert', AlertTypes.info)
      }
      else { this.alertService.alert('Die Zeitschaltuhr wurde deaktiviert', AlertTypes.info) }
    });
  }

  public onAddAQMQTimeSwitchBtnClick(timeSwitchId: number, project: Project, aq: boolean, flasher?: boolean): void {
    const dialogRef = this.dialog.open(ProjectTimeSwitchAddModalWrapperComponent, { data: { id: timeSwitchId, project: project, aq: aq, flasher: flasher } });
    dialogRef.afterClosed().subscribe((x: ProjectTimeSwitchInterface) => {
      if (x) {
        if (x.aq) {
          this.aqTimerCreater(x, timeSwitchId)
        }
        if (!x.aq) {
          this.mqTimerCreater(x, timeSwitchId)
        }
      }
    });
  }

  public onHideAQClick(timerSwitchId: number) {
    let timeswitch = this.timerSwitches.find(x => x.id == timerSwitchId)
    timeswitch!.showAQ = !timeswitch!.showAQ
  }

  public onHideBlinkerClick(timerSwitchId: number) {
    let timeswitch = this.timerSwitches.find(x => x.id == timerSwitchId)
    timeswitch!.showBlinker = !timeswitch!.showBlinker
  }

  public onHideMQClick(timerSwitchId: number) {
    let timeswitch = this.timerSwitches.find(x => x.id == timerSwitchId)
    timeswitch!.showMQ = !timeswitch!.showMQ
  }
  public onDeleteTimerSwitch(timerSwitchId: number) {
    this.modalService.openDialog('Zeitschaltuhr löschen?', 'Nein', 'Ja').subscribe(() => {
      this.timerService.deleteTimerSwitch(timerSwitchId).subscribe(() => {
        this.alertService.alert('Die Zeitschaltuhr wurde erfolgreich gelöscht', AlertTypes.info);
        this.getAllTimeSwitches()
      })
    })
  }
  public samIds: string[] = [];
  public deIds: string[] = [];
  public swaIds: string[] = [];
  public rest: string[] = [];

  public getStartTime(config: ProjectTimeSwitchInterface) {
    let uhrzeitH = config.hourOn
    let uhrzeitM;
    if (config.minuteOn! < 10) { uhrzeitM = config.minuteOn?.toString().padStart(2, '0') }
    else uhrzeitM = config.minuteOn
    return uhrzeitH + ':' + uhrzeitM
  }

  public getEndTime(config: ProjectTimeSwitchInterface) {
    let uhrzeitH = config.hourOff
    let uhrzeitM;
    if (config.minuteOff! < 10) { uhrzeitM = config.minuteOff?.toString().padStart(2, '0') }
    else uhrzeitM = config.minuteOff
    return uhrzeitH + ':' + uhrzeitM
  }

  public getStartDate(config: ProjectTimeSwitchInterface) {
    /* TODO: in globale utils bauen? und dann ebenfalls an anderen stellen einsetzen **** */
    let day;
    let month;
    let year;
    let hour;
    let min;
    if (config.dayOn! < 10) { day = config.dayOn?.toString().padStart(2, '0') }
    else day = config.dayOn
    if (config.monthOn! < 10) { month = config.monthOn?.toString().padStart(2, '0') }
    else month = config.monthOn
    if (config.yearOn! < 10) { year = config.yearOn?.toString().padStart(2, '0') }
    else year = config.yearOn
    hour = config.hourOn
    if (config.minuteOn! < 10) { min = config.minuteOn?.toString().padStart(2, '0') }
    else min = config.minuteOn
    return day + '.' + month + '.' + year + ' - ' + hour + ':' + min
  }

  public getEndDate(config: ProjectTimeSwitchInterface) {
    let day;
    let month;
    let year;
    let hour;
    let min;
    if (config.dayOff! < 10) { day = config.dayOff?.toString().padStart(2, '0') }
    else day = config.dayOff
    if (config.monthOff! < 10) { month = config.monthOff?.toString().padStart(2, '0') }
    else month = config.monthOff
    if (config.yearOff! < 10) { year = config.yearOff?.toString().padStart(2, '0') }
    else year = config.yearOff
    hour = config.hourOff
    if (config.minuteOff! < 10) { min = config.minuteOff?.toString().padStart(2, '0') }
    else min = config.minuteOff
    return day + '.' + month + '.' + year + ' - ' + hour + ':' + min
  }

  public onCheckboxDropdownChanged(value: boolean, timerSwitchId: number): void {
    this.isCheckboxDropdownOpen = value;
    let timeswitch = this.timerSwitches.find(x => x.id == timerSwitchId)
    timeswitch!.showDropdown = !timeswitch!.showDropdown
    if (!this.isCheckboxDropdownOpen) {
    }
  }

  public assignGuids(timerSwitchId: number) {
    if (this.guidList.length > 0)
      this.timerService.assignTimerSwitchToGuids(timerSwitchId, this.guidList).subscribe(x => {

        this.dialog.open(ErrorDialogComponent, {
          data: x,
          width: '98vw'
        })
      })
    this.guidList = [];
  }
  /* Private Methoden */
  private getAllTimeSwitches() {
    this.timerService.getTimerSwitchByProjectId(this.activeProject?.id!).subscribe(data => {
      if (data) {
        this.timerSwitches = data;
        if (this.timerSwitches.length > 0)
          this.timerSwitches.forEach(x => { x.showAQ = false; x.showMQ = false })
      }
      else return
    })
  }

  private routeSationAsCheckboxItem() {
    this.ProjectGroupSelectInput = [];
    this.routeStations.forEach(x => { let checkboxItem: CheckboxDropdownItem = { name: x.name, checked: false, guid: x.id }; this.ProjectGroupSelectInput.push(checkboxItem) })

  }
  private aqTimerCreater(x: ProjectTimeSwitchInterface, timeSwitchId: number) {
    if (x.tlS_Code === 61) {
      this.Blinker.push(x)
      let apidata: ProjectTimeSwitchInterface = {
        projectId: this.activeProject?.id!,
        tlS_Code: x.tlS_Code,
        tlS_Function: x.tlS_Function,
        tlS_Time: x.tlS_Time,
        minuteOff: x.minuteOff,
        minuteOn: x.minuteOn,
        hourOff: x.hourOff,
        hourOn: x.hourOn,
        yearOff: x.yearOff,
        yearOn: x.yearOn,
        one_time: x.one_time,
        secondOff: x.secondOff,
        secondOn: x.secondOn,
        priority: x.priority,
        monday: x.monday,
        tuesday: x.tuesday,
        friday: x.friday,
        wednesday: x.wednesday,
        thursday: x.thursday,
        saturday: x.saturday,
        sunday: x.sunday,
        monthOff: x.monthOff,
        monthOn: x.monthOn,
        dayOff: x.dayOff,
        dayOn: x.dayOn,
        timerSwitchId: timeSwitchId
      };
      this.timerService.createBlinkerTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches())
    }
    else {
      this.AQs.push(x)
      if (x.tlS_Function === 2) {
        let apidata: ProjectTimeSwitchInterface = {
          projectId: this.activeProject?.id!,
          tlS_Code: x.tlS_Code,
          tlS_Function: x.tlS_Function,
          tlS_Time: x.tlS_Time,
          minuteOff: x.minuteOff,
          minuteOn: x.minuteOn,
          hourOff: x.hourOff,
          hourOn: x.hourOn,
          yearOff: x.yearOff,
          yearOn: x.yearOn,
          one_time: x.one_time,
          secondOff: x.secondOff,
          secondOn: x.secondOn,
          priority: x.priority,
          monday: x.monday,
          tuesday: x.tuesday,
          friday: x.friday,
          wednesday: x.wednesday,
          thursday: x.thursday,
          saturday: x.saturday,
          sunday: x.sunday,
          monthOff: x.monthOff,
          monthOn: x.monthOn,
          dayOff: x.dayOff,
          dayOn: x.dayOn,
          timerSwitchId: timeSwitchId,
        };
        this.timerService.createAQTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches()); /* ggf. optimieren, datensatz manuell hinzufügen anstatt zu laden */
        if (x.createBlinkerTimer) {
          let apidata: ProjectTimeSwitchInterface = {
            projectId: this.activeProject?.id!,
            tlS_Code: 61,
            tlS_Function: 1,
            tlS_Time: 0,
            minuteOff: x.minuteOff,
            minuteOn: x.minuteOn,
            hourOff: x.hourOff,
            hourOn: x.hourOn,
            yearOff: x.yearOff,
            yearOn: x.yearOn,
            one_time: x.one_time,
            secondOff: x.secondOff,
            secondOn: x.secondOn,
            priority: x.priority,
            monday: x.monday,
            tuesday: x.tuesday,
            friday: x.friday,
            wednesday: x.wednesday,
            thursday: x.thursday,
            saturday: x.saturday,
            sunday: x.sunday,
            monthOff: x.monthOff,
            monthOn: x.monthOn,
            dayOff: x.dayOff,
            dayOn: x.dayOn,
            timerSwitchId: timeSwitchId
          };
          this.timerService.createBlinkerTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches());
        }
      }
      else {
        let apidata: ProjectTimeSwitchInterface = {
          projectId: this.activeProject?.id!,
          tlS_Code: x.tlS_Code,
          tlS_Function: x.tlS_Function,
          minuteOff: x.minuteOff,
          minuteOn: x.minuteOn,
          hourOff: x.hourOff,
          hourOn: x.hourOn,
          yearOff: x.yearOff,
          yearOn: x.yearOn,
          one_time: x.one_time,
          secondOff: x.secondOff,
          secondOn: x.secondOn,
          priority: x.priority,
          monday: x.monday,
          tuesday: x.tuesday,
          friday: x.friday,
          wednesday: x.wednesday,
          thursday: x.thursday,
          saturday: x.saturday,
          sunday: x.sunday,
          monthOff: x.monthOff,
          monthOn: x.monthOn,
          dayOff: x.dayOff,
          dayOn: x.dayOn,
          timerSwitchId: timeSwitchId
        };
        this.timerService.createAQTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches());
        if (x.createBlinkerTimer) {
          let apidata: ProjectTimeSwitchInterface = {
            projectId: this.activeProject?.id!,
            tlS_Code: 61,
            tlS_Function: 1,
            tlS_Time: 0,
            minuteOff: x.minuteOff,
            minuteOn: x.minuteOn,
            hourOff: x.hourOff,
            hourOn: x.hourOn,
            yearOff: x.yearOff,
            yearOn: x.yearOn,
            one_time: x.one_time,
            secondOff: x.secondOff,
            secondOn: x.secondOn,
            priority: x.priority,
            monday: x.monday,
            tuesday: x.tuesday,
            friday: x.friday,
            wednesday: x.wednesday,
            thursday: x.thursday,
            saturday: x.saturday,
            sunday: x.sunday,
            monthOff: x.monthOff,
            monthOn: x.monthOn,
            dayOff: x.dayOff,
            dayOn: x.dayOn,
            timerSwitchId: timeSwitchId
          };
          this.timerService.createBlinkerTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches())
        }
      }
      this.alertService.alert('Die Konfiguration wurde hinzugefügt', AlertTypes.info);
    }
  }

  private mqTimerCreater(x: ProjectTimeSwitchInterface, timeSwitchId: number) {
    this.MQs.push(x);

    let apidata: ProjectTimeSwitchInterface = {
      projectId: this.activeProject?.id!,
      minuteOff: x.minuteOff,
      minuteOn: x.minuteOn,
      hourOff: x.hourOff,
      hourOn: x.hourOn,
      yearOff: x.yearOff,
      yearOn: x.yearOn,
      one_time: x.one_time,
      secondOff: x.secondOff,
      secondOn: x.secondOn,
      priority: x.priority,
      monday: x.monday,
      tuesday: x.tuesday,
      friday: x.friday,
      wednesday: x.wednesday,
      thursday: x.thursday,
      saturday: x.saturday,
      sunday: x.sunday,
      monthOff: x.monthOff,
      monthOn: x.monthOn,
      dayOff: x.dayOff,
      dayOn: x.dayOn,
      trafic: x.trafic,
      timerSwitchId: timeSwitchId,
      vs: x.vs
    };
    this.timerService.createMQTimer(timeSwitchId, apidata).subscribe(x => this.getAllTimeSwitches());
    this.alertService.alert('Die Konfiguration wurde hinzugefügt', AlertTypes.info);
  }

  private editBlinkerTimer(x: ProjectTimeSwitchInterface) {
    let apidata: ProjectTimeSwitchInterface = {
      projectId: this.activeProject?.id!,
      minuteOff: x.minuteOff,
      minuteOn: x.minuteOn,
      hourOff: x.hourOff,
      hourOn: x.hourOn,
      yearOff: x.yearOff,
      yearOn: x.yearOn,
      one_time: x.one_time,
      secondOff: x.secondOff,
      secondOn: x.secondOn,
      priority: x.priority,
      monday: x.monday,
      tuesday: x.tuesday,
      friday: x.friday,
      wednesday: x.wednesday,
      thursday: x.thursday,
      saturday: x.saturday,
      sunday: x.sunday,
      monthOff: x.monthOff,
      monthOn: x.monthOn,
      dayOff: x.dayOff,
      dayOn: x.dayOn,
      tlS_Code: 61,
      timerSwitchId: x.timerSwitchId,
      id: x.id, 
      tlS_Function: x.tlS_Function,
   /*    tlS_Time: x.tlS_Time,
      trafic: x.trafic,
      vs: x.vs */

    };
    this.timerService.updateBlinkerTimer(x.timerSwitchId!, apidata).subscribe(x => { this.getAllTimeSwitches() }); /* ggf. optimierne und direkt in datenschreiben */
  }


  private editAqTimer(x: ProjectTimeSwitchInterface) {
    if (x.tlS_Function === 2) {
      let apidata: ProjectTimeSwitchInterface = {
        projectId: x.projectId,
        tlS_Code: x.tlS_Code,
        tlS_Function: x.tlS_Function,
        tlS_Time: x.tlS_Time,
        minuteOff: x.minuteOff,
        minuteOn: x.minuteOn,
        hourOff: x.hourOff,
        hourOn: x.hourOn,
        yearOff: x.yearOff,
        yearOn: x.yearOn,
        one_time: x.one_time,
        secondOff: x.secondOff,
        secondOn: x.secondOn,
        priority: x.priority,
        monday: x.monday,
        tuesday: x.tuesday,
        friday: x.friday,
        wednesday: x.wednesday,
        thursday: x.thursday,
        saturday: x.saturday,
        sunday: x.sunday,
        monthOff: x.monthOff,
        monthOn: x.monthOn,
        dayOff: x.dayOff,
        dayOn: x.dayOn,
        timerSwitchId: x.timerSwitchId,
        id: x.id
      };
      this.timerService.updateAQTimer(x.timerSwitchId!, apidata).subscribe(x => { this.getAllTimeSwitches() })
    }
    else {
      let apidata: ProjectTimeSwitchInterface = {
        projectId: this.activeProject?.id!,
        tlS_Code: x.tlS_Code,
        tlS_Function: x.tlS_Function,
        minuteOff: x.minuteOff,
        minuteOn: x.minuteOn,
        hourOff: x.hourOff,
        hourOn: x.hourOn,
        yearOff: x.yearOff,
        yearOn: x.yearOn,
        one_time: x.one_time,
        secondOff: x.secondOff,
        secondOn: x.secondOn,
        priority: x.priority,
        monday: x.monday,
        tuesday: x.tuesday,
        friday: x.friday,
        wednesday: x.wednesday,
        thursday: x.thursday,
        saturday: x.saturday,
        sunday: x.sunday,
        monthOff: x.monthOff,
        monthOn: x.monthOn,
        dayOff: x.dayOff,
        dayOn: x.dayOn,
        timerSwitchId: x.timerSwitchId,
        id: x.id
      };
      this.timerService.updateAQTimer(x.timerSwitchId!, apidata).subscribe(x => { this.getAllTimeSwitches() })
    }
  } /* unten den service für beide einbinden */

  private editMqTimer(x: ProjectTimeSwitchInterface) {
    let apidata: ProjectTimeSwitchInterface = {
      projectId: this.activeProject?.id!,
      minuteOff: x.minuteOff,
      minuteOn: x.minuteOn,
      hourOff: x.hourOff,
      hourOn: x.hourOn,
      yearOff: x.yearOff,
      yearOn: x.yearOn,
      one_time: x.one_time,
      secondOff: x.secondOff,
      secondOn: x.secondOn,
      priority: x.priority,
      monday: x.monday,
      tuesday: x.tuesday,
      friday: x.friday,
      wednesday: x.wednesday,
      thursday: x.thursday,
      saturday: x.saturday,
      sunday: x.sunday,
      monthOff: x.monthOff,
      monthOn: x.monthOn,
      dayOff: x.dayOff,
      dayOn: x.dayOn,
      trafic: x.trafic,
      timerSwitchId: x.timerSwitchId,
      vs: x.vs,
      id: x.id
    };
    this.timerService.updateMQTimer(x.timerSwitchId!, apidata).subscribe(x => { this.getAllTimeSwitches() });
  }
}
