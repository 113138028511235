import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-dashboard',
  templateUrl: './faq-dashboard.component.html',
  styleUrls: ['./faq-dashboard.component.css']
})
export class FaqDashboardComponent {

 public isShowDashboardAllocation: boolean = false;
 public isShowDashboardWorkload: boolean = false;
 public isShowDashboardErrorRate: boolean = false;

 constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}


public onShowDashboardAllocation() {
  this.isShowDashboardAllocation = !this.isShowDashboardAllocation
}

public onShowDashboardWorkload() {
  this.isShowDashboardWorkload = !this.isShowDashboardWorkload
}

public onShowDashboardErrorRate() {
  this.isShowDashboardErrorRate = !this.isShowDashboardErrorRate
}
}
