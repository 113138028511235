import { Component, Input } from '@angular/core';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';

@Component({
  selector: 'avs-eva-reset',
  templateUrl: './eva-reset.component.html',
  styleUrls: ['./eva-reset.component.css']
})
export class EvaResetComponent {



  @Input() showReset: boolean = false;
  @Input() routeStationGuid?: string;

  constructor(
    private deviceService: DeviceService,
    private alertService: AlertService

  ) { }

  public onResetEvaClicked(): void {
    if (!this.routeStationGuid) { return; }
    this.deviceService.resetDevice(this.routeStationGuid, 0).subscribe(() => {
      this.alertService.alert("Eva Reset wurde ausgeführt", AlertTypes.info);
    });
  }
}
