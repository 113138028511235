import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectRouteStationWithParam } from '../../shared/interfaces/project-route-station.class';
import { ProjectImageData } from '../../shared/interfaces/project-image-data.interface';
import { ProjectScenarioRouteStationModulComponent } from './project-scenario-route-station-modul/project-scenario-route-station-modul.component';
import { MatDialog } from '@angular/material/dialog';
import { DataEndChannel } from '../../shared/interfaces/data-end-channel.interface';
import { ManualControlService } from '../../shared/services/manual-control.service';
import { ManualControl } from '../../shared/interfaces/manual-control.interface';
import { ScenarioService } from '../../shared/services/scenario.service';
import { ScenarioManual } from '../../shared/interfaces/scenario-manual';
import { environment } from 'src/environments/environment';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';


@Component({
  selector: 'avs-modular-route-station',
  templateUrl: './modular-route-station.component.html',
  styleUrls: ['./modular-route-station.component.css']
})
export class ModularRouteStationComponent implements OnInit {

  @Input() routeStation?: ProjectRouteStationWithParam;
  @Input() images?: ProjectImageData[] = [];
  @Input() projectId?: number;
  @Input() scenarioId?: number;
  @Input() scenarioManuals?: ScenarioManual[] = [];
  @Input() users?: EditUser[] = []
  @Output()
  myEvent = new EventEmitter<boolean>();


  protected readonly environment = environment;
  constructor(private dialog: MatDialog,
    private manualControlService: ManualControlService,
    private scenarioService: ScenarioService
  ) { }



  public emitEvent() {
    this.myEvent.emit(true);
  }
  public ngOnInit(): void {
    if (this.scenarioManuals!.length > 0) {
      let scenarioManuals = this.scenarioManuals!.filter((x) => x.scenarioId === this.scenarioId)
      this.checkForManualControl(scenarioManuals);
    }
    this.sortBydeKanal(this.routeStation!.routeStation!.dataEndChannels!)
  }

  public sortBydeKanal(dataEndChannels: DataEndChannel[]) {
    dataEndChannels.sort((a, b) => {
      if (a.de < b.de) return 1;
      if (a.de > b.de) return -1;
      return 0;
    }) 
  }

  public getProperScenarioManual(kanalId: number): any {
    let response = this.scenarioManuals!.filter((x) => x.scenarioId === this.scenarioId && x.dataEndChannelId === kanalId)

    return response[0]
  }

  private checkForManualControl(scenarioManuals: ScenarioManual[]): void {
    scenarioManuals.forEach((scenarioManual) => {
      this.routeStation!.routeStation!.dataEndChannels?.forEach((dataEndChannel: DataEndChannel) => {
        if (dataEndChannel.id === scenarioManual.dataEndChannelId && scenarioManual.scenarioId === this.scenarioId) {
          dataEndChannel.manualControlObj = [];
          dataEndChannel.manualControlObj?.push({ scenarioId: scenarioManual.scenarioId, manualControl: scenarioManual.manualControl });
        }
      });
    })
  }


  public getPathByStellcodeId(stellcode: number) {
    if (this.images) {
      let imageObject = this.images.find((x) => x.stellcode == stellcode)
      return imageObject?.path as string
    }
    else return
  }

  public onOpenComponentClicked(kanal: DataEndChannel) {
    let edit: boolean = false;
    let scenarioManual = this.getProperScenarioManual(kanal.id)
    if (scenarioManual !== undefined) edit = true;

    const dialogRef = this.dialog.open(ProjectScenarioRouteStationModulComponent, {
      disableClose: true,
      data: {
        kanal: kanal,
        routeStation: this.routeStation,
        projectId: this.projectId,
        scenarioId: this.scenarioId,
        scenarioManual: scenarioManual,
      }
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result === undefined) return;
      if (!edit) {
        this.manualControlService.createManualControl({ settings: result.settings, highPriority: result.highPriority }).subscribe((res: ManualControl) => {
          this.scenarioService.addManualControlToScenario(this.scenarioId!, res.id!, kanal.id).subscribe((addResponse) => {
            this.emitEvent()
          });
        })
      }
      else {
        this.manualControlService.updateManualControl({ id: result.manualControlId, settings: result.settings, highPriority: result.highPriority }).subscribe(x => {
          this.emitEvent()
        })
      }
      /* TODO: Es muss noch ein reload hat die parent gegeben werden, damit die scenarien neu geladen werden */


    });
  }
}