<div class="d-flex justify-content-center mb-5">
  <div class="card shadow rounded-0 border-0 avs-user-settings p-3">
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="userId; else newUser">
        <h6>Benutzer Einstellungen</h6>
      </ng-container>
      <ng-template #newUser>
        <h6>Benutzer hinzufügen</h6>
      </ng-template>
      <div class="mb-3 avs-arrow-left" (click)="onBackIconClicked()">
        <img class="p-1" src="assets/icons/arrow-left.svg">
        <span class="me-2">zurück</span>
      </div>
    </div>
    <div class="mt-3 mb-3">
      <p>Benutzerdaten</p>
      <div class="row">
        <div class="col-sm-6 col-md-4 mb-2">
          <avs-text-input [value]="userData ? userData.title : ''"
            (valueChange)="onCompanyDataValueChange($event, UserDataTypes.title)" [required]="true" placeholder="Titel">
          </avs-text-input>
        </div>
        <div class="col-sm-6 col-md-4 mb-2">
          <avs-text-input [value]="userData ? userData.firstName : ''"
            (valueChange)="onCompanyDataValueChange($event, UserDataTypes.firstName)" [required]="true"
            placeholder="Vorname">
          </avs-text-input>
        </div>
        <div class="col-md-4 mb-2">
          <avs-text-input [value]="userData ? userData.lastName : ''"
            (valueChange)="onCompanyDataValueChange($event, UserDataTypes.lastName)" [required]="true"
            placeholder="Nachname">
          </avs-text-input>
        </div>
      </div>

      <ng-container *ngIf="!userId">
        <!--         <div class="mt-4">
          <div class="row">
            <div class="col-md-6 mb-2">
              <avs-text-input
                [value]="userData ? userData.password : ''"
                (valueChange)="onCompanyDataValueChange($event, UserDataTypes.password)"
                [required]="true"
                type="password"
                placeholder="Passwort">
              </avs-text-input>
            </div>
            <div class="col-md-6 mb-2">
              <avs-text-input
                [value]="userData ? userData.confirmPassword : ''"
                (valueChange)="onCompanyDataValueChange($event, UserDataTypes.confirmPassword)"
                [required]="true"
                type="password"
                placeholder="Passwort wiederhohlen">
              </avs-text-input>
            </div>
          </div>
        </div> -->
        <div class="mt-4">
          <avs-text-input [value]="userData ? userData.email : ''"
            (valueChange)="onCompanyDataValueChange($event, UserDataTypes.email)" [required]="true"
            placeholder="E-Mail">
          </avs-text-input>
        </div>
      </ng-container>

      <ng-container *ngIf="userId">
        <ng-container *canAccess="[1]">
          <div class="mt-4">
            <div class="row">
              <div class="col-md-6 mb-2">
                <avs-text-input [value]="userData ? userData.password : ''"
                  (valueChange)="onCompanyDataValueChange($event, UserDataTypes.password)" [required]="true"
                  type="password" placeholder="Passwort">
                </avs-text-input>
              </div>
            </div>
          </div>

        </ng-container>
      </ng-container>
      <ng-container *ngIf="userId">
        <div class="mt-4">
          <div class="row">
            <div class="col-md-12 mb-2">
              <avs-text-input [value]="userData ? userData.email : ''"
                (valueChange)="onCompanyDataValueChange($event, UserDataTypes.email)" [required]="true"
                placeholder="E-Mail">
              </avs-text-input>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row mt-4">
        <div class="col-md-6 mb-4">
          <p>Institution auswählen</p>
          <div class="avs-user-dropdown-container position-relative">
            <div [ngClass]="isCompanyDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
              role="button" (click)="onCompanyDropdownClicked()">

              <ng-container *ngIf="userId">
                <div class="ms-2 p-1">{{ selectedCompany ? selectedCompany.name : 'Keine Institution hinterlegt'}}</div>
              </ng-container>
              <ng-container *ngIf="!userId">
                <div class="ms-2 p-1">{{ selectedCompany ? selectedCompany.name : 'Institution auswählen'}}</div>
              </ng-container>

              <img *ngIf="!isCompanyDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                alt="caret-down open select">
              <img *ngIf="isCompanyDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="companySelectInput"
              [activeItem]="selectedCompany"
              (onItemClicked)="onCompanyDropdownValueChange($event)" [isHeaderActive]="false"
              *ngIf="isCompanyDropdownOpen" class="position-absolute avs-user-dropdown-container">
            </avs-dropdown>
          </div>
        </div>
        <div class="col-md-6">
          <p>Rolle auswählen</p>
          <div class="avs-user-dropdown-container position-relative">
            <div [ngClass]="isRoleDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
              role="button" (click)="onRoleDropdownClicked()">
              <div class="ms-2 p-1">{{ selectedRole ? selectedRole.name : 'Rolle auswählen'}}</div>
              <img *ngIf="!isRoleDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                alt="caret-down open select">
              <img *ngIf="isRoleDropdownOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="roleSelectInput" [activeItem]="selectedRole ?  selectedRole : undefined"
              (onItemClicked)="onRoleDropdownValueChange($event)" [isHeaderActive]="false" *ngIf="isRoleDropdownOpen"
              class="position-absolute avs-user-dropdown-container">
            </avs-dropdown>
          </div>
        </div>
      </div>

      <ng-container *ngIf="userId">
        <div class="row mt-4">
          <div class="col-md-6 mb-4">
            <div class="d-flex justify-content-between">
              <p>Zugewiesene Projekte</p>
              <img (click)="onAddProjectBtnClicked()" class="avs-icon-add mb-3" src="assets/icons/plus.svg"
                alt="icon-add-group">
            </div>
            <div class="card border-1 rounded-0">
              <div *ngFor="let project of this.assignedProjects">
                <div class="m-2 border-bottom border-1 d-flex justify-content-between">
                  <span class="p-1 avs-group-list-item"> {{ project.name }}</span>
                  <div>
                    <img (click)="onDeleteBtnClicked(project.id)" class="avs-icon-delete p-1"
                      src="assets/icons/trash.svg" alt="icon-delete-group">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-end" *ngIf="isCardChanged">
        <button class="btn btn-outline-success rounded-0 mt-4 avs-test-area-save-btn" type="button"
          (click)="onSaveCardBtnClicked()">
          Speichern
        </button>
      </div>
    </div>
  </div>
</div>