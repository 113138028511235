<div class="row m-3 avs-device-overview-body">
  <div class="col-4 p-3 mt-3 d-none d-lg-block d-xl-block">
    <div class="card mt-2 shadow rounded-0">
      <avs-device-menu></avs-device-menu>
    </div>
  </div>
  <div class="col-lg-8 p-3 mt-3">
    <div>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="test-area" mat-raised-button>Testbereich</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="settings" mat-raised-button>Einstellungen</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="route-station" mat-raised-button>Station</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="notes" mat-raised-button>Notizen</a>
    </div>
    <div class="mt-4" *ngIf="activeRouteStation">
      <span class="avs-label-position-nav ms-2">
        <span>Station: <span class="text-danger fw-bolder">{{activeRouteStation.name}}</span></span>
        <span class="ms-3"> Sam ID: <span class="text-secondary fw-bolder">{{activeRouteStation.samId}}</span></span>
      </span>
    </div>
    <div class="d-block d-lg-none d-xl-none">
        <div class="avs-menu-hide ms-2" (click)="onMenuClicked()">
          <span>Geräte Menü</span>
          <img *ngIf="!isMenuOpen" class="ms-1" src="assets/icons/caret-down.svg"
               alt="Project Menu Pfeil nach unten">
          <img *ngIf="isMenuOpen" class="ms-1" src="assets/icons/caret-up.svg"
               alt="Project Menu Pfeil nach unten">
        </div>
        <div *ngIf="isMenuOpen" class="card shadow rounded-0 mt-3">
          <avs-device-menu></avs-device-menu>
        </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
