import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartData } from "../shared/chart-data.class";
import { ReportService } from '../shared/services/report.service';


@Component({
  selector: 'avs-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.css']
})

export class DashboardOverviewComponent implements OnInit {
  public chart: Chart | undefined;

  private chartAssignmentData = new ChartData({ name: 'Nicht zugewiesen', value: 200 }, { name: 'Zugewiesen', value: 300 })
  private chartWorkloadData = new ChartData({ name: 'Online', value: 600 }, { name: 'Offline', value: 600 })
  private chartErrorRateData = new ChartData({ name: 'Geräte mit Problem', value: 200 }, { name: 'Geräte in einwandfreiem Betrieb', value: 840 }, { name: 'Geräte mit Warnung', value: 200 })

  public isChartAssignmentDataZero: boolean = false;
  public isChartWorkloadDataZero: boolean = false;
  public isChartErrorRateDataZero: boolean = false; /*  */
  constructor(private reportService: ReportService) { }

  public ngOnInit() {
    this.getReports();
  }

  private getReports() {
    this.reportService.getAssignedReport().subscribe(x => {
      if (x) {
        this.chartAssignmentData.item1.value = x.unAssignedDevices;
        this.chartAssignmentData.item2.value = x.assignedDevices;
        if (x.totalDevices === 0) this.isChartAssignmentDataZero = true
      }
      this.createChartAssignment();
    })
    this.reportService.getRouteStationUsageReport().subscribe(x => {
      if (x) {
        this.chartWorkloadData.item1.value = x.totalOnlineDevices!;
        this.chartWorkloadData.item2.value = x.totalOfflineDevices!;
        /*    this.chartWorkloadData.item3!.value = x.offlineWithoutProject;
           this.chartWorkloadData.item4!.value = x.onlineWithoutProject; */

        if (x.totalDevices === 0) this.isChartWorkloadDataZero = true
      }
      this.createChartWorkload();
    })
    this.reportService.getRouteStationErrorOverview().subscribe(x => {
      if (x) {
        this.chartErrorRateData.item1.value = x.error;
        this.chartErrorRateData.item2.value = x.noError;
        this.chartErrorRateData.item3!.value = x.warnings;
        if (x.totalDevices === 0) this.isChartErrorRateDataZero = true
      }
      this.createChartErrorRate();
    })
  }

  private createChartAssignment() {
    this.chart = new Chart('canvasAssignment', {
      type: 'doughnut',

      data: {
        labels: [this.chartAssignmentData.item1.name, this.chartAssignmentData.item2.name],
        datasets: [{
          data: [this.chartAssignmentData.item1.value, this.chartAssignmentData.item2.value],
          backgroundColor: [
            '#d40c14',
            '#84c36c',
          ],
          hoverBackgroundColor: [
            '#bc0404',
            '#5ca43c',
          ],
          hoverOffset: 0
        }],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            font: {
              size: 13,
              weight: 'bold',
            },
            color: 'white',
            formatter: function (value: number, context: any) {
              const dataPoints = context.chart.data.datasets[0].data;
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              const totalValue = dataPoints.reduce(totalSum, 0);
              const percentageValue = (value / totalValue * 100).toFixed(1);

              return ` ${percentageValue}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });

  }

  public createChartErrorRate() {

    this.chart = new Chart('canvasErrorRate', {
      type: 'doughnut',

      data: {
        labels: [this.chartErrorRateData.item1.name, this.chartErrorRateData.item2.name, this.chartErrorRateData.item3!.name],
        datasets: [{
          data: [this.chartErrorRateData.item1.value, this.chartErrorRateData.item2.value, this.chartErrorRateData.item3!.value],
          backgroundColor: [
            '#d40c14', /* rot */
            '#84c36c', /* grün */
            '#f7b731', /* gelb */

          ],
          hoverBackgroundColor: [
            '#bc0404',
            '#5ca43c',
            '#f39c12',
          ],
          hoverOffset: 0
        }],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function (value: number, context: any) {
              const dataPoints = context.chart.data.datasets[0].data;
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              const totalValue = dataPoints.reduce(totalSum, 0);
              const percentageValue = (value / totalValue * 100).toFixed(1);

              return ` ${percentageValue}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  public createChartWorkload() {
    this.chart = new Chart('canvasWorkload', {
      type: 'doughnut',

      data: {
        labels: [this.chartWorkloadData.item1.name, this.chartWorkloadData.item2.name],
        datasets: [{
          data: [this.chartWorkloadData.item1.value, this.chartWorkloadData.item2.value],
          backgroundColor: [
            '#d40c14',
            '#84c36c',
          ],
          hoverBackgroundColor: [
            '#bc0404',
            '#5ca43c',
          ],
          hoverOffset: 0
        }],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            color: 'white',
            font: {
              size: 13,
              weight: 'bold',
            },
            formatter: function (value: number, context: any) {
              const dataPoints = context.chart.data.datasets[0].data;
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              const totalValue = dataPoints.reduce(totalSum, 0);
              const percentageValue = (value / totalValue * 100).toFixed(1);

              return ` ${percentageValue}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}
