export enum SolarErrorMessagesStatus {
    Ok,
    Warnung,
    Fehler,
    Ungueltig = 255,
}

export function getSolarErrorMessagesStatusString(type: SolarErrorMessagesStatus): string {
    switch (type) {
        case SolarErrorMessagesStatus.Ok:
            return 'Ok';
        case SolarErrorMessagesStatus.Warnung:
            return 'Warnung';
        case SolarErrorMessagesStatus.Fehler:
            return 'Fehler';
        case SolarErrorMessagesStatus.Ungueltig:
            return 'Ungültig';
    }
}

export enum SolaWarningStatus {
    Ok,
    Unbekannt,
    Overload,
    LowVoltage = 4,
    HighTemperature = 8,
    Ungueltig = 255,
}

export function getSolaWarningStatusString(type: SolaWarningStatus): string {
    switch (type) {
        case SolaWarningStatus.Ok:
            return 'Ok';
        case SolaWarningStatus.Unbekannt:
            return 'Unbekannt';
        case SolaWarningStatus.Overload:
            return 'Überladen';
        case SolaWarningStatus.LowVoltage:
            return 'Niedrige Spannung';
        case SolaWarningStatus.HighTemperature:
            return 'Hohe Temperatur';
        case SolaWarningStatus.Ungueltig:
            return 'Ungültig';
    }
}

export enum SolarPanelErrorVictron {

    Ok,
    battery_temperature_too_high,
    battery_voltage_too_high, /* https://www.victronenergy.com/live/mppt-error-codes#err_2_-_battery_voltage_too_high */
    remote_temperature_sensor_failure1, /* err_3_err_4_-_remote_temperature_sensor_failure */
    remote_temperature_sensor_failure2, /* err_3_err_4_-_remote_temperature_sensor_failure */
    remote_temperature_sensor_failure_connection_lost, /* err_5_-_remote_temperature_sensor_failure_connection_lost */
    remote_battery_voltage_sense_failure1, /* err_6_err_7_-_remote_battery_voltage_sense_failure */
    remote_battery_voltage_sense_failure2, /* err_6_err_7_-_remote_battery_voltage_sense_failure */
    remote_battery_voltage_sense_failure_connection_lost,
    battery_high_ripple_voltage = 11, /* err_11_-_battery_high_ripple_voltage */
    battery_low_temperature = 14, /* err_14_-_battery_low_temperature */
    controller_overheated_despite_reduced_output_current = 17,
    controller_over_current = 18, /* err_18_-_controller_over-current */
    maximum_bulk_time_exceeded = 20, /* err_20_-_maximum_bulk-time_exceeded */
    current_sensor_issue, /*  err_21_-_current_sensor_issue */
    internal_temperature_sensor_failure1, /*   err_22_err_23_-_internal_temperature_sensor_failure */
    internal_temperature_sensor_failure2, /*   err_22_err_23_-_internal_temperature_sensor_failure */
    fan_failure, /* err_24_-_fan_failure */
    terminal_overheated = 26,    /* err_26_-_terminal_overheated */
    charger_short_circuit, /* err_27_-_charger_short_circuit */
    power_stage_issue, /* err_28_-_power_stage_issue */
    over_charge_protection,/*  err_29_-_over-charge_protection */
    pv_over_voltage = 33,/* err_33_-_pv_over-voltage */
    pv_over_current = 34,/* err_34_-_pv_over-current */
    pv_over_power = 35, /* err_35_-_pv_over-power */
    pv_input_shutdown1 = 38,
    pv_input_shutdown2 = 39,/* err_38_err_39_and_err_80_to_err_87_-_pv_input_shutdown */
    pv_input_failed_to_shutdown = 40,  /*  err_40_-_pv_input_failed_to_shutdown */
    inverter_shutdown_pv_isolation1 = 41,/* err_41_-_inverter_shutdown_pv_isolation */
    inverter_shutdown_pv_isolation2 = 42,/* err_42_-_inverter_shutdown_pv_isolation */
    inverter_shutdown_ground_fault = 43, /*   err_43_-_inverter_shutdown_ground_fault*/
    inverter_overload_inverter_peak_current1 = 50, /* err_50_err_52_-_inverter_overload_inverter_peak_current */
    inverter_temperature_too_high = 51,/* err_51_-_inverter_temperature_too_high */
    inverter_overload_inverter_peak_current2 = 52, /* err_50_err_52_-_inverter_overload_inverter_peak_current */
    inverter_output_voltage1, /* err_53_-_inverter_output_voltage */
    inverter_output_voltage2, /*  err_54_-_inverter_output_voltage */
    inverter_self_test_failed1, /* err_55_err_56_err_58_-_inverter_self_test_failed */
    inverter_self_test_failed2, /* err_55_err_56_err_58_-_inverter_self_test_failed */
    inverter_self_test_failed3 = 58, /* err_55_err_56_err_58_-_inverter_self_test_failed */
    inverter_ac_voltage_on_output = 54, /* err_57_-_inverter_ac_voltage_on_output*/
    acin1_relay_test_fault = 59, /* err_59_-_acin1_relay_test_fault */
    communication_warning = 65,    /* information_65_-_communication_warning */
    incompatible_device = 66,    /*   information_66_-_incompatible_device */
    bms_connection_lost,  /*  err_67_-_bms_connection_lost */
    network_misconfigured1, /* err_68_-_network_misconfigured */
    network_misconfigured2,/* err_69_-_network_misconfigured */
    network_misconfigured3, /* err_70_-_network_misconfigured */
    network_misconfigured4, /* err_71_-_network_misconfigured */
    phase_rotation, /* err_72_-_phase_rotation */
    multiple_ac_inputs,/* err_73_-_multiple_ac_inputs */
    phase_overload, /*  err_74_-_phase_overload */
    pv_input_shutdown3 = 80,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown4 = 81,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown5 = 82,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown6 = 83,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown7 = 84,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown8 = 85,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown9 = 86,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    pv_input_shutdown10 = 87,/* err_80_err_81_err_82_err_83_err_84_err_85_err_86_and_err_87_-_pv_input_shutdown */
    cpu_temperature_too_high = 114, /* err_114_-_cpu_temperature_too_high */
    calibration_data_lost = 116, /*  err_116_-_calibration_data_lost */
    incompatible_firmware = 117, /*    err_117_-_incompatible_firmware */
    settings_data_lost = 119, /* err_119_-_settings_data_lost */
    tester_fail = 121, /* err_121_-_tester_fail */
    internal_dc_voltage_error1 = 200, /* err_200_-_internal_dc_voltage_error */
    internal_dc_voltage_error2 = 201, /* err_201_-_internal_dc_voltage_error*/
    internal_gfci_sensor_error = 202, /* err_202_-_internal_gfci_sensor_error */
    internal_supply_voltage_error1 = 203, /* err_203_err_205_err_212_err_215_-_internal_supply_voltage_error */
    internal_supply_voltage_error2 = 205, /* err_203_err_205_err_212_err_215_-_internal_supply_voltage_error */
    internal_supply_voltage_error3 = 212, /* err_203_err_205_err_212_err_215_-_internal_supply_voltage_error */
    internal_supply_voltage_error4 = 215, /* err_203_err_205_err_212_err_215_-_internal_supply_voltage_error */




}

export function getSolarPanelErrorVictronString(type: SolarPanelErrorVictron): string {
    switch (type) {
        case SolarPanelErrorVictron.Ok:
            return 'Ok';
        case SolarPanelErrorVictron.battery_temperature_too_high:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_1_-_battery_temperature_too_high'
        case SolarPanelErrorVictron.battery_voltage_too_high:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_2_-_battery_voltage_too_high'
        case SolarPanelErrorVictron.remote_temperature_sensor_failure1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_3_-_remote_temperature_sensor_failure'
        case SolarPanelErrorVictron.remote_temperature_sensor_failure2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_4_-_remote_temperature_sensor_failure'
        case SolarPanelErrorVictron.remote_temperature_sensor_failure_connection_lost:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_5_-_remote_temperature_sensor_failure_connection_lost'
        case SolarPanelErrorVictron.remote_battery_voltage_sense_failure1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_6_-_remote_battery_voltage_sense_failure'
        case SolarPanelErrorVictron.remote_battery_voltage_sense_failure2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_7_-_remote_battery_voltage_sense_failure'
        case SolarPanelErrorVictron.remote_battery_voltage_sense_failure_connection_lost:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_8_-_remote_battery_voltage_sense_failure_connection_lost'
        case SolarPanelErrorVictron.battery_high_ripple_voltage:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_11_-_battery_high_ripple_voltage'
        case SolarPanelErrorVictron.battery_low_temperature:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_14_-_battery_low_temperature'
        case SolarPanelErrorVictron.controller_overheated_despite_reduced_output_current:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_17_-_controller_overheated_despite_reduced_output_current'
        case SolarPanelErrorVictron.controller_over_current:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_18_-_controller_over-current'
        case SolarPanelErrorVictron.maximum_bulk_time_exceeded:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_20_-_maximum_bulk-time_exceeded'
        case SolarPanelErrorVictron.current_sensor_issue:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_21_-_current_sensor_issue'
        case SolarPanelErrorVictron.internal_temperature_sensor_failure1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_22_-_internal_temperature_sensor_failure'
        case SolarPanelErrorVictron.internal_temperature_sensor_failure2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_23_-_internal_temperature_sensor_failure'
        case SolarPanelErrorVictron.fan_failure:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_24_-_fan_failure'
        case SolarPanelErrorVictron.terminal_overheated:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_26_-_terminal_overheated'
        case SolarPanelErrorVictron.charger_short_circuit:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_27_-_charger_short_circuit'
        case SolarPanelErrorVictron.power_stage_issue:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_28_-_power_stage_issue'
        case SolarPanelErrorVictron.over_charge_protection:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_29_-_over-charge_protection'
        case SolarPanelErrorVictron.pv_over_voltage:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_33_-_pv_over-voltage'
        case SolarPanelErrorVictron.pv_over_current:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_34_-_pv_over-current'
        case SolarPanelErrorVictron.pv_over_power:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_35_-_pv_over-power'
        case SolarPanelErrorVictron.pv_input_shutdown1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_38_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_39_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_failed_to_shutdown:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_40_-_pv_input_failed_to_shutdown'
        case SolarPanelErrorVictron.inverter_shutdown_pv_isolation1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_41_-_inverter_shutdown_pv_isolation'
        case SolarPanelErrorVictron.inverter_shutdown_pv_isolation2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_42_-_inverter_shutdown_pv_isolation'
        case SolarPanelErrorVictron.inverter_shutdown_ground_fault:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_43_-_inverter_shutdown_ground_fault'
        case SolarPanelErrorVictron.inverter_overload_inverter_peak_current1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_50_-_inverter_overload_inverter_peak_current'
        case SolarPanelErrorVictron.inverter_temperature_too_high:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_51_-_inverter_temperature_too_high'
        case SolarPanelErrorVictron.inverter_overload_inverter_peak_current2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_52_-_inverter_overload_inverter_peak_current'
        case SolarPanelErrorVictron.inverter_output_voltage1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_53_-_inverter_output_voltage'
        case SolarPanelErrorVictron.inverter_output_voltage2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_54_-_inverter_output_voltage'
        case SolarPanelErrorVictron.inverter_self_test_failed1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_55_-_inverter_self_test_failed'
        case SolarPanelErrorVictron.inverter_self_test_failed2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_56_-_inverter_self_test_failed'
        case SolarPanelErrorVictron.inverter_self_test_failed3:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_58_-_inverter_self_test_failed'
        case SolarPanelErrorVictron.inverter_ac_voltage_on_output:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_57_-_inverter_ac_voltage_on_output'
        case SolarPanelErrorVictron.acin1_relay_test_fault:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_59_-_acin1_relay_test_fault'
        case SolarPanelErrorVictron.communication_warning:
            return 'https://www.victronenergy.com/live/mppt-error-codes#information_65_-_communication_warning'
        case SolarPanelErrorVictron.incompatible_device:
            return 'https://www.victronenergy.com/live/mppt-error-codes#information_66_-_incompatible_device'
        case SolarPanelErrorVictron.bms_connection_lost:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_67_-_bms_connection_lost'
        case SolarPanelErrorVictron.network_misconfigured1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_68_-_network_misconfigured'
        case SolarPanelErrorVictron.network_misconfigured2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_69_-_network_misconfigured'
        case SolarPanelErrorVictron.network_misconfigured3:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_70_-_network_misconfigured'
        case SolarPanelErrorVictron.network_misconfigured4:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_71_-_network_misconfigured'
        case SolarPanelErrorVictron.phase_rotation:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_72_-_phase_rotation'
        case SolarPanelErrorVictron.multiple_ac_inputs:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_73_-_multiple_ac_inputs'
        case SolarPanelErrorVictron.phase_overload:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_74_-_phase_overload'
        case SolarPanelErrorVictron.pv_input_shutdown3:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_80_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown4:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_81_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown5:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_82_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown6:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_83_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown7:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_84_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown8:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_85_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown9:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_86_-_pv_input_shutdown'
        case SolarPanelErrorVictron.pv_input_shutdown10:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_87_-_pv_input_shutdown'
        case SolarPanelErrorVictron.cpu_temperature_too_high:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_114_-_cpu_temperature_too_high'
        case SolarPanelErrorVictron.calibration_data_lost:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_116_-_calibration_data_lost'
        case SolarPanelErrorVictron.incompatible_firmware:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_117_-_incompatible_firmware'
        case SolarPanelErrorVictron.settings_data_lost:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_119_-_settings_data_lost'
        case SolarPanelErrorVictron.tester_fail:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_121_-_tester_fail'
        case SolarPanelErrorVictron.internal_dc_voltage_error1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_200_-_internal_dc_voltage_error'
        case SolarPanelErrorVictron.internal_dc_voltage_error2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_201_-_internal_dc_voltage_error'
        case SolarPanelErrorVictron.internal_gfci_sensor_error:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_202_-_internal_gfci_sensor_error'
        case SolarPanelErrorVictron.internal_supply_voltage_error1:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_203_-_internal_supply_voltage_error'
        case SolarPanelErrorVictron.internal_supply_voltage_error2:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_205_-_internal_supply_voltage_error'
        case SolarPanelErrorVictron.internal_supply_voltage_error3:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_212_-_internal_supply_voltage_error'
        case SolarPanelErrorVictron.internal_supply_voltage_error4:
            return 'https://www.victronenergy.com/live/mppt-error-codes#err_215_-_internal_supply_voltage_error'

    }
}




export enum SolarPanelErrorLuminax {
    Ok,
    LowVoltage,
    Overload,
    ShortCircuit,
    Hardware,
    OpenCircuit
}

export function getSolarPanelErrorLuminaxString(type: SolarPanelErrorLuminax): string {
    switch (type) {
        case SolarPanelErrorLuminax.Ok:
            return 'Ok';
        case SolarPanelErrorLuminax.LowVoltage:
            return 'Niedrige Spannung';
        case SolarPanelErrorLuminax.Overload:
            return 'Überlastschutz';
        case SolarPanelErrorLuminax.ShortCircuit:
            return 'Kurzschlussschutz';
        case SolarPanelErrorLuminax.Hardware:
            return 'Hardwareschutz';
        case SolarPanelErrorLuminax.OpenCircuit:
            return 'Leerlaufschutz';
    }
}


export enum SolarPanelType {
    Victron = 1,
    Luminax = 2,
    Unknown = 255
}

export function getSolarPanelTypeString(type: SolarPanelType): string {
    switch (type) {
        case SolarPanelType.Victron:
            return 'Victron';
        case SolarPanelType.Luminax:
            return 'Luminax';
        case SolarPanelType.Unknown:
            return 'Unbekannt';

    }
}