<div>

    <form class="example-form" [formGroup]="form">
    <!-- Dropdown für Traffic erstellen -->
    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Verkehrsstufe</span>
                <div [ngClass]="isTrafficDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onTrafficDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedTraffic.name }}</div>
                    <img *ngIf="!isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="trafficSelect" [activeItem]="selectedTraffic"
                    (onItemClicked)="onTrafficItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isTrafficDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>


    <!-- Priorität Auswahl -->

    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Priorität</span>
                <div [ngClass]="isPriorityDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onPriorityDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedPriority.name }}</div>
                    <img *ngIf="!isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="prioritySelect" [activeItem]="selectedPriority"
                    (onItemClicked)="onPriorityItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isPriorityDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>


    <!-- Verkehrssituation Auswahl -->

    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Verkehrssituation</span>
                <div [ngClass]="isVerkehrszustandDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button"
                    (click)="onVerkehrszustandDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedVerkehrszustand.name }}</div>
                    <img *ngIf="!isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="verkehrszustandSelect" [activeItem]="selectedVerkehrszustand"
                    (onItemClicked)="onVerkehrszustandItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isVerkehrszustandDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>

    <!-- OneTime Toggle -->

    <div class="w-100 d-flex justify-content-center mb-2">
        <mat-slide-toggle [checked]="isOneTime" color="primary" (change)="onOneTimeChange()">
            Einmalig
        </mat-slide-toggle>
    </div>

    <!-- Datepicker Toggle -->

    <div *ngIf="isOneTime" class="w-100 d-flex justify-content-evenly mb-2 ms-2 me-2">

        <ngbd-datepicker-range-popup (startDate)="onStartDateSelect($event)"
            (endDate)="onEndDateSelect($event)"></ngbd-datepicker-range-popup>

    </div>


    <!-- Wochentage Picker -->

    
        <div *ngIf="!isOneTime" class="d-flex w-100 justify-content-center pe-4 ">
            <mat-checkbox color="primary" class="example-margin" formControlName="monday">Mo</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="tuesday">Di</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="wednesday">Mi</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="thursday">Do</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="friday">Fr</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="saturday">Sa</mat-checkbox>
            <mat-checkbox color="primary" class="example-margin" formControlName="sunday">So</mat-checkbox>
        </div>


   
    <!-- Uhrzeit Input -->

    <div class="w-100 d-flex justify-content-evenly mb-2 ">
        <avs-time-input class="" label="Startzeit" (valueChange)="onEnterStartTimeChange($event)" [value]="starttime"
            id="starttime" name="starttime">
        </avs-time-input>
        <avs-time-input class="ml-1" label="Endzeit" (valueChange)="onEnterEndTimeChange($event)" [value]="endtime"
            id="endtime" name="endtime">
        </avs-time-input>
    </div>
</form>

</div>