import { Component, Input, OnChanges } from '@angular/core';
import { DeviceParameter } from "../../../device/shared/interfaces/device-parameter.interface";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { ProjectImageBrightness } from "../../shared/interfaces/project-image-brightness.interface";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { ImageService } from "../../shared/services/image.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";

@Component({
  selector: 'avs-test-area-brightness',
  templateUrl: './test-area-brightness.component.html',
  styleUrls: ['./test-area-brightness.component.css']
})
export class TestAreaBrightnessComponent implements OnChanges {
  @Input() deviceParameter?: DeviceParameter
  @Input() activeStation?: ProjectRouteStation
  @Input() dataEndChannels: DataEndChannel[] = [];
  public useSpontaneousMessages: boolean = false;
  public isKanalDropdownOpen: boolean = false;
  public selectedKanal?: SelectInput;
  public selectKanalInput: SelectInput[] = [];
  public isAnyValueChanged: boolean = false;

  constructor(private imageService: ImageService,
    private alertService: AlertService) {
  }

  public ngOnChanges(): void {
    this.fillSelectDropdownBrightnessSettings(this.dataEndChannels);
  }

  public onBrightnessInputValueChange(newValue: number): void {
      this.deviceParameter!.led.defaultSettings.helligkeitswert = newValue;
      this.isAnyValueChanged = true;
  }

  public onKanelDropdownClicked(): void {
    if (this.selectKanalInput.length < 1) { return; }
    this.isKanalDropdownOpen = !this.isKanalDropdownOpen
  }

  public onSelectKanalItemClicked(selectItem: SelectInput): void {
    this.selectedKanal = selectItem;
    this.isKanalDropdownOpen = false;
  }

  public onAutomatikChanged(value: boolean): void {
    this.deviceParameter!.led.defaultSettings.lokaleHelligkeitssteuerung = value;
    this.isAnyValueChanged = true;
  }

  public onSpontaneousReportChanged(value: boolean): void {
    this.deviceParameter!.led.defaultSettings.spontaneMeldungen = value;
    this.isAnyValueChanged = true;
  }

  public onSaveBtnClicked(): void {
    if (!this.activeStation) { return; }
    let newBrightnessInputValueObject = {
      deviceId: this.activeStation.samId,
      brightness: this.deviceParameter!.led.defaultSettings.helligkeitswert,
      deKanal: this.selectedKanal?.id,
      useLocalSensor: this.deviceParameter!.led.defaultSettings.lokaleHelligkeitssteuerung,
      useSpontaneousMessages: this.deviceParameter!.led.defaultSettings.spontaneMeldungen
    } as ProjectImageBrightness
    this.imageService.updateImageBrightness(newBrightnessInputValueObject).subscribe(newBrightnessInputValueObject => {
      this.alertService.alert("Die Änderungen wurden erfolgreich gespeichert", AlertTypes.info);
      this.isAnyValueChanged = false;
    });
  }

  private fillSelectDropdownBrightnessSettings(dataEndChannels: DataEndChannel[]): void {
    this.selectKanalInput = [];
    this.selectedKanal = undefined;
    if (!dataEndChannels) { return; }
    for (let channel of dataEndChannels) {
      if (channel.de > 190) {
        let select = {
          name: 'Kanal ' + channel.de,
          id: channel.de
        } as SelectInput
        if (!this.selectedKanal) {
          this.selectedKanal = select;
        }
        this.selectKanalInput.push(select);
      }
    }
    this.selectKanalInput.forEach(x => { if (x.id === 194) { this.selectedKanal = x } }) /* filter ggf besser geignet, weniger code */

  }
}
