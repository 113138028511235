<div class="avs-red-line"></div>
<div class="avs-white-line"></div>
<div class="d-flex justify-content-around avs-footer">
  <div class="mt-1 d-none d-lg-block d-xl-block">
    <a class="avs-footer-link" href="../impressum">Impressum</a>
    <span> | </span>
    <a class="avs-footer-link" href="#">Datenschutz</a>
    <span> | </span>
    <a class="avs-footer-link" href="https://www.avs-verkehrssicherung.de/de/agb.html" target="_blank">AGB</a>
    <span> | </span>
    <a class="avs-footer-link" href="#">Cookie-Einstellungen</a>
    <span> | </span>
    <a class="avs-footer-link"
       (mouseover)="isMouseOverIcon = true"
       (mouseleave)="isMouseOverIcon = false" routerLink="faq">
      <img [src]="isMouseOverIcon ? 'assets/icons/question-circle_red.svg' : 'assets/icons/question-circle_white.svg'" alt="Q&A Icon">
    </a>
  </div>
  <div class="d-block d-lg-none d-xl-none">
    <div class="position-relative mt-1">
      <img class="avs-menu-icon" src="assets/icons/list_lightgrey.svg"
           (click)="onFooterMenuButtonClicked()" alt="menu icon button" height="24">
      <div class="position-absolute avs-footer-small-menu-position">
        <avs-dropdown
                *ngIf="isFooterMenuOpen"
                [selectInput]="footerMenuSelectInput"
                [isHeaderActive]="false"
                (onItemClicked)="onMenuListItemClicked()">
        </avs-dropdown>
      </div>
    </div>
  </div>
  <div class="mt-1">
    &copy; 2024 | AVS Verkehrssicherung GmbH
  </div>
</div>
