import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpContentTypes } from 'src/app/core/shared/http-content-types.enum';
import { HttpService } from 'src/app/core/shared/http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  public apiAddress: string = environment.apiAddress;
  constructor(private httpService: HttpService, private http: HttpClient) { }

  uploadAttachment(file: File, projectId: number, filename: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('name', filename)
    formData.append('projectId', projectId.toString())
    return this.httpService.post('Attachment', formData, true, HttpContentTypes.formData);
  }

  getAllAttachments(): Observable<any> {
    return this.httpService.get('Attachment');
  }

  getBlob(id: number) {
    return this.httpService.getBlob('Attachment/file/' + id);
  }

  deleteAttachment(id: number): Observable<any> {
    return this.httpService.delete('Attachment/' + id);
  }

}
