export enum CompanyDataTypes {
    firstname = 1,
    lastname = 2,
    companyName = 3,
    streetNo = 4,
    zip = 5,
    street = 6,
    country = 7,
    city = 8,
    emergencyPhone = 9,
    phone = 10
}