import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-account',
  templateUrl: './faq-account.component.html',
  styleUrls: ['./faq-account.component.css']
})
export class FaqAccountComponent {

public isShowAccountLogin: boolean = false;
public isShowAccountData: boolean = false;
public isShowAccountLogOut: boolean = false;

constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}

public onShowAccountLogin() {
  this.isShowAccountLogin = !this.isShowAccountLogin
}

public onShowAccountData(){
  this.isShowAccountData =! this.isShowAccountData
}

public onShowAccountLogout(){
  this.isShowAccountLogOut =! this.isShowAccountLogOut
}
}

