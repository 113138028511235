<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Zeitschaltuhr erstellen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseAddTimeSwitchClicked()">
    </div>

    <ng-container [formGroup]="mainForm">
        <div *ngIf="aq">
            <div *ngIf="!isFlasher">
                <avs-add-aq-time-switch [projectId]="data.project.id" [images]="images" (refIsWithBlinkerTimer)="getIsWithBlinkerTimer($event)"
                    (refIsOneTime)="getIsOneTime($event)" (refIsTouched)="getIsTouched($event)">
                </avs-add-aq-time-switch>
            </div>
            <div *ngIf="isFlasher">
                <avs-add-flasher-time-switch (refIsOneTime)="getIsOneTime($event)"
                    (refIsTouched)="getIsTouched($event)">

                </avs-add-flasher-time-switch>
            </div>
        </div>
        <div *ngIf="!aq">
            <avs-add-mq-time-switch (refIsOneTime)="getIsOneTime($event)"
                (refIsTouched)="getIsTouched($event)"></avs-add-mq-time-switch>
        </div>

        <div class="d-flex justify-content-between mt-5 border-top pt-3" style="z-index: 1;">
            <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseAddTimeSwitchClicked()">
                Abbrechen
            </button>

            <button [disabled]="!objIsTouched" class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitBtnClicked()">
                Erstellen
            </button>
        </div>
    </ng-container>
</mat-dialog-content>