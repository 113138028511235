<!-- <div>
    <div style="display: flex; flex-direction: row;">
        <div class="col-2 text-danger fw-semibold border-end border-bottom border-secondary ps-1">Standort</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">SST</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">Zeit (von)</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">Auslöser</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">q</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">kp</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">b</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">Vkfg,g</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">Zi</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">VS</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">De-K.</div>
        <div class="col text-danger fw-semibold border-end border-bottom border-secondary ps-1">Zeit (bis)</div>








        <div class="col text-danger fw-semibold border-bottom border-secondary ps-1">Dauer</div>
    </div>
    <div *ngIf="changelogs.length === 0">Keine Daten vorhanden</div>
    <div *ngFor="let changelog of limitedChangelogs"
        style="display: flex; flex-direction: row; border-bottom: 1px solid lightgray;">
        <div class="col-2 border-end ps-1">{{changelog.lineDescribtion}}</div>
        <div class="col border-end ps-1">{{changelog.samId}}</div>
        <div class="col-border-end ps-1">{{changelog.timestampFrom | date: 'dd.MM.yyyy - H:mm':
            offset(changelog.timestampFrom) }}</div>
        <div class="col border-end ps-1">{{changelog.trigger}}</div>
        <div class="col border-end ps-1">{{changelog.q}}</div>
        <div class="col border-end ps-1">{{changelog.kp}}</div>
        <div class="col border-end ps-1">{{changelog.utilization}}</div>
        <div class="col border-end ps-1">{{changelog.kfzAverageSmoothSpeed}}</div>
        <div class="col border-end ps-1">{{changelog.z}}</div>
        <div class="col border-end ps-1">{{changelog.vs}}</div>
        <div class="col border-end ps-1">{{changelog.deKanal}}</div>
        <div class="col border-end ps-1">{{changelog.timestampTo | date: 'dd.MM.yyyy - H:mm':
            offset(changelog.timestampTo ) }}</div>
        <div class="col ps-1">{{changelog.duration}}</div>
    </div>

</div>
<div style="height: 40px;"></div>
<div class="position-absolute" style="bottom: 0; right: 10px;">
    <div class="m-2 d-flex">
        <div class="d-flex">
            <avs-text-input [value]="rowQuantity" [classInputOnly]="'form-control-sm'"
                (valueChange)="onRowQuantityChanged(+$event)" [type]="'number'" style="width: 50px;">
            </avs-text-input>
            <div class="text-secondary mt-1 ms-1">Zeilen<span *ngIf="changelogs.length > 0"> von <span
                        class="text-danger">{{changelogs.length}}</span></span></div>
        </div>
        <div class="ms-3">
            <img src="assets/icons/chevron-double-left.svg" alt="double left Icon" role="button" width="24"
                class="avs-changelog-table-paginator-arrow" (click)="onDoubleLeftIconClicked()">
            <img src="assets/icons/chevron-left-grey.svg" alt="left Icon" width="24" (click)="onLeftIconClicked()"
                role="button" class="avs-changelog-table-paginator-arrow">
            <span class="text-secondary mx-2">
                <span class="text-danger">{{rowRange.start}}</span> -
                <span class="text-danger">{{rowRange.end}}</span>
            </span>
            <img src="assets/icons/chevron-right-grey.svg" alt="right left Icon" width="24"
                (click)="onRightIconClicked()" role="button" class="avs-changelog-table-paginator-arrow">
            <img src="assets/icons/chevron-double-right.svg" alt="double right Icon" width="24"
                class="avs-changelog-table-paginator-arrow" role="button" (click)="onDoubleRightIconClicked()">
        </div>
    </div>
</div> -->



<!-- aufräumen,
 
images aufräumen, (updaten wenng elöscht, updaten wenn hinzugefügt)
neu erstellte gruppen, fehlen im dropdown von wechselbild erstellen, bildlöschen etc



Christian 9 Uhr Solar






Reportings - Schaltungsgrundentscheid
https://ramuddenglobal.atlassian.net/browse/RPA-460
Meine Fragen zu anderen Themen:
Berichte? Welche werden jetzt gebraucht? Wie sollen die genannt werden?
Knöpfen nesten?

Oder generell noch etwas mit der Überarbeitung aufgrund der Erweiterung/Änderungen zurückhalten?
Dann aber bitte schon mal grob das Ziel besprechen.





CZ112x64 - "neues Tafelformat"
https://ramuddenglobal.atlassian.net/browse/RPA-440
Auflösung groß:

Vorstellen, mock-up daten einbauen - damit ich es im zeigen kann, ansonsten kurz setzen.
mixed / unset dunkler geworden, restliche um Auflösung (quadratisch, rechteckig und vertikal) innerhalb der Platzierung zu erkennen

passt so!!!




Kaputte LED in Kürten?
Wie kann ich das beheben?
Muss ich dafür vor Ort sein?




https://ramuddenglobal.atlassian.net/browse/RPA-444
Frontend implementierung der Daten zur Brennstoffzelle

Icon gas_meter, Position dynamisch







FallbackSetting Ansatz vorstellen
in deChannels der Dialog versteckt

also, um den pixelfehler zu beheben muss auf die richtige Resolution verwiesen werden.
ggf. ist dies nicht passiert, weil ich die bilder nicht nochmal hochgeladen habe?

dann die fallback Implementierung nochmal testen und bilder anzeigen lassen

anzeigeprinzip klären? habs leider wieder vergessen :D





Dashboard 

Jede Minute aktualisieren.

Verfügbarkeit (NUR ADMINS)
Neuen Parameter GeräteStatus
siehe Liste
beim auflösen des Projekts, (löschen der routeStation) Auswahl wohin?
Kategorie 1-5?

gerät muss dann auch noch eine Status Zuordnung innerhalb der Einstellung haben, damit dies z.B. nach Reperatur wieder auf einsatzbereit gestellt werden kann
Dann in Form einer Tabelle anzeigen.


User nur Fehlerquote, Akkuspannung nur USER (beiden linken Tabellen ausblenden)


Auslastung Online-Status
5 Minuten? Trifft das zu?



Berichte
Bericht 2 Schaltgrundentscheidung

Verkehrsdaten Bericht Intervall einbauen.....


Solardetails

Hinweis in den Solardetail Dialog aufbauen.


Brennstoffzelle

efoy-cloud registrieren.
mit elke besprechen wegen "power unit" und Verknüpfung, wie diese dargestellt wird





merhere Images hintereinander löschen, führt zu 

gruppen, wenn neu erstellt...
projekt, wenn neu erstellt

-->

<table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8">


    <ng-container matColumnDef="lineDescribtion">
        <th mat-header-cell *matHeaderCellDef> Standort </th>
        <td mat-cell *matCellDef="let element"> {{element.lineDescribtion }} </td>
    </ng-container>

    <ng-container matColumnDef="samId">
        <th mat-header-cell *matHeaderCellDef> SST </th>
        <td mat-cell *matCellDef="let element"> {{element.samId}} </td>
    </ng-container>

    <ng-container matColumnDef="timestampFrom">
        <th mat-header-cell *matHeaderCellDef> Zeit (von) </th>
        <td mat-cell *matCellDef="let element"> {{element.timestampFrom | date: 'dd.MM.yyyy - H:mm':
            offset(element.timestampFrom) }} </td>
    </ng-container>

    <ng-container matColumnDef="trigger">
        <th mat-header-cell *matHeaderCellDef> Auslöser</th>
        <td mat-cell *matCellDef="let element"> {{element.trigger}} </td>
    </ng-container>

    <ng-container matColumnDef="q">
        <th mat-header-cell *matHeaderCellDef> q </th>
        <td mat-cell *matCellDef="let element"> {{element.q}} </td>
    </ng-container>
    <ng-container matColumnDef="kp">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                K <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">p</span>
            </div>


        </th>
        <td mat-cell *matCellDef="let element"> {{element.kp}} </td>
    </ng-container>
    <ng-container matColumnDef="utilization">
        <th mat-header-cell *matHeaderCellDef> <span style="font-size: 8px;"> b </span></th>
        <td mat-cell *matCellDef="let element"> {{element.utilization }} </td>
    </ng-container>
    <ng-container matColumnDef="kfzAverageSmoothSpeed">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                V <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">kfz,g</span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.kfzAverageSmoothSpeed}} </td>
    </ng-container>
    <ng-container matColumnDef="z">
        <th mat-header-cell *matHeaderCellDef>
            <div class="position-relative">
                Z <span class="position-absolute" style="bottom: 2px; left: 9px; font-size: 8px;">i</span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.z}} </td>
    </ng-container>
    <ng-container matColumnDef="vs">
        <th mat-header-cell *matHeaderCellDef> VS </th>
        <td mat-cell *matCellDef="let element"> {{element.vs}} </td>
    </ng-container>
    <ng-container matColumnDef="deKanal">
        <th mat-header-cell *matHeaderCellDef> De-K. </th>
        <td mat-cell *matCellDef="let element"> {{element.deKanal}} </td>
    </ng-container>
    <ng-container matColumnDef="timestampTo">
        <th mat-header-cell *matHeaderCellDef> Zeit (bis) </th>
        <td mat-cell *matCellDef="let element"> {{element.timestampTo | date: 'dd.MM.yyyy - H:mm':
            offset(element.timestampTo) }} </td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef> Dauer </th>
        <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>