<div class="bg-white shadow">
    <div [ngClass]="isSelectOpen ? 'border-danger' : ''" [style]="styleTitleDiv"
         class="border d-flex justify-content-between" role="button"
         (click)="onSelectDropdownClicked()">
        <div class="ms-2 p-1">{{ title }}</div>
        <img *ngIf="!isSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
             alt="caret-down open select">
        <img *ngIf="isSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
             alt="caret-up close select">
    </div>
    <div *ngIf="isSelectOpen" class="position-absolute z-1 bg-white shadow p-2" [style]="styleItemsDiv">
        <div class="ms-1 mb-2">
            <mat-slide-toggle (change)="isThisMonthChanged($event.checked)"
                              [checked]="isThisMonth"
                              color="primary">diesen Monat
            </mat-slide-toggle>
        </div>
        <div class="ms-1 mb-2">
            <mat-slide-toggle (change)="isThisYearChanged($event.checked)"
                              [checked]="isThisYear"
                              color="primary">dieses Jahr
            </mat-slide-toggle>
        </div>
        <div class="d-flex border-top">
            <avs-text-input [label]="'von'"
                            class="me-1"
                            [value]="fromDate"
                            [type]="'date'"
                            (valueChange)="onFromDateChanged($event)">
            </avs-text-input>
            <avs-text-input [label]="'bis'"
                            [value]="toDate"
                            class="ms-1"
                            [type]="'date'"
                            (valueChange)="onToDateChanged($event)">
            </avs-text-input>
        </div>
    </div>
</div>