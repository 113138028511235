<ng-container *ngIf="deviceParameter">
    <div class="d-flex justify-content-between">
        <h6 class="text-secondary">Helligkeitseinstellungen</h6>
        <span *ngIf="selectKanalInput.length < 1" class="text-danger ms-2">Keine Kanäle gefunden!</span>
    </div>
    <div class="row align-items-center mb-3 mt-4">
        <div class="col-md-5 d-flex align-items-center mb-2">
            <p class="mb-0" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Helligkeit:</p>
            <avs-text-input type="number"
                            class="avs-test-area-input mx-3"
                            [value]="deviceParameter.led.defaultSettings.helligkeitswert"
                            (valueChange)=onBrightnessInputValueChange(+$event)
                            [disabled]="selectKanalInput.length < 1  || !deviceParameter.online"
            ></avs-text-input>
        </div>
        <div class="col-md-7">
            <input type="range"
                   [(ngModel)]="deviceParameter.led.defaultSettings.helligkeitswert"
                   (ngModelChange)="onBrightnessInputValueChange($event)"
                   [disabled]="selectKanalInput.length < 1  || !deviceParameter.online"
                   min="0" max="100"
                   class="form-range w-100 p-3">
        </div>
    </div>
    <div class="d-flex align-items-center">
        <div class="row">
            <div class="col-md-6 d-flex mt-4">
                <p class="mb-0 mt-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Kanal:</p>
                <div class="avs-test-area-dropdown-container ms-2">
                    <div *ngIf="deviceParameter.online" [ngClass]="isKanalDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                         role="button" (click)="onKanelDropdownClicked()">
                        <div class="ms-2 p-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">
                            {{ selectedKanal ? selectedKanal.name : 'N/A' }}
                        </div>
                        <img *ngIf="!isKanalDropdownOpen" class="me-2" src="../../../../assets/icons/caret-down.svg" alt="caret-down open select">
                        <img *ngIf="isKanalDropdownOpen" class="me-2" src="../../../../assets/icons/caret-up.svg" alt="caret-up close select">
                    </div>
                    <div *ngIf="!deviceParameter.online" [ngClass]="isKanalDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between">
                   <div class="ms-2 p-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">
                       {{ selectedKanal ? selectedKanal.name : 'N/A' }}
                   </div>
                   <img *ngIf="!isKanalDropdownOpen" class="me-2" src="../../../../assets/icons/caret-down.svg" alt="caret-down open select">
                   <img *ngIf="isKanalDropdownOpen" class="me-2" src="../../../../assets/icons/caret-up.svg" alt="caret-up close select">
               </div>
                    <avs-dropdown [selectInput]="selectKanalInput"
                                  [activeItem]="selectedKanal"
                                  (onItemClicked)="onSelectKanalItemClicked($event)"
                                  [isHeaderActive]="false"
                                  *ngIf="isKanalDropdownOpen"
                                  
                                  class="position-absolute avs-test-area-dropdown-container">
                    </avs-dropdown>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <mat-slide-toggle class="mb-3 pt-4"
                                      [checked]="deviceParameter.led.defaultSettings.lokaleHelligkeitssteuerung"
                                      (change)="onAutomatikChanged($event.checked)"
                                      [disabled]="selectKanalInput.length < 1 || !deviceParameter.online"
                                      color="primary">
                        Automatisch
                    </mat-slide-toggle>
                    <mat-slide-toggle color="primary"
                                      [checked]="deviceParameter.led.defaultSettings.spontaneMeldungen"
                                      (change)="onSpontaneousReportChanged($event.checked)"
                                      [disabled]="selectKanalInput.length < 1 || !deviceParameter.online">
                        Spontane Meldung
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>
    <div class="text-end" *ngIf="isAnyValueChanged">
        <button class="btn btn-outline-success rounded-0 mt-5"
                type="button" (click)="onSaveBtnClicked()">Speichern</button>
    </div>
</ng-container>
