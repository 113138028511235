<mat-dialog-content class="rounded-0 overflow-x-hidden">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Wechselbild erstellen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseModalClicked()">
    </div>

    <div>
        <span class="fw-semibold text-black "> Fehlerhafte RouteStations</span>
        <div *ngFor="let error of errors" class="text-danger  ">
            SAM ID: {{error.samId }} - Name: {{ error.name}}
        </div>

        <span class="fw-semibold text-black ">Erfolgreiche RouteStations</span>
        <div *ngFor="let success of successes" class="text-success  ">
            SAM ID: {{success.samId }} - Name: {{ success.name}}
        </div>
    </div>

    <div class="d-flex justify-content-between mt-5 justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseModalClicked()">Verstanden</button>

    </div>
</mat-dialog-content>