import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../modal.component";
import {Observable} from "rxjs";
import {ProjectImageData} from "../../../project/shared/interfaces/project-image-data.interface";

@Injectable({
    providedIn: 'root'
})
export class ModalService {
constructor(private dialog: MatDialog) {}

    public openDialog(title: string = "", firstButtonText: string = "", secondButtonText: string = "", image: ProjectImageData | undefined = undefined): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const dialogRef = this.dialog.open(ModalComponent, {
                data: {
                    'title': title,
                    'button_1': firstButtonText,
                    'button_2': secondButtonText,
                    'image': image
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    observer.next(result);
                }
            });

        }) as Observable<boolean>
    }
}
