<form class="row row-cols-sm-auto mt-2 ">
	<div class="col-12">
		<div class="dp-hidden position-absolute">
			<div class="input-group">
				<input
					name="datepicker"
					class="form-control rounded-0"
					ngbDatepicker
					style="border: unset; cursor: unset; z-index: -1"
					#datepicker="ngbDatepicker"
					[autoClose]="'outside'"
					(dateSelect)="onDateSelection($event)"
					[displayMonths]="2"
					[dayTemplate]="t"
					outsideDays="hidden"
					[startDate]="fromDate!"
					tabindex="-1"
				/>
				<ng-template #t let-date let-focused="focused">
					<span
						class="custom-day rounded-0"
						[class.focused]="focused"
						[class.range]="isRange(date)"
						[class.faded]="isHovered(date) || isInside(date)"
						(mouseenter)="hoveredDate = date"
						(mouseleave)="hoveredDate = null"
					>
						{{ date.day }}
					</span>
				</ng-template>
			</div>
		</div>
		<div class="input-group rounded-0">
			<input
				#dpFromDate
				class="form-control rounded-0"
				placeholder="yyyy-mm-dd"
				name="dpFromDate"
				[value]="formatter.format(fromDate)"
				(input)="fromDate = validateInput(fromDate, dpFromDate.value, true)"
			/>
			<button class="btn btn-outline-secondary bi bi-calendar3 rounded-0" (click)="datepicker.toggle()" type="button"><img src="assets/icons/calendar3.svg" alt="icon-start-date"></button>
		</div>
	</div>
	<div class="col-12">
		<div class="input-group">
			<input
				#dpToDate
				class="form-control rounded-0"
				placeholder="yyyy-mm-dd"
				name="dpToDate"
				[value]="formatter.format(toDate)"
				(input)="toDate = validateInput(toDate, dpToDate.value, false)"
			/>
			<button class="btn btn-outline-secondary bi bi-calendar3 rounded-0" (click)="datepicker.toggle()" type="button"><img src="assets/icons/calendar3.svg" alt="icon-end-date"></button>
		</div>
	</div>
</form>

<!--  <hr />
<pre>From date model: {{ fromDate | json }}</pre>
<pre>To date model: {{ toDate | json }}</pre> -->
