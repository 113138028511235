<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Fallback definieren</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>

    <div class="row avs-project-images-container d-flex justify-content-center mb-2">
        <ng-container *ngFor="let image of images">
            <div *ngIf="!image.isAnimated" class="mb-3 avs-project-images " (click)="onProjectImageClicked(image)">
                <div class="d-flex justify-content-center">
                    <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
                </div>
                <div class="small text-center">Stellcode: {{image.stellcode}}</div>
            </div>
        </ng-container>
        <ng-template *ngIf="images.length === 0">
            <div class="text-center">Keine Bilder vorhanden</div>
        </ng-template>
    </div>

    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="avs-route-station-settings-dropdown-container justify-content-between"
            style="width: 240px !important">
            <span class="ms-1 fw-semibold text-secondary">Funktion</span>
            <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onFunctionDropdownClicked()">
                <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false" *ngIf="isFunctionDropdownOpen"
                class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="d-flex justify-content-center mb-2 flex-column">
            <span class="d-flex  fw-semibold text-secondary justify-content-center"> Anzeigeprinzip </span>
            <input type="number" placeholder="Anzeigeprinzip" [required]="true" label="Anzeigeprinzip"
                [(ngModel)]="anzeigeprinzip" min="1" max="3" style="width: 240px !important"
                class="d-flex   justify-content-center">
        </div>

    <!--     <div *ngIf="selectedFunction.id == 2" class="d-flex justify-content-center mb-2 flex-column">
            <span class="d-flex  fw-semibold text-secondary justify-content-center"> Intervall  </span>
            <input type="number" placeholder="Intervall in ms angeben" *ngIf="selectedFunction.id === 2"
                [required]="true" label="Blinker-Intervall in ms" formControlName="tlS_Time" min="1" max="15">
        </div> -->
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button [disabled]="onFunctionSelected" class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitBtnClicked()">
        Erstellen
    </button>
</mat-dialog-actions>