import { Injectable } from "@angular/core";

const localStorageTokenKey = 'user_token';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

  public getJwtToken(): string | null {
    return localStorage.getItem(localStorageTokenKey);
  }

  public setJwtToken(token: string): void {
    localStorage.setItem(localStorageTokenKey, token);
  }

  public removeJwtToken(): void {
    localStorage.removeItem(localStorageTokenKey);
  }
}
