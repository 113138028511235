import {GroupTypeEnum} from "../../../shared/enums/group-type.enum";
import {DiagramTypeEnum} from "../../../shared/enums/diagram-type.enum";
import {TrafficRulesEnum} from "../../../shared/enums/traffic-rules.enum";

export interface GroupMetaData {
  name: string | undefined,
  routeName: string | undefined,
  groupType: GroupTypeEnum | undefined,
  diagramType: DiagramTypeEnum | undefined,
  trafficRules: TrafficRulesEnum | undefined,
  isActive: boolean | undefined,
  isArchived?: boolean,
  trafficLanes?: number
}

export enum GroupMetaDataTypes {
  name,
  routeName,
  groupType,
  diagramType,
  trafficRules,
  isActive
}
