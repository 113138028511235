import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-timer-switch',
  templateUrl: './faq-timer-switch.component.html',
  styleUrls: ['./faq-timer-switch.component.css']
})
export class FaqTimerSwitchComponent {

  public isShowCreateTimerSwitch: boolean = false;
  public isShowCreateAQ: boolean = false;
  public isShowCreateMQ: boolean = false;
  public isShowCreateindicator: boolean = false;
  public isShowparticularities: boolean = false;
  constructor(private location:Location){}
  
  public onBackButtonKlick(){
    this.location.back();
  }
  
  public onShowCreateTimerSwitch() {
    this.isShowCreateTimerSwitch = !this.isShowCreateTimerSwitch
  }
  
  public onShowCreateAQ(){
    this.isShowCreateAQ =! this.isShowCreateAQ
  }
  
  public onShowCreateMQ(){
    this.isShowCreateMQ =! this.isShowCreateMQ
  }

  public onShowCreateindicator(){
    this.isShowCreateindicator =! this.isShowCreateindicator
  }

  public onShowparticularities(){
    this.isShowparticularities =! this.isShowparticularities
  }
  }