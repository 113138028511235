<mat-dialog-content class="rounded-0">

    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Sonderprogramm erstellen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>
    <avs-text-input label="Name" (valueChange)="onNameChange($event)" [value]="name"> </avs-text-input>

    <div class="d-flex justify-content-between mt-5 border-top pt-3">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseDialogClicked()">
            Abbrechen
        </button>
        <button [disabled]="untouched" class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitClicked()">
            Erstellen
        </button>
        <!--    <button *ngIf="editingActive" class="btn btn-sm btn-outline-success rounded-0" (click)="onEditClicked()">
            Bearbeiten
        </button> -->
    </div>
</mat-dialog-content>