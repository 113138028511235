import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getPrioritySelect, getPriorityString } from '../../shared/enum/priority.enum';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { getVerkehrszustandSelect } from 'src/app/project/shared/enums/verkehrszustand';
import { getVerkehrsstufeSelect } from 'src/app/project/shared/enums/verkehrsstufe';



@Component({
  selector: 'avs-add-mq-time-switch',
  templateUrl: './add-mq-time-switch.component.html',
  styleUrls: ['./add-mq-time-switch.component.css']
})
export class AddMqTimeSwitchComponent implements OnInit{
  @Output() refIsOneTime = new EventEmitter<boolean>();
  @Output() refIsTouched: EventEmitter<boolean> = new EventEmitter<boolean>();
  public allImages: ProjectImageData[] = [];

  public isPriorityDropdownOpen: boolean = false;
  public prioritySelect: SelectInput[] = getPrioritySelect();
  public selectedPriority: SelectInput = { name: "Mittel", id: 2 };

  public isVerkehrszustandDropdownOpen: boolean = false;
  public verkehrszustandSelect: SelectInput[] = getVerkehrszustandSelect();
  public selectedVerkehrszustand: SelectInput = { name: "Verkehrssituation " };

  public isTrafficDropdownOpen: boolean = false;
  public trafficSelect: SelectInput[] = getVerkehrsstufeSelect();
  public selectedTraffic: SelectInput = { name: "freier Verkehr", id: 1 };


  public flashIntervall: number = 0;
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00";
  public isOneTime: boolean = false;
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
    this.form = this.rootFormGroup.control.get("timerSwitchForm") as FormGroup;
  }

  public ngOnInit(): void {
    this.onPriorityItemClicked({ name: "Mittel", id: 2 });
  }

  public onEnterStartTimeChange(newValue: string): void {
    this.starttime = newValue;
    this.form.patchValue({ starttime: this.starttime })
  }

  public onEnterEndTimeChange(newValue: string): void {
    this.endtime = newValue;
    this.form.patchValue({ endtime: this.endtime })
  }

  public onPriorityItemClicked(item: SelectInput): void {
    this.isPriorityDropdownOpen = false;
    this.selectedPriority.name = item.name
    this.selectedPriority.id = item.id
    let prioId = item.id as number
    this.form.patchValue({ priority: prioId })
  }

  public onVerkehrszustandItemClicked(item: SelectInput): void {
    this.isVerkehrszustandDropdownOpen = false;
    this.selectedVerkehrszustand.name = item.name
    this.selectedVerkehrszustand.id = item.id
    let vsId = item.id as number
    this.form.patchValue({ vs: vsId })
    this.onTouchedChange()
  }
  
  public onTouchedChange(): void {
    this.refIsTouched.emit(true)
  }
  public onPriorityDropdownClicked() {
    this.isPriorityDropdownOpen = !this.isPriorityDropdownOpen;
  }

  public onVerkehrszustandDropdownClicked() {
    this.isVerkehrszustandDropdownOpen = !this.isVerkehrszustandDropdownOpen;
  }

  public onTrafficItemClicked(item: SelectInput): void {
    this.isTrafficDropdownOpen = false;
    this.selectedTraffic.name = item.name
    this.selectedTraffic.id = item.id
    let trafficId = item.id as number
    this.form.patchValue({ trafic: trafficId })
  }

  public onTrafficDropdownClicked() {
    this.isTrafficDropdownOpen = !this.isTrafficDropdownOpen;
  }

  public onOneTimeChange(): void {
    this.isOneTime = !this.isOneTime
    this.refIsOneTime.emit(this.isOneTime)
  }

  public onStartDateSelect(newValue: NgbDate) {
    this.startDate = newValue;
    this.form.patchValue({ startdate: { year: newValue.year, month: newValue.month, day: newValue.day } })
  }

  public onEndDateSelect(newValue: NgbDate) {
    this.endDate = newValue;
    this.form.patchValue({ enddate: { year: newValue.year, month: newValue.month, day: newValue.day } })
  }
}
