import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CompanyService} from "../../shared/services/company.service";
import {CompanyData} from "../../shared/interfaces/company-data.interface";
import {ModalService} from "../../../shared/modal/shared/modal.service";
import {AlertTypes} from "../../../shared/alert/shared/alert-types";
import {AlertService} from "../../../shared/alert/shared/alert.service";
import {Router} from "@angular/router";

@Component({
  selector: 'avs-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.css']
})
export class CompanyOverviewComponent implements OnInit{
  public companyData: CompanyData[] = [];

  constructor(private companyService: CompanyService,
              private modalService: ModalService,
              private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit() {
    this.getCompanyData();
  }

  public getCompanyData() {
    this.companyService.getCompanys().subscribe(companyData => {
      this.companyData = companyData;
    });
  }
  public onEditBtnClicked(companyId: number){
    this.router.navigate(['company/edit/', companyId]);
  }

  public onDeleteBtnClicked(companyId: number) {
    this.deleteCompany(companyId);
  }

  public deleteCompany(companyId: number) {
    this.modalService.openDialog('Firma löschen?', 'Nein', 'Ja').subscribe(() => {
      this.companyService.deleteCompany(companyId).subscribe(() => {
        this.alertService.alert('Firma wurde erfolgreich gelöscht', AlertTypes.info);
        this.getCompanyData();
      })
    });
  }

  public onAddBtnClicked(): void {
    this.router.navigate(['company/create']);
  }
}
