import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'avs-add-scenario-dialog',
  templateUrl: './add-scenario-dialog.component.html',
  styleUrls: ['./add-scenario-dialog.component.css']
})
export class AddScenarioDialogComponent {
  public activeGroupId: number = 0;
  public untouched: boolean = true;

  public name: string = '';
  public enabled: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddScenarioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.activeGroupId = data.group.id
  }

  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }

  public onNameChange(newValue: string): void {
    this.name = newValue;
    this.untouched = false;
  }

  public onSubmitClicked(): void {
    this.dialogRef.close({ name: this.name, enabled: this.enabled })
  }
}
