<div class="ms-4 mt-5 ; shadow rounded-0 bg-white " style="border: 1px solid #adb5bd; padding: 8px; margin-top: 8px;">
    <div class="mx-2  mt-2  " style=" width: 400px; background-color: white;">
        <div class="ms-3    justify-content-start  align-items-center">
            <img class="me-2" style=" height: 26px ">
            <span class="me-5 text-secondary faq-header"> FAQ Navigation</span>
        </div>

        <div class="ms-3 mb-2 d-flex mt-1 pb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">

            <a class="view-all ms-2 me-2 mt-2 " routerLinkActive="active" routerLink="../faq/account">Account</a>
            <a class="view-all ms-2 me-2" routerLinkActive="active" routerLink="../faq/overview">Übersicht</a>
            <a class="view-all ms-2 me-2" routerLinkActive="active" routerLink="../faq/project">Projekt</a>
            <a class="view-all ms-2 me-2" routerLinkActive="active" routerLink="../faq/report">Bericht</a>
            <a class="view-all ms-2 me-2" routerLinkActive="active" *canAccess="[1,5]"
                routerLink="../faq/bilder">Bilder</a>
            <a class="view-all ms-2 me-2" routerLinkActive="active" *canAccess="[1,5]"
                routerLink="../faq/swa">SWA/Stauwarnanlagen</a>
            <a class="view-all ms-2 me-2 " routerLinkActive="active" *canAccess="[1,5]"
                routerLink="../faq/timer-switch">Zeitschaltuhr</a>
        </div>
    </div>
</div>