import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EmnifyService {
    constructor(private readonly httpService: HttpService) { }

    public getRutOnlineStatus(guid: string): Observable<{ online: boolean }> {
        return this.httpService.get<{ online: boolean }>('Emnify/getRutOnlineStatus?guid=' + guid);
    }

    public EmnifySendSmsToEva(guid: string, command: number): Observable<any> {
        return this.httpService.post('Emnify/sendSms?guid=' + guid + '&message=' + command, {})
    }
}