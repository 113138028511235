import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ProjectImageData} from "../../shared/interfaces/project-image-data.interface";

@Injectable({
    providedIn: 'root'
})
export class ImageDataService {
    private projectImagesSubject$ = new BehaviorSubject<ProjectImageData[]>([]);
    public projectImages = this.projectImagesSubject$.asObservable();
    private projectUnassignedImagesSubject$ = new BehaviorSubject<ProjectImageData[]>([]);
    public projectUnassignedImages = this.projectUnassignedImagesSubject$.asObservable();
    private projectRouteStationImagesSubject$ = new BehaviorSubject<ProjectImageData[]>([]);
    public projectRouteStationImages = this.projectRouteStationImagesSubject$.asObservable();
    private projectRouteStationUnassignedImagesSubject$ = new BehaviorSubject<ProjectImageData[]>([]);
    public projectRouteStationUnassignedImages = this.projectRouteStationUnassignedImagesSubject$.asObservable();

    public updateProjectImages(images: ProjectImageData[]): void {
        this.projectImagesSubject$.next(images);
    }

    public updateUnassignedProjectImages(images: ProjectImageData[]): void {
        this.projectUnassignedImagesSubject$.next(images);
    }

    public updateProjectRouteStationImages(images: ProjectImageData[]): void {
        this.projectRouteStationImagesSubject$.next(images);
    }

    public updateUnassignedProjectRouteStationImages(images: ProjectImageData[]): void {
        this.projectRouteStationUnassignedImagesSubject$.next(images);
    }
}