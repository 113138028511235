import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { ProjectService } from "../../shared/services/project.service";
import { GroupMetaData, GroupMetaDataTypes } from "../shared/interfaces/group-meta.interface";
import { ProjectPositionData } from "../shared/interfaces/project-position.interface";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { ProjectGroup } from "../../shared/interfaces/project-group.interface";
import { getDiagramTypeString, getDiagramTypeSelect } from "../../shared/enums/diagram-type.enum";
import { getTrafficRulesString, getTrafficRulesSelect } from "../../shared/enums/traffic-rules.enum";
import { getGroupTypeString, getGroupTypeSelect } from "../../shared/enums/group-type.enum";
import { GroupService } from "../../shared/services/group.service";

@Component({
  selector: 'avs-group-settings-create-modal',
  templateUrl: './group-settings-create-modal.component.html',
  styleUrls: ['./group-settings-create-modal.component.css']
})
export class GroupSettingsCreateModalComponent {
  public modalPage: number = 1;
  public metaData: GroupMetaData = {
    name: undefined, routeName: undefined, groupType: undefined, diagramType: undefined, trafficRules: undefined, isActive: true
  };
  public positionData: ProjectPositionData = { latitude: undefined, longitude: undefined };
  protected readonly GroupMetaDataTypes = GroupMetaDataTypes;
  public isGroupTypesSelectOpen: boolean = false;
  public isDiagramTypesSelectOpen: boolean = false;
  public isTrafficRulesSelectOpen: boolean = false;
  public trafficLanes: number = 2;
  public groupTypeSelect: SelectInput[] = getGroupTypeSelect();
  public diagramTypeSelect: SelectInput[] = getDiagramTypeSelect();
  public trafficRulesSelect: SelectInput[] = getTrafficRulesSelect();
  protected readonly getGroupTypeString = getGroupTypeString;
  protected readonly getDiagramTypeString = getDiagramTypeString;
  protected readonly getTrafficRulesString = getTrafficRulesString;

  constructor(public dialogRef: MatDialogRef<GroupSettingsCreateModalComponent>,
    private alertService: AlertService,
    private projectService: ProjectService,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public onTrafficLanesSelectClicked(): void {
    this.isGroupTypesSelectOpen = !this.isGroupTypesSelectOpen;
    this.isDiagramTypesSelectOpen = false;
    this.isTrafficRulesSelectOpen = false;
  }

  public onGroupTypeSelectClicked(): void {
    this.isGroupTypesSelectOpen = !this.isGroupTypesSelectOpen;
    this.isDiagramTypesSelectOpen = false;
    this.isTrafficRulesSelectOpen = false;
  }

  public onDiagramTypeSelectClicked(): void {
    this.isDiagramTypesSelectOpen = !this.isDiagramTypesSelectOpen;
    this.isGroupTypesSelectOpen = false;
    this.isTrafficRulesSelectOpen = false;
  }

  public onTrafficRulesSelectClicked(): void {
    this.isTrafficRulesSelectOpen = !this.isTrafficRulesSelectOpen;
    this.isGroupTypesSelectOpen = false;
    this.isDiagramTypesSelectOpen = false;
  }

  public onMetaValueChange(value: any, type: GroupMetaDataTypes): void {
    switch (type) {
      case GroupMetaDataTypes.name:
        this.metaData.name = value;
        break;
      case GroupMetaDataTypes.routeName:
        this.metaData.routeName = value;
        break;
      case GroupMetaDataTypes.diagramType:
        this.metaData.diagramType = value.id;
        this.isDiagramTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.groupType:
        this.metaData.groupType = value.id;
        this.isGroupTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.trafficRules:
        this.metaData.trafficRules = value.id;
        this.isTrafficRulesSelectOpen = false;
        break;
      case GroupMetaDataTypes.isActive:
        this.metaData.isActive = value.checked;
        break;
    }
  }

  public onPositionValueChange(value: string, isLatitude: boolean): void {
    if (isLatitude) {
      this.positionData.latitude = +value;
    } else {
      this.positionData.longitude = +value;
    }
  }

  public onCancelBtnClicked(): void {
    this.dialogRef.close();
  }

  public onFurtherBtnClicked(): void {
    if (!this.metaData.name) {
      this.alertService.alert('Gruppen Name ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    this.modalPage++;
  }

  public onBackBtnClicked(): void {
    this.modalPage--;
  }

  public onSaveBtnClicked(): void {
    if (!this.positionData.latitude) {
      this.alertService.alert('Latitude ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    if (!this.positionData.longitude) {
      this.alertService.alert('Longitude ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    this.createGroup();
  }

  private createGroup(): void {
    let newGroup = {
      name: this.metaData.name,
      routeName: this.metaData.routeName,
      groupType: this.metaData.groupType,
      diagramType: this.metaData.diagramType,
      trafficRules: this.metaData.trafficRules,
      isActive: this.metaData.isActive,
      latitude: this.positionData.latitude,
      longitude: this.positionData.longitude,
      isDeleted: false,
      projectId: this.data,
      timer: '{}', /* ? */
      trafficLanes: this.trafficLanes
    } as ProjectGroup
    this.groupService.createNewProjectGroup(newGroup).subscribe(group => {
      this.alertService.alert('Neue Projekt Gruppe "' + group.name + '" erstellt.', AlertTypes.success);
      this.dialogRef.close(true);
    });
  }
}
