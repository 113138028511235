import { Component } from '@angular/core';
import { UserDataResponse } from '../../user/shared/interfaces/login-data-response.interface';
import { ActivatedRoute } from '@angular/router';
import { UserDataService } from '../../user/shared/services/user-data.service';

@Component({
  selector: 'avs-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent {
  public userData?: UserDataResponse;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly userDataService: UserDataService) {
    this.userData = activatedRoute.snapshot.data['userData'];
    this.userDataService.updateUserData(activatedRoute.snapshot.data['userData']);
  }
}
