<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Dokumente zu {{ data.name }} </h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseFileUploaderClicked()">
    </div>

    <input type="file" class="file-upload" (change)="onFileSelected($event)">


    <div class="file-upload d-flex justify-content-between mt-2">
        <div>
            {{fileName ? fileName : ''}}
        </div>
        <button class="btn btn-sm btn-success rounded-0" (click)="onUploadFileClicked()">
            Hinzufügen
        </button>
    </div>