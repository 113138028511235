import {Component, OnInit} from '@angular/core';
import {AlertService} from "../../shared/alert/shared/alert.service";
import {UserDataService} from "../shared/services/user-data.service";
import {UserDataResponse} from "../shared/interfaces/login-data-response.interface";
import {UserService} from "../shared/services/user.service";
import {AlertTypes} from "../../shared/alert/shared/alert-types";
import {PasswordData} from "./shared/password-data.interface";
import {EditUserPassword} from "./shared/edit-user-password.interface";
import {ActivatedRoute} from "@angular/router";
import {EditUser} from "../shared/interfaces/edit-user.interface";

@Component({
  selector: 'avs-user-data',
  templateUrl: './user-data-settings.component.html',
  styleUrls: ['./user-data-settings.component.css']
})
export class UserDataSettingsComponent implements OnInit {
  public userData: UserDataResponse | undefined;
  public passwordData: PasswordData = {oldPassword: '',newPassword: '',confirmNewPassword: ''};
  public editUserPassword: EditUserPassword | undefined;
  private isPasswordsMatch: boolean = false;

  constructor(private userDataService: UserDataService,
              private userService: UserService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.userDataService.userData.subscribe(userData => {
      this.userData = userData;
    });
  }

  public onTitleValueChange(newValue: string){
    if(this.userData) {
      this.userData.title = newValue;
    }
  }

  public onFirstNameValueChange(newValue: string){
    if(this.userData) {
      this.userData.firstName = newValue;
    }
  }

  public onLastNameValueChange(newValue: string){
    if(this.userData) {
      this.userData.lastName = newValue;
    }
  }

  public onEmailValueChange(newValue: string){
    if(this.userData) {
      this.userData.email = newValue;
    }
  }

  public onNewPasswordChange(newValue: string) {
    this.passwordData.newPassword = newValue;
    this.validateNewPassword();
  }

  public onConfirmNewPasswordChange(newValue: string) {
    this.passwordData.confirmNewPassword = newValue;
    this.validateNewPassword();
  }

  public onOldPasswordChange(newValue: string) {
    this.passwordData.oldPassword = newValue;
  }

  public onSaveUserDataBtnClicked() {
    this.updateUserData();
  }

  public onSaveNewPasswordBtnClicked() {
    if (this.isPasswordsMatch) {
      this.setNewUserPassword();
      this.updatePasswordData();
    } else {
      this.alertService.alert("Die neuen Passwörter stimmen nicht überein.", AlertTypes.error);
    }
  }

  private updateUserData() {
    if (this.userData) {
      let user = {
        id: this.userData.id,
        title: this.userData.title,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        role: this.userData.role,
        email: this.userData.email,
        company: this.userData.company
      }as EditUser
      this.userService.editUserData(this.userData.id, user).subscribe(userData => {
        this.alertService.alert("Benutzerdaten wurden erfolgreich geändert.", AlertTypes.info);
      });
    }
  }

  private updatePasswordData() {
    if(this.editUserPassword) {
      this.userService.editPasswordData(this.userData?.id!,this.editUserPassword).subscribe(userData => {
        this.alertService.alert("Das Passwort wurde erfolgreich geändert.", AlertTypes.info);
      });
    }
  }

  private setNewUserPassword() {
    if (this.userData) {
      this.editUserPassword = {
        title: this.userData.title,
        id: this.userData.id,
        email: this.userData.email,
        oldPassword: this.passwordData.oldPassword,
        newPassword: this.passwordData.newPassword,
   /*      role: this.userData.role, */
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        company: this.userData.company

        
      }
    }
  }

  private validateNewPassword() {
    if (this.passwordData.newPassword === this.passwordData.confirmNewPassword) {
      this.isPasswordsMatch = true;
    } else {
      this.isPasswordsMatch = false;
    }
  }
}
