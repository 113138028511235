import {Injectable} from "@angular/core";
import {HttpService} from "../../../core/shared/http.service";
import {Observable} from "rxjs";
import {Display} from "../interfaces/display.interface";

@Injectable({
    providedIn: 'root'
})
export class DisplayService {
    constructor(private httpService: HttpService) {
    }

    public getDisplayTypes(): Observable<Display[]> {
        return this.httpService.get<Display[]>('Display');
    }
}