import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";

export enum DiagramTypeEnum {
    Static,
    Dragable,
    User
}
export function getDiagramTypeString(diagramType: DiagramTypeEnum): string {
    switch (diagramType) {
        case DiagramTypeEnum.Static:
            return 'Statisch'
        case DiagramTypeEnum.Dragable:
            return 'Dynamisch'
        case DiagramTypeEnum.User:
            return 'Benutzerdefiniert'
    }
}

export function getDiagramTypeSelect(): SelectInput[] {
    return [
        {name: getDiagramTypeString(DiagramTypeEnum.Static), id: 0},
        {name: getDiagramTypeString(DiagramTypeEnum.Dragable), id: 1},
        {name: getDiagramTypeString(DiagramTypeEnum.User), id: 2}
    ];
}