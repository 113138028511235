import { Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { CheckboxDropdownItem } from 'src/app/shared/checkbox-dropdown/shared/checkbox-dropdown-item.interface';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { WorkloadData } from 'src/app/dashboard/shared/interfaces/workloadData.interface';
import { ChartData } from 'src/app/dashboard/shared/chart-data.class';

@Component({
  selector: 'avs-workload-diagramm',
  templateUrl: './workload-diagramm.component.html',
  styleUrls: ['./workload-diagramm.component.css']
})

export class WorkloadDiagrammComponent {
  public chart: any;
  public projectIds: number[] = [];
  public projects: Project[] = [];
  private chartWorkloadData = new ChartData({ name: 'Offline mit Projekt', value: 600 }, { name: 'Online mit Projekt', value: 600 },{ name: 'Offline ohne Projekt', value: 600 }, { name: 'Online ohne Projekt', value: 600 })
  public isDropdownOpen: boolean = false;
  public chartData?: WorkloadData;
  public projectSelectInput: CheckboxDropdownItem[] = [];
  public isCheckboxDropdownOpen: boolean = false;

  constructor(private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getData();
    }
      , 1000);

  }


  private getData(): void {
    this.reportService.getRouteStationUsageReport().subscribe((x: WorkloadData) => {
      this.chartWorkloadData.item1.value = Number(x.offlineDevicesWithProject);
      this.chartWorkloadData.item2.value = Number(x.onlineDevicesWithProject)
    
      this.chartWorkloadData.item3!.value = Number(x.offlineDevicesWithoutProject)
      this.chartWorkloadData.item4!.value = Number(x.onlineDevicesWithoutProject)
      this.createChart();
    })
  }


  private createChart(): void {
    this.chart = new Chart("workload", {
      type: 'doughnut',

      data: {
        labels: [this.chartWorkloadData.item1.name, this.chartWorkloadData.item2.name, this.chartWorkloadData.item3!.name, this.chartWorkloadData.item4!.name],
        datasets: [{
          data: [this.chartWorkloadData.item1.value, this.chartWorkloadData.item2.value, this.chartWorkloadData.item3!.value, this.chartWorkloadData.item4!.value],
          backgroundColor: [
            '#d40c14',  /* rot */
            '#84c36c', /* grün */
            '#d40c14',  /* rot */
            '#84c36c', /* grün */
          ],
          hoverBackgroundColor: [
            '#bc0404',
            '#5ca43c',
            '#bc0404',
            '#5ca43c',
          ],
          hoverOffset: 0
        }],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            font: {
              size: 13,
              weight: 'bold',
            },
            color: 'white',
            formatter: function (value: number, context: any) {
              const dataPoints = context.chart.data.datasets[0].data;
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              const totalValue = dataPoints.reduce(totalSum, 0);
              const percentageValue = (value / totalValue * 100).toFixed(1);

              return ` ${percentageValue}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

}
