import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getVerkehrsstufeSelect, getVerkehrsstufeString } from 'src/app/project/shared/enums/verkehrsstufe';
import { getVerkehrszustandSelect, getVerkehrszustandString } from 'src/app/project/shared/enums/verkehrszustand';
import { DataEndChannel } from 'src/app/project/shared/interfaces/data-end-channel.interface';
import { ManualContrlObject } from 'src/app/project/shared/interfaces/manual-contrl-object';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { ProjectRouteStationWithParam } from 'src/app/project/shared/interfaces/project-route-station.class';
import { ManualControlService } from 'src/app/project/shared/services/manual-control.service';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'avs-project-scenario-route-station-modul',
  templateUrl: './project-scenario-route-station-modul.component.html',
  styleUrls: ['./project-scenario-route-station-modul.component.css']
})
export class ProjectScenarioRouteStationModulComponent implements OnInit, OnDestroy {
  public routeStation?: ProjectRouteStationWithParam | undefined;
  public fg?: number;
  public de?: number;
  public localbusaddress?: number;
  public tlS_Time: number = 0;
  public highPriority: boolean = false;

  public manualControlId?: number;

  public isVerkehrszustandDropdownOpen: boolean = false;
  public verkehrszustandSelect: SelectInput[] = getVerkehrszustandSelect();
  public selectedVerkehrszustand: SelectInput = { name: "Verkehrssituation " };


  public isTrafficDropdownOpen: boolean = false;
  public trafficSelect: SelectInput[] = getVerkehrsstufeSelect();
  public selectedTraffic: SelectInput = { name: "freier Verkehr", id: 1 };

  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }, { name: 'Blinkend', id: 2 }];
  public selectedFunction: SelectInput = { name: "Funktion" };

  public isPWFunctionDropdownOpen: boolean = false;
  public PWFunctionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }];
  public selectedPWFunction: SelectInput = { name: "Funktion" };



  public allImages: ProjectImageData[] = [];
  public images: ProjectImageData[] = [];
  public imagesPW: ProjectImageData[] = [];
  public selectedImage?: ProjectImageData;
  public preSelectedImage?: ProjectImageData;

  public imageIntervalCollection: any[] = [];
  protected readonly environment = environment;
  constructor(public dialogRef: MatDialogRef<ProjectScenarioRouteStationModulComponent>,
    private projectService: ProjectService,
    private manualControlService: ManualControlService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.fg = data.kanal.fg;
    this.de = data.kanal.de;
    this.localbusaddress = data.kanal.localbusAddress;
    this.routeStation = data.routeStation;
  }

  public ngOnInit(): void {

    if (this.data.kanal.manualControlObj && this.data.scenarioManual) {
      this.data.kanal.manualControlObj[0].manualControl = this.data.scenarioManual.manualControl
      this.data.kanal.manualControlObj[0].scenarioId = this.data.scenarioManual.scenarioId
      let manualControlSettings = this.data.kanal.manualControlObj
      let settings = manualControlSettings.find((x: ManualContrlObject) => x.scenarioId === this.data.scenarioId)
      let parsedSettings = JSON.parse(settings.manualControl.settings)
      if (this.data.kanal.manualControlObj[0].manualControl) { this.highPriority = this.data.kanal.manualControlObj[0].manualControl.highPriority }
      if (this.data.routeStation !== undefined) { if (parsedSettings) { this.getRouteStationsImages(parsedSettings) } else return }
      this.SelectSettings(parsedSettings)
    }
    else {
      this.getRouteStationsImages()
    }

  }

  public ngOnDestroy(): void {
    this.imageIntervalCollection.forEach(id => clearInterval(id));
  }

  public SelectSettings(settings: any): void {
    if (settings.MQ !== null) {
      this.onVerkehrszustandItemClicked({ id: settings.MQ.VS, name: getVerkehrszustandString(settings.MQ.VS) })
      this.onTrafficItemClicked({ id: settings.MQ.Traffic, name: getVerkehrsstufeString(settings.MQ.Traffic) })
    }
    if (settings.AQ !== null) {
      this.preSelectedImage = this.getImageByStellcode(settings.AQ.Code)
      if (this.preSelectedImage) {
        this.onProjectImageClicked(this.preSelectedImage!)
      }
      if (settings.AQ.Function === 2) {
        this.onFunctionItemClicked({ id: settings.AQ.Function, name: 'Blinkend' });
        this.tlS_Time = settings.AQ.Time
      }
      if (settings.AQ.Function === 1) {
        this.onFunctionItemClicked({ id: settings.AQ.Function, name: 'An' });
      }
      if (settings.AQ.Function === 0) {
        this.onFunctionItemClicked({ id: settings.AQ.Function, name: 'Aus' });
      }
    }
  }

  public getImageByStellcode(stellcode: number) {
    if (!stellcode) return
    if (this.images.length === 0) return
    let image = this.images.find((x) => x.stellcode == stellcode)
    return image

  }

  public getRouteStationsImages(settings?: any) {
    if (settings) {
      if (settings.AQ !== null) {
        this.projectService.getAllRouteStationImagesWithStationId(this.data.projectId, this.data.routeStation.routeStation.id).subscribe(images => {
          this.allImages = images;
          this.images = images;
          this.imagesPW = images.filter(x => x.stellcode > 240)
          this.rotateImagesPaths(images);
          this.preSelectedImage = this.getImageByStellcode(settings.AQ.Code)
          if (this.preSelectedImage) {
            setTimeout(() => {
              this.onProjectImageClicked(this.preSelectedImage!)
            }, 25);
          }
        });
      }
    }
    else {
      this.projectService.getAllRouteStationImagesWithStationId(this.data.projectId, this.data.routeStation.routeStation.id).subscribe(images => {
        this.allImages = images;
        this.images = images;
        this.imagesPW = images.filter(x => x.stellcode > 240)
        this.rotateImagesPaths(images);
      });
    }
  }

  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
    this.imageIntervalCollection.push(id);
  }

  public getPathByImageId(imageId: number) {

    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }

  public onTrafficItemClicked(item: SelectInput): void {
    this.isTrafficDropdownOpen = false;
    this.selectedTraffic.name = item.name
    this.selectedTraffic.id = item.id
  }

  public onVerkehrszustandItemClicked(item: SelectInput): void {
    this.isVerkehrszustandDropdownOpen = false;
    this.selectedVerkehrszustand.name = item.name
    this.selectedVerkehrszustand.id = item.id
  }

  public onVerkehrszustandDropdownClicked() {
    this.isVerkehrszustandDropdownOpen = !this.isVerkehrszustandDropdownOpen;
  }
  public onTrafficDropdownClicked() {
    this.isTrafficDropdownOpen = !this.isTrafficDropdownOpen;
  }
  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }

  public onDeleConfigClicked(): void {
    this.manualControlService.deleteManualControl(this.data.kanal.manualControlObj[0].manualControl.id).subscribe(() => {
      this.dialogRef.close();
    });
  }

  public onProjectImageClicked(image: ProjectImageData) {
    if (this.images.length > 1) { this.allImages = this.images }
    if (this.images) {
      if (this.images.length > 1) {
        this.selectedImage = image
        this.images = [];
        this.images.push(image)
      }
      else if (this.images.length === 1) {
        this.images = this.allImages
      }
    }
  }


  public onProjectPWImageClicked(image: ProjectImageData) {
    if (this.imagesPW.length > 1) { this.imagesPW = this.images.filter(x => x.stellcode > 240) }
    if (this.imagesPW) {
      if (this.imagesPW.length > 1) {
        this.selectedImage = image
        this.imagesPW = [];
        this.imagesPW.push(image)
      }
      else if (this.imagesPW.length === 1) {
        this.imagesPW = this.images.filter(x => x.stellcode > 240)
      }
    }
  }

  public onSubmitMQClicked(): void {
    if (this.data.kanal.manualControlObj) {
      this.dialogRef.close({
        settings: `{\"AQ\": null, \"MQ\": {\"VS\": ${this.selectedVerkehrszustand.id}, \"Active\": true, \"Traffic\": ${this.selectedTraffic.id}}}`,
        kanal: this.data.kanal,
        highPriority: this.highPriority,
        type: 'MQ',
        manualControlId: this.data.kanal.manualControlObj[0].manualControl.id,
      })
    }
    else
      this.dialogRef.close({
        settings: `{\"AQ\": null, \"MQ\": {\"VS\": ${this.selectedVerkehrszustand.id}, \"Active\": true, \"Traffic\": ${this.selectedTraffic.id}}}`,
        kanal: this.data.kanal,
        highPriority: this.highPriority,
        type: 'MQ'
      }) /* Active Bool - Bleibt erstmal TRUE */
  }

  public onSubmitAQClicked(): void {
    if (this.data.kanal.manualControlObj) {
      this.dialogRef.close({
        settings: `{\"AQ\": {\"Active\": true, \"Code\": ${this.selectedImage?.stellcode}, \"Function\": ${this.selectedFunction.id}, \"Time\":${this.tlS_Time} }, \"MQ\": null}`,
        manualControlId: this.data.kanal.manualControlObj[0].manualControl.id,
        highPriority: this.highPriority,
        type: 'AQ'
      }) /* Active Bool - Bleibt erstmal TRUE */

    }
    else {
      this.dialogRef.close({
        settings: `{\"AQ\": {\"Active\": true, \"Code\": ${this.selectedImage?.stellcode}, \"Function\": ${this.selectedFunction.id}, \"Time\":${this.tlS_Time} }, \"MQ\": null}`,

        highPriority: this.highPriority,
        type: 'AQ'
      })
    }
  }


  public onSubmitFlasherClicked(): void {
    if (this.data.kanal.manualControlObj) {
      this.dialogRef.close({
        settings: `{\"AQ\": {\"Active\": true, \"Code\": 61, \"Function\": ${this.selectedFunction.id}, \"Time\":${this.tlS_Time} }, \"MQ\": null}`,
        kanal: this.data.kanal,
        highPriority: this.highPriority,
        type: 'AQ',
        manualControlId: this.data.kanal.manualControlObj[0].manualControl.id,
      })
    }
    else {
      this.dialogRef.close({
        settings: `{\"AQ\": {\"Active\": true, \"Code\": 61, \"Function\": ${this.selectedFunction.id}, \"Time\":${this.tlS_Time} }, \"MQ\": null}`,
        kanal: this.data.kanal,
        highPriority: this.highPriority,
        type: 'AQ'
      })
    }
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    if (this.selectedFunction.id !== 2) this.tlS_Time = 0;
  }

  public onPWFunctionItemClicked(item: SelectInput): void {
    this.isPWFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id

  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onPWFunctionDropdownClicked(): void {
    this.isPWFunctionDropdownOpen = !this.isPWFunctionDropdownOpen;
  }
}
