<mat-dialog-content class="rounded-0 overflow-x-hidden">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Custom Bild hochladen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseModalClicked()">
    </div>
    <input type="file" (change)="handleFileInput($event)" class="form-control form-control-sm ms-2 mb-2 rounded-0 " #fileUpload>

    <div class="ms-2 mb-2 d-flex justify-content-center ">
        <img [src]="preview" class="preview">
    </div>
    <div class="d-flex justify-content-center "> Stellcodeauswahl</div>
    <div class="w-100 d-flex justify-content-center ms-2 mb-2">

        <div class="avs-route-station-settings-dropdown-container justify-content-between">
            <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onFunctionDropdownClicked()">
                <div class="ms-2 p-1" [ngClass]="onGetSelectedClass()">{{ selectStellcode.name }}</div> <!-- in ngclass eine return methode einbinden die prüft ob stellcode.id in filtered.imageId vorhanden ist und wenn ja add class highlight green -->
                <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="stellcodeSelect" [activeItem]="selectStellcode"
                (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false" *ngIf="isFunctionDropdownOpen"
                class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>

    <div class="d-flex justify-content-between mt-5 justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseModalClicked()">Abbrechen</button>
        <button *ngIf="preview" class="btn btn-sm btn-outline-success rounded-0" (click)="confirmationsDialog()">Bild
            hochladen</button>
    </div>
</mat-dialog-content>
