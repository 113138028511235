import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";
import {DiagramTypeEnum} from "./diagram-type.enum";

export enum GroupTypeEnum {
    SWA,
    LIST,
    WeighingSystem
}
export function getGroupTypeString(groupType: GroupTypeEnum): string {
    switch (groupType) {
        case GroupTypeEnum.SWA:
            return 'SWA'
        case GroupTypeEnum.LIST:
            return 'Liste'
        case GroupTypeEnum.WeighingSystem:
            return 'Wiegeanlage'
    }
}

export function getGroupTypeSelect(): SelectInput[] {
    return [
        {name: getGroupTypeString(GroupTypeEnum.SWA), id: 0} as SelectInput,
        {name: getGroupTypeString(GroupTypeEnum.LIST), id: 1} as SelectInput,
        {name: getGroupTypeString(GroupTypeEnum.WeighingSystem), id: 2} as SelectInput
    ];
}