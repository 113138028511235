import {Injectable} from "@angular/core";

const DeviceRouteStationSession: string = 'activeDeviceRouteStation';

@Injectable({
    providedIn: 'root'
})
export class DeviceDataSessionStorageService {

    public getActiveRouteStation(): string | null {
        return sessionStorage.getItem(DeviceRouteStationSession);
    }

    public setActiveRouteStation(value: string): void {
        sessionStorage.setItem(DeviceRouteStationSession, value);
    }

    public deleteActiveRouteStation(): void {
        sessionStorage.removeItem(DeviceRouteStationSession);
    }
}