import { SelectInput } from "src/app/shared/dropdown/shared/select-input.class";


export enum PriorityEnum {
    HIGHEST,
    HIGH,
    MEDIUM,
    LOW,
    LOWEST
}




export function getPriorityString(type: PriorityEnum): string {
    switch (type) {
        case PriorityEnum.HIGHEST:
            return 'Sehr hoch';
        case PriorityEnum.HIGH:
            return 'Hoch';
        case PriorityEnum.MEDIUM:
            return 'Mittel';
        case PriorityEnum.LOW:
            return 'Niedrig';
        case PriorityEnum.LOWEST:
            return 'Sehr niedrig';
    }
}

export function getPrioritySelect(): SelectInput[] {
    return [
        { name: getPriorityString(PriorityEnum.HIGHEST), id: 0 },
        { name: getPriorityString(PriorityEnum.HIGH), id: 1 },
        { name: getPriorityString(PriorityEnum.MEDIUM), id: 2 },
        { name: getPriorityString(PriorityEnum.LOW), id: 3 },
        { name: getPriorityString(PriorityEnum.LOWEST), id: 4 },
    ];
}