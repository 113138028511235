<div class="d-flex justify-content-between">
    <h5 class="fw-semibold ms-2 ">Engine Parameter</h5>
    <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
        (click)="onNoClick()">
</div>
<mat-dialog-content>

<span class="text-danger mb-1"> {{ information }}</span>
    <ng-container *ngIf="fg1Obj">
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsstärke kfz/h Harmonisierung 120 aus" (valueChange)="fg1Obj.Q_Bg120_aus = +$event"
            [value]="fg1Obj.Q_Bg120_aus" placeholder="Verkehrsstärke kfz/h Harmonisierung 120 aus" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsstärke kfz/h Harmonisierung 120 ein" (valueChange)="fg1Obj.Q_Bg120_ein = +$event"
            [value]="fg1Obj.Q_Bg120_ein" placeholder="Verkehrsstärke kfz/h Harmonisierung 120 ein" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 100 aus" (valueChange)="fg1Obj.K_g100_aus = +$event"
            [value]="fg1Obj.K_g100_aus" placeholder="Verkehrsdichte kfz/km Harmonisierung 100 aus" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 100 ein" (valueChange)="fg1Obj.K_g100_ein = +$event"
            [value]="fg1Obj.K_g100_ein" placeholder="Verkehrsdichte kfz/km Harmonisierung 100 ein" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 80 aus" (valueChange)="fg1Obj.K_g80_aus = +$event"
            [value]="fg1Obj.K_g80_aus" placeholder="Verkehrsdichte kfz/km Harmonisierung 80 aus" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 80 ein" (valueChange)="fg1Obj.K_g80_ein = +$event"
            [value]="fg1Obj.K_g80_ein" placeholder="Verkehrsdichte kfz/km Harmonisierung 80 ein" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 60 aus" (valueChange)="fg1Obj.K_g60_aus = +$event"
            [value]="fg1Obj.K_g60_aus" placeholder="Verkehrsdichte kfz/km Harmonisierung 60 aus" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsdichte kfz/km Harmonisierung 60 ein" (valueChange)="fg1Obj.K_g60_ein = +$event"
            [value]="fg1Obj.K_g60_ein" placeholder="Verkehrsdichte kfz/km Harmonisierung 60 ein" [type]="'number'"
            [measurement]="'kfz/km'" [min]="1" [max]="100">
        </avs-text-input>



        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsstärke kfz/h Harmonisierung 100 aus" (valueChange)="fg1Obj.Q_Bg100_aus = +$event"
            [value]="fg1Obj.Q_Bg100_aus" placeholder="Verkehrsstärke kfz/h Harmonisierung 100 aus" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Verkehrsstärke kfz/h Harmonisierung 100 ein" (valueChange)="fg1Obj.Q_Bg100_ein = +$event"
            [value]="fg1Obj.Q_Bg100_ein" placeholder="Verkehrsstärke kfz/h Harmonisierung 100 ein" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Verkehrsstärke kfz/h Harmonisierung 80 aus"
            (valueChange)="fg1Obj.Q_Bg80_aus = +$event" [value]="fg1Obj.Q_Bg80_aus"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 80 aus" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Verkehrsstärke kfz/h Harmonisierung 80 ein"
            (valueChange)="fg1Obj.Q_Bg80_ein = +$event" [value]="fg1Obj.Q_Bg80_ein"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 80 ein" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Verkehrsstärke kfz/h Harmonisierung 60 aus"
            (valueChange)="fg1Obj.Q_Bg60_aus = +$event" [value]="fg1Obj.Q_Bg60_aus"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 60 aus" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
        </avs-text-input>

        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Verkehrsstärke kfz/h Harmonisierung 60 ein"
            (valueChange)="fg1Obj.Q_Bg60_ein = +$event" [value]="fg1Obj.Q_Bg60_ein"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 60 ein" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
        </avs-text-input>


        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Geschwindigkeit km/h Harmonisierung 100 aus" (valueChange)="fg1Obj.V_pkq100_aus = +$event"
            [value]="fg1Obj.V_pkq100_aus" placeholder="Geschwindigkeit km/h Harmonisierung 100 aus" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Geschwindigkeit km/h Harmonisierung 100 ein" (valueChange)="fg1Obj.V_pkq100_ein = +$event"
            [value]="fg1Obj.V_pkq100_ein" placeholder="Geschwindigkeit km/h Harmonisierung 100 ein" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit km/h Harmonisierung 80 aus"
            (valueChange)="fg1Obj.V_pkq80_aus = +$event" [value]="fg1Obj.V_pkq80_aus"
            placeholder="Geschwindigkeit km/h Harmonisierung 80 aus" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit km/h Harmonisierung 80 ein"
            (valueChange)="fg1Obj.V_pkq80_ein = +$event" [value]="fg1Obj.V_pkq80_ein"
            placeholder="Geschwindigkeit km/h Harmonisierung 80 ein" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit km/h Harmonisierung 60 aus"
            (valueChange)="fg1Obj.V_pkq60_aus = +$event" [value]="fg1Obj.V_pkq60_aus"
            placeholder="Geschwindigkeit km/h Harmonisierung 60 aus" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit km/h Harmonisierung 60 ein"
            (valueChange)="fg1Obj.V_pkq60_ein = +$event" [value]="fg1Obj.V_pkq60_ein"
            placeholder="Geschwindigkeit km/h Harmonisierung 60 ein" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>

        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit in km/h für Stau an"
            (valueChange)="fg1Obj.V_stau_aus = +$event" [value]="fg1Obj.V_stau_aus"
            placeholder="Geschwindigkeit in km/h für Stau an" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeit in km/h für Stau aus"
            (valueChange)="fg1Obj.V_stau_ein = +$event" [value]="fg1Obj.V_stau_ein"
            placeholder="Geschwindigkeit in km/h für Stau aus" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Geschwindigkeit in km/h für Stau nach Belegung" (valueChange)="fg1Obj.Vb_stau_ein = +$event"
            [value]="fg1Obj.Vb_stau_ein" placeholder="Geschwindigkeit in km/h für Stau nach Belegung" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeitsbegrenzung"
            (valueChange)="fg1Obj.Vmax = +$event" [value]="fg1Obj.Vmax" placeholder="Geschwindigkeitsbegrenzung"
            [type]="'number'" [measurement]="'km/h'" [min]="1" [max]="120">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Belegung % Stau aus"
            (valueChange)="fg1Obj.b_stau_aus = +$event" [value]="fg1Obj.b_stau_aus" placeholder="Belegung % Stau aus"
            [type]="'number'" [measurement]="'%'" [min]="1" [max]="100">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Geschwindigkeit in km/h für Stau nach Belegung" (valueChange)="fg1Obj.b_stau_ein = +$event"
            [value]="fg1Obj.b_stau_ein" placeholder="Geschwindigkeit in km/h für Stau nach Belegung" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Fahrspuren"
            (valueChange)="fg1Obj.LINES = +$event" [value]="fg1Obj.LINES" placeholder="Anzahl der erfassten Fahrspuren"
            [type]="'number'" [measurement]="' '" [min]="1" [max]="3">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Erfassungsperiode in Sekunden"
            (valueChange)="fg1Obj.Periode = +$event" [value]="fg1Obj.Periode"
            placeholder="Erfassungsperiode in Sekunden" [type]="'number'" [measurement]="'s'" [min]="15" [max]="120">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Verkehrsstärke kfz/h Stau"
            (valueChange)="fg1Obj.Q_kfz_stau = +$event" [value]="fg1Obj.Q_kfz_stau"
            placeholder="Verkehrsstärke kfz/h Stau" [type]="'number'" [measurement]="'kfz/h'" [min]="1" [max]="10000">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'"
            label="Anzahl der Perioden die ein Zustand anliegen muss bis dieser übernommen wird"
            (valueChange)="fg1Obj.ResponseDelay = +$event" [value]="fg1Obj.ResponseDelay"
            placeholder="Anzahl der Perioden die ein Zustand anliegen muss bis dieser übernommen wird" [type]="'number'"
            [measurement]="' '" [min]="1" [max]="10">
        </avs-text-input>
        <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Geschwindigkeitsdifferenz pkw/lkw für Stau"
            (valueChange)="fg1Obj.V_dif_stau = +$event" [value]="fg1Obj.V_dif_stau"
            placeholder="Geschwindigkeitsdifferenz pkw/lkw für Stau" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="50">
        </avs-text-input>
    </ng-container>
    <ng-container *ngIf="fg4Obj">
        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Fließverkehr-Code (z1,z2)</mat-label> <!-- Freie Fahrt? deswegen code aus? fehlt 1 z? -->
                    <mat-select [(ngModel)]="fg4Obj.FreeCode">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue ? img.viewValue : 'Stellcode fehlt'}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Fließverkehr Funktion (z1,z2)</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.FreeFunktion" (ngModelChange)="freeChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.FreeFunktion !== 2 ? 'grayscale' : ''">Fließverkehr
                    (200ms)</label>
                <input [disabled]="fg4Obj.FreeFunktion !== 2" type="number"
                    [ngClass]="fg4Obj.FreeFunktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="FreeTime" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.FreeTime" min="1"
                    max="15">
            </div>
        </div>


        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline" color="primary" style="border-radius: 0px;">
                    <mat-label>zäher Verkehr-Code (z3)</mat-label> <!-- Staugefahr? -->
                    <mat-select [(ngModel)]="fg4Obj.DangerCode">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>zäher Verkehr-Funktion (z3)</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.DangerFunktion" (ngModelChange)="dangerChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.DangerFunktion !== 2 ? 'grayscale' : ''">zäher Verkehr
                    (200ms)</label>
                <input [disabled]="fg4Obj.DangerFunktion !== 2" type="number" class="form-control input-as-FF rounded-0"
                    aria-label="DangerTime" aria-describedby="basic-addon2"
                    [ngClass]="fg4Obj.DangerFunktion !== 2 ? 'grayscale' : ''" [(ngModel)]="fg4Obj.DangerTime" min="1"
                    max="15">
            </div>
        </div>

        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Stau-Code (z4)</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.JamCode">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Stau-Funktion (z4)</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.JamFunktion" (ngModelChange)="jamChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.JamFunktion !== 2 ? 'grayscale' : ''">Stau (200ms)</label>
                <input [disabled]="fg4Obj.JamFunktion !== 2" type="number"
                    [ngClass]="fg4Obj.JamFunktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="JamTime" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.JamTime" min="1" max="15">
            </div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 120 - Code</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V120Code">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 120 - Funktion</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V120Funktion" (ngModelChange)="v120ChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.V120Funktion !== 2 ? 'grayscale' : ''">Harmonisierung 120
                    (200ms)</label>
                <input [disabled]="fg4Obj.V120Funktion !== 2" type="number"
                    [ngClass]="fg4Obj.V120Funktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="V120Time" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.V120Time" min="1"
                    max="15">
            </div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 100 - Code</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V100Code">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 100 - Funktion</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V100Funktion" (ngModelChange)="v100ChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.V100Funktion !== 2 ? 'grayscale' : ''">Harmonisierung 100
                    (200ms)</label>
                <input [disabled]="fg4Obj.V100Funktion !== 2" type="number"
                    [ngClass]="fg4Obj.V100Funktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="V100Time" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.V100Time" min="1"
                    max="15">
            </div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 80 - Code</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V80Code">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 80 - Funktion</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V80Funktion" (ngModelChange)="v80ChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.V80Funktion !== 2 ? 'grayscale' : ''">Harmonisierung 80
                    (200ms)</label>
                <input [disabled]="fg4Obj.V80Funktion !== 2" type="number"
                    [ngClass]="fg4Obj.V80Funktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="V80Time" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.V80Time" min="1" max="15">
            </div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 60 - Code</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V60Code">
                        <mat-option *ngFor="let img of images" [value]="img.value">
                            {{img.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px">
                <mat-form-field appearance="outline">
                    <mat-label>Harmonisierung 60 - Funktion</mat-label>
                    <mat-select [(ngModel)]="fg4Obj.V60Funktion" (ngModelChange)="v60ChangeFn($event)">
                        <mat-option *ngFor="let func of functions" [value]="func.value">
                            {{func.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff" [ngClass]="fg4Obj.V60Funktion !== 2 ? 'grayscale' : ''">Harmonisierung 60
                    (200ms)</label>
                <input [disabled]="fg4Obj.V60Funktion !== 2" type="number"
                    [ngClass]="fg4Obj.V60Funktion !== 2 ? 'grayscale' : ''" class="form-control input-as-FF rounded-0"
                    aria-label="V60Time" aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.V60Time" min="1" max="15">
            </div>
        </div>



        <div style="display: flex; flex-direction: row;">
            <div style="width:220px; position: relative; margin-left: 2px;">
                <label class="label-ff">Display Prinzip</label>
                <input type="number" class="form-control input-as-FF rounded-0" aria-label="DisplayPrinciple"
                    aria-describedby="basic-addon2" [(ngModel)]="fg4Obj.DisplayPrinciple">
            </div>
        </div>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions class="w-100">
    <div class="d-flex justify-content-between border-top w-100">
        <button class="btn btn-sm btn-outline-danger  rounded-0" (click)="onNoClick()">
            Schließen
        </button>
        <button class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitClick()">
            Übernehmen
        </button>

    </div>
</mat-dialog-actions>