import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RouteStationService} from "../../project/shared/services/route-station.service";
import {
  RouteStationAddModalComponent
} from "../../project/project-settings/route-station-add-modal/route-station-add-modal.component";
import {ProjectRouteStation} from "../../project/shared/interfaces/project-route-station.interface";
import {Projects} from "@angular/cli/lib/config/workspace-schema";
import {Project} from "../../project/shared/interfaces/project.interface";
import {ProjectService} from "../../project/shared/services/project.service";
import {UserProjects} from "../shared/interfaces/user-projects.interface";

@Component({
  selector: 'avs-user-project-add-modal',
  templateUrl: './user-project-add-modal.component.html',
  styleUrls: ['./user-project-add-modal.component.css']
})
export class UserProjectAddModalComponent implements OnInit{
  public allProjects: Project[] = [];
  private filter: string = "";


  constructor(private dialogRef: MatDialogRef<RouteStationAddModalComponent>,
              private projectService: ProjectService,
              @Inject(MAT_DIALOG_DATA) public data: UserProjects[]) {}

  ngOnInit() {
    this.getAllProjects(this.data);
  }

  public onCloseBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSearchValueChange(value: string): void {
    this.filter = value;
  }

  public onItemClicked(item: any): void {
    this.dialogRef.close(item);
  }

  public getFilteredInput(): Project[]{
      return this.allProjects.filter(
        s => s.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

  private getAllProjects(userProjects: UserProjects[] | undefined) {
    this.projectService.getProjects().subscribe(projects => {
      if (userProjects) {
        projects.forEach(p => {
          var findProject = userProjects.find(up => up.id === p.id);
          if (!findProject) {
            this.allProjects.push(p);
          }
        });
      } else {
        this.allProjects = projects;
      }
    })
  }
}
