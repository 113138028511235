<div *ngIf="activeGroup; else noActiveProject" class="m-0">

  <div class="card rounded-0 shadow mt-4 p-2">
    <div class="fw-semibold">Stauwarnanlagen Einstellungen: <span class="text-danger">{{activeGroup.name}} </span> <span class="text-danger"
      *ngIf="!activeGroup.isActive">- Gruppe ist deaktiviert </span></div>
    <div>
      <div style="height: 50px;" class="d-flex align-items-center mt-2 ms-3 ">
        <mat-slide-toggle *ngIf="matchingSWAConfig" [checked]="matchingSWAConfig.active"
          (change)="onSWAOnOffBtnToggle()" color="primary"> SWA {{ matchingSWAConfig.active ? 'ausschalten' :
          'einschalten'}} </mat-slide-toggle>
        <div *ngIf="matchingSWAConfig == null">
          Keine SWA vorhanden
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="w-100">
          <div class="ms-2 mb-1 me-1">Gruppentyp</div>
          <div [ngClass]="isGroupTypesSelectOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
            role="button" (click)="onGroupTypeSelectClicked()">
            <div class="ms-2 p-1">{{getGroupTypeString(activeGroup.groupType)}}</div>
            <img *ngIf="!isGroupTypesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
              alt="caret-down open select">
            <img *ngIf="isGroupTypesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
              alt="caret-up close select">
          </div>
          <avs-dropdown *ngIf="isGroupTypesSelectOpen" [isHeaderActive]="false"
            [activeItem]="onGetActiveSelect(GroupMetaDataTypes.groupType, activeGroup.groupType)"
            (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.groupType)" [selectInput]="groupTypeSelect">
          </avs-dropdown>
        </div>
        <div class="w-100 ms-1">
          <div class="ms-2 mb-1">Diagrammtyp</div>
          <div [ngClass]="isDiagramTypesSelectOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
            role="button" (click)="onDiagramTypeSelectClicked()">
            <div class="ms-2 p-1">{{getDiagramTypeString(activeGroup.diagramType)}}</div>
            <img *ngIf="!isDiagramTypesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
              alt="caret-down open select">
            <img *ngIf="isDiagramTypesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
              alt="caret-up close select">
          </div>
          <avs-dropdown *ngIf="isDiagramTypesSelectOpen"
            (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.diagramType)" [isHeaderActive]="false"
            [activeItem]="onGetActiveSelect(GroupMetaDataTypes.diagramType, activeGroup.diagramType)"
            [selectInput]="diagramTypeSelect">
          </avs-dropdown>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="w-100">
            <div class="ms-2 mb-1 me-1">Fahrbahn Regeln</div>
            <div [ngClass]="isTrafficRulesSelectOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
            role="button" (click)="onTrafficRulesSelectClicked()">
            <div class="ms-2 p-1">{{getTrafficRulesString(activeGroup.trafficRules)}}</div>
            <img *ngIf="!isTrafficRulesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
              alt="caret-down open select">
            <img *ngIf="isTrafficRulesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
              alt="caret-up close select">
          </div>
          <avs-dropdown *ngIf="isTrafficRulesSelectOpen"
            (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.trafficRules)" [isHeaderActive]="false"
            [activeItem]="onGetActiveSelect(GroupMetaDataTypes.trafficRules, activeGroup.trafficRules)"
            [selectInput]="trafficRulesSelect">
          </avs-dropdown>
        </div>
        <div class="w-100 me-2">
            <div class="ms-2 mb-1 ">Fahrbahn Spuren (1-10)</div>
            <input type="number" style="height: 34px;" class="ms-2 w-100 rounded-0 form-control" min="1" max="10" [ngModel]="trafficLanes" (ngModelChange)="changeTrafficLane($event)"  >
        </div>
    </div>
      <div class="d-flex justify-content-end me-3 mt-2">

        <button class="btn btn-outline-success mt-auto rounded-0" type="button" *ngIf="groupMetaData || isTrafficLanesChanged"
          (click)="onSaveMetaDataClicked()">
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </div>



  <div class="card rounded-0 shadow mt-4 p-2">


    <div class="fw-semibold">Stauwarnanlage
      erstellen<!--  für: <span class="text-danger">{{activeGroup.name}}</span> --></div>
    <div class="mt-2 ms-3">
      <div style="height: 50px;" class="d-flex align-items-center row">
        <mat-slide-toggle class="col-3" [checked]="onWithJson" (change)="onWithJsonToggle()" color="primary">
          Selbst konfigurieren
        </mat-slide-toggle>
        <mat-checkbox class="me-2 col-3" color="primary" *ngIf="onWithJson" [checked]="onWithJsonUpload"
          (change)="onWithJsonUploadCheckbox()"> JSON einfügen</mat-checkbox>
        <div
          [ngClass]="isJsonValid ? 'col fw-semibold me-2 text d-flex align-items-center' : 'col fw-semibold text-danger me-2  d-flex align-items-center' "
          *ngIf="isJsonValid && onWithJsonUpload"> 
          <img *ngIf="isJsonValid" src="assets/icons/check-circle.svg" style="height: 20px; margin-right: 4px;" alt="json is valid"> 
            <img *ngIf="!isJsonValid" src="assets/icons/ban.svg" style="height: 20px; margin-right: 4px;" alt="json is valid"> 
            {{ isJsonValid ? 'JSON ist valid' : 'JSON ungültig' }}
        </div>
      </div>
      <div *ngIf="onWithJson">
        <div *ngIf="!onWithJsonUpload">
          <avs-text-input [classItem]="'d-flex w-100 space-between justify-content-between mt-2 '"
            [classInputLabel]="'w-50'" [classInputOnly]="' text-end'" label="Fahrspuren"
            (valueChange)="swaConfig.lines = +$event" [value]="swaConfig.lines"
            placeholder="Anzahl der erfassten Fahrspuren" [type]="'number'" [measurement]="' '" [min]="1" [max]="3">
          </avs-text-input>

          <avs-text-input label="Geschwindigkeitsbegrenzung"
            [classItem]="'d-flex w-100 space-between justify-content-between  mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.vmax = +$event" [value]="swaConfig.vmax"
            placeholder="Geschwindigkeitsbegrenzung in km/h" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="120">
          </avs-text-input>

          <avs-text-input class="mt-2" label="Belegung % Stau ein"
            [classItem]="'d-flex w-100 space-between justify-content-between  mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.b_stau_ein = +$event" [value]="swaConfig.b_stau_ein"
            placeholder="Belegung % Stau ein" [type]="'number'" [measurement]="'%'" [min]="1" [max]="100">
          </avs-text-input>

          <avs-text-input class="mt-2" label="Belegung % Stau aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.b_stau_aus = +$event" [value]="swaConfig.b_stau_aus"
            placeholder="Belegung % Stau aus" [type]="'number'" [measurement]="'%'" [min]="1" [max]="100">
          </avs-text-input>

          <avs-text-input class="mt-2 mb-2" label="Geschwindigkeit in km/h für Stau nach Belegung"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.vb_stau_ein = +$event"
            [value]="swaConfig.vb_stau_ein" placeholder="Geschwindigkeit in km/h für Stau nach Belegung"
            [type]="'number'" [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>

          <avs-text-input class="mt-2" label="Geschwindigkeit in km/h für Stau aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_stau_ein = +$event" [value]="swaConfig.v_stau_ein"
            placeholder="Geschwindigkeit in km/h für Stau aus" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
          </avs-text-input>

          <avs-text-input class="mt-2" label="Geschwindigkeit in km/h für Stau an"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_stau_aus = +$event" [value]="swaConfig.v_stau_aus"
            placeholder="Geschwindigkeit in km/h für Stau an" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="200">
          </avs-text-input>

          <avs-text-input label="Geschwindigkeitsdifferenz pkw/lkw für Stau"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_dif_stau = +$event" [value]="swaConfig.v_dif_stau"
            placeholder="Geschwindigkeitsdifferenz pkw/lkw für Stau" [type]="'number'" [measurement]="'km/h'" [min]="1"
            [max]="50">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Stau"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_kfz_stau = +$event" [value]="swaConfig.q_kfz_stau"
            placeholder="Verkehrsstärke kfz/h Stau" [type]="'number'" [measurement]="'kfz/h'" [min]="1" [max]="10000">
          </avs-text-input>

          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 120 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg120_ein = +$event"
            [value]="swaConfig.q_Bg120_ein" placeholder="Verkehrsstärke kfz/h Harmonisierung 120 ein" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 120 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg120_aus = +$event"
            [value]="swaConfig.q_Bg120_aus" placeholder="Verkehrsstärke kfz/h Harmonisierung 120 aus" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 100 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg100_ein = +$event"
            [value]="swaConfig.q_Bg100_ein" placeholder="Verkehrsstärke kfz/h Harmonisierung 100 ein" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 100 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg100_aus = +$event"
            [value]="swaConfig.q_Bg100_aus" placeholder="Verkehrsstärke kfz/h Harmonisierung 100 aus" [type]="'number'"
            [measurement]="'kfz/h'" [min]="1" [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 80 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg80_ein = +$event" [value]="swaConfig.q_Bg80_ein"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 80 ein" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 80 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg80_aus = +$event" [value]="swaConfig.q_Bg80_aus"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 120 ein" [type]="'number'" [measurement]="'kfz/h'"
            [min]="1" [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 60 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg60_ein = +$event" [value]="swaConfig.q_Bg60_ein"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 60 ein" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
          </avs-text-input>
          <avs-text-input label="Verkehrsstärke kfz/h Harmonisierung 60 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.q_Bg60_aus = +$event" [value]="swaConfig.q_Bg60_aus"
            placeholder="Verkehrsstärke kfz/h Harmonisierung 60 aus" [type]="'number'" [measurement]="'kfz/h'" [min]="1"
            [max]="10000">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 100 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq100_ein = +$event"
            [value]="swaConfig.v_pkq100_ein" placeholder="Geschwindigkeit km/h Harmonisierung 100 ein" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 100 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq100_aus = +$event"
            [value]="swaConfig.v_pkq100_aus" placeholder="Geschwindigkeit km/h Harmonisierung 100 aus" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 80 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq80_ein = +$event"
            [value]="swaConfig.v_pkq80_ein" placeholder="Geschwindigkeit km/h Harmonisierung 80 ein" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 80 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq80_aus = +$event"
            [value]="swaConfig.v_pkq80_aus" placeholder="Geschwindigkeit km/h Harmonisierung 80 aus" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 60 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq60_ein = +$event"
            [value]="swaConfig.v_pkq60_ein" placeholder="Geschwindigkeit km/h Harmonisierung 60 ein" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Geschwindigkeit km/h Harmonisierung 60 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.v_pkq60_aus = +$event"
            [value]="swaConfig.v_pkq60_aus" placeholder="Geschwindigkeit km/h Harmonisierung 60 aus" [type]="'number'"
            [measurement]="'km/h'" [min]="1" [max]="200">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 100 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g100_ein = +$event" [value]="swaConfig.k_g100_ein"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 100 ein" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 100 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g100_aus = +$event" [value]="swaConfig.k_g100_aus"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 100 aus" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 80 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g80_ein +$event" [value]="swaConfig.k_g80_ein"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 80 ein" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 80 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g80_aus = +$event" [value]="swaConfig.k_g80_aus"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 80 aus" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 60 ein"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g60_ein = +$event" [value]="swaConfig.k_g60_ein"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 60 ein" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Verkehrsdichte kfz/km Harmonisierung 60 aus"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.k_g60_aus = +$event" [value]="swaConfig.k_g60_aus"
            placeholder="Verkehrsdichte kfz/km Harmonisierung 60 aus" [type]="'number'" [measurement]="'kfz/km'"
            [min]="1" [max]="100">
          </avs-text-input>
          <avs-text-input label="Erfassungsperiode in Sekunden"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.periode = +$event" [value]="swaConfig.periode"
            placeholder="Erfassungsperiode in Sekunden" [type]="'number'" [measurement]="'KFZ'" [min]="15" [max]="120">
          </avs-text-input>
          <avs-text-input label="Anzahl der Perioden die ein Zustand anliegen muss bis dieser übernommen wird"
            [classItem]="'d-flex w-100 space-between justify-content-between mt-2'" [classInputLabel]="'w-50'"
            [classInputOnly]="' text-end'" (valueChange)="swaConfig.responceDelay = +$event"
            [value]="swaConfig.responceDelay"
            placeholder="Anzahl der Perioden die ein Zustand anliegen muss bis dieser übernommen wird" [type]="'number'"
            [measurement]="' '" [min]="1" [max]="10">
          </avs-text-input>
        </div>
        <div *ngIf="onWithJsonUpload">
          <textarea [(ngModel)]="settings" class="avs-code-notes w-100 h-auto form-control rounded-0"
            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
            placeholder="Bitte Konfiguration einfügen..." (keydown)="onKeyDown($event)">
          </textarea>
        </div>
      </div>

    </div>
    <div class="actionbar">
      <button *ngIf="settings && onWithJsonUpload" class="btn btn-outline-success rounded-0 me-3 mt-4 mb-2" type="button"
        (click)="checkJson()">Check JSON</button>
      <button *ngIf="matchingSWAConfig" class="btn btn-outline-danger rounded-0 me-3 mt-4 mb-2" type="button"
        (click)="deleteSWA()"> Bestehende SWA löschen</button>
      <div *ngIf="!activeGroup.isActive" matTooltip="Gruppe ist deaktiv">
        <button disabled class="btn btn-outline-success rounded-0 me-3 mt-4 mb-2" type="button"
          (click)="onSWAGenerateBtnClicked()"> {{
          onWithJson ? 'Konfigurierte ' : 'Einfache '}}SWA erstellen</button> <!-- Buttons anpassen -->
      </div>
      <div *ngIf="activeGroup.isActive">
        <button class="btn btn-outline-success rounded-0 me-3 mt-4 mb-2" type="button"
          (click)="onSWAGenerateBtnClicked()"> {{
          onWithJson ? 'Konfigurierte ' : 'Einfache '}}SWA erstellen</button>
      </div>
    </div>




    <!-- <div> {{ validJson | json }}</div> -->
    <div *ngIf="settings && onWithJsonUpload && isJsonValid">
      <avs-json-data-viewer [json]="validJson"></avs-json-data-viewer>
    </div>
  </div>
</div>
<ng-template #noActiveProject>
  <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!</p>
</ng-template>