import {Injectable} from "@angular/core";
import {ProjectSessionStorageData} from "../interfaces/project-session-storage-data.interface";

const projectSession: string = 'activeProject'
const groupSession: string = 'activeGroup'
const routeStationSession: string = 'activeRouteStation'


@Injectable({
  providedIn: 'root'
})
export class ProjectDataSessionStorageService {

  public getProjectSessionData(): ProjectSessionStorageData {
    return {
      activeProjectId: this.getActiveProjectId() ? this.getActiveProjectId() : "",
      activeProjectGroupId: this.getActiveGroupId() ? this.getActiveGroupId() : "",
      activeProjectRouteStation: this.getActiveRouteStationId() ? this.getActiveRouteStationId() : ""
    } as ProjectSessionStorageData;
  }

  private getActiveProjectId(): any {
    return sessionStorage.getItem(projectSession);
  }

  private  getActiveGroupId(): any {
    return sessionStorage.getItem(groupSession);
  }

  private  getActiveRouteStationId():any {
    return sessionStorage.getItem(routeStationSession);
  }

  public  setActiveProjectId(activeProjectId: any): void {
    sessionStorage.setItem(projectSession, activeProjectId);
  }

  public  setActiveProjectGroupId(activeGroupId: any): void {
    sessionStorage.setItem(groupSession, activeGroupId);
  }

  public  setActiveProjectRouteStationId(activeRouteStationId: any) {
    sessionStorage.setItem(routeStationSession, activeRouteStationId);
  }
}
