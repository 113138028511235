import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectDataService } from '../shared/services/project-data.service';
import { ProjectService } from '../shared/services/project.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { SWAConfigService } from '../shared/services/swaconfig.service';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { SWAConfigFull } from './shared/interfaces/swa-config.interface';
import { SWAConfig } from '../shared/interfaces/swaconfig';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getDiagramTypeSelect, getDiagramTypeString } from '../shared/enums/diagram-type.enum';
import { getTrafficRulesSelect, getTrafficRulesString } from '../shared/enums/traffic-rules.enum';
import { getGroupTypeSelect, getGroupTypeString } from '../shared/enums/group-type.enum';
import { GroupMetaData, GroupMetaDataTypes } from '../project-settings/shared/interfaces/group-meta.interface';
import { GroupService } from '../shared/services/group.service';
import { ModalService } from 'src/app/shared/modal/shared/modal.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'avs-project-swa-config',
  templateUrl: './project-swa-config.component.html',
  styleUrls: ['./project-swa-config.component.css']
})
export class ProjectSwaConfigComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public isNoteChanged: boolean = false;
  public onWithJson: boolean = false;
  public onWithJsonUpload: boolean = false;
  public trafficLanes: number = 2;
  public swaConfig: SWAConfigFull = {
    lines: 2, vmax: 80, b_stau_ein: 50, b_stau_aus: 35,
    vb_stau_ein: 45, v_stau_ein: 35, v_stau_aus: 50, v_dif_stau: 25, q_kfz_stau: 1000,
    q_Bg120_ein: 3200, q_Bg120_aus: 2900, q_Bg100_ein: 3600, q_Bg100_aus: 3300, q_Bg80_ein: 4000,
    q_Bg80_aus: 3700, q_Bg60_ein: 4400, q_Bg60_aus: 4100, v_pkq100_ein: 90, v_pkq100_aus: 95,
    v_pkq80_ein: 70, v_pkq80_aus: 75, v_pkq60_ein: 50, v_pkq60_aus: 55, k_g100_ein: 35,
    k_g100_aus: 45, k_g80_ein: 35, k_g80_aus: 45, k_g60_ein: 35, k_g60_aus: 45, periode: 60, responceDelay: 5
  }
  public settings?: string;
  public formattedJson: any;
  public isGroupTypesSelectOpen: boolean = false;
  public isDiagramTypesSelectOpen: boolean = false;
  public isTrafficRulesSelectOpen: boolean = false;
  public groupTypeSelect: SelectInput[] = getGroupTypeSelect();
  public diagramTypeSelect: SelectInput[] = getDiagramTypeSelect();
  public trafficRulesSelect: SelectInput[] = getTrafficRulesSelect();
  public matchingSWAConfig?: SWAConfig;
  public groupMetaData?: GroupMetaData;
  public isJsonValid?: boolean
  public validJson: any;
  public isTrafficLanesChanged: boolean = false;
  protected readonly GroupMetaDataTypes = GroupMetaDataTypes;
  protected readonly getGroupTypeString = getGroupTypeString;
  protected readonly getDiagramTypeString = getDiagramTypeString;
  protected readonly getTrafficRulesString = getTrafficRulesString;

  public subscriptionCollection: Subscription[] = [];
  public activeProjectSubscription?: Subscription;
  public activeGroupSubscription?: Subscription;


  constructor(private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private SWAConfigService: SWAConfigService,
    private groupService: GroupService,
    private modalService: ModalService
  ) {
    this.activeProjectSubscription = this.projectDataService.activeProject.subscribe((project) => {
      this.activeProject = project;
    });
    this.activeGroupSubscription = this.projectDataService.activeGroup.subscribe((group) => {
      this.activeGroup = group;
      if (this.activeGroup) this.trafficLanes = this.activeGroup.trafficLanes
      this.getAllSWAConfigs();
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeGroupSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onGetActiveSelect(type: GroupMetaDataTypes, value: number): SelectInput {
    switch (type) {
      case GroupMetaDataTypes.groupType:
        return {
          name: getGroupTypeString(value),
          id: value,
        } as SelectInput;
      case GroupMetaDataTypes.diagramType:
        return {
          name: getDiagramTypeString(value),
          id: value,
        } as SelectInput;
      case GroupMetaDataTypes.trafficRules:
        return {
          name: getTrafficRulesString(value),
          id: value,
        } as SelectInput;
      default:
        return {} as SelectInput;
    }
  }

  public checkJson() {
    try {
      if (this.settings)
        this.validJson = JSON.parse(this.settings);
    } catch (exception) {
      this.validJson = null;
    }

    if (this.validJson) {
      this.isJsonValid = true;
    }
    else this.isJsonValid = false;
  }

  public onSWAGenerateBtnClicked(): void {
    if (!this.onWithJson) {
      this.SWAConfigService.createSWA(this.activeGroup!.id).subscribe(data => { this.alertService.alert('SWA generiert', AlertTypes.success); this.matchingSWAConfig = data })
    }
    else {
      if (this.onWithJsonUpload) {
        this.formattedJson = this.settings?.replace(/(\r\n|\n|\r)/gm, "")
        this.formattedJson = this.formattedJson.replace(/\s/g, "")
        this.SWAConfigService.createSWAwithJsonAsSetting(this.activeGroup!.id, this.formattedJson).subscribe(data => { this.alertService.alert('SWA generiert', AlertTypes.success); this.matchingSWAConfig = data })
      }
      else {
        this.SWAConfigService.PostMQSettingsAndCreateSWA(this.activeGroup!.id, this.swaConfig).subscribe(data => { this.alertService.alert('SWA generiert', AlertTypes.success); this.matchingSWAConfig = data })
      }
    }
  }

  public changeTrafficLane(newValue: any) {
    this.trafficLanes = newValue
    this.isTrafficLanesChanged = true;
  }


  public onSaveMetaDataClicked(): void {
    if (this.activeGroup) {
      this.activeGroup.name = this.groupMetaData?.name === undefined ? this.activeGroup.name : this.groupMetaData.name;
      this.activeGroup.routeName = this.groupMetaData?.routeName === undefined ? this.activeGroup.routeName : this.groupMetaData.routeName;
      this.activeGroup.trafficLanes = this.trafficLanes
      this.groupService.editProjectGroup(this.activeGroup.id, this.activeGroup).subscribe(() => {
        this.alertService.alert('Guppen Metadaten wurden geändert', AlertTypes.info)
        this.groupMetaData = undefined;
      });
    }
  }


  @HostListener('document:keypress', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this.isNoteChanged = true;
  }

  public onMetaValueChange(newValue: any, type: GroupMetaDataTypes): void {
    if (!this.groupMetaData) {
      this.groupMetaData = {
        name: undefined, routeName: undefined, groupType: undefined, diagramType: undefined,
        isActive: undefined, trafficRules: undefined
      };
    }
    if (!this.activeGroup) { return; }
    switch (type) {
      case GroupMetaDataTypes.groupType:
        this.groupMetaData.groupType = newValue;
        this.activeGroup.groupType = newValue.id;
        this.isGroupTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.diagramType:
        this.groupMetaData.diagramType = newValue.name;
        this.activeGroup.diagramType = newValue.id;
        this.isDiagramTypesSelectOpen = false;
        break;
      case GroupMetaDataTypes.trafficRules:
        this.groupMetaData.trafficRules = newValue.name;
        this.activeGroup.trafficRules = newValue.id;
        this.isTrafficRulesSelectOpen = false;
        break;
    }
  }

  public onGroupTypeSelectClicked(): void {
    this.isGroupTypesSelectOpen = !this.isGroupTypesSelectOpen;
    this.isDiagramTypesSelectOpen = false;
    this.isTrafficRulesSelectOpen = false;
  }

  public onDiagramTypeSelectClicked(): void {
    this.isDiagramTypesSelectOpen = !this.isDiagramTypesSelectOpen;
    this.isGroupTypesSelectOpen = false;
    this.isTrafficRulesSelectOpen = false;
  }

  public onTrafficRulesSelectClicked(): void {
    this.isTrafficRulesSelectOpen = !this.isTrafficRulesSelectOpen;
    this.isGroupTypesSelectOpen = false;
    this.isDiagramTypesSelectOpen = false;
  }

  public onWithJsonToggle(): void {
    this.onWithJson = !this.onWithJson
    this.onWithJsonUpload = false;
  }
  public onWithJsonUploadCheckbox(): void {
    this.onWithJsonUpload = !this.onWithJsonUpload
  }

  public getAllSWAConfigs(): void {
    this.settings = '';
    this.formattedJson = '';
    this.validJson = '';
    this.isJsonValid = undefined;
    this.matchingSWAConfig = undefined;
    if (this.activeGroup)
      this.SWAConfigService.getSWAConfigs(this.activeGroup.id).subscribe(data => {
        if (data === null) { return; }
        this.matchingSWAConfig = data[0];

      })
  }

  public onSWAOnOffBtnToggle(): void {
    if (this.matchingSWAConfig) {
      this.SWAConfigService.changeActiveSWAStatus(this.matchingSWAConfig).subscribe((data) => {
        this.matchingSWAConfig = data;
        this.alertService.alert('SWA wurde umgestellt', AlertTypes.info)
      })
    }
  }

  onDeleteSWAClicked(): void {
    this.SWAConfigService.deleteSWA(this.matchingSWAConfig!.id).subscribe(data => {
      this.alertService.alert('SWA wurde gelöscht', AlertTypes.info);
      this.matchingSWAConfig = undefined
    })
  }


  public deleteSWA(): void {
    this.modalService.openDialog('SWA löschen?', 'Nein', 'Ja').subscribe(() => {
      this.onDeleteSWAClicked();
    })
  }
}
