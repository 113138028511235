import {Component, Inject} from '@angular/core';
import {ProjectRouteStation} from "../../shared/interfaces/project-route-station.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProjectRouteStationWithParam} from "../../shared/interfaces/project-route-station.class";
import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";

@Component({
  selector: 'avs-schema-add-station-modal',
  templateUrl: './schema-add-station-modal.component.html',
  styleUrls: ['./schema-add-station-modal.component.css']
})
export class SchemaAddStationModalComponent {
  public filter: string = "";
  public selectInput: SelectInput[] = [];

  constructor(private dialogRef: MatDialogRef<SchemaAddStationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ProjectRouteStationWithParam[]) {
    for (let stationWithParam of data) {
      let selectInput = {
        name: stationWithParam.routeStation.name,
        id: stationWithParam.routeStation.id,
        samId: stationWithParam.routeStation.samId
      } as SelectInput
      this.selectInput.push(selectInput);
    }
  }
  public onCloseBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSearchValueChange(value: string): void {
    this.filter = value;
  }

  public getFilteredInput(): SelectInput[]{
    return this.selectInput.filter(
        s => s.name.toLowerCase().includes(this.filter.toLowerCase()) ||
            (s.samId ? s.samId.toString().includes(this.filter.toString()) : true));
  }

  public onItemClicked(item: SelectInput): void {
    this.dialogRef.close(this.data.find(s =>
        s.routeStation.samId === item.samId && s.routeStation.name === item.name));
  }
}
