<div class="avs-user-data mt-4">
<div class="row justify-content-center d-flex avs-user-data-container">
  <div class="col-12 col-lg-5">
      <div>
        <h5>Profilinformationen</h5>
        <p>Passen sie Ihre Profil informationen und E-Mail an</p>
      </div>
  </div>
  <div class="col-12 col-lg-7 mb-5">
    <div class="card rounded-0 shadow h-100">
      <div *ngIf="userData" class="w-75 p-3">
        <div class="mb-3">
          <avs-text-input label="Titel"
                          [value]="userData.title"
                          (valueChange)="onTitleValueChange($event)"
          ></avs-text-input>
        </div>
        <div class="mb-3">
          <avs-text-input label="Vorname"
                          [value]="userData.firstName"
                          (valueChange)="onFirstNameValueChange($event)"
          ></avs-text-input>
        </div>
        <div class="mb-3">
          <avs-text-input label="Name"
                          [value]="userData.lastName"
                          (valueChange)="onLastNameValueChange($event)"
          ></avs-text-input>
        </div>
        <div class="mb-3">
          <avs-text-input label="E-Mail"
                          [value]="userData.email"
                          (valueChange)="onEmailValueChange($event)"
          ></avs-text-input>
        </div>
      </div>
      <div class="bg-light w-100 p-3 text-end">
        <button type="button" class="btn btn-primary ms-0 avs-user-data-button rounded-0 border-0 shadow" (click)="onSaveUserDataBtnClicked()">Speichern</button><br>
      </div>
    </div>
  </div>
  <hr>
</div>

<div class="row justify-content-center d-flex mt-4 avs-user-data-container mb-5">
  <div class="col-12 col-lg-5">
    <div>
      <h5>Password anpassen</h5>
      <p>Stellen sie sicher das Ihr Account ein langes, zufälliges und sicheres Passwort benutzt</p>
    </div>
  </div>
  <div class="col-12 col-lg-7 mb-5">
    <div class="card rounded-0 shadow  h-100">
      <div  class="w-75 p-3">
        <div class="mb-3">
          <avs-text-input label="Altes Password"
                          type="password"
                          [required]="true"
                          [value]="passwordData.oldPassword"
                          (valueChange)="onOldPasswordChange($event)"
          ></avs-text-input>
        </div>
        <div class="mb-3">
          <avs-text-input label="Neues Password"
                          type="password"
                          [required]="true"
                          [value]="passwordData.newPassword"
                          (valueChange)="onNewPasswordChange($event)"
          ></avs-text-input>
        </div>
        <div class="mb-3">
          <avs-text-input label="Password wiederhohlen"
                          type="password"
                          [required]="true"
                          [value]="passwordData.confirmNewPassword"
                          (valueChange)="onConfirmNewPasswordChange($event)"
          ></avs-text-input>
        </div>
      </div>
      <div class="bg-light w-100 p-3 text-end">
        <button type="button" class="btn btn-primary ms-0 avs-user-data-button rounded-0 border-0 shadow" (click)="onSaveNewPasswordBtnClicked()">Speichern</button><br>
      </div>
    </div>
  </div>
</div>
</div>
