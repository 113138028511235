import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";

export enum TrafficRulesEnum {
    R210,
    R2210,
    R2110
}
export function getTrafficRulesString(trafficRule: TrafficRulesEnum): string {
    switch (trafficRule) {
        case TrafficRulesEnum.R210:
            return 'R210';
        case TrafficRulesEnum.R2110:
            return 'R2110';
        case TrafficRulesEnum.R2210:
            return 'R2210';
    }
}

export function getTrafficRulesSelect(): SelectInput[] {
    return [
        {name: getTrafficRulesString(TrafficRulesEnum.R210), id: 0},
        {name: getTrafficRulesString(TrafficRulesEnum.R2210), id: 1},
        {name: getTrafficRulesString(TrafficRulesEnum.R2110), id: 2},
    ]
}