import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'avs-lisa-gmap',
  templateUrl: './lisa-gmap.component.html',
  styleUrls: ['./lisa-gmap.component.css']
})
export class LisaGmapComponent implements OnInit {
  @Input() pins: any[] = [];
  public map?: google.maps.Map


  /* center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 4; */



  constructor() { }

  public ngOnInit(): void {
/*     this.createMarker({ lat: 24, lng: 12, title: 'Hello World!' });

    let marker = new google.maps.marker.AdvancedMarkerElement({map: this.map, position: {lat: 24, lng: 12}, title: 'Hello World!'});

    marker */
  }

  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 51.0305,
    lng: 7.20643,
  };
  zoom = 6;
  

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = event.latLng.toJSON();
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  // function to create an advancedMarker
  createMarker = (info: any) => {
    new google.maps.marker.AdvancedMarkerElement({map: this.map, position: {lat: 24, lng: 12}, title: 'Hello World!'});
  }

}