import { NgModule } from "@angular/core";
import { ProjectOverviewComponent } from "./project-overview/project-overview.component";
import { ProjectSettingsOverviewComponent } from './project-settings/project-settings-overview.component';
import { ProjectSchemaComponent } from './project-schema/project-schema.component';
import { ProjectTestAreaComponent } from './project-test-area/project-test-area.component';
import { ProjectStationOverviewComponent } from './project-station-overview/project-station-overview.component';
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from "@angular/router";
import { ProjectDocumentsComponent } from './project-documents/project-documents.component';
import { ProjectNotesComponent } from './project-notes/project-notes.component';
import { ProjectMenuComponent } from './project-menu/project-menu.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { ProjectRouteStationComponent } from './project-route-station/project-route-station.component';
import { ProjectSettingsComponent } from './project-settings/project-settings/project-settings.component';
import { GroupSettingsComponent } from './project-settings/group-settings/group-settings.component';
import { RouteStationSettingsComponent } from './project-settings/route-station-settings/route-station-settings.component';
import { ProjectSettingsCreateModalComponent } from './project-settings/project-settings-create-modal/project-settings-create-modal.component';
import { GroupSettingsCreateModalComponent } from './project-settings/group-settings-create-modal/group-settings-create-modal.component';
import { DataEndChannelTableComponent } from './project-settings/data-end-channel-table/data-end-channel-table.component';
import { RouteStationAddModalComponent } from './project-settings/route-station-add-modal/route-station-add-modal.component';
import { DataEndChannelAddModalComponent } from './project-settings/data-end-channel-add-modal/data-end-channel-add-modal.component';
import { DataEndChannelEditModalComponent } from './project-settings/data-end-channel-edit-modal/data-end-channel-edit-modal.component';
import { SchemaAddStationModalComponent } from './project-schema/schema-add-station-modal/schema-add-station-modal.component';
import { ProjectTimeSwitchComponent } from './project-time-switch/project-time-switch.component';
import { TimeInputComponent } from "./project-time-switch/shared/time-input/time-input.component";
import { CdkDropListGroup, CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { ProjectImagesComponent } from './project-images/project-images.component';
import { SetImageModalComponent } from './project-images/set-image-modal/set-image-modal.component';
import { MatDialogModule } from "@angular/material/dialog";
import { SchemaRouteStationModalComponent } from './project-schema/schema-route-station-modal/schema-route-station-modal.component';
import { ProjectSettingsMapComponent } from './project-settings/project-settings-map/project-settings-map.component';
import { UserModule } from "../user/user.module";
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadImageModalComponent } from './project-images/upload-image-modal/upload-image-modal.component';
import { AddAqTimeSwitchComponent } from './project-time-switch/project-time-switch-add-modal/add-aq-time-switch/add-aq-time-switch.component';
import { AddMqTimeSwitchComponent } from './project-time-switch/project-time-switch-add-modal/add-mq-time-switch/add-mq-time-switch.component';
import { ProjectTimeSwitchAddModalWrapperComponent } from './project-time-switch/project-time-switch-add-modal/project-time-switch-add-modal-wrapper/project-time-switch-add-modal-wrapper.component';
import { PopupRangePickerComponent } from './project-time-switch/shared/popup-range-picker/popup-range-picker.component';
import { ProjectSwaConfigComponent } from './project-swa-config/project-swa-config.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddTimeSwitchComponent } from './project-time-switch/project-time-switch-add-modal/add-time-switch/add-time-switch.component';
import { DatePipe } from "@angular/common";
import { MatSelectModule } from '@angular/material/select';
import { TestAreaBrightnessComponent } from "./project-test-area/test-area-brightness/test-area-brightness.component";
import { TestAreaImageSettingsComponent } from "./project-test-area/test-area-image-settings/test-area-image-settings.component";
import { TestAreaAdditionalSettingsComponent } from "./project-test-area/test-area-additional-settings/test-area-additional-settings.component";
import { TestAreaDigitalOutputsComponent } from "./project-test-area/test-area-digital-outputs/test-area-digital-outputs.component";
import { AddFlasherTimeSwitchComponent } from './project-time-switch/project-time-switch-add-modal/add-flasher-time-switch/add-flasher-time-switch.component';
import { ErrorDialogComponent } from './project-time-switch/error-dialog/error-dialog.component';
import { LineNameComponent } from './project-schema/shared/line-name/line-name.component';
import { VoltageChartComponent } from "./project-schema/shared/voltage-chart/voltage-chart.component";
import { ProjectTimeSwitchEditModalWrapperComponent } from './project-time-switch/project-time-switch-edit-modal-wrapper/project-time-switch-edit-modal-wrapper.component';
import { DataEndChannelSettingsDialogComponent } from './project-settings/shared/data-end-channel-settings-dialog/data-end-channel-settings-dialog.component';
import { RadarDataChartComponent } from './project-schema/shared/radar-data-chart/radar-data-chart.component';
import { FileUploadModalComponent } from './project-documents/shared/file-upload-modal/file-upload-modal.component';
import { CurrentChartComponent } from './project-schema/shared/current-chart/current-chart/current-chart.component';
import { TestAreaSmartMicroComponent } from './project-test-area/test-area-smart-micro/test-area-smart-micro.component';
import { ProjectScenarioComponent } from './project-scenario/project-scenario.component';
import { AddScenarioDialogComponent } from './project-scenario/add-scenario-dialog/add-scenario-dialog.component';
import { ScenarioSelectDialogComponent } from './project-schema/scenario-select-dialog/scenario-select-dialog.component';
import { ModularRouteStationComponent } from './project-scenario/modular-route-station/modular-route-station.component';
import { AnimatedImageModalComponent } from './project-settings/project-settings/animated-image-modal/animated-image-modal.component';
import { ProjectScenarioRouteStationModulComponent } from './project-scenario/modular-route-station/project-scenario-route-station-modul/project-scenario-route-station-modul.component';
import { AnimatedImagesErrorDialogComponent } from './project-settings/project-settings/animated-images-error-dialog/animated-images-error-dialog.component';
import { StmChartComponent } from './project-schema/shared/stm-chart/stm-chart.component';
import { ManualControlResultComponent } from './project-scenario/manual-control-result/manual-control-result.component';
import { GroupStellcodeDeleteDialogComponent } from './project-settings/project-settings/group-stellcode-delete-dialog/group-stellcode-delete-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SpeedChartComponent } from './project-schema/shared/speed-chart/speed-chart.component';
import { OderByPipe } from './shared/pipes/oder-by.pipe';
import { ProjectLisamessageComponent } from './project-lisamessage/project-lisamessage.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { GoogleMapsModule } from "@angular/google-maps";
import { LisaGmapComponent } from './project-lisamessage/lisa-gmap/lisa-gmap.component';
import { SolarDetailsComponent } from './project-schema/shared/solar-details/solar-details.component';
import { NewMapComponent } from './project-lisamessage/new-map/new-map.component';
import { NewMapJsonpLoadingComponent } from './project-lisamessage/new-map-jsonp-loading/new-map-jsonp-loading.component';
import { FallbackImageSettingsComponent } from './project-settings/shared/fallback-image-settings/fallback-image-settings.component';

import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { MatIconModule } from "@angular/material/icon";
import { WorkloadDiagrammComponent } from "../report/shared/components/workload-diagramm/workload-diagramm.component";
import { BrennstoffzellenDetailsComponent } from './project-schema/shared/brennstoffzellen-details/brennstoffzellen-details.component';
import { EvaResetComponent } from './shared/components/eva-reset/eva-reset.component';
import { ProjectGooglemapsComponent } from './project-googlemaps/project-googlemaps.component';

@NgModule({

  declarations: [
    ProjectOverviewComponent,
    ProjectSettingsOverviewComponent,
    ProjectSchemaComponent,
    ProjectTestAreaComponent,
    ProjectStationOverviewComponent,
    ProjectDocumentsComponent,
    ProjectNotesComponent,
    ProjectMenuComponent,
    ProjectRouteStationComponent,
    ProjectSettingsComponent,
    GroupSettingsComponent,
    RouteStationSettingsComponent,
    ProjectSettingsCreateModalComponent,
    GroupSettingsCreateModalComponent,
    DataEndChannelTableComponent,
    RouteStationAddModalComponent,
    DataEndChannelAddModalComponent,
    DataEndChannelEditModalComponent,
    SchemaAddStationModalComponent,
    ProjectTimeSwitchComponent,
    TimeInputComponent,
    SchemaRouteStationModalComponent,
    SetImageModalComponent,
    SchemaRouteStationModalComponent,
    ProjectSettingsMapComponent,
    UploadImageModalComponent,
    ProjectImagesComponent,
    AddAqTimeSwitchComponent,
    AddMqTimeSwitchComponent,
    ProjectTimeSwitchAddModalWrapperComponent,
    ProjectSwaConfigComponent,
    AddTimeSwitchComponent,
    TestAreaBrightnessComponent,
    TestAreaImageSettingsComponent,
    TestAreaAdditionalSettingsComponent,
    TestAreaDigitalOutputsComponent,
    AddFlasherTimeSwitchComponent,
    ErrorDialogComponent,
    LineNameComponent,
    VoltageChartComponent,
    ProjectTimeSwitchEditModalWrapperComponent,
    DataEndChannelSettingsDialogComponent,
    RadarDataChartComponent,
    CurrentChartComponent,
    TestAreaSmartMicroComponent,
    FileUploadModalComponent,
    ProjectScenarioComponent,
    AddScenarioDialogComponent,
    ScenarioSelectDialogComponent,
    ModularRouteStationComponent,
    AnimatedImageModalComponent,
    TestAreaSmartMicroComponent,
    ProjectScenarioRouteStationModulComponent,
    AnimatedImagesErrorDialogComponent,
    StmChartComponent,
    ManualControlResultComponent,
    GroupStellcodeDeleteDialogComponent,
    SpeedChartComponent,
    OderByPipe,
    ProjectLisamessageComponent,
    LisaGmapComponent,
    SolarDetailsComponent,
    NewMapComponent,
    NewMapJsonpLoadingComponent,
    WorkloadDiagrammComponent,
    BrennstoffzellenDetailsComponent,
    EvaResetComponent,
    ProjectGooglemapsComponent,
    FallbackImageSettingsComponent,
    EvaResetComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatButtonModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatCheckboxModule,
    CommonModule,
    MatButtonModule,
    MatSlideToggleModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    MatDialogModule,
    UserModule,
    MatTooltipModule,
    PopupRangePickerComponent,
    DatePipe,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    MtxTooltipModule,
    MatIconModule
  ],
  exports: [
    ProjectMenuComponent,
    ProjectRouteStationComponent,
    RouteStationSettingsComponent,
    TestAreaBrightnessComponent,
    TestAreaImageSettingsComponent,
    TestAreaAdditionalSettingsComponent,
    TestAreaDigitalOutputsComponent
  ],
  providers: [],
})
export class ProjectModule { }
