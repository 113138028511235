import { NgModule } from "@angular/core";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { TextInputComponent } from "./text-input/text-input.component";
import { AlertComponent } from './alert/alert.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ModalComponent } from './modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from "@angular/forms";
import { CheckboxDropdownComponent } from './checkbox-dropdown/checkbox-dropdown.component';
import { JsonDataViewerComponent } from './json-data-viewer/json-data-viewer.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({

  declarations: [
    DropdownComponent,
    TextInputComponent,
    AlertComponent,
    ModalComponent,
    CheckboxDropdownComponent,
    JsonDataViewerComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    MatProgressSpinnerModule

  ],
  exports: [
    DropdownComponent,
    TextInputComponent,
    AlertComponent,
    CheckboxDropdownComponent,
    JsonDataViewerComponent,
    LoadingSpinnerComponent
  ],
  providers: [],
})
export class SharedModule { }
