import { Component, Input } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader"
import { MarkerObject } from '../marker-object';

const loader: Loader = new Loader({
  apiKey: "AIzaSyBcos3aEaS6CkhPm_2YPTrbv3f1XUH6Hf0",
  version: "weekly"
});

const bakeImg = document.createElement('img');

bakeImg.src = '../../assets/icons/Bake.svg'

const glyphImg = document.createElement('img');
glyphImg.src = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/google_logo_g.svg';


/*
TODO: Full example
https://developers.google.com/maps/documentation/javascript/advanced-markers/accessible-markers?hl=de#expandable-1 */
@Component({
  selector: 'avs-new-map',
  templateUrl: './new-map.component.html',
  styleUrls: ['./new-map.component.css']
})
export class NewMapComponent {
  public map?: google.maps.Map;
  public center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };
  @Input() pins?: MarkerObject[];


  public ngOnInit(): void {
    this.initMap();
    /* this.addMarker(); */
  }

  public initMap(): void {
    loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
      const { PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
      /*  const parser = new DOMParser(); */
      /*    const pinSvgString = '<svg src="../assets/Bake.svg" width="56" height="56">' */
      const glyphSvgPinElement = new PinElement({
        glyph: glyphImg,
      });

      /* const pinSvg =
      parser.parseFromString(pinSvgString, 'image/svg+xml').documentElement; */

      this.map = new Map(document.getElementById("map") as HTMLElement, {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
        mapId: "YOUR_MAP_ID"
      });
      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: this.center,
        title: 'Base',
        content: bakeImg,
      });
      const glyphSvgMarkerView = new AdvancedMarkerElement({
        map: this.map,
        position: { lat: 37.425, lng: -122.07 },
        content: glyphSvgPinElement.element,
        title: "A marker using a custom SVG for the glyph.",
      });
      /*  const pinSvgMarkerView = new AdvancedMarkerElement({
         map: this.map,
         position: { lat: 37.42475, lng: -122.094 },
         content: bakeImg,
         title: 'A marker using a custom SVG image.',
       }); */



      /* https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers?hl=de#inline-svg */
    });
  }

  public addMarker(): void {
    if (this.pins && this.map) {
      this.pins.forEach((pin: MarkerObject) => {
        new google.maps.marker.AdvancedMarkerElement({
          position: { lat: pin.lat, lng: pin.lng },
          map: this.map,
          title: pin.title,
          content: bakeImg,

        });
      });
    }
  }
}
