import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { SWAConfig } from '../interfaces/swaconfig';
import { Observable, last } from 'rxjs';
import { HttpContentTypes } from 'src/app/core/shared/http-content-types.enum';

@Injectable({
  providedIn: 'root'
})
export class SWAConfigService {

  constructor(private readonly httpService: HttpService) { }

  public getSWAConfigs(groupID: number): Observable<any> { // TODO -> Typ ANY?
    return this.httpService.get<SWAConfig>('SWAConfig/Group/' + groupID);
  }

  public changeActiveSWAStatus(SWA: SWAConfig): Observable<SWAConfig> {
    let data = {
      id: SWA.id,
      settings: SWA.settings,
      active: !SWA.active,
      groupId: SWA.groupId,
      createdAt: SWA.createdAt
    }
    return this.httpService.put('SWAConfig/' + data.id, data)
  }

  public PostMQSettingsAndCreateSWA(groupId: number, data: any): Observable<SWAConfig> {
    return this.httpService.post('SWAConfig/PostMQSettingsAndCreateSWA/' + groupId, data)
  }

  public createSWA(groupId: number): Observable<SWAConfig> {
    return this.httpService.get('SWAConfig/CreateSWA/' + groupId)
  }

  public deleteSWA(groupId: number): Observable<any> {
    return this.httpService.delete('SWAConfig/' + groupId)
  }

  public createSWAwithJsonAsSetting(groupId: number, settings: any): Observable<SWAConfig> {
    let data = {
      groupId: groupId,
      custom: true,
      settings: settings 
    }
    return this.httpService.post('SWAConfig/', data)
  }
}
