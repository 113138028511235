import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getCustomstellcodeSelect } from '../../shared/enums/custom-stellcodes.enum';
import { ProjectImageData } from '../../shared/interfaces/project-image-data.interface';
import { ModalService } from 'src/app/shared/modal/shared/modal.service';

@Component({
  selector: 'avs-upload-image-modal',
  templateUrl: './upload-image-modal.component.html',
  styleUrls: ['./upload-image-modal.component.css']
})
export class UploadImageModalComponent implements OnInit {
  preview: any = '';
  selectedFiles?: FileList;
  currentFile?: File;
  progress: number = 0;
  public isFunctionDropdownOpen: boolean = false;
  public selectStellcode: SelectInput = { name: "201", id: 122 };
  public confirmationsDialogBoolean: boolean = false;
  public stellcodeSelect: SelectInput[] = getCustomstellcodeSelect()
  public filteredImages: ProjectImageData[] = [];
  public nextOpenImageId: number = 0;

  constructor(private dialogRef: MatDialogRef<UploadImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public modalService: ModalService) {
      this.filteredImages = this.data.notAssignedImages;
      this.nextOpenImageId = this.data.openImageId
    }


  public ngOnInit(): void {
    var stellcodeFilteredById = this.stellcodeSelect.find((e) => e.id === this.nextOpenImageId)
    if (stellcodeFilteredById != undefined) { this.selectStellcode.name = stellcodeFilteredById.name; this.selectStellcode.id = stellcodeFilteredById.id }
    this.getSelectInputClass();
  }

  public onGetSelectedClass(): string {
    if (this.filteredImages.find(fi => fi.imageId == this.selectStellcode.id)) {
      this.confirmationsDialogBoolean = true
      return 'fw-bolder text-success';
    } else {
      this.confirmationsDialogBoolean = false
      return 'fw-bolder text-danger';
    }
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public handleFileInput(event: any): void {
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectStellcode.name = item.name
    this.selectStellcode.id = item.id
  }

  public confirmationsDialog(): void {
    if (!this.confirmationsDialogBoolean) {
      this.modalService.openDialog('Stellcode wirklich überschreiben?', 'Nein', 'Ja').subscribe((result) => {
        if (result) {
          this.onUploadImageClicked()
        } else return
      })
    }
    else {
      this.onUploadImageClicked()
    }
  }

  public onUploadImageClicked(): void {
    if (this.selectedFiles)
      this.dialogRef.close({ file: this.selectedFiles[0], imageId: this.selectStellcode.id })
  }

  private getSelectInputClass(): void {
    this.stellcodeSelect.forEach(item => {
      if (this.filteredImages.find(fi => fi.imageId === item.id)) {
        item.class = 'fw-bolder text-success';
      } else {
       item.class = 'fw-bolder text-danger';
      }
    });
  }
}