import { Component, Input, OnInit } from '@angular/core';
import { ManualControl } from '../../shared/interfaces/manual-control.interface';
import { ManualControlService } from '../../shared/services/manual-control.service';
import { ScenarioManual } from '../../shared/interfaces/scenario-manual';
import { ParsedManualControlSettings } from '../../shared/interfaces/parsed-manual-control-settings.interface';
import { ProjectImageData } from '../../shared/interfaces/project-image-data.interface';
import { environment } from 'src/environments/environment';
import { getVerkehrsstufeString } from '../../shared/enums/verkehrsstufe';
import { getVerkehrszustandString } from '../../shared/enums/verkehrszustand';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';
@Component({
  selector: 'avs-manual-control-result',
  templateUrl: './manual-control-result.component.html',
  styleUrls: ['./manual-control-result.component.css']
})
export class ManualControlResultComponent implements OnInit {


  /* gebe ich das scenario manual weiter und sortiere hier drinnen? */
  /* @Input() manualControl?: ManualControl[] = []; */
  @Input() scenarioManual?: ScenarioManual | undefined;
  @Input() images?: ProjectImageData[] = [];
  @Input() users?: EditUser[] = []
  public getVerkehrsstufeString = getVerkehrsstufeString;
  public getverkehrszustandString = getVerkehrszustandString;
  public manualControl?: ManualControl;
  protected readonly environment = environment;

  public isAQ?: boolean;
  public isMQ?: boolean;
  public username?: string;


  public tooltipActive: boolean = false;
  public parsedSettings?: ParsedManualControlSettings;
  public createdAt?: Date;
  public lastChangedAt?: Date;
  public createdAtFormatted?: string;
  public lastChangedAtFormatted?: string;

  constructor(private manualControlService: ManualControlService) { }
  /* Ich brauche die User, für Umwandlung der UserId */







  public ngOnInit(): void {
  
    if (this.scenarioManual !== undefined && this.scenarioManual.manualControl !== undefined) {
      this.manualControl = this.scenarioManual?.manualControl;
      this.parsedSettings = JSON.parse(this.manualControl.settings);
      if (this.parsedSettings!.AQ === null) {
        this.isAQ = false;
      }
      if (this.parsedSettings!.MQ === null) {
        this.isMQ = false;
      }
    }
    if (this.manualControl !== undefined) {
      this.getUsername()
      this.getEditData()
    }
  }
  public getEditData() {
    this.createdAt = new Date(this.manualControl?.createdAt as string)
    this.lastChangedAt = new Date(this.manualControl?.lastChangedAt as string)
  
    this.createdAtFormatted  =  (this.createdAt.getUTCDate()as unknown) as string + '.' + ((this.createdAt.getUTCMonth() + 1) as unknown) as string + '.' + (this.createdAt.getUTCFullYear()as unknown) as string   // months from 1-12 /* so bauen, dass das für beide erledigt wird und in eine variable geschrieben wird */
    this.lastChangedAtFormatted  =  (this.lastChangedAt.getUTCDate()as unknown) as string + '.' + ((this.lastChangedAt.getUTCMonth() + 1) as unknown) as string + '.' + (this.lastChangedAt.getUTCFullYear()as unknown) as string   // months from 1-12 /* so bauen, dass das für beide erledigt wird und in eine variable geschrieben wird */

  }
  public getUsername() {
    let id = this.scenarioManual?.manualControl.lastChangedByUserId
    let user = this.users?.find((x) => x.id === id)
    this.username = user?.lastName + ', ' + user?.firstName

  }

  public getPathByStellcodeId(stellcode: number) {
    if (this.images) {
      let imageObject = this.images.find((x) => x.stellcode == stellcode)
      return imageObject?.path as string
    }
    else return
  }

  public checkIfStellcodeIsAnimated(stellcode: number) {
    if (this.images) {
      let imageObject = this.images.find((x) => x.stellcode == stellcode)
      return imageObject?.isAnimated as boolean
    }
    else return
  }


  getFunctionString(functionId: number): string {
    let response = ''
    if (functionId === 1) {
      response = 'An'
    }
    else if (functionId === 0) {
      response = 'Aus'
    }
    else if (functionId === 2) {
      response = 'Blinkend'
    }
    return response
  }

  getUserById(userId: number): string {
    let response = ''
    if (this.users) {
      let user = this.users.find((x) => x.id === userId)
      response = user?.lastName as string, user?.firstName
    }
    return response

  }
  /* brauche hier projektsImages für die Code Darstellung */
}
