import { Component } from '@angular/core';

@Component({
  selector: 'avs-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {

}
