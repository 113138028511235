import { Component, OnInit } from '@angular/core';
import { CompanyData } from "../../company/shared/interfaces/company-data.interface";
import { UserService } from "../shared/services/user.service";
import { UserDataResponse } from "../shared/interfaces/login-data-response.interface";
import { CompanyService } from "../../company/shared/services/company.service";
import { AlertTypes } from "../../shared/alert/shared/alert-types";
import { ModalService } from "../../shared/modal/shared/modal.service";
import { AlertService } from "../../shared/alert/shared/alert.service";
import { Router } from "@angular/router";
import { getRoleTypesString } from "../shared/enums/role.enum";
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { ProjectService } from 'src/app/project/shared/services/project.service';

@Component({
  selector: 'avs-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.css']
})
export class UserOverviewComponent implements OnInit {
  public userData: UserDataResponse[] = [];
  public companyData: CompanyData[] = [];
  public filteredUserData: UserDataResponse[] = [];
  protected readonly getRoleTypesString = getRoleTypesString;
  public projects: Project[] = [];

  constructor(private userService: UserService,
    private companyService: CompanyService,
    private modalService: ModalService,
    private alertService: AlertService,
    private router: Router,
    private projectService: ProjectService) {
  }

  public ngOnInit(): void {
    this.getAllUsers();
    this.getCompanyData();
    this.getProjects();
  }

  public getCompanyName(companyId: number): string {
    const company = this.companyData.find(company => company.id === companyId);
    return company ? company.companyName : 'Keine Institution hinterlegt';
  }

  public getProjectName(projectId: number): string {
    const project = this.projects.find(project => project.id === projectId);
    return project ? project.name : 'Fehler bei Zuweisung';
  }

  public onDeleteBtnClicked(userId: number): void {
    this.deleteUser(userId);
  }

  public onEditBtnClicked(userId: number): void {
    this.router.navigate(['user/edit/', userId]); /* eigene route... */
  }

  public onAddBtnClicked(): void {
    this.router.navigate(['user/create']);
  }
  public filterResults(text: string): void {
    if (!text) {
      this.filteredUserData = this.userData;
      return;
    }
    this.filteredUserData = this.userData.filter(
      userData => userData.firstName?.toLowerCase().includes(text.toLowerCase()) ||
        userData.lastName?.toLowerCase().includes(text.toLowerCase()) ||
        userData.email?.toLowerCase().includes(text.toLowerCase()) ||
        this.getCompanyName(userData.company)?.toLowerCase().includes(text.toLowerCase()) ||
        getRoleTypesString(userData.role)?.toLowerCase().includes(text.toLowerCase()) ||
        userData.userProjects?.find(element => this.getProjectName(element.projectId!).toLowerCase().includes(text.toLowerCase()))
    );
  }

  private deleteUser(userId: number): void {
    this.modalService.openDialog('Benutzer löschen?', 'Nein', 'Ja').subscribe(() => {
      this.userService.deleteUser(userId).subscribe(() => {
        this.alertService.alert('Der Benutzer wurde erfolgreich gelöscht', AlertTypes.info);
        this.userData = this.userData.filter(x => x.id !== userId)
        this.filteredUserData = this.filteredUserData.filter(x => x.id !== userId)
      })
    });
  }

  private getAllUsers(): void {
    this.userService.getAllUsers().subscribe(userData => {
      this.userData = userData;
      this.filteredUserData = userData
    })
  }

  private getCompanyData(): void {
    this.companyService.getCompanys().subscribe(companyData => {
      this.companyData = companyData;
    })
  }

  private getProjects(): void {
    this.projectService.getProjects().subscribe(projects => { this.projects = projects })
  }
}
