<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Zeitschaltuhr {{ ts ? ' bearbeiten' : 'erstellen'}}</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseAddTimeSwitchClicked()">
    </div>


    <avs-text-input label="Name" (valueChange)="onNameChange($event)" [value]="name"> </avs-text-input>

    <ngbd-datepicker-range-popup (startDate)="onStartDateSelect($event)" [loadedFromDate]="inputStart"
        [loadedToDate]="inputEnd" (endDate)="onEndDateSelect($event)"></ngbd-datepicker-range-popup>


    <div class="w-100 d-flex justify-content-evenly mb-2 ">
        <avs-time-input class="" label="Startzeit" (valueChange)="onEnterStartTimeChange($event)" [value]="starttime"
            id="starttime" name="starttime">
        </avs-time-input>
        <avs-time-input class="ml-1" label="Endzeit" (valueChange)="onEnterEndTimeChange($event)" [value]="endtime"
            id="endtime" name="endtime">
        </avs-time-input>
    </div>
    <div class="d-flex justify-content-between mt-5 border-top pt-3">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseAddTimeSwitchClicked()">
            Abbrechen
        </button>
        <button *ngIf="!editingActive" [disabled]="untouched" class="btn btn-sm btn-outline-success rounded-0"
            (click)="onSubmitClicked()">
            Erstellen
        </button>
        <button *ngIf="editingActive" class="btn btn-sm btn-outline-success rounded-0" (click)="onEditClicked()">
            Bearbeiten
        </button>
    </div>
</mat-dialog-content>