import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";
import { ProjectImageBrightness } from "../interfaces/project-image-brightness.interface";
import { ProjectImageDisplay } from "../interfaces/project-image-display.interface";
import { SetImage } from "../../project-images/shared/set-image.interface";
import { HttpContentTypes } from "../../../core/shared/http-content-types.enum";
import { UploadImage } from "../../project-images/shared/upload-image";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private httpService: HttpService) { }

  public updateImageBrightness(brightnessObject: ProjectImageBrightness): Observable<any> {
    const data = new FormData();
    data.append('deviceId', brightnessObject.deviceId.toString());
    data.append('brightness', brightnessObject.brightness.toString());
    data.append('deKanal', brightnessObject.deKanal.toString());
    data.append('useLocalSensor', brightnessObject.useLocalSensor ? 'true' : 'false');
    data.append('useSpontaneousMessages', brightnessObject.useSpontaneousMessages ? 'true' : 'false');
    return this.httpService.post<any>('Tls/Image/brightness', data, true, HttpContentTypes.formData);
  }

  public setImageForStation(setImage: SetImage): Observable<any> {
    const data = new FormData();
    data.append('stellcode', setImage.stellcode.toString());
    data.append('deviceId', setImage.deviceId.toString());
    data.append('deKanal', setImage.daKanal.toString());
    data.append('anzeigeprinzip', setImage.anzeigeprinzip.toString());
    data.append('indicator', setImage.indicator!.toString());
    data.append('flashingtime', setImage.flashingtime!.toString());
    return this.httpService.post('Tls/Image/Display', data, true, HttpContentTypes.formData);
  }

  public syncStellcodes(channelId: any, devId: number): Observable<any> {
    const data = new FormData();
    data.append('deKanal', `${channelId}`)
    data.append('deviceId', `${devId}`)
    return this.httpService.post('Tls/Image/syncStellcodes', data, true, HttpContentTypes.formData);
  }

  public setImageForGroup(groupId: number, stellcode: number, anzeigeprinzip: any, deKanal: any): Observable<any> {
    const data = new FormData();
    data.append('groupId', `${groupId}`)
    data.append('stellcode', `${stellcode}`)
    data.append('anzeigeprinzip', `${anzeigeprinzip}`)
    data.append('deKanal', `${deKanal}`)
    return this.httpService.post('Tls/Image/Gruppenstellcode', data, true, HttpContentTypes.formData);
  }


  public uploadImageToEva(guid: string, projectId: number): Observable<any> {
    return this.httpService.get(`Image/UploadImagesToEva/${guid}/${projectId}`);
  }
}
