<ng-container *ngIf="activeStation; else noActiveStation">
    <h6 class="mt-2 text-danger" *ngIf="!deviceParameter?.online">Station ist Offline</h6>
    <div class="row">
        <div class="col-xxl-6 mt-3">
            <div class="card rounded-0 p-3 shadow h-100">
                <avs-test-area-brightness [deviceParameter]="deviceParameter" [activeStation]="activeStation"
                    [dataEndChannels]="dataEndChannels">
                </avs-test-area-brightness>
            </div>
        </div>
        <div class="col-xxl-6 mt-3">
            <div class="card rounded-0 p-3 shadow h-100">
                <avs-test-area-image-settings [deviceParameter]="deviceParameter" [activeStation]="activeStation"
                    [dataEndChannels]="dataEndChannels">
                </avs-test-area-image-settings>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xxl-6 mt-3">
            <div class="card rounded-0 p-3 shadow h-100">
                <avs-test-area-additional-settings [deviceParameter]="deviceParameter" [activeStation]="activeStation">
                </avs-test-area-additional-settings>
            </div>
        </div>
        <div class="col-xxl-6 mt-3">
            <div class="card rounded-0 p-3 shadow h-100">
                <avs-test-area-digital-outputs [deviceParameter]="deviceParameter" [activeStation]="activeStation">
                </avs-test-area-digital-outputs>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xxl-6 mt-3">
            <div class="card rounded-0 p-3 shadow h-100">
                <avs-test-area-smart-micro [deviceParameter]="deviceParameter" [activeStation]="activeStation"> 
                </avs-test-area-smart-micro>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noActiveStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
</ng-template>