<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">

        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/problem.svg" alt="question-circle Icon">
            Bericht
        </h1>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wieso ? </h4> <button (click)="onShowReport()"> <img *ngIf="!isShowReport"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowReport" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowReport"> Wenn Sie im Reiter auf „Berichte“ klicken, wird die Meldung
                „report-overview works!“angezeigt,
                da gerade noch keine Daten enthalten sind.
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Weshalb ? </h4> <button (click)="onShowReportForWhatEverReason()"> <img
                            *ngIf="!isShowReportForWhatEverReason" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowReportForWhatEverReason" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowReportForWhatEverReason"> Wenn Sie im Reiter auf „Berichte“ klicken,
                wird die Meldung „report-overview works!“angezeigt,
                da gerade noch keine Daten enthalten sind.
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Warum ? </h4> <button (click)="onShowReportWhy()"> <img *ngIf="!isShowReportWhy"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowReportWhy" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowReportWhy"> Wenn Sie im Reiter auf „Berichte“ klicken, wird die
                Meldung „report-overview works!“angezeigt,
                da gerade noch keine Daten enthalten sind.
            </div>
        </div>
    </div>
</div>