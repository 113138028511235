import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProjectImageData } from "../../shared/interfaces/project-image-data.interface";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { ProjectDataService } from "../../shared/services/project-data.service";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { SetImage } from "../shared/set-image.interface";
import { ImageService } from "../../shared/services/image.service";
import { ProjectService } from "../../shared/services/project.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { DataEndChannelTypeEnum } from "../../shared/enums/data-end-channel-type.enum";
import { DeviceParameter } from "../../../device/shared/interfaces/device-parameter.interface";
import { DeviceService } from "../../../device/shared/services/device.service";
import { environment } from "../../../../environments/environment";
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-set-image-modal',
  templateUrl: './set-image-modal.component.html',
  styleUrls: ['./set-image-modal.component.css']
})
export class SetImageModalComponent implements OnInit, OnDestroy {
  public isKanalSelectOpen: boolean = false;
  public kanalSelectInput: SelectInput[] = [];
  public selectedKanal?: SelectInput;
  public deviceParameter?: DeviceParameter
  private routeStation?: ProjectRouteStation;
  protected readonly environment = environment;

  public subscriptionCollection: Subscription[] = [];
  public activeRouteStationSubscription?: Subscription;
  public dataEndChannelsSubscription?: Subscription;

  constructor(private dialogRef: MatDialogRef<SetImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectImageData,
    private projectDataService: ProjectDataService,
    private imageService: ImageService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private deviceService: DeviceService) {
    this.dataEndChannelsSubscription = projectDataService.dataEndChannels.subscribe(endChannels => {
      this.getKanalSelect(endChannels);
    });
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(station => {
      this.routeStation = station;
      if (station) {
        deviceService.getDeviceParameter(station?.samId).subscribe(parameter => {
          this.deviceParameter = parameter;
        });
      }
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
    this.subscriptionCollection.push(this.dataEndChannelsSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public onKanalSelectClicked(): void {
    this.isKanalSelectOpen = !this.isKanalSelectOpen;
  }

  public onKanalSelectValueChange(event: SelectInput): void {
    this.selectedKanal = event;
    this.isKanalSelectOpen = false;
  }

  public onSetImageClicked(): void {
    if (!this.selectedKanal) {
      this.alertService.alert('Kein Kanal ausgewählt', AlertTypes.warning);
      return;
    }
    let anzeigeprinzip: number;
    switch (this.selectedKanal.dataEndChannelType) {
      case DataEndChannelTypeEnum.LED:
        anzeigeprinzip = 3;
        break;
      case DataEndChannelTypeEnum.PW:
        anzeigeprinzip = 0;
        break;
      case DataEndChannelTypeEnum.BLINKER:
        anzeigeprinzip = 1;
        break;
      default:
        anzeigeprinzip = 3;
        break;
    }
    let setImage = {
      stellcode: this.data.stellcode,
      anzeigeprinzip: anzeigeprinzip!,
      daKanal: this.selectedKanal.id,
      deviceId: this.routeStation?.samId,
      indicator: 1,
      flashingtime: 0
    } as SetImage
    this.imageService.setImageForStation(setImage).subscribe(() => {
      this.alertService.alert('Stellcode ' + setImage.stellcode + ' gesetzt', AlertTypes.info);
      this.dialogRef.close(true);
    });
  }

  public onDeleteImageClicked(): void {
    this.projectService.deleteImageForRouteStation(this.data.projectRouteStationImageId).subscribe(() => {
      this.alertService.alert('Das Bild wurde aus der Station entfernt', AlertTypes.info);
      this.dialogRef.close(true);
    });
  }

  private getKanalSelect(endChannels: DataEndChannel[]): void {
    this.kanalSelectInput = [];
    for (let filteredChannel of endChannels.filter(e => e.fg === 4 && e.eakanalnr !== 0)) {
      let selectInput = {
        name: 'Kanal ' + filteredChannel.de,
        id: filteredChannel.de,
        dataEndChannelType: filteredChannel.dataEndChannelType
      } as SelectInput
      this.kanalSelectInput.push(selectInput);
    }
  }
}
