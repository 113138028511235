import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {DeviceParameter} from "../../../device/shared/interfaces/device-parameter.interface";
import {ImageSettings} from "../shared/interfaces/image-settings.interface";
import {EndpointTypes} from "../shared/enums/test-area-endpoint-types.enum";
import {StateTypes} from "../shared/enums/test-area-state-types.enum";
import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";
import {ProjectRouteStation} from "../../shared/interfaces/project-route-station.interface";
import {DataEndChannel} from "../../shared/interfaces/data-end-channel.interface";
import {ImageService} from "../../shared/services/image.service";
import {AlertService} from "../../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../../shared/alert/shared/alert-types";
import {DataEndChannelTypeEnum} from "../../shared/enums/data-end-channel-type.enum";
import {SetImage} from "../../project-images/shared/set-image.interface";

@Component({
  selector: 'avs-test-area-image-settings',
  templateUrl: './test-area-image-settings.component.html',
  styleUrls: ['./test-area-image-settings.component.css']
})
export class TestAreaImageSettingsComponent implements OnChanges{
  @Input() deviceParameter?: DeviceParameter;
  @Input() activeStation?: ProjectRouteStation;
  @Input() dataEndChannels: DataEndChannel[] = [];
  public imageSettings: ImageSettings = {endpoint: EndpointTypes.led, state: StateTypes.on, interval: 0};
  public selectKanalInput: SelectInput[] = [];
  public isDropdownOpen: boolean = false;
  public selectedKanal?: SelectInput;
  public isAnyValueChanged: boolean = false;
  protected readonly EndpointTypes = EndpointTypes;
  protected readonly StateTypes = StateTypes;

  constructor(private imageService: ImageService,
              private alertService: AlertService) {}

  public ngOnChanges(): void {
    this.getKanalSelect(this.dataEndChannels);
  }

  public onSettingCodeInputValueChange(value: number): void {
    this.deviceParameter!.led.stellcode = value;
    this.isAnyValueChanged = true;
  }

  public onDropdownClicked(): void {
    if (this.selectKanalInput.length < 1) {return;}
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public onSelectKanalItemClicked(selectItem: SelectInput): void {
    this.selectedKanal = selectItem;
    this.isDropdownOpen = false;
    this.isAnyValueChanged = true;
  }

  public onEndpointOptionsRadioBtnValueChange(type: EndpointTypes): void {
    this.isAnyValueChanged = true;
    switch (type) {
      case EndpointTypes.led:
        this.imageSettings.endpoint = EndpointTypes.led;
        break;
      case EndpointTypes.pw:
        this.imageSettings.endpoint = EndpointTypes.pw;
        break;
      case EndpointTypes.blinker:
        this.imageSettings.endpoint = EndpointTypes.blinker;
        break;
    }
  }

  public onStatusRadioBtnValueChange(type: StateTypes): void {
    this.isAnyValueChanged = true;
    switch (type) {
      case StateTypes.on:
        this.imageSettings.state = StateTypes.on;
        break;
      case StateTypes.off:
        this.imageSettings.state = StateTypes.off;
        break;
      case StateTypes.flashing:
        this.imageSettings.state = StateTypes.flashing;
        break;
    }
  }

  private getKanalSelect(endChannels: DataEndChannel[]): void {
    this.selectKanalInput = [];
    this.selectedKanal = undefined;
    if (!endChannels) {return;}
    for (let filteredChannel of endChannels.filter(e =>
        (e.fg === 4) && (e.de < 190))) {
      let selectInput = {
        name: 'Kanal ' + filteredChannel.de,
        id: filteredChannel.de,
        dataEndChannelType: filteredChannel.dataEndChannelType
      } as SelectInput
      this.selectKanalInput.push(selectInput);
    }
    this.selectedKanal = this.selectKanalInput.find(k => k.id === 1);
  }


  public onSaveBtnClicked(): void {
    if (!this.activeStation) { return; }
    if (!this.selectedKanal) {
      this.alertService.alert('Kein Kanal ausgewählt', AlertTypes.warning);
      return;
    }
    let anzeigeprinzip: number;
    switch (this.selectedKanal.dataEndChannelType) {
      case DataEndChannelTypeEnum.LED:
        anzeigeprinzip = 3;
        break;
      case DataEndChannelTypeEnum.PW:
        anzeigeprinzip = 0;
        break;
      case DataEndChannelTypeEnum.BLINKER:
        anzeigeprinzip = 1;
        break;
      default:
        anzeigeprinzip = 3;
        break;
    }
    let newDisplayObject = {
      stellcode: this.deviceParameter!.led.stellcode,
      daKanal: this.selectedKanal.id,
      deviceId: this.activeStation.samId,
      flashingtime: this.imageSettings.interval,
      indicator: this.imageSettings.state,
      anzeigeprinzip: anzeigeprinzip!
    } as SetImage
    this.imageService.setImageForStation(newDisplayObject).subscribe(newDisplayObject => {
      this.alertService.alert("Die Änderungen wurden erfolgreich gespeichert", AlertTypes.info);
      this.isAnyValueChanged = false;
    });
  }
}
