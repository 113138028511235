import {DataEndChannelTypeEnum} from "../../../project/shared/enums/data-end-channel-type.enum";

export class SelectInput {
  name: string;
  id?: string | number;
  routerLink?: string;
  samId?: number;
  dataEndChannelType?: DataEndChannelTypeEnum;
  class?: string;
  projectName?: string;

  constructor (name: string, id?: string, routerLink?: string, samId?: number, dataEndChannelType?: DataEndChannelTypeEnum, itemClass?: string, projectName?: string) {
    this.name = name;
    this.id = id;
    this.routerLink = routerLink;
    this.samId = samId;
    this.dataEndChannelType = dataEndChannelType;
    this.class = itemClass;
    this.projectName = projectName
  }
}
