<div class="d-flex justify-content-between">
  <h5 class="m-3">Navigation & Filter</h5>
</div>
<div class="d-flex">
  <avs-text-input placeholder="Suchen"
                  class="me-1 ms-1 container rounded-0"
                  [value]="searchInput"
                  (valueChange)="onSearchInputChanged($event)">
  </avs-text-input>
</div>
<div class="avs-nav-tree m-3">
  <ng-container *ngFor="let routeStation of filteredStations[0] ? filteredStations: routeStations">
    <div class="ms-2 me-3 mb-2 mt-2 avs-item-div"
         (click)="onRouteStationClicked(routeStation)"
      [ngClass]="activeRouteStation === routeStation ? 'avs-active' : ''">
      <img class="me-1 img" height="18px" src="../../../assets/icons/pin-map.svg" alt="ordner icon">
      <span>{{routeStation.name}}</span>
    </div>
  </ng-container>
</div>
