import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Changelog} from "../shared/interfaces/changelog.interface";

@Component({
  selector: 'avs-changelog-table',
  templateUrl: './changelog-table.component.html',
  styleUrls: ['./changelog-table.component.css']
})
export class ChangelogTableComponent implements OnChanges {
  @Input() changelogs: Changelog[] = [];
  limitedChangelogs: Changelog[] = []
  public rowQuantity: number = 50;
  public rowRange : {start: number, end: number} = {start: 0, end: 50};

  public ngOnChanges(): void {
    this.onDoubleLeftIconClicked();
  }

  public onRowQuantityChanged(newValue: number): void {
    this.rowRange.end = this.rowRange.end + newValue - this.rowQuantity;
    this.rowQuantity = newValue;
    this.limitRows();
  }

  public onLeftIconClicked(): void {
    if (this.rowRange.start > this.rowQuantity) {
      this.rowRange.start = this.rowRange.start - this.rowQuantity;
      this.rowRange.end = this.rowRange.end - this.rowQuantity;
    } else {
      this.rowRange.start = 0;
      this.rowRange.end = this.rowQuantity;
    }
    this.limitRows();
  }

  public onRightIconClicked(): void {
    if (this.rowRange.end + this.rowQuantity <= this.changelogs.length) {
      this.rowRange.start = this.rowRange.start + this.rowQuantity;
      this.rowRange.end = this.rowRange.end + this.rowQuantity;
    } else {
      this.rowRange.end = this.changelogs.length;
      this.rowRange.start = this.changelogs.length - this.rowQuantity;
    }
    this.limitRows();
  }

  public onDoubleLeftIconClicked(): void {
    this.rowRange.start = 0;
    this.rowRange.end = this.rowQuantity;
    this.limitRows();
  }

  public onDoubleRightIconClicked(): void {
    this.rowRange.start = this.changelogs.length - this.rowQuantity;
    this.rowRange.end = this.changelogs.length;
    this.limitRows();
  }

  private limitRows(): void {
    this.changelogs = !this.changelogs ? [] : this.changelogs;
    this.limitedChangelogs = this.changelogs.slice(this.rowRange.start, this.rowRange.start + this.rowQuantity);
  }
}
