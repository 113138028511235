import { Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { BatteryGraph } from '../../interfaces/battery-graph.interface';
import { CheckboxDropdownItem } from 'src/app/shared/checkbox-dropdown/shared/checkbox-dropdown-item.interface';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';

@Component({
  selector: 'avs-batterie-diagramm',
  templateUrl: './batterie-diagramm.component.html',
  styleUrls: ['./batterie-diagramm.component.css']
})
export class BatterieDiagrammComponent {
  public chart: any;
  public projectIds: number[] = [];
  public projects: Project[] = [];
  public selectedProjectFilter: SelectInput = { name: "Aktive Projekte", id: 2 };
  public projectFilter: SelectInput[] = [{ name: "Alle", id: 1 }, { name: "Aktive Projekte", id: 2 }];
  public isDropdownOpen: boolean = false;
  public chartData?: BatteryGraph;
  public projectSelectInput: CheckboxDropdownItem[] = [];
  public isCheckboxDropdownOpen: boolean = false;

  constructor(private reportService: ReportService,
    private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.getProjects().subscribe(x => {
      this.projects = x;
      x.forEach(element => {
        if (element.id === 2) { return }
        if (element.isActive)
          this.projectIds.push(element.id)
      })
      this.getData();
    })
  }

  public onDropdownClicked(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private getData(): void {
    this.reportService.getVoltageOverview(this.projectIds).subscribe((x: BatteryGraph) => {
      this.chartData = x;
      this.createChart();
    })
  }


  private createChart(): void {
    this.chart = new Chart("MyChart", {
      type: 'doughnut',

      data: {
        labels: ['<12V', '11,8-12V', '>11,8V', 'Übersprungene Geräte'],
        datasets: [{
          data: [this.chartData?.highVoltage!, this.chartData?.mediumVoltage, this.chartData?.lowVoltage, this.chartData?.skippedDevices],
          backgroundColor: [
            '#84c36c', /* grün */
            '#f7b731', /* gelb */
            '#d40c14',  /* rot */
            '#bdc3c7', /* grau */
          ],
          hoverBackgroundColor: [
            '#5ca43c',
            '#f39c12',
            '#bc0404',
            '#7f8c8d',
          ],
          hoverOffset: 0
        }],
      },
      options: {
        aspectRatio: 2.5,
        responsive: true,
        maintainAspectRatio: false,
        events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          datalabels: {
            font: {
              size: 13,
              weight: 'bold',
            },
            color: 'white',
            formatter: function (value: number, context: any) {
              const dataPoints = context.chart.data.datasets[0].data;
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              const totalValue = dataPoints.reduce(totalSum, 0);
              const percentageValue = (value / totalValue * 100).toFixed(1);

              return ` ${percentageValue}%`;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

}
