import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Project } from "../../shared/interfaces/project.interface";

@Injectable({
  providedIn: 'root'
})
export class MapHandlerService {

  private resizeSubject$ = new BehaviorSubject<boolean>(false);
  public isResize = this.resizeSubject$.asObservable();

  private groupLayerSubject$ = new BehaviorSubject<number>(0);
  public groupLayer = this.groupLayerSubject$.asObservable();

  private projectLayerSubject$ = new BehaviorSubject<number>(0);
  public projectLayer = this.projectLayerSubject$.asObservable();

  private RouteStationLayerSubject$ = new BehaviorSubject<number>(0);
  public routeStationLayer = this.RouteStationLayerSubject$.asObservable();


  /* 
  Need a default value;
  gonna extend it to numbers as follows:
  0 = default
  1 = true
  2 = false
  */

  public initResize(): void {
    this.resizeSubject$.next(true);
  }

  public removeGroupLayer(statement: number): void {
    this.groupLayerSubject$.next(statement);
  }

  public removeProjectLayer(statement: number): void {
    this.projectLayerSubject$.next(statement)
  }

  public removeRouteStationLayer(statement: number): void {
    this.RouteStationLayerSubject$.next(statement)
  }
}
