<h5 class="text-secondary p-2 ms-1 me-1 border-2 border-bottom text-center">Projekt erstellen</h5>
<div *ngIf="modalPage === 1" class="m-2">
  <avs-text-input label="Name"
                  [value]="projectMetaData.name"
                  (valueChange)="onMetaValueChange($event, ProjectMetaDataTypes.name)"
                  [required]="true"
                  placeholder="Projekt Name...">
  </avs-text-input>
  <div class="d-flex mt-3">
    <avs-text-input class="me-1"
                    label="von"
                    [value]="projectMetaData.timeSlotFrom"
                    (valueChange)="onMetaValueChange($event, ProjectMetaDataTypes.timeSlotFrom)"
                    [required]="true"
                    placeholder="dd.mm.yyyy">
    </avs-text-input>
    <avs-text-input class="ms-1"
                    label="bis"
                    [value]="projectMetaData.timeSlotTo"
                    (valueChange)="onMetaValueChange($event, ProjectMetaDataTypes.timeSlotTo)"
                    [required]="true"
                    placeholder="dd.mm.yyyy">
    </avs-text-input>
  </div>
  <div class="mt-3">
    <avs-text-input label="Notizen"
                    [value]="projectMetaData.notes"
                    (valueChange)="onMetaValueChange($event, ProjectMetaDataTypes.notes)"
                    placeholder="Notizen...">
    </avs-text-input>
  </div>
  <div class="mt-3 ms-1">
    <mat-slide-toggle color="primary"
                      [checked]="projectMetaData.isActiveChange"
                      (change)="onMetaValueChange($event, ProjectMetaDataTypes.isActive)">
      Aktiv
    </mat-slide-toggle>
  </div>
</div>
<div *ngIf="modalPage === 2" class="m-2">
  <div class="d-flex">
    <avs-text-input class="me-1"
                    label="Latitude"
                    [value]="projectPositionData.latitude ? projectPositionData.latitude.toLocaleString(
                    'en-EN', {minimumFractionDigits: 14}) : ''"
                    (valueChange)="onPositionValueChange($event, true)"
                    [required]="true"
                    placeholder="breitengrad...">
    </avs-text-input>
    <avs-text-input class="ms-1"
                    label="Longitude"
                    [value]="projectPositionData.longitude ? projectPositionData.longitude.toLocaleString(
                    'en-EN', {minimumFractionDigits: 14}) : ''"
                    (valueChange)="onPositionValueChange($event, false)"
                    [required]="true"
                    placeholder="längengrad...">
    </avs-text-input>
  </div>
</div>
<div class="p-2 mt-3 ms-1 me-1 border-2 border-top">
  <p *ngIf="modalPage === 1" class="text-center text-secondary">Projekt Meta Daten</p>
  <p *ngIf="modalPage === 2" class="text-center text-secondary">Projekt Positions Daten</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-sm btn-outline-danger rounded-0" type="button"
            (click)="onCancelBtnClicked()">Abbrechen</button>
    <button *ngIf="modalPage === 1" class="btn btn-sm btn-outline-success rounded-0"
            type="button" (click)="onFurtherBtnClicked()">Weiter</button>
    <div *ngIf="modalPage === 2">
      <button class="btn btn-sm btn-outline-secondary me-2 rounded-0"
              type="button" (click)="onBackBtnClicked()">Zurück</button>
      <button class="btn btn-sm btn-outline-success rounded-0"
              type="button" (click)="onSaveBtnClicked()">Speichern</button>
    </div>
  </div>
</div>
