export interface RouteStationMetaData {
    name: string | undefined;
    version: string | undefined;
    telefon: string | undefined;
}

export enum RouteStationMetaDataTypes {
    name,
    version,
    telefon
}