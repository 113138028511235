import { Component, Input, OnInit } from '@angular/core';
import { MetaInformationService } from 'src/app/project/shared/services/meta-information.service';
import { ProjectGroup } from 'src/app/project/shared/interfaces/project-group.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getMetaInformationTypeSelect } from '../enum/meta-information-type.enum'; 

@Component({
  selector: 'avs-line-name',
  templateUrl: './line-name.component.html',
  styleUrls: ['./line-name.component.css']
})
export class LineNameComponent implements OnInit {
  @Input() x?: number;
  @Input() y?: number;
  @Input() group?: ProjectGroup
  @Input() editActive?: boolean;
  public description: string = '';
  public componentActive: boolean = false;
  public id?: number;
  public descriptionTypeId: number = 0;
  public isDescriptionTypeDropdownOpen: boolean = false;
  public selectedDescriptionType: SelectInput = { name: "Default", id: 0 };
  public metaInformationTypeSelect: SelectInput[] = [{ name: "Default", id: 0 }, { name: "Parkplatz", id: 1 },{ name: "Ausfahrt - Austobahn", id: 4 },{ name: "Ausfahrt - Bundesstraße", id: 5 },{ name: "Autobahnkreuz", id: 6 }]
  public isBlocked: boolean = false;
  constructor(private metaInfoService: MetaInformationService) { }

  public ngOnInit(): void {
    this.getNameByCoordinates();
  }

  public deleteDescription(): void {
    this.metaInfoService.deleteMetaInformationById(this.id!).subscribe(x => this.description = '')
    this.id = undefined;
    this.description = ''
    this.descriptionTypeId = 0;
  }

  public onEnter(value: string): void {
    this.description = value;
    this.finishEdit();
    this.changeActiveStatus();
  }

  public onDescriptionTypeClicked(item: SelectInput): void {
    this.isDescriptionTypeDropdownOpen = false;
    this.selectedDescriptionType.name = item.name
    let descriptionType: number = item.id as number
    this.selectedDescriptionType.id = descriptionType
    this.descriptionTypeId = descriptionType
  }

  public onDescriptionTypeDropdownClicked(): void {
    this.isDescriptionTypeDropdownOpen = !this.isDescriptionTypeDropdownOpen;
  }

  public changeActiveStatus(): void {
    if (this.editActive)
      this.componentActive = !this.componentActive
    else return
  }

  public stopEditing(): void {
    this.description = '';
    this.descriptionTypeId = 0;
    this.changeActiveStatus()
  }

  public finishEdit(): void {
    this.createChangeDescription();
  }

  private createChangeDescription(): void {
    this.metaInfoService.postLineDescription(this.group?.id!, this.x!, this.y!, this.description, this.descriptionTypeId).subscribe(x => { this.id = x.id; this.description = x.description })
  }

  private getNameByCoordinates(): void {
    if (this.group?.metaInformation) {

      let metainfoDescription = this.group?.metaInformation.find(metainfo => metainfo.x === this.x && metainfo.y === this.y);
      if (metainfoDescription) {
        this.description = metainfoDescription.description; this.id = metainfoDescription.id; this.descriptionTypeId = metainfoDescription.descriptionType
      }
      let routeStation = this.group?.routeStations.find(routeStation => routeStation.x === this.x && routeStation.y === this.y)
      if (routeStation) {
        this.descriptionTypeId = 0;
        this.isBlocked = true;
      }
    }
  }
}
