<ng-container *ngIf="activeProject">
  <div class="row">
    <div class="col-xl-6 mt-3">
      <div class="card h-100 rounded-0 shadow p-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex ">
            <p class="fw-semibold text-secondary">Meta</p><span class="ms-2 text-secondary">Id:
              {{activeProject.id}}</span>
          </div>
          <mat-slide-toggle [checked]="activeProject.isActive" color="primary" (change)="onActiveProjeckChange()">
            Aktiv
          </mat-slide-toggle>
        </div>
        <avs-text-input label="Name" [value]="activeProject.name" placeholder="Project Name..."
          (valueChange)="onProjectMetaChange($event, ProjectMetaDataTypes.name)"></avs-text-input>
        <label class="form-label ms-2 mt-3">Zeitraum</label>
        <div class="d-flex">
          <avs-text-input class="w-100 me-1" [value]="activeProject.from" placeholder="von" [isDateString]="true"
            (valueChange)="onProjectMetaChange($event, ProjectMetaDataTypes.timeSlotFrom)"></avs-text-input>
          <avs-text-input class="w-100 ms-1" [value]="activeProject.to" placeholder="bis" [isDateString]="true"
            (valueChange)="onProjectMetaChange($event, ProjectMetaDataTypes.timeSlotTo)"></avs-text-input>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-success mt-3 rounded-0" type="button" *ngIf="projectMetaData"
            (click)="onSaveMetaDataClicked()">
            <span>Speichern</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-6 mt-3">
      <div class="card h-100 rounded-0 shadow">
        <div class="placeholder-map-project"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="mt-3 col-xl-6">
      <div class="card h-100 rounded-0 shadow p-3">
        <div class="d-flex justify-content-between">
          <p class="fw-semibold text-secondary">Gruppen</p>
          <img (click)="onAddGroupBtnClicked()" class="avs-icon-add mb-3" src="assets/icons/plus.svg"
            alt="icon-add-group">
        </div>
        <div *ngIf="projectGroups && projectGroups.length > 0; else noGroups" class="border-2 border">
          <ng-container *ngFor="let projectGroup of projectGroups">
            <div class="m-2 border-bottom border-1 d-flex justify-content-between">
              <span class="p-1 avs-group-list-item" (click)="onGroupClicked(projectGroup)">{{projectGroup.name}}</span>
              <div>
                <img class="avs-icon-delete p-1" src="assets/icons/trash.svg" alt="icon-delete-group"
                  (click)="onDeleteGroupClicked(projectGroup)">
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #noGroups>
          <p class="mt-3 fw-semibold text-secondary">Keine Gruppen angelegt</p>
        </ng-template>
      </div>
    </div>
    <div class="mt-3 col-xl-6">
      <div class="card shadow h-100 rounded-0 p-3">
        <div class="d-flex justify-content-between">
          <p class="text-secondary fw-semibold ms-3">Ort</p>
          <div>
            <a href="https://maps.google.com/?q={{activeProject.latitude}},{{activeProject.longitude}}" target="_blank"
              class="btn">
              <img src="../../../../assets/icons/pin-map-fill.svg" alt="Open" class="avs-icon-edit p-2" height="36"></a>
            <button class="btn" (click)="onCopyCoordinatesLinkBtnClick()"><img src="../../../../assets/icons/copy.svg"
                alt="Open" height="36" class="avs-icon-edit p-2"></button>
          </div>
        </div>
        <div class="row">
          <avs-text-input class="col-xxl-6 mb-2" label="Latitude"
            [value]="activeProject.latitude.toLocaleString('en-EN', {minimumFractionDigits: 14})"
            placeholder="latitude..." (valueChange)="onProjectPositionChange($event, true)"></avs-text-input>
          <avs-text-input class="col-xxl-6" label="Longitude"
            [value]="activeProject.longitude.toLocaleString('en-EN', {minimumFractionDigits: 14})"
            placeholder="longitude" (valueChange)="onProjectPositionChange($event, false)"></avs-text-input>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-success mt-3 rounded-0" type="button" *ngIf="projectPositionData"
            (click)="onSavePositionDataClicked()">
            <span>Speichern</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 mt-3">
      <div class="card shadow rounded-0 p-3 h-100">
        <p class="fw-semibold text-secondary">Unterlagen</p>
      </div>
    </div>
    <div class="col-xl-12 mt-3">
      <div class="card shadow rounded-0 p-3 h-100">
        <p class="fw-semibold text-secondary">Zugewiesene Nutzer</p>
        <div *ngIf="activeProjectUsers && activeProjectUsers.length > 0; else noUsers" class="border-2 border">
          <ng-container *ngFor="let user of activeProjectUsers">
            <div class="m-2 border-bottom border-1 d-flex justify-content-between">
              <span class="p-1 avs-group-list-item">{{user.firstName}} {{ user.lastName}}</span>
              <div><img *canAccess="[1]" class="avs-icon-delete p-1" src="assets/icons/pencil.svg" alt="avs-icon-edit"
                  (click)="onEditBtnClicked(user.id)">
                <img class="avs-icon-delete p-1" src="assets/icons/trash.svg" alt="icon-delete-group"
                  (click)="onDeleteUsersAssignmentClicked(user)">
              </div>
            </div>

          </ng-container>
        </div>
      </div>
      <ng-template #noUsers>
        <p class="mt-3 ms-1 fw-semibold ">Keine Nutzer zugewiesen</p>
      </ng-template>
    </div>
    <div class="col-xl-12 mt-3">
      <div class="card shadow rounded-0 p-3 h-100">
        <div class="d-flex justify-content-between">
          <p class="fw-semibold text-secondary">Bilder</p>
          <div>
            <img (click)="onDeleteStellcodeForGroup()" class="avs-icon-add mb-3" src="assets/icons/trash.svg"
              alt="Stellcode für Gruppe löschen">
            <img (click)="onGenerateAnimatedImageClicked()" class="avs-icon-add mb-3" src="assets/icons/images.svg"
              alt="Wechselbild erstellen">
            <img (click)="onAddCustomImgClicked()" class="avs-icon-add mb-3" src="assets/icons/plus.svg"
              alt="Customstellcode hochladen">
          </div>
        </div>
        <div class="row avs-project-images-container">
          <ng-container *ngFor="let image of images">
            <div (click)="onProjectImageClicked(image)" class="mb-3 avs-project-images">
              <div class="d-flex justify-content-center">
                <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
              </div>
              <div class="small text-center">Stellcode: {{image.stellcode}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>