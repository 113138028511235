import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { ProjectDataService } from 'src/app/project/shared/services/project-data.service';
import { EngineService } from '../shared/services/engine.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { Subscription } from 'rxjs';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { FormControl, FormGroup } from '@angular/forms';
import { ChangelogProjectFilter } from '../shared/interfaces/changelog-project-filter.interface';
import { Changelog } from '../shared/interfaces/changelog.interface';
import { ChangelogGroupFilter } from '../shared/interfaces/changelog-group-filter.interface';
import { SettingWithAQChangelogs, SettingWithChangelogs, SettingWithEngineChangelogs, SettingWithMQChangelogs } from '../shared/interfaces/setting-with-changelogs.interface';
import { ChangelogConfigOverviewModalComponent } from '../changelog-config-overview-modal/changelog-config-overview-modal.component';
import { ChangelogConfigurationModalComponent } from '../changelog-configuration-modal/changelog-configuration-modal.component';
import { RouteStationService } from 'src/app/project/shared/services/route-station.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChangelogDeviceFilter } from '../shared/interfaces/changelog-device-filter.interface';
import { ModalService } from 'src/app/shared/modal/shared/modal.service';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { ProjectRouteStation } from 'src/app/project/shared/interfaces/project-route-station.interface';
import { ProjectRouteStationWithParam } from 'src/app/project/shared/interfaces/project-route-station.class';
import { ChangelogMqFilter } from '../shared/interfaces/changelog-mq-filter.interface';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { ChangelogMqReport } from '../shared/interfaces/changelog-mq-report.interface';
import { ChangelogMqReportCollection } from '../shared/interfaces/changelog-mq-report-collection.interface';
import { ChangelogAqFilter } from '../shared/interfaces/changelog-aq-filter.interface';
import { ChangelogAqReport } from '../shared/interfaces/changelog-aq-report';
import { ChangelogAqReportCollection } from '../shared/interfaces/changelog-aq-report-collection';
import { EngineChangelog } from '../shared/interfaces/changelogEngine.interface';
import { JpgUrlBySam } from '../shared/interfaces/jpg-url-by-sam';
import { PdfUrlBySam } from '../shared/interfaces/pdf-url-by-sam';

/* TODO: 
Wenn Reportings als fertig akzeptiert werden, dann gerne mal refactorn.
download auslagern,
datehandling auslagern,
separate components für tree 1 und 2?
etc...
*/
interface ProjectNode {
  name: string;
  children?: GroupNode[];
  status?: string;
  active?: boolean;
  inactive?: boolean;
  archived?: boolean;
  projectId?: number;
  groupId?: number;
}

interface GroupNode {
  name: string;
  children?: DeviceNode[];
  groupId?: number,
  projectId?: number
}

interface DeviceNode {
  name: string;
  samId?: number,
}

interface DeviceTreeNode {
  name: string;
  groupName?: string;
  children?: GroupNode[];
  groupId?: number;
  samId?: number;
  guid?: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  groupId?: number;
}

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear()
const day = today.getDate();
@Component({
  selector: 'avs-detailed-reportings',
  templateUrl: './detailed-reportings.component.html',
  styleUrls: ['./detailed-reportings.component.css']
})
export class DetailedReportingsComponent {

  public projects: Project[] = [];
  public selectedProject: SelectInput = { name: 'alle Projekte', id: 0 };
  public selectedGroup: SelectInput = { name: 'alle Gruppen', id: 0 };
  public selectedStatus = 'aktiv'
  public selectedDevice = 'alle'
  public downloadType = '';
  public showTree: boolean = true;
  public projectReportData: ProjectNode[] = [];
  public routeStationReportData: DeviceTreeNode[] = [];
  public projectSelectInput: SelectInput[] = [];
  public fromDate: string = '';
  public toDate: string = '';
  private subscriptionCollection: Subscription[] = [];
  private projectSubscription: Subscription;
  public rangePicker: any = ''
  public test: any = ''
  public searchFilter: string = '';
  public searchFilterTree: string = '';
  public isLoading: boolean = false;
  public changelogs: Changelog[] = [];
  public engineChangelogs: EngineChangelog[] = [];
  public reportCollection: SettingWithChangelogs[] = [];
  public reportEngineCollection: SettingWithEngineChangelogs[] = [];
  public reportMQCollection: SettingWithMQChangelogs[] = [];
  public reportAQCollection: SettingWithAQChangelogs[] = [];
  public userResponse: string = 'Bitte Filter setzen';
  public isGroupSelected?: boolean;
  public range = new FormGroup({
    start: new FormControl(new Date(year, month - 1, 1)),
    end: new FormControl(new Date(year, month, 0)),
  });
  public minDate: Date;
  public maxDate: Date;
  public selectedDeviceTree: SelectInput = { name: '-', samId: 0 };
  public tabs = ['Projekt-Übersicht', 'Geräte-Übersicht'];
  public selected = new FormControl(0);
  public activeTab: string = 'Projekt-Übersicht';

  public activeRouteStation: ProjectRouteStationWithParam | undefined;
  public projectRouteStations: ProjectRouteStation[] = [];
  public mqReport: ChangelogMqReport[] = [];
  public mqReportCollection: ChangelogMqReportCollection[] = [];

  public aqReportCollection: ChangelogAqReportCollection[] = [];
  public aqReport: ChangelogAqReport[] = [];

  public intervall: number = 60;
  public intervalls: number[] = [5, 15, 30, 60];

  public jpgURL: string = '';
  public pdfURL: string = '';

  public jpgURLObjects: JpgUrlBySam[] = [];
  public pdfURLObjects: PdfUrlBySam[] = [];

  constructor(private readonly projectService: ProjectService,
    private readonly projectDataService: ProjectDataService,
    private readonly engineService: EngineService,
    private readonly dialog: MatDialog,
    private readonly routeStationService: RouteStationService,
    private alertService: AlertService,
    private modalService: ModalService,
    private deviceService: DeviceService,
    private reportService: ReportService
  ) {
    this.projectSubscription = projectDataService.projects.subscribe(projects => {
      if (projects.length > 0) {
        this.projects = projects;
        this.fillProjectSelectInput(projects);
      }
    });
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date()/* heute */
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public ngOnInit(): void {
    this.projectService.getProjects().subscribe(projects => {
      projects.forEach(project => {
        let children: GroupNode[] = [];
        project.groups.forEach(group => {
          children.push({ name: group.name, children: [], groupId: group.id });
        });
        this.projectReportData.push({ name: project.name, children: children, active: project.isActive, inactive: !project.isActive, projectId: project.id });
     });
      this.dataSource.data = this.projectReportData;
      this.filterDataSource();
      this.projectDataService.updateProjects(projects);
    });
    this.routeStationService.getRouteStations().subscribe(routeStations => {
      this.projectRouteStations = routeStations;
      routeStations.forEach(routeStation => {

        this.routeStationReportData.push({ name: routeStation.name, groupId: routeStation.groupId, samId: routeStation.samId, guid: routeStation.id, children: [] });
      });
      this.dataSourceRouteStations.data = this.routeStationReportData;
    })
    this.subscriptionCollection.push(this.projectSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }



  public recievedJPGURL(url: string, samId: number, channel: number, dateFrom: string, dateTo: string) {
    this.jpgURLObjects.push({ samId: samId, channel: channel, jpgUrl: url, dateFrom: dateFrom, dateTo: dateTo })
  }

  public recievedPDFURL(url: string, samId: number, channel: number, dateFrom: string, dateTo: string) {
    this.pdfURLObjects.push({ samId: samId, channel: channel, pdfUrl: url, dateFrom: dateFrom, dateTo: dateTo })
  }

  public filterURLs(samId: number, channel: number, fromDate: string, toDate: string): string {
    let url = this.jpgURLObjects.find(url => url.samId === samId && url.channel === channel && url.dateFrom === fromDate && url.dateTo === toDate)
    return url?.jpgUrl! ? url.jpgUrl : ''
  }

  public createReport(): void {
    if (this.selectedDeviceTree.samId === 0 && this.selectedGroup.id === 0 && this.selectedProject.id === 0) {
      this.alertService.alert('Bitte wählen Sie ein Projekt, eine Gruppe oder ein Gerät aus', AlertTypes.error);
      return;
    }
    if (this.activeTab === 'Projekt-Übersicht') {
      if (this.isGroupSelected) {
        this.getGroupChangelos();
      }
      else {
        this.getProjectChangelogs();
      }
    }
    else {
      this.getRouteStationChangelogs();
    }
  }

  public createEngineReport(): void {
    if (this.selectedDeviceTree.samId === 0 && this.selectedGroup.id === 0 && this.selectedProject.id === 0) {
      this.alertService.alert('Bitte wählen Sie ein Projekt, eine Gruppe oder ein Gerät aus', AlertTypes.error);
      return;
    }
    if (this.activeTab === 'Projekt-Übersicht') {
      if (this.isGroupSelected) {
        this.getGroupEngineChangelos();
      }
      else {
        this.getProjectEngineChangelogs();
      }
    }
    else {
      this.getRouteStationEngineChangelogs();
    }
  }

  public isDisabled(): boolean {
    return this.selectedProject.id === 0;
  }

  public isIntervallDropdownOpen: boolean = false;
  public intervallSelect: SelectInput[] = [{ name: '5 Minuten', id: 5 }, { name: '15 Minuten', id: 15 }, { name: '30 Minuten', id: 30 }, { name: '60 Minuten', id: 60 }];
  public selectedIntervall: SelectInput = { name: '5 Minuten', id: 5 };

  public filterDataSource() {
    let filteredData: ProjectNode[] = [];
    this.dataSource.data = [];
    if (this.selectedStatus === 'aktiv') {
      let activeProjectsData = this.projectReportData.filter(project => project.active);
      activeProjectsData.forEach(project => filteredData.push(project as ProjectNode));
    }
    else if (this.selectedStatus === 'inaktiv') {
      let inactiveProjectsData = this.projectReportData.filter(project => project.inactive);
      inactiveProjectsData.forEach(project => { filteredData.push(project as ProjectNode) });
    }
    else if (this.selectedStatus === 'alle') {
      let activeProjectsData = this.projectReportData.filter(project => project.active);
      activeProjectsData.forEach(project => filteredData.push(project as ProjectNode));
      let inactiveProjectsData = this.projectReportData.filter(project => project.inactive);
      inactiveProjectsData.forEach(project => { filteredData.push(project as ProjectNode) });
    }
    this.dataSource.data = filteredData;
    if (this.searchFilter !== '') {
      this.dataSource.data = this.dataSource.data.filter(project => project.name.toLowerCase().includes(this.searchFilter.toLowerCase()));
    }
  }

  public filterDataSourceTree() {
    this.dataSourceRouteStations.data = [];
    if (this.searchFilterTree === '') {
      this.dataSourceRouteStations.data = this.routeStationReportData;
    }
    if (this.searchFilterTree !== '') {
      this.dataSourceRouteStations.data = this.routeStationReportData.filter(rs => rs.name.toLowerCase().includes(this.searchFilterTree.toLowerCase()) || rs.samId?.toString().includes(this.searchFilterTree));
    }
  }

  public setGroupIdByName(node: GroupNode): void {

    let NameParts = node.name.split(' ')
    let cleanName = node.name.split(' ').slice(1).join(' ')
    let projectIdpart = NameParts[0].split(':')[1]
    let projectId = projectIdpart.split(';')[0]

    let groupIdpart = NameParts[0].split(':')[2]
    let groupId = groupIdpart.split(';')[0]


    this.isGroupSelected = true;
    this.downloadType = 'json';
    this.projectReportData.forEach(project => {
      project.children?.forEach(group => {
          this.selectedGroup = { name: cleanName, id: groupId }
      })
    })
    if (Number(groupId) > 0) {
      let project = this.getProjectByGroupId(Number(groupId));
      this.projects.forEach(project => { project.groups.forEach(group => { if (group.name === node.name) { groupId = groupId; this.selectedGroup = { name: group.name, id: groupId } } }) })
      this.selectedProject = { name: project.name, id: project.id };
    }
    else
      this.alertService.alert('Keine Gruppen für dieses Projekt vorhanden', AlertTypes.error)
  }

  public setProjectIdByName(node: ProjectNode): void {
    let NameParts = node.name.split(' ')
    let cleanName = node.name.split(' ').slice(1).join(' ')
    let projectIdpart = NameParts[0].split(':')[1]
    let projectId = projectIdpart.split(';')[0]
    this.selectedGroup = { name: '', id: 0 };
    this.isGroupSelected = false;
    this.downloadType = 'json';
    let project = this.projectReportData.find(project => cleanName === node.name);
    this.selectedProject = { name: cleanName, id: projectId };
    this.selectedGroup = { name: 'alle Gruppen', id: 0 };
  }

  public setSamIDByName(node: DeviceTreeNode): void {
    this.triggerLoadingSpinner();
    let samId = node.name.split(' ')[1]
    this.selectedDeviceTree = { name: '', samId: 0 };
    this.downloadType = 'json';
    this.selectedDeviceTree = { name: node.name, samId: +(samId) };
    this.selectedGroup = { name: 'alle Gruppen', id: 0 };
    this.selectedProject = { name: 'alle Projekte', id: 0 };

    this.deviceService.getDeviceParameter(Number(samId)).subscribe(deviceParameter => {
      this.isLoading = false;
      this.activeRouteStation = new ProjectRouteStationWithParam({} as ProjectRouteStation, deviceParameter);
      this.activeRouteStation.routeStation = this.projectRouteStations.find(routeStation => routeStation.samId === +(samId))!;
    })
  }

  public correctTimezone(date: string): string {
    let conv = new Date(date)
    let fromTime = new Date(conv).getTime()
    let fromMs = new Date(date).setTime(fromTime + 2 * 60 * 60 * 1000)
    let fromIso = new Date(fromMs).toISOString()
    return fromIso
  }


  public getProjectByGroupId(projectId: number): Project {
    let project = this.projects.find(project => project.groups.find(group => group.id === projectId));
    return project!
  }

  public getProjectChangelogs(): void { /* DEPRECATED */
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()
    let formattedToDate = newToDate.toISOString()
    if (this.selectedProject.id) {
      let projectFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        projectId: +(this.selectedProject.id),
        type: this.downloadType
      } as ChangelogProjectFilter;
      this.engineService.getChangelogProject(projectFilter).subscribe(changelogs => {
        this.changelogs = changelogs;
        this.isLoading = false;

        this.reportCollection.push({ projectName: this.selectedProject.name, changelog: changelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: false, filter: projectFilter, filterType: 'project' });
        this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + this.aqReportCollection.length + 1));
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für dieses Projekt vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) })

    }
  }

  public getProjectEngineChangelogs(): void {
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()
    let formattedToDate = newToDate.toISOString()
    if (this.selectedProject.id) {
      let projectFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        projectId: +(this.selectedProject.id),
        type: this.downloadType
      } as ChangelogProjectFilter;
      this.engineService.getEngineChangeLogMQProject(projectFilter).subscribe(changelogs => {
        this.engineChangelogs = changelogs;
        this.isLoading = false;

        this.reportEngineCollection.push({ projectName: this.selectedProject.name, changelog: changelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: false, filter: projectFilter, filterType: 'project' });
        this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + 1));
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für dieses Projekt vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) })

    }
  }

  public getRouteStationChangelogs(): void { /* DEPRECATED */
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()/* .split('T')[0]; */
    let formattedToDate = newToDate.toISOString()/* .split('T')[0]; */
    if (this.selectedDeviceTree.samId) {
      let deviceFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        samId: this.selectedDeviceTree.samId,
        type: this.downloadType
      } as ChangelogDeviceFilter;
      this.engineService.getChangelogDevice(deviceFilter).subscribe(changelogs => {
        this.changelogs = changelogs;
        this.isLoading = false;
        this.reportCollection.push({ projectName: this.selectedDeviceTree.name, changelog: changelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: false, filter: deviceFilter, filterType: 'device' }); this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + this.aqReportCollection.length + 1));
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          /*  this.alertService.alert('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', AlertTypes.error) */
          this.modalService.openDialog('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) })
    }
  }

  public getRouteStationEngineChangelogs(): void {
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()/* .split('T')[0]; */
    let formattedToDate = newToDate.toISOString()/* .split('T')[0]; */
    if (this.selectedDeviceTree.samId) {
      let deviceFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        samId: this.selectedDeviceTree.samId,
        type: this.downloadType
      } as ChangelogDeviceFilter;
      this.engineService.getEngineChangeLogMQDevice(deviceFilter).subscribe(changelogs => {
        this.engineChangelogs = changelogs;
        this.isLoading = false;
        this.reportEngineCollection.push({ projectName: this.selectedDeviceTree.name, changelog: changelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: false, filter: deviceFilter, filterType: 'device' }); this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + 1));
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          /*  this.alertService.alert('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', AlertTypes.error) */
          this.modalService.openDialog('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) })
    }
  }
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string; /*  */


  public repeatingRequest(maxKanal: number, filter: ChangelogMqFilter) {
    this.triggerLoadingSpinner();
    for (let index = 0; index < maxKanal; index++) {
      filter.deKanal = index + 1;
      this.mqReport = []
      this.reportService.getRouteStationsSpeedWithFilterNew(filter.id, filter.dateFrom, filter.dateTo, filter.minutes, filter.deKanal, filter.projectId).subscribe(changelogs => {
        this.mqReport = changelogs;

        this.mqReportCollection.push({ changeLogMqReport: changelogs });
        if (this.mqReportCollection.length === maxKanal) {
          this.reportMQCollection.push({ projectName: this.selectedDeviceTree.name, changelog: this.mqReportCollection, fromDate: filter.dateFrom, toDate: filter.dateTo, deviceType: this.selectedDevice, filter: filter, filterType: 'device', samId: filter.id, projectId: this.activeRouteStation?.routeStation.groupId });
          this.tabs.push(this.selectedProject.name);
          this.selected.setValue(this.tabs.length - 1);
          this.isLoading = false;
        }
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
          this.isLoading = false;
        }
      })
    }

  }

  lastSunday(year: number, month: number) {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);
    return date;
  }

  isCEST(d: Date): boolean {
    let yearString = d as unknown as number;
    let startCET: Date = this.lastSunday(yearString, 3);
    let endCET: Date = this.lastSunday(yearString, 10);

    return !(startCET < d && d < endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }

  public getMQChangelog(): void {
    this.mqReportCollection = [];

    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }

    let formattedFromDate = newFromDate.toISOString()
    let formattedToDate = newToDate.toISOString()
    let maxKanal = this.activeRouteStation?.deviceParameter.radar.length;
    if (this.selectedDeviceTree.samId) {
      let mqFilter = {
        dateFrom: formattedFromDate,
        dateTo: formattedToDate,
        id: this.selectedDeviceTree.samId,
        minutes: this.intervall,
        type: this.downloadType,
        projectId: this.activeRouteStation?.routeStation.groupId,
        deKanal: 1
      } as ChangelogMqFilter;
      this.repeatingRequest(maxKanal!, mqFilter)
    }
  }

  public getAQChangelog(): void { /* DEPRECATED */
    this.aqReportCollection = [];
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()
    let formattedToDate = newToDate.toISOString()

    if (this.selectedDeviceTree.samId) {
      let aqFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        samId: this.selectedDeviceTree.samId,
        type: this.downloadType,

      } as ChangelogAqFilter;
      this.aqReport = []
      this.reportService.getAQReport(aqFilter).subscribe(changelogs => {
        this.aqReport = changelogs;
        this.aqReportCollection.push({ changeLogAqReport: changelogs });
        this.reportAQCollection.push({ projectName: this.selectedDeviceTree.name, changelog: this.aqReportCollection, fromDate: aqFilter.from, toDate: aqFilter.to, deviceType: this.selectedDevice, filter: aqFilter, filterType: 'device', samId: Number(this.selectedDeviceTree.samId), projectId: this.activeRouteStation?.routeStation.groupId });
        this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + 1));
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für dieses Gerät vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      })
      this.isLoading = false;
    }
  }

  public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.activeTab = tabChangeEvent.tab.textLabel;
  }

  public downloadReport(type: string, filter: SettingWithChangelogs) {
    filter.filter.type = type;
    if (filter.filterType === 'project') {
      this.getProjectDownload(filter);
    }
    else if (filter.filterType === 'group') {
      this.getGroupDownload(filter);
    }
    else if (filter.filterType === 'device') {
      this.getRouteStationDownload(filter);
    }
  }

  public downloadEngineReport(type: string, filter: SettingWithEngineChangelogs) {
    filter.filter.type = type;
    if (filter.filterType === 'project') {
      this.getEngineProjectDownload(filter);
    }
    else if (filter.filterType === 'group') {
      this.getEngineGroupDownload(filter);
    }
    else if (filter.filterType === 'device') {

      this.getEngineRouteStationDownload(filter);
    }
  }

  public removeTab(index: number) {
    this.reportEngineCollection.splice(index, 1);
  }

  public removeMQTab(index: number) {
    this.reportMQCollection.splice(index, 1);
  }

  public removeAQTab(index: number) {
    this.reportAQCollection.splice(index, 1);
  }

  public resetTree(): void {
    this.searchFilter = '';
    this.filterDataSource();
  }

  public resetTreeTree(): void {
    this.searchFilterTree = '';
    this.filterDataSourceTree();
  }


  public getGroupEngineChangelos(): void {
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()
    let formattedToDate = newToDate.toISOString()

    if (this.selectedGroup.id) {
      let groupFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        groupId: +(this.selectedGroup.id!),
        type: this.downloadType
      } as ChangelogGroupFilter;
      this.engineService.getEngineChangeLogMQGroup(groupFilter).subscribe(changelogs => {
        this.engineChangelogs = changelogs;
        this.reportEngineCollection.push({ projectName: this.selectedProject.name + ' | ' + this.selectedGroup.name, changelog: this.engineChangelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: true, filter: groupFilter, filterType: 'group' });
        this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + 1)); /* TODO: position noch updaten */
        this.isLoading = false;
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für diese Gruppe vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) }
      );
    }
  }

  public returnCleanName(name: string): string {
    return name.split(' ').slice(1).join(' ');
  }

  public getGroupChangelos(): void {
    this.triggerLoadingSpinner();
    let newFromDate = new Date(this.range.value.start!);
    let newToDate = new Date(this.range.value.end!);
    if (this.isCEST(newToDate)) { newToDate.setHours(23, 59, 59, 999) }
    else { newToDate.setHours(22, 59, 59, 999) }
    let formattedFromDate = newFromDate.toISOString()/* .split('T')[0]; */
    let formattedToDate = newToDate.toISOString()/* .split('T')[0]; */

    if (this.selectedGroup.id) {
      let groupFilter = {
        from: formattedFromDate,
        to: formattedToDate,
        groupId: +(this.selectedGroup.id!),
        type: this.downloadType
      } as ChangelogGroupFilter;
      this.engineService.getChangelogGroup(groupFilter).subscribe(changelogs => {
        this.changelogs = changelogs;
        this.reportCollection.push({ projectName: this.selectedProject.name + ' | ' + this.selectedGroup.name, changelog: changelogs, fromDate: formattedFromDate, toDate: formattedToDate, deviceType: this.selectedDevice, isGroup: true, filter: groupFilter, filterType: 'group' });
        this.tabs.push(this.selectedProject.name);
        this.selected.setValue(this.tabs.length - (this.mqReportCollection.length + this.aqReportCollection.length + 1));
        this.isLoading = false;
        this.userResponse = '';
        if (changelogs !== null) this.showTree = false;
        if (changelogs === null) {
          this.modalService.openDialog('Keine Daten für diese Gruppe vorhanden und/oder diesen Zeitraum', 'OK').subscribe();
        }
      },
        err => { this.isLoading = false; this.alertService.alert('Fehler bei der Anfrage', AlertTypes.error) }
      );
    }
  }

  public getProjectDownload(settings?: SettingWithChangelogs): void {
    this.triggerLoadingSpinner();
    let projectFilter = settings?.filter;
    let filename = this.generateFileNames('EngineLog', projectFilter as ChangelogProjectFilter, settings?.projectName!);
    filename = filename.replace(/[^a-z0-9_ _-]/gi, '');
    this.engineService.getChangelogProjectDownload(projectFilter as ChangelogProjectFilter).subscribe(changelogs => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      a.click();
    });
  }

  public getEngineProjectDownload(settings?: SettingWithEngineChangelogs): void {
    this.triggerLoadingSpinner();
    let projectFilter = settings?.filter;
    let filename = this.generateFileNames('Schaltungsgrundentscheid', projectFilter as ChangelogProjectFilter, settings?.projectName!);
    filename = filename.replace(/[^a-z0-9_ _-]/gi, '');
    this.engineService.getEngineChangeLogMQProjectDownload(projectFilter as ChangelogProjectFilter).subscribe(changelogs => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      a.click();
    });
  }
  public openChangelogConfigOverviewModal(): void {
    this.dialog.open(ChangelogConfigOverviewModalComponent);
  }

  public openChangelogConfigurationModal(): void {
    this.dialog.open(ChangelogConfigurationModalComponent, { data: { groupSelectItem: this.selectedGroup, congfig: undefined } });
  }

  private generateFileNames(type: string, filter: ChangelogGroupFilter | ChangelogProjectFilter | ChangelogDeviceFilter, projectNameFromDownload: string): string {
    let from = new Date(filter.from);
    let fromDay = from.getUTCDate();
    let fromMonth = from.getUTCMonth() + 1;
    let fromYear = from.getUTCFullYear();
    let to = new Date(filter.to);
    let toDay = to.getUTCDate();
    let toMonth = to.getUTCMonth() + 1;
    let toYear = to.getUTCFullYear();
    let projectName = projectNameFromDownload;
    let fileName = '';
    let reportType = type; /* TODO: abhängig vom type setzen */
    fileName = reportType + '_' + projectName + '_' + fromYear + '-' + fromMonth + '-' + fromDay + '_' + toYear + '-' + toMonth + '-' + toDay; /* TODO: dynamischer gestalten,  Schaltungsgrundentscheid: etc
    
    mit Christian sprechen
    
    */
    return fileName;
  }

  public getGroupDownload(settings?: SettingWithChangelogs): void {
    this.triggerLoadingSpinner();
    let groupFilter = settings?.filter as ChangelogGroupFilter;
    let filename = this.generateFileNames('EngineLog', groupFilter, settings?.projectName!);

    this.engineService.getChangelogGroupDownload(groupFilter).subscribe(changelogs => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  }

  public getEngineGroupDownload(settings?: SettingWithEngineChangelogs): void {
    this.triggerLoadingSpinner();
    let groupFilter = settings?.filter as ChangelogGroupFilter;
    let filename = this.generateFileNames('Schaltungsgrundentscheid', groupFilter, settings?.projectName!);

    this.engineService.getEngineChangeLogMQGroupDownload(groupFilter).subscribe((changelogs: Blob) => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  }

  public getRouteStationDownload(settings?: SettingWithChangelogs): void {
    this.triggerLoadingSpinner();
    let routeStationFilter = settings?.filter as ChangelogDeviceFilter;
    let filename = this.generateFileNames('EngineLog', routeStationFilter, settings?.projectName!);

    this.engineService.getChangelogRouteStationDownload(routeStationFilter).subscribe(changelogs => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  }
  public getEngineRouteStationDownload(settings?: SettingWithEngineChangelogs): void {
    this.triggerLoadingSpinner();
    let routeStationFilter = settings?.filter as ChangelogDeviceFilter;
    let filename = this.generateFileNames('Schaltungsgrundentscheid', routeStationFilter, settings?.projectName!);

    this.engineService.getEngineChangeLogMQDeviceDownload(routeStationFilter).subscribe(changelogs => {
      this.isLoading = false;
      const blob = new Blob([changelogs], { type: changelogs.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  }
  private triggerLoadingSpinner() {
    this.isLoading = true;
    setTimeout(() => {
      if (this.isLoading) {
        this.modalService.openDialog('Fehler bei der Anfrage, bitte versuchen Sie es erneut', 'OK').subscribe();
        this.isLoading = false;
      }
    }, 30000);
  }

  private fillProjectSelectInput(projects: Project[]): void {
    this.projectSelectInput = [];
    this.projectSelectInput.push({ name: 'alle Projekte', id: 0 });
    for (let project of projects) {
      let selectItem = {
        name: project.name,
        id: project.id
      } as SelectInput;
      this.projectSelectInput.push(selectItem);
    }
  }

  /* Tree Handling */
  private _transformer = (node: ProjectNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: 'ProjectId:' + node.projectId + ';' + 'GroupId:' + node.groupId + '; ' + node.name,
      level: level,

    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  private _transformer2 = (node: DeviceTreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: 'SAM-ID: ' + node.samId + ' | ' + node.name,
      level: level,
    };
  }

  treeControl2 = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener2 = new MatTreeFlattener(
    this._transformer2,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourceRouteStations = new MatTreeFlatDataSource(this.treeControl2, this.treeFlattener2);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
