<mat-dialog-content>
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Radardaten</h5>
        <div class="d-flex">
            <div>
                <img alt="icon reload schema data" class="opacity-50 me-1" src="assets/icons/arrow-clockwise.svg">
                <span>{{ countdown }} s</span>
            </div>
            <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
                (click)="onCloseAddTimeSwitchClicked()">
        </div>
    </div>
    <div class="d-flex row">

        <div *ngFor="let radar of radare" class="col">
            <div class="border">
                <h4 *ngIf="radar.de === 1"> Hauptfahrspur</h4>
                <h4 *ngIf="radar.de !== 1"> {{radar.de - 1}}. Überholstreifen</h4>
                <div class="w-100 d-flex justify-content-between">
                    <span> Anzahl KFZ</span>
                    <span> {{ radar.kfzTrafficVolume}}</span>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <span> Anzahl LKW</span>
                    <span> {{ radar.lkwTrafficVolume}}</span>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <span> Durschnittsgeschwindigkeit PKW</span>
                    <span> {{ radar.pkwAverageSpeed}}</span>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <span> Durschnittsgeschwindigkeit LKW</span>
                    <span> {{ radar.lkwAverageSpeed}}</span>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <span> Durschnittsgeschwindigkeit KFZ</span>
                    <span> {{ radar.kfzSmoothedAverageSpeed}}</span>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <span> Letzte Messung</span>
                    <span> {{ radar.dateTime | date: 'dd.MM.yyyy H:mm:ss'}}</span>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>