import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-swa',
  templateUrl: './faq-swa.component.html',
  styleUrls: ['./faq-swa.component.css']
})
export class FaqSwaComponent {

public isShowSwaSettings: boolean = false;
public isShowSwaCreate: boolean = false;
public isShowSwaCreateOption2: boolean = false;
public isShowSpezcialFeatures: boolean = false;

constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}

public onShowSwaSettings() {
  this.isShowSwaSettings = !this.isShowSwaSettings
}

public onShowSwaCreate() {
  this.isShowSwaCreate = !this.isShowSwaCreate
}

public onShowSwaCreateOption2() {
  this.isShowSwaCreateOption2 = !this.isShowSwaCreateOption2
}

public onShowSpezcialFeatures() {
  this.isShowSpezcialFeatures = !this.isShowSpezcialFeatures
}
}
