import {Component, Input, OnInit} from '@angular/core';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { UserDataResponse } from '../../user/shared/interfaces/login-data-response.interface';
import {LoginService} from "../../user/shared/services/login.service";
import {checkAccessAuthorization} from "../../user/shared/role.guard";

@Component({
  selector: 'avs-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit{
  public isMenuOpen: boolean = false;
  public isAccountSelectOpen: boolean = false;
  @Input() user?: UserDataResponse;
  public menuSelectInput: SelectInput[] = [];
  public userSelectInput: SelectInput[] = [
    {name: 'Benutzer Einstellungen', routerLink: '/user/user-data-settings'},
    {name: 'Ausloggen'}
  ];

  constructor(private loginService: LoginService) {}

  public ngOnInit() {
    this.menuSelectInput.push(
        {name: 'Übersicht', routerLink: '/dashboard'},
        {name: 'Projekte', routerLink: '/project'},
        {name: 'Berichte', routerLink: '/report'}
    )
    if (!this.user) {return;}
    if (checkAccessAuthorization([1], this.user.role)) {
      this.menuSelectInput.push(
        {name: 'Geräte', routerLink: '/device'},
        {name: 'Benutzerverwaltung', routerLink: '/user'},
        {name: 'Firmenverwaltung', routerLink: '/company'}
      )
    }
  }

  public onMenuButtonClicked(): void {
    this.isMenuOpen = true;
  }

  public onMenuCloseButtonClicked(): void {
    this.isMenuOpen = false;
  }

  public onMenuListItemClicked(): void {
    this.isMenuOpen = false;
  }

  public onAccountCircleClicked(): void {
    this.isAccountSelectOpen = true;
  }

  public onUserSelectCloseButtonClicked(): void {
    this.isAccountSelectOpen = false;
  }

  public onUserSelectItemClicked(item: SelectInput): void {
    this.isAccountSelectOpen = false;
    if (item.name === 'Ausloggen') {
      this.loginService.logout();
    }
  }
}
