import { Component } from '@angular/core';

@Component({
  selector: 'avs-tree-flat-overview',
  templateUrl: './tree-flat-overview.component.html',
  styleUrls: ['./tree-flat-overview.component.css']
})
export class TreeFlatOverviewComponent {

}
