<div class="d-flex justify-content-center mb-5">
  <div class="card shadow rounded-0 border-0 avs-company-settings p-3">
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="companyId; else newUser" >
        <h6>Firmen Einstellungen</h6>
      </ng-container>
      <ng-template #newUser >
        <h6>Firma hinzufügen</h6>
      </ng-template>
        <div class="mb-3 avs-arrow-left" (click)="onBackIconClicked()">
          <img class="p-1" src="assets/icons/arrow-left.svg">
          <span class="me-2">zurück</span>
        </div>
    </div>
    <div class="mt-2">
      <p class="ms-2">Besitzer</p>
      <div class="row">
        <div class="col-md-6 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.firstname : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.firstname)"
            [required]="true"
            placeholder="Vorname">
          </avs-text-input>
        </div>
        <div class="col-md-6 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.lastname : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.lastname)"
            [required]="true"
            placeholder="Nachname">
          </avs-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.emergencyPhone : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.emergencyPhone)"
            [required]="true"
            placeholder="Notrufnummer">
          </avs-text-input>
        </div>
        <div class="col-md-6 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.phone : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.phone)"
            placeholder="Telefonnummer">
          </avs-text-input>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <p class="ms-2">Firmenname</p>
      <avs-text-input
        [value]="companyData ? companyData.companyName : ''"
        (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.companyName)"
        [required]="true"
        placeholder="Firmenname">
      </avs-text-input>
    </div>

    <div class="mt-4">
      <p class="ms-2">Addresse</p>
      <div class="row">
        <div class="col-8 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.street : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.street)"
            [required]="true"
            placeholder="Straße">
          </avs-text-input>
        </div>
        <div class="col-4 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.streetNo : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.streetNo)"
            [required]="true"
            type="number"
            placeholder="Haus-Nr.">
          </avs-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-4 mb-2">
          <avs-text-input
            [value]="!companyData ? '' : companyData.zip ? companyData.zip : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.zip)"
            [required]="true"
            type="number"
            placeholder="Postleitzahl">
          </avs-text-input>
        </div>
        <div class="col-6 col-md-4 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.city : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.city)"
            [required]="true"
            placeholder="Stadt">
          </avs-text-input>
        </div>
        <div class="col-md-4 mb-2">
          <avs-text-input
            [value]="companyData ? companyData.country : ''"
            (valueChange)="onCompanyDataValueChange($event, CompanyDataTypes.country)"
            [required]="true"
            placeholder="Land">
          </avs-text-input>
        </div>
      </div>
    </div>
    <div class="text-end" *ngIf="isCardChanged">
      <button class="btn btn-outline-success rounded-0 mt-4 avs-test-area-save-btn"
              type="button" (click)="onSaveCardBtnClicked()">Speichern</button>
    </div>
  </div>
</div>
