import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
 /* LÜCKE BEACHTEN */
export enum CustomStellcodeEnum {
    C201,
    C202,
    C203,
    C204,
    C205,
    C206,
    C207,
    C208,
    C209,
    C210,
    C211,
    C212,
    C213,
    C214,
    C215,
    C216,
    C217,
    C218,
    C219,
    C220,
    C225,
    C226,
    C227,
    C228,
    C229,
    C230,
    C231,
    C232,
    C233,
    C234,
    C235,
    C236,
    C237,
    C238,
    C239,
    C240
}

export function getCustomStellcodeString(trafficRule: CustomStellcodeEnum): string {
    switch (trafficRule) {
        case CustomStellcodeEnum.C201:
            return '201';
        case CustomStellcodeEnum.C202:
            return '202';
        case CustomStellcodeEnum.C203:
            return '203';
        case CustomStellcodeEnum.C204:
            return '204';
        case CustomStellcodeEnum.C205:
            return '205';
        case CustomStellcodeEnum.C206:
            return '206';
        case CustomStellcodeEnum.C207:
            return '207';
        case CustomStellcodeEnum.C208:
            return '208';
        case CustomStellcodeEnum.C209:
            return '209';
        case CustomStellcodeEnum.C210:
            return '210';
        case CustomStellcodeEnum.C211:
            return '211';
        case CustomStellcodeEnum.C212:
            return '212';
        case CustomStellcodeEnum.C213:
            return '213';
        case CustomStellcodeEnum.C214:
            return '214';
        case CustomStellcodeEnum.C215:
            return '215';
        case CustomStellcodeEnum.C216:
            return '216';
        case CustomStellcodeEnum.C217:
            return '217';
        case CustomStellcodeEnum.C218:
            return '218';
        case CustomStellcodeEnum.C219:
            return '219';
        case CustomStellcodeEnum.C220:
            return '220';
        case CustomStellcodeEnum.C225:
            return '225';
        case CustomStellcodeEnum.C226:
            return '226';
        case CustomStellcodeEnum.C227:
            return '227';
        case CustomStellcodeEnum.C228:
            return '228';
        case CustomStellcodeEnum.C229:
            return '229';
        case CustomStellcodeEnum.C230:
            return '230';
        case CustomStellcodeEnum.C231:
            return '231';
        case CustomStellcodeEnum.C232:
            return '232';
        case CustomStellcodeEnum.C233:
            return '233';
        case CustomStellcodeEnum.C234:
            return '234';
        case CustomStellcodeEnum.C235:
            return '235';
        case CustomStellcodeEnum.C236:
            return '236';
        case CustomStellcodeEnum.C237:
            return '237';
        case CustomStellcodeEnum.C238:
            return '238';
        case CustomStellcodeEnum.C239:
            return '239';
        case CustomStellcodeEnum.C240:
            return '240';
    }
}

export function getCustomstellcodeSelect(): SelectInput[] {
    return [
        { name: getCustomStellcodeString(CustomStellcodeEnum.C201), id: 122 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C202), id: 123 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C203), id: 124 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C204), id: 125 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C205), id: 126 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C206), id: 127 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C207), id: 128 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C208), id: 129 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C209), id: 130 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C210), id: 131 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C211), id: 132 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C212), id: 133 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C213), id: 134 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C214), id: 135 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C215), id: 136 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C216), id: 137 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C217), id: 138 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C218), id: 139 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C219), id: 140 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C220), id: 141 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C225), id: 142 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C226), id: 143 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C227), id: 144 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C228), id: 145 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C229), id: 146 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C230), id: 147 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C231), id: 148 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C232), id: 149 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C233), id: 150 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C234), id: 151 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C235), id: 152 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C236), id: 153 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C237), id: 154 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C238), id: 155 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C239), id: 156 },
        { name: getCustomStellcodeString(CustomStellcodeEnum.C240), id: 157 },
    ]
    
   
}