import { Injectable } from "@angular/core";
import { UserDataResponse } from "../interfaces/login-data-response.interface";
import { BehaviorSubject } from "rxjs";
import { RoleTypes } from "../enums/role.enum";

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private userDataSubject$ = new BehaviorSubject<UserDataResponse | undefined>(undefined);
  public userData = this.userDataSubject$.asObservable();

  private user?: UserDataResponse;

  public updateUserData(user: UserDataResponse): void {
    this.userDataSubject$.next(user);
    if (user) {
      this.user = user;
      this.saveUserRoleInLocalStorage(user.role)
    }
  }

  private saveUserRoleInLocalStorage(userRole: RoleTypes): void {
    let roleEncryption = "";
    switch (userRole) {
      case RoleTypes.user:
        roleEncryption = 'jh6s9g123kajhsvbci9jhkj';
        break;
      case RoleTypes.streetworker:
        roleEncryption = '8shjk29jkalkjjücgqgg6jh';
        break;
      case RoleTypes.extern:
        roleEncryption = 'mmvgfg11trztr1fgpoo7dhg';
        break;
      case RoleTypes.adminReadOnly:
        roleEncryption = '4rrztsxlöaqggfhhsttwiq8';
        break;
      case RoleTypes.admin:
        roleEncryption = 'fferrrtsgloo75agf3gahga';
        break;
      case RoleTypes.userReadOnly:
        roleEncryption = 'aospudhasiodasdjind123e';
        break;
      case RoleTypes.none:
        roleEncryption = 'etjshhha60jdk222kj1htwz';
        break;
    }
    localStorage.setItem('role', Math.floor(Math.random() * Date.now()).toString(36) +
      roleEncryption + Math.floor(Math.random() * Date.now()).toString(36));
  }

  public isAdmin() {
    if (this.user!.role === 1 || this.user!.role === 5) { return true } /* 1Admin 5AdminReadOnly */
    else return false
  }


  public isUser() {
    if (this.user!.role === 2) { return true } /* 2User 3Streetworker 4Extern 7UserReadOnly */
    else return false
  }
}
