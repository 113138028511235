<div *ngIf="data.type === AlertTypes.success" class="alert-success align-items-center">
    <img src="assets/icons/check-circle-success.svg" alt="success-circle-icon">
    <span class="ms-2">{{data.message}}</span>
</div>
<div *ngIf="data.type === AlertTypes.error" class="alert-danger align-items-center fw-bold">
    <img src="assets/icons/exclamation-circle-danger.svg" alt="error-circle-icon">
    <span class="ms-2">{{data.message}}</span>
</div>
<div *ngIf="data.type === AlertTypes.warning" class="alert-warning align-items-center">
    <img src="assets/icons/exclamation-circle-warning.svg" alt="warning-circle-icon">
    <span class="ms-2">{{data.message}}</span>
</div>
<div *ngIf="data.type === AlertTypes.info" class="alert-info align-items-center">
    <img src="assets/icons/info-circle-primary.svg" alt="info-circle-icon">
    <span class="ms-2">{{data.message}}</span>
</div>
