import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './services/login.service';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UserResolver {
  constructor(private readonly loginService: LoginService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loginService.getUserViaJwtToken()
  }
}
