import { NgModule } from "@angular/core";
import { NavbarComponent } from "./navbar/navbar.component";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { LayoutComponent } from './layout/layout.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { UserModule } from "../user/user.module";
import { FaqComponent } from './faq/faq.component';
import { FaqAccountComponent } from './faq/faq-account/faq-account.component';
import { FaqOverviewComponent } from './faq/faq-overview/faq-overview.component';
import { FaqDashboardComponent } from './faq/faq-dashboard/faq-dashboard.component';
import { FaqProjectComponent } from './faq/faq-project/faq-project.component';
import { FaqReportComponent } from './faq/faq-report/faq-report.component';
import { FaqBilderComponent } from './faq/faq-admin-overview/faq-bilder/faq-bilder.component';
import { FaqSwaComponent } from './faq/faq-admin-overview/faq-swa/faq-swa.component';
import { FaqMenuComponent } from './faq/faq-menu/faq-menu.component';
import { FaqTimerSwitchComponent } from './faq/faq-admin-overview/faq-timer-switch/faq-timer-switch.component';
import { ImpressumComponent } from './impressum/impressum.component';


@NgModule({

  declarations: [
    NavbarComponent,
    LayoutComponent,
    AppFooterComponent,
    FaqComponent,
    FaqAccountComponent,
    FaqOverviewComponent,
    FaqDashboardComponent,
    FaqProjectComponent,
    FaqReportComponent,
    FaqBilderComponent,
    FaqSwaComponent,
    FaqMenuComponent,
    FaqTimerSwitchComponent,
    ImpressumComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    UserModule
  ],
  exports: [
    LayoutComponent,
    ],
  providers: [],
})
export class CoreModule { }
