import { Component } from '@angular/core';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { Subscription } from 'rxjs';
import { ProjectDataService } from '../shared/services/project-data.service';
import { ProjectService } from '../shared/services/project.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageDataService } from '../shared/services/image-data.service';
import { ProjectImageData } from '../shared/interfaces/project-image-data.interface';
import { AddScenarioDialogComponent } from './add-scenario-dialog/add-scenario-dialog.component';
import { ScenarioService } from '../shared/services/scenario.service';
import { Scenario } from '../shared/interfaces/scenario.interface';
import { GroupService } from '../shared/services/group.service';
import { SchemaLine } from '../project-schema/shared/interfaces/schema-line.class'; 
import { ProjectRouteStationWithParam } from '../shared/interfaces/project-route-station.class';
import { ScenarioManual } from '../shared/interfaces/scenario-manual';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';

@Component({
  selector: 'avs-project-scenario',
  templateUrl: './project-scenario.component.html',
  styleUrls: ['./project-scenario.component.css']
})
export class ProjectScenarioComponent {
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public routeStations: ProjectRouteStation[] = [];
  public routeStationsY1: ProjectRouteStation[] = [];
  public routeStationsY2: ProjectRouteStation[] = [];
  public projectRouteStations: ProjectRouteStationWithParam[] | undefined;
  public subscriptionCollection: Subscription[] = [];
  public activeProjectSubscription?: Subscription;
  public activeGroupSubscription?: Subscription;
  public routeStationsSubscription?: Subscription;
  public projectImagesSubscription?: Subscription;
  public activeProjectsUsers: EditUser[] = [];
  public projectRouteStationsWithParamSubscription?: Subscription;
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public scenarios: Scenario[] = [];
  public unassignedStations: ProjectRouteStationWithParam[] = [];
  private activeProjectsUsersSubscription?: Subscription;
  public width: number[] = [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50];
  public line: SchemaLine = new SchemaLine(
    [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] }, /* schema line erweitern um piktogram? */
    { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }],

    [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }]);


  constructor(
    private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private imageDataService: ImageDataService,
    private scenarioService: ScenarioService,
    private groupService: GroupService
  ) {
    this.activeProjectSubscription = this.projectDataService.activeProject.subscribe((project) => {
      this.activeProject = project;
    });
    this.activeGroupSubscription = this.projectDataService.activeGroup.subscribe((group) => {
      if (group !== this.activeGroup)
        this.activeGroup = group;
      this.getGroupsScenarios();
      if (this.activeGroup !== undefined) {
        this.getGroupsScenarios();
      }
    });
    this.activeProjectsUsersSubscription = projectDataService.activeProjectsUsers.subscribe(users => {
      this.activeProjectsUsers = users;
    })
    this.routeStationsSubscription = projectDataService.projectsRouteStations.subscribe(routeStations => {
      this.routeStations = routeStations;
      this.sortRouteStationsByY(routeStations)
    });
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.images = images;
      this.allImages = images;
      if (images) { this.rotateImagesPaths(images) }
    });
    this.projectRouteStationsWithParamSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(routeStations => {
      this.projectRouteStations = routeStations;
      this.sortRouteStationsIntoLine();
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeGroupSubscription!);
    this.subscriptionCollection.push(this.routeStationsSubscription!);
    this.subscriptionCollection.push(this.projectImagesSubscription!);
    this.subscriptionCollection.push(this.projectRouteStationsWithParamSubscription!);
    this.subscriptionCollection.push(this.activeProjectsUsersSubscription!);

  }
  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
  }

  public getPathByImageId(imageId: number) {

    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }
  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }


  public handleEvent(event: boolean) {
    if (event) {
      this.getGroupsScenarios()
    }
  }
  public sortRouteStationsByY(routeStations: ProjectRouteStation[]): void {
    this.routeStationsY1 = [];
    this.routeStationsY2 = [];
    routeStations.forEach((station) => {
      if (station.y === 1) {
        this.routeStationsY1.push(station);
      }
      if (station.y === 2) {
        this.routeStationsY2.push(station);
      }
    });
  }

  public sortRouteStationsIntoLine(): void {
    this.line = new SchemaLine(
      [{ station: undefined, x: 1, isActive: this.line.areaA[0].isActive, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: this.line.areaA[1].isActive, line: [], colorArr: [] },
      { station: undefined, x: 3, isActive: this.line.areaA[2].isActive, line: [], colorArr: [] }, { station: undefined, x: 4, isActive: this.line.areaA[3].isActive, line: [], colorArr: [] },
      { station: undefined, x: 5, isActive: this.line.areaA[4].isActive, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: this.line.areaA[5].isActive, line: [], colorArr: [] },
      { station: undefined, x: 7, isActive: this.line.areaA[6].isActive, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: this.line.areaA[7].isActive, line: [], colorArr: [] },
      { station: undefined, x: 9, isActive: this.line.areaA[8].isActive, line: [], colorArr: [] }, { station: undefined, x: 10, isActive: this.line.areaA[9].isActive, line: [], colorArr: [] },
      { station: undefined, x: 11, isActive: this.line.areaA[10].isActive, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: this.line.areaA[11].isActive, line: [], colorArr: [] },
      { station: undefined, x: 13, isActive: this.line.areaA[12].isActive, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: this.line.areaA[13].isActive, line: [], colorArr: [] },
      { station: undefined, x: 15, isActive: this.line.areaA[14].isActive, line: [], colorArr: [] }],
      [{ station: undefined, x: 1, isActive: this.line.areaB[0].isActive, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: this.line.areaB[1].isActive, line: [], colorArr: [] },
      { station: undefined, x: 3, isActive: this.line.areaB[2].isActive, line: [], colorArr: [] }, { station: undefined, x: 4, isActive: this.line.areaB[3].isActive, line: [], colorArr: [] },
      { station: undefined, x: 5, isActive: this.line.areaB[4].isActive, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: this.line.areaB[5].isActive, line: [], colorArr: [] },
      { station: undefined, x: 7, isActive: this.line.areaB[6].isActive, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: this.line.areaB[7].isActive, line: [], colorArr: [] },
      { station: undefined, x: 9, isActive: this.line.areaB[8].isActive, line: [], colorArr: [] }, { station: undefined, x: 10, isActive: this.line.areaB[9].isActive, line: [], colorArr: [] },
      { station: undefined, x: 11, isActive: this.line.areaB[10].isActive, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: this.line.areaB[11].isActive, line: [], colorArr: [] },
      { station: undefined, x: 13, isActive: this.line.areaB[12].isActive, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: this.line.areaB[13].isActive, line: [], colorArr: [] },
      { station: undefined, x: 15, isActive: this.line.areaB[14].isActive, line: [], colorArr: [] }]);

    let widthA: number[] = [150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150];
    let widthB: number[] = [150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150];

    if (this.projectRouteStations === undefined) return;
    for (let station of this.projectRouteStations!) {
      if (station.routeStation.y === 1) {
        if (station.routeStation.x > 0 && station.routeStation.x < 16) {
          if (this.line.areaA[station.routeStation.x - 1].station) {
            this.line.areaA[station.routeStation.x - 1].station?.push(station);
            this.line.areaA[station.routeStation.x - 1].isActive = true;
            widthA[station.routeStation.x - 1] += 300;
          } else {
            this.line.areaA[station.routeStation.x - 1].station = [station];
            this.line.areaA[station.routeStation.x - 1].isActive = true;
            widthA[station.routeStation.x - 1] = 300;
          }
        } else {
          this.unassignedStations.push(station);
        }
      } else if (station.routeStation.y === 2) {
        if (station.routeStation.x > 0 && station.routeStation.x < 16) {
          if (this.line.areaB[station.routeStation.x - 1].station) {
            this.line.areaB[station.routeStation.x - 1].station?.push(station);
            this.line.areaB[station.routeStation.x - 1].isActive = true;
            widthB[station.routeStation.x - 1] += 300;
          } else {
            this.line.areaB[station.routeStation.x - 1].station = [station];
            this.line.areaB[station.routeStation.x - 1].isActive = true;
            widthB[station.routeStation.x - 1] = 300;
          }
        } else {
          this.unassignedStations.push(station);
        }
      } else {
        this.unassignedStations.push(station);
      }
    }
    this.width[0] = widthA[0] > widthB[0] ? widthA[0] : widthB[0];
    this.width[1] = widthA[1] > widthB[1] ? widthA[1] : widthB[1];
    this.width[2] = widthA[2] > widthB[2] ? widthA[2] : widthB[2];
    this.width[3] = widthA[3] > widthB[3] ? widthA[3] : widthB[3];
    this.width[4] = widthA[4] > widthB[4] ? widthA[4] : widthB[4];
    this.width[5] = widthA[5] > widthB[5] ? widthA[5] : widthB[5];
    this.width[6] = widthA[6] > widthB[6] ? widthA[6] : widthB[6];
    this.width[7] = widthA[7] > widthB[7] ? widthA[7] : widthB[7];
    this.width[8] = widthA[8] > widthB[8] ? widthA[8] : widthB[8];
    this.width[9] = widthA[9] > widthB[9] ? widthA[9] : widthB[9];
    this.width[10] = widthA[10] > widthB[10] ? widthA[10] : widthB[10];
    this.width[11] = widthA[11] > widthB[11] ? widthA[11] : widthB[11];
    this.width[12] = widthA[12] > widthB[12] ? widthA[12] : widthB[12];
    this.width[13] = widthA[13] > widthB[13] ? widthA[13] : widthB[13];
    this.width[14] = widthA[14] > widthB[14] ? widthA[14] : widthB[14];
    this.getHighestXAndCollapse()
  }

  public getHighestXAndCollapse() {
    let highestX = 0;
    this.routeStations.forEach((station) => {
      if (station.x > highestX) {
        highestX = station.x;
      }
    });

    for (let index = highestX; index < 15; index++) {
      this.line.areaA[index].isHidden = true;
      this.line.areaB[index].isHidden = true;
    }
  }

  public getGroupsScenarios(): void {
    this.scenarios = [];
    if (this.activeGroup === undefined) return;
    this.scenarioService.getAllScenariosByGroupId(this.activeGroup?.id as number).subscribe((scenarios) => {
      this.scenarios = scenarios;
    });
  }

  public onEditScenarioBtnClicked(scenario: Scenario) {
    const dialogRef = this.dialog.open(AddScenarioDialogComponent, { data: { group: this.activeGroup, scenario: scenario } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.scenarioService.updateScenario(scenario.id as number, { name: result.name, enabled: result.enabled, groupId: this.activeGroup?.id as number }).subscribe((scenario) => {
          this.getGroupsScenarios();
        });
      }
    });
  }

  public onDeleteScenarioBtnClicked(scenario: Scenario) {
    this.scenarioService.deleteScenario(scenario.id as number).subscribe(() => {
      this.getGroupsScenarios();
    });
  }

  public onStopScenarioBtnClicked(scenario: Scenario) {
    this.scenarioService.disableScenario(scenario.id as number).subscribe(() => {
      this.alertService.alert('Szenario deaktiviert', AlertTypes.success);
      this.getGroupsScenarios();
    });
  }

  public onPlayScenarioBtnClicked(scenario: Scenario) {
    this.scenarioService.enableScenario(scenario.id as number).subscribe(() => {
      this.alertService.alert('Szenario aktiviert', AlertTypes.success);
      this.getGroupsScenarios();
    });
  }

  public getScenarioManualByScenarioID(scenario: Scenario) {
    let manuals = this.scenarios.find((x) => x.id === scenario.id)?.scenarioManuals;
    return manuals;
  }

  public onExpandScenarioClicked(): void {
    for (let area of this.line.areaA) {
      area.isActive = true;
    }
    for (let area of this.line.areaB) {
      area.isActive = true;
    }
  }

  public onCollapseSchemeClicked(): void {
    if (!this.projectRouteStations) {
      return;
    }
    if (this.projectRouteStations.length === 0) {
      for (let area of this.line.areaA) {
        area.isActive = false;
      }
      for (let area of this.line.areaB) {
        area.isActive = false;
      }
      return;
    }
    let stationsA: ProjectRouteStationWithParam[] = [];
    let stationsB: ProjectRouteStationWithParam[] = [];
    for (let station of this.projectRouteStations) {
      if (station.routeStation.y === 1) {
        stationsA.push(station);
      } else if (station.routeStation.y === 2) {
        stationsB.push(station);
      }
    }
    let stations = this.projectRouteStations.sort(
      (a, b) => b.routeStation.x - a.routeStation.x);
    for (let area of this.line.areaA) {
      if (stationsA[0]) {
        if (area.x > stations[0].routeStation.x) {
          area.isActive = false;
        }
      } else {
        area.isActive = false;
      }
    }
    for (let area of this.line.areaB) {
      if (stationsB[0]) {
        if (area.x > stations[0].routeStation.x) {
          area.isActive = false;
        }
      } else {
        area.isActive = false;
      }
    }
  }

  public onCreateScenarioBtnClicked(): void {
    const dialogRef = this.dialog.open(AddScenarioDialogComponent, { data: { group: this.activeGroup } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.scenarioService.createScenario({ name: result.name, enabled: result.enabled, groupId: this.activeGroup?.id as number }).subscribe((scenario) => {
          this.getGroupsScenarios();
        });
      }
    });
  }
}
