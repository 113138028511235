import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceParameter } from "../../../device/shared/interfaces/device-parameter.interface";
import { DigitaleOutputs } from "../shared/interfaces/digitale-outputs.interface";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { StmService } from "../../shared/services/stm.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";

@Component({
  selector: 'avs-test-area-digital-outputs',
  templateUrl: './test-area-digital-outputs.component.html',
  styleUrls: ['./test-area-digital-outputs.component.css']
})
export class TestAreaDigitalOutputsComponent implements OnChanges {
  @Input() deviceParameter: DeviceParameter | undefined
  @Input() activeStation?: ProjectRouteStation
  public digitalOutputsToggleSettings?: DigitaleOutputs = {digitalOutput1: '', digitalOutput2: '', viaFalcon: '', anna: ''}
  public isAnyValueChanged: boolean = false;
  public isOnline: boolean = true;

  constructor(private stmService: StmService,
    private alertService: AlertService) { }



  public ngOnChanges(changes: SimpleChanges): void {
    if (this.deviceParameter !== undefined && this.deviceParameter!.io!.length > 0) this.getDigitalOutputs(this.deviceParameter!);

  }

  public isParameterHigh(param: string) {
    if (param === 'high') {
      return true;
    } else {
      return false;
    }
  }

  public onDigitalOutputsToggleValueChange(channel: number, value: boolean): void {
    if (!this.activeStation) { return; }
    this.stmService.setDigitalOutput(channel.toString(), this.activeStation.samId, value).subscribe(() => {
      this.alertService.alert("Die Outputs wurden erfolgreich angesteuert", AlertTypes.info)
    });
    this.isAnyValueChanged = true;
  }

  public getDigitalOutputs(deviceParameter: DeviceParameter): void {
    if (deviceParameter !== undefined) {
      deviceParameter?.io.forEach((io) => {
      if (io.de === 6) {
        this.digitalOutputsToggleSettings!.digitalOutput1 = this.isParameterHigh(io.status);
      } else if (io.de === 7) {
          this.digitalOutputsToggleSettings!.digitalOutput2 = this.isParameterHigh(io.status);
        }
        else if (io.de === 8) {
          this.digitalOutputsToggleSettings!.viaFalcon = this.isParameterHigh(io.status);
        }
        else if (io.de === 9) {
          this.digitalOutputsToggleSettings!.anna = this.isParameterHigh(io.status);
        }
      })
      if (this.deviceParameter?.io.length === 0) {
        this.isOnline = false;
      }
    }

  }
}

