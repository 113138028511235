import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";

export enum VerkehrszustandEnum {
    VS1 = 1,
    VS2 = 2,
    VS3 = 3,
    VS4 = 4,
    VS5 = 5,

}
export function getVerkehrszustandString(VS: VerkehrszustandEnum): string {
    switch (VS) {
        case VerkehrszustandEnum.VS1:
            return 'Verkehrssituation 1 (120, aus)';
        case VerkehrszustandEnum.VS2:
            return 'Verkehrssituation 2 (120, ein)';
        case VerkehrszustandEnum.VS3:
            return 'Verkehrssituation 3 (100, ein)';
        case VerkehrszustandEnum.VS4:
            return 'Verkehrssituation 4 (80, ein)';
        case VerkehrszustandEnum.VS5:
            return 'Verkehrssituation 5 (60, ein)';

    }
}

export function getVerkehrszustandSelect(): SelectInput[] {
    return [
        { name: getVerkehrszustandString(VerkehrszustandEnum.VS1), id: 1 },
        { name: getVerkehrszustandString(VerkehrszustandEnum.VS2), id: 2 },
        { name: getVerkehrszustandString(VerkehrszustandEnum.VS3), id: 3 },
        { name: getVerkehrszustandString(VerkehrszustandEnum.VS4), id: 4 },
        { name: getVerkehrszustandString(VerkehrszustandEnum.VS5), id: 5 },

    ]
}