import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectDataService } from '../shared/services/project-data.service';
import { ProjectService } from '../shared/services/project.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { RouteStationService } from '../shared/services/route-station.service';
import { RouteStationNote } from '../shared/interfaces/route-station-note';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-project-notes',
  templateUrl: './project-notes.component.html',
  styleUrls: ['./project-notes.component.css'],
})
export class ProjectNotesComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public isNoteChanged: boolean = false;
  public isSingleNoteChanged: boolean = false;
  public routeStation: ProjectRouteStation | undefined;
  public note: string = '';
  public showProjectNotes: boolean = true;
  public latestNote: RouteStationNote | undefined;
  public groupsRouteStations: ProjectRouteStation[] = [];
  public projectsActiveRouteStation: ProjectRouteStation[] = [];

  public subscriptionCollection: Subscription[] = [];
  public activeProjectSubscription?: Subscription;
  public activeRouteStationSubscription?: Subscription;
  public projectsRouteStationsSubscription?: Subscription;


  constructor(private projectDataService: ProjectDataService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private routeStationService: RouteStationService,
    private router: Router) {
    this.activeProjectSubscription = projectDataService.activeProject.subscribe((project) => {
      this.activeProject = project;
    });
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(station => {
      this.routeStation = station;
      this.getLatestNote();
      if (station) this.showProjectNotes = false;
    });
    this.projectsRouteStationsSubscription = projectDataService.projectsRouteStations.subscribe(stations => {
      this.projectsActiveRouteStation = stations;
      this.getProjectsRouteStationsLatestNote();
      if (stations) this.showProjectNotes = true;
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
    this.subscriptionCollection.push(this.projectsRouteStationsSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onSaveNotesBtnClicked(): void {
    this.projectService.editProject(this.activeProject!.id, this.activeProject!).subscribe(project => {
      this.projectDataService.updateActiveProject(project);
      this.alertService.alert('Notizen gespeichert', AlertTypes.success);
      this.isNoteChanged = false;
    });
  }

  public onSaveSingleNotes(): void {
    this.routeStationService.postRouteStationNote(this.note, this.routeStation!.id).subscribe((note) => {
      this.alertService.alert('Notiz gespeichert', AlertTypes.success);
      this.routeStation?.routeStationNotes.push(note);
      this.getLatestNote();
      this.isSingleNoteChanged = false;
      this.note = '';
    })
  }

  public onEditSingleNote(id: number) {
    this.routeStationService.editRouteStationNote(this.latestNote?.note!, id).subscribe((note) => {
      this.alertService.alert('Notiz geändert', AlertTypes.success);
      if (this.latestNote) this.latestNote.noteChanged = false;
      this.latestNote = note;
    })
  }

  private getLatestNote() {
    if (this.routeStation) {
      let latestNoteId = Math.max(...(this.routeStation?.routeStationNotes.map(note => note.id!) ?? []));
      this.latestNote = this.routeStation.routeStationNotes.find(note => note.id === latestNoteId);
    }
  }

  private getProjectsRouteStationsLatestNote() {
    this.groupsRouteStations = [];
    this.projectsActiveRouteStation.forEach(station => { this.groupsRouteStations.push(station) });
    this.groupsRouteStations.forEach(station => {
      let latestStationsNoteId = Math.max(...(station?.routeStationNotes.map(note => note.id!) ?? []));
      if (latestStationsNoteId !== -Infinity) {
        let latestStationsNote = station.routeStationNotes.filter(note => note.id === latestStationsNoteId)
        station.routeStationNotes.push(latestStationsNote[0]);
        station.routeStationNotes = latestStationsNote;
      }
      else {
        station.routeStationNotes = [];
      }
    });
  }

  public onShowProjectNotes(): void {
    this.showProjectNotes = !this.showProjectNotes
  }

  @HostListener('document:keypress', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this.isNoteChanged = true;
  }

  @HostListener('document:keypress', ['$event'])
  public onKeyDownCreateSingleNote(event: KeyboardEvent): void {
    this.isSingleNoteChanged = true;
  }

  onChangeLatestNote(newValue: string, note: RouteStationNote) {
    this.latestNote!.note = newValue;
    note.noteChanged = true;
  }

  public changeActiveRouteStation(station: ProjectRouteStation) {
    this.projectDataService.updateActiveRouteStation(station);
  }
}
