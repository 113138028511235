import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CompanyService} from "../../shared/services/company.service";
import {CompanyData} from "../../shared/interfaces/company-data.interface";
import {AlertService} from "../../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../../shared/alert/shared/alert-types";
import {CompanyDataTypes} from "../../shared/enums/company-data-types.enum";
import {UserDataService} from "../../../user/shared/services/user-data.service";

@Component({
  selector: 'avs-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit{
  public companyId: any;
  public companyData: CompanyData | undefined;
  public isCardChanged: boolean = false;
  protected readonly CompanyDataTypes = CompanyDataTypes;


  constructor(private activatedRoute: ActivatedRoute,
              private companyService: CompanyService,
              private alertService: AlertService,
              private router: Router) {}

  public ngOnInit(): void {
    this.getCompanyId();
    if (this.companyId) {
      this.getCompanyData();
    }
  }

  public onBackIconClicked(): void {
    this.router.navigate(['company/company-overview']);
  }

  public onCompanyDataValueChange(newValue: any, type: CompanyDataTypes) {
    if (!this.companyData) {
      this.companyData = {
        firstname: "",
        lastname: "",
        companyName: "",
        street: "",
        streetNo: "",
        zip: 0,
        city: "",
        country: "",
        emergencyPhone: "",
        phone: ""
      } as CompanyData;
    }
    switch (type) {
      case CompanyDataTypes.firstname:
        this.companyData.firstname = newValue;
        break;
      case CompanyDataTypes.lastname:
        this.companyData.lastname = newValue;
        break;
      case CompanyDataTypes.street:
        this.companyData.street = newValue;
        break;
      case CompanyDataTypes.streetNo:
        this.companyData.streetNo = newValue;
        break;
      case CompanyDataTypes.zip:
        this.companyData.zip = newValue;
        break;
      case CompanyDataTypes.city:
        this.companyData.city = newValue;
        break;
      case CompanyDataTypes.country:
        this.companyData.country = newValue;
        break;
      case CompanyDataTypes.companyName:
        this.companyData.companyName = newValue;
        break;
      case CompanyDataTypes.phone:
        this.companyData.phone = newValue;
        break;
      case CompanyDataTypes.emergencyPhone:
        this.companyData.emergencyPhone = newValue;
        break;
    }
    this.isCardChanged = true;
  }

  public onSaveCardBtnClicked() {
    if (!this.validCompanyData()) {return;}
    if (this.companyId) {
      this.updateCompany();
    } else {
      this.createNewCompany();
    }
  }

  private getCompanyData() {
    if (this.companyId) {
      this.companyService.getCompany(this.companyId).subscribe(companyData => {
        this.companyData = companyData;
      });
    }
  }

  private getCompanyId(): void {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params['id'];
    });
  }

  private createNewCompany() {
    if (!this.companyData) {return;}
    this.companyService.createCompany(this.companyData).subscribe(response => {
      this.alertService.alert('Die Firma "' + response.companyName + '" wurde erfolgreich erstellt', AlertTypes.success);
      this.isCardChanged = false;
      this.router.navigate(['user/company']);
    });
  }

  private updateCompany() {
    if (!this.companyData) {return;}
    this.companyService.updateCompany(this.companyId, this.companyData).subscribe(() => {
      this.alertService.alert('Die Daten wurden erfolgreich geändert', AlertTypes.info);
      this.isCardChanged = false;
      this.router.navigate(['user/company']);
    });
  }

  private validCompanyData(): boolean {
    if (!this.companyData) {return false;}
    if (!this.companyData.firstname || !this.companyData.lastname || !this.companyData.street ||
        !this.companyData.streetNo || !this.companyData.zip || !this.companyData.city || !this.companyData.country ||
        !this.companyData.companyName || !this.companyData.emergencyPhone) {
      this.alertService.alert('Nicht alle Pflichtfelder ausgefüllt', AlertTypes.warning);
      return false;
    } else {
      return true;
    }
  }
}
