<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">
        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/card-image.svg" alt="question-circle Icon">
            Bilder
        </h1>
        <!-- die extra 1-4 Anleitung Bilder -->
        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie füge ich ein neues Bild einem Projekt hinzu? </h4> <button
                        (click)="onShowPicturesInProjekt()"> <img *ngIf="!isShowPicturesInProjekt" style="height: 24px"
                            class="me-4
                    back-button" src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/Backbutton"><img
                            *ngIf="isShowPicturesInProjekt" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/Back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowPicturesInProjekt">
                Wenn sie im Reiter auf Projekte Klicken öffnet sich ein Navigation & Filter Fenster.<br> Da finden
                sie auf der rechten Seite eine Karte.
                Darüber finden sie ein neuen Reiter wo sie auf Bilder klicken können wo sie dann ein neues Bild ins
                Projekt hinzufügen können. <br>
                Als nächstes müsste (siehe Beispiel) das Bild in etwa genauso aussehen wie auf ihren Monitor.<br>
                Anschließend können sie über das Icon Bild (+)
                das Bild ins Projekt legen.
                <br>
                <img style="max-width: 90%;" class="me-4 ms-12 mb-4 " src="assets/FAQ/Faqbild1..png"
                    alt="Referenz zu dem FAQ Bild 1">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Was macht der Button + </h4> <button (click)="onShowDialog()"> <img
                            *ngIf="!isShowDialog" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowDialog" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowDialog">
                In den Projekt Einstellungen, befindet sich eine Karte mit der Überschrift „Bilder“. In dieser Karte
                befindet sich ein Icon mit einem „+ (Plus)“.
                <br>
                Mit Klick auf das „Plus“, öffnet sich folgender Dialog. Wo sie ihr Custom Bild hochladen können.
                <br>
                <img style="max-width: 90%;" class="me-4 ms-12 mb-4 " src="assets/FAQ/Faqbild2.png"
                    alt="Referenz zu dem Bild 7">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie Lade ich das Bild vom Gerät ins Projekt ? </h4> <button
                        (click)="onShowStellcodeAuswahl()"> <img *ngIf="!isShowStellcodeAuswahl" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowStellcodeAuswahl"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowStellcodeAuswahl">
                In diesem Dialog habe ich die Möglichkeit ein Bild von dem Gerät in das Projekt zu laden.
                <br>
                <img style="height: 252px" class="me-4 ms-12 mb-4 " src="assets/FAQ/faqBild3.png"
                    alt="Referenz zu dem Bild 7"> <br>
                Bei der Stellcodeauswahl wird einem farblich signalisiert welche Stellcodes frei zur Verfügung
                stehen und welche bereits belegt sind.<br>
                Belegte Stellcodes können jederzeit überschrieben werden. Dafür muss lediglich bestätigt werden,
                dass man dies auch möchte.
            </div>
        </div>

        <!-- für alle zugänglich  -->
        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie Öffne ich einen Dialog mit den Stationen? </h4> <button
                        (click)="onShowSchieldsPicture()"> <img *ngIf="!isShowSchieldsPicture" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowSchieldsPicture"
                            style="height: 24px" class="me-4 bacl-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowSchieldsPicture">
                Falls man im Reiter auf Projekte geht und anschließend im neuen Reiter auf Bilder öffnen sich
                mehrere Stellcodes.<br> In diesem Fall würden
                die blauen Verkehrsschilder erst ab dem Stellcode 120 anfagen (siehe beispiel).<br>
                Möchte man nun ein Bild aus dem Bilderkatalog den Stationen hinzufügen, wählt man ein Bild aus (Bild
                anklicken). Es öffnet sich ein Dialog:
                <br>
                <img style="max-width: 90%;" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 5..png"
                    alt="Referenz zu dem Bild 7">
                <img style="height: 200px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 6.png"
                    alt="Referenz zu dem Bild 6">
            </div>
        </div>


        <div class="col-xl-12 mt-4">
            <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
                <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                    <div class="d-flex justify-content-between w-100 ">
                        <h4 class="me-5"> Wie sehe ich die Routestations? </h4> <button
                            (click)="onShowRouteStationPicture()"> <img *ngIf="!isShowRouteStationPicture"
                                style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                                alt="open-faq-answer-icon/back-button"><img *ngIf="isShowRouteStationPicture"
                                style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                                alt="close-faq-answer-icon/back-button"></button>
                    </div>
                </div>
                <div class="ms-4 mt-2" *ngIf="isShowRouteStationPicture">
                    Falls man im Reiter auf Projekte geht und anschließend im neuen Reiter auf Bilder öffnen sich
                    mehrere Stellcodes.<br> Wenn sie dann einen
                    Dialog auswählen, können sie anschließend der stellcode zur Routestation hinzufügen. <br> Sie
                    bekommen eine übersicht ,siehe Abbildung.
                    Mit Bestätigung des Dialoges, stehen die Bilder, der RouteStation zur Verfügung.
                    <br>
                    <img style="height: 305px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 7..png"
                        alt="Referenz zu dem Bild 7">
                </div>
            </div>


            <div class="col-xl-12 mt-4">
                <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
                    <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                        <div class="d-flex justify-content-between w-100 ">
                            <h4 class="me-5"> Wie kann ich die Bilder hochladen ? </h4> <button
                                (click)="onShowSchilderpicture()"> <img *ngIf="!isShowSchilderpicture"
                                    style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                                    alt="open-faq-answer-icon/back-button"><img *ngIf="isShowSchilderpicture"
                                    style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                                    alt="close-faq-answer-icon/back-button"></button>
                        </div>
                    </div>
                    <div class="ms-4 mt-2" *ngIf="isShowSchilderpicture">
                        Wurden die Bilder im Portal hinzugefügt und werden als „Gesetzte Bilder“ angezeigt, muss im
                        Anschluss im Testbereich des Schildes der Knopf „Bilder hochladen“ angeklickt werden.
                        <br>
                        Nach ungefähr einer Minute, müssten die Bilder auf dem Anzeigegerät zur Verfügung stehen.
                        <br>
                        <img style="height: 200px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 8.png"
                            alt="Referenz zu Abbildung 1">
                    </div>
                </div>


                <div class="col-xl-12 mt-4">
                    <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
                        <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                            <div class="d-flex justify-content-between w-100 ">
                                <h4 class="me-5"> Wie kann ich meinen Kanal Synchroniesieren ? </h4> <button
                                    (click)="onShowResetEVA()"> <img *ngIf="!isShowResetEVA" style="height: 24px"
                                        class="me-4 back-button" src="assets/icons/caret-down.svg"
                                        alt="open-faq-answer-icon/back-button"><img *ngIf="isShowResetEVA"
                                        style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                                        alt="close-faq-answer-icon/back-button"></button>
                            </div>
                        </div>
                        <div class="ms-4 mt-2" *ngIf="isShowResetEVA">
                            Anschließend muss der Kanal der Anzeige synchronisiert werden, dafür muss man im
                            Dropdown den Kanal auswählen und die Synchronisierung bestätigen.
                            <br>
                            <img style="height: 250px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 9.png"
                                alt="Referenz zu Abbildung 1">
                            <img style="height: 200px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Bild 9.1.png"
                                alt="Referenz zu Abbildung 1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>