<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">
        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/calendar-week-fill.svg"
                alt="question-circle Icon"> Zeitschaltuhr
        </h1>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie erstelle ich eine Zeitschaltuhr ? </h4> <button
                        (click)="onShowCreateTimerSwitch()"> <img *ngIf="!isShowCreateTimerSwitch" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowCreateTimerSwitch"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>

            </div>
            <div class="ms-4 mt-2" *ngIf="isShowCreateTimerSwitch"> Wenn sie im Reiter auf Projekte gehen.Öffnet
                sich ein weiterer Reiter wo
                sie auf die Zeitschaltuhr navigieren können.<br>
                <br>
                <!-- TODO: Bilder dynamische größen verpassen width 1400px?! :D --> <img
                    style="width: 1400px; max-width: 90%;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild1Zeitschaltuhr.png" alt="Referenz zu Abbildung 1">In den Zeitschaltuhr
                Einstellungen, befindet sich eine Karte mit der Überschrift „Zeitschaltuhr erstellen“.<br>
                In dieser Karte befindet sich ein Icon mit einem „+ (Plus)“. <br>
                <br>
                Mit Klick auf das „Plus“, öffnet sich folgender Dialog.
                <br>
                <br>
                <img style="height: 409px; width: 669px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild2Zeitschaltuhr.png" alt="Referenz zu Abbildung 2">
                <br>
                In diesem Dialog gebe ich der Zeitschaltuhr einen Namen und definiere die Start- und Endzeit der
                allgemeinen Gültigkeit.
                <br>
                <br>
                <img style="height: 137px; width: 1031px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild3Zeitschaltuhr.png" alt="Referenz zu Abbildung 3">
                <br>
                Nach Erstellung der Zeitschaltuhr habe ich nun die Möglichkeit, einzelne AQ-, MQ- und
                Blinker-Steuerungen für die Zeitschaltuhr zu definieren.
                <br>
                <br>
                <img style="height: 80px; width: 205px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild4Zeitschaltuhr.png" alt="Referenz zu Abbildung 4">
                <br>
                Mit dem Wechselbild Play/Pause, habe ich die Möglichkeit, die Zeitschaltuhr zu aktivieren oder zu
                deaktivieren.
                <br>
                <br>
                <img style="height: 86px; width: 117px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild5Zeitschaltuhr.png" alt="Referenz zu Abbildung 5">
                <br>
                Mit dem Mülleimer lösche ich die gesamte Zeitschaltuhr.

            </div>
        </div>


        <!--inhalt zu dem nächstes kästchen-->
        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> AQ Einstellungen ? </h4> <button (click)="onShowCreateAQ()"> <img
                            *ngIf="!isShowCreateAQ" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowCreateAQ" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowCreateAQ"> wenn wir in der Zeitschaltuhr auf die AQ einstellungen
                "klicken"
                <br>
                <br>
                öffnet sich folgendes Fenster
                <br>
                <img style="height: 670px; width: 532px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild6Zeitschaltuhr.png" alt="Referenz zu Abbildung 6">
                <br>
                <br>
                Hier können sie, in Ihren AQ Einstellung eine Priorität erstellen von 1-5.
                <br>
                Dazu haben sie unter "Funktion" die möglichkeit den Blinker An oder Aus zu stellen
                <br>
                <br>
                Wenn sie die Funktion 'Blinkend' auswählen müssen sie ein intervall in MS angegeben werden
                <br>
                <br>
                <img style="height: 118px; width: 305px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild7Zeitschaltuhr.png" alt="Referenz zu Abbildung 7">
                <br>
                <br>
                Bilderkatalog
                <br>
                <img style="height: 229px; width: 639px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild8Zeitschaltuhr.png" alt="Referenz zu Abbildung 8">
                <br>
                <br>
                Mit Klick auf ein Bild wird der Stellcode ausgewählt
                <br>
                <img style="height: 218px; width: 513px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild9ZeitschaltUhr.png" alt="Referenz zu Abbildung 9">
                <br>
                <br>
                Steht der Toggle auf Einmalig = falsch, kann man feste Zeiträume für bestimmte Wochentage
                definieren.
                <br>
                Werden die Werte aus dem Beispiel übernommen, Mo – So ausgewählt.<br>
                Mit den Uhrzeiten Startzeit 8:00 Uhr und Endzeit 16:00. Ist an den ausgewählten Tagen, die Schaltung
                aktiv.
                <br>
                <img style="height: 200px; width: 633px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild10ZeitschaltUhr.png" alt="Referenz zu Abbildung 10">
                <br>
                Steht der Toggle auf Einmalig = richtig, kann man einen festen Zeitraum für die Steuerung definieren
                <br>
                <br>
                Werden die Werte aus dem Beispiel übernommen, ist die Schaltung vom 17.01.24 – 8:00 Uhr bis<br>
                einschließlich dem 27.01.24 um 16:00 die Steuerung aktiv.
                <br>
                <img style="height: 56px; width: 138px" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild11ZeitschaltUhr.png" alt="Referenz zu Abbildung 11">
                <br>
                <br>
                Abhängig vom Blinker Toggle, werden analog zu den ausgewählten Zeiten, <br>
                separate Timer für die Blinker erstellt oder nicht erstellt. <br>
                Damit kann man die angebrachten Blinker, wenn man möchte, unabhängig bedienen.
            </div>
        </div>




        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> MQ Einstellungen ? </h4> <button (click)="onShowCreateMQ()"> <img
                            *ngIf="!isShowCreateMQ" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowCreateMQ" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowCreateMQ">
                <img style="height: 606px; width: 528px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild12ZeitschaltUhr.png" alt="Referenz zu Abbildung 12">
                <br>
                Hier können sie, in Ihren MQ Einstellung eine Priorität erstellen von 1-5.
                <br>
                Dazu können sie eine verkehrstufe erstellen zwischen von 1-4.
                <br>
                Anschließend können sie eine Verkehrssituation erstellen zwischen 1.5.
                <br>
                <br>
                besonderheiten :
                <br>
                Die Verkehrssituation hat eine niedrigere Prio als die Verkehrsstufe.
                <br>Nur wenn z größer oder gleich 2 ist, schaltet die Einstellung zu Ermittelte Verkehrssituation.
                <br>
                <br>
                Die Einstellung der Zeiten ist analog zu den Zeiten der AQ-Erstellung.
            </div>
        </div>



        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Blinker Einstellung ? </h4> <button (click)="onShowCreateindicator()"> <img
                            *ngIf="!isShowCreateindicator" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowCreateindicator" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowCreateindicator">
                <img style="height: 527px; width: 542px;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild13ZeitschaltUhr.png" alt="Referenz zu Abbildung 13">
                <br>
                Hier können sie eine priorität in ihren Blinker Einstellung erstellen zwischen 1-5.
                <br>
                Anschließend können sie eine Funktion hinzufügen An,Aus oder Blinkend.
                <br>
                <br>
                Besonderheiten:
                <br>
                Funktion An, beim Blinker bedeutet das er blinkt! Je nach Hardware, kann man auch ein eigenes
                Intervall definieren. <br>
                Dann wäre die Funktion ` Blinkend` auszuwählen.<br>
                Und den Zeitraum im vorgesehenem Input zu definieren (Siehe AQ-Funktion `Blinkend`) <br>
                <br>
                Die Einstellung der Zeiten ist analog zu den Zeiten der AQ-Erstellung.

            </div>
        </div>



        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Tabelle Zeitschaltuhr </h4> <button (click)="onShowparticularities()"> <img
                            *ngIf="!isShowparticularities" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowparticularities" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowparticularities"> Hier können sie eventuelle besonderheiten in einer
                Blau markierten Farbe erkennen.
                <br>
                <br>
                <img style="width: 1441px; max-width: 90%;" class="me-4 ms-12 mb-4 "
                    src="assets/FAQ/Bild14ZeitschaltUhr.png" alt="Referenz zu Abbildung 14">
            </div>
        </div>
    </div>
</div>