import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReportConfigurationService } from "../shared/services/report-configuration.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SelectInput } from "../../shared/dropdown/shared/select-input.class";
import { GroupService } from "../../project/shared/services/group.service";
import { ProjectGroup } from "../../project/shared/interfaces/project-group.interface";
import { UserDataService } from "../../user/shared/services/user-data.service";
import { UserDataResponse } from "../../user/shared/interfaces/login-data-response.interface";
import { UserService } from "../../user/shared/services/user.service";
import { ReportConfiguration } from "../shared/interfaces/report-configuration.interface";
import { AlertService } from "../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../shared/alert/shared/alert-types";
import { Subscription } from 'rxjs';
import { group } from '@angular/animations';

@Component({
  selector: 'avs-changelog-configuration-modal',
  templateUrl: './changelog-configuration-modal.component.html',
  styleUrls: ['./changelog-configuration-modal.component.css']
})
export class ChangelogConfigurationModalComponent implements OnInit, OnDestroy {
  public reportConfigs: ReportConfiguration[] = [];
  public userData?: UserDataResponse;
  public isEditUserActive: boolean = false;
  public isUserDropdownOpen: boolean = false;
  public isEditGroupActive: boolean = false;
  public isGroupDropdownOpen: boolean = false;
  public selectedUser: SelectInput = { name: "", id: "" };
  public userSelectInput: SelectInput[] = [];
  public groupSelectInput: SelectInput[] = [];
  public groupSelectInputBackUp: SelectInput[] = [];
  public sendMailInterval: number = 1;
  public sendDataType: string = 'pdf';
  public selectedGroup: SelectInput = { name: "", id: "" };
  private subscriptionCollection: Subscription[] = [];
  private userDataSubscribtion?: Subscription;
  public searchFilter: string = '';

  constructor(private readonly reportConfigService: ReportConfigurationService,
    private readonly groupService: GroupService,
    private readonly userDataService: UserDataService,
    private readonly userService: UserService,
    private readonly alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: { groupSelectItem: SelectInput, congfig: ReportConfiguration | undefined },
    private readonly dialogRef: MatDialogRef<ChangelogConfigurationModalComponent>) {
    this.userDataSubscribtion = userDataService.userData.subscribe(userData => {
      this.userData = userData;
      if (userData) {
        this.fillSelectedUser(userData);
      }
    });
  }
  public ngOnInit(): void {
    this.getData();
    this.fillDataForEditConfig();
    this.subscriptionCollection.push(this.userDataSubscribtion!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public onEditUserBtnClicked(): void {
    this.isEditUserActive = !this.isEditUserActive;
  }
  public filterDataSource() {

    if (this.searchFilter !== '') {
      this.groupSelectInput = this.groupSelectInputBackUp.filter(group => group.name.toLowerCase().includes(this.searchFilter.toLowerCase()));
    }
    if (this.searchFilter === '') {
      this.groupSelectInput = this.groupSelectInputBackUp;
    }
  }

  public resetData(): void {
    this.groupSelectInput = this.groupSelectInputBackUp;
  }

  public onEditGroupBtnClicked(): void {
    this.isEditGroupActive = !this.isEditGroupActive;
  }

  public onUserItemClicked(user: SelectInput): void {
    this.selectedUser = user;
    this.isUserDropdownOpen = false;
    this.isEditUserActive = false;
  }

  public onGroupItemClicked(group: SelectInput): void {
    this.data.groupSelectItem = group;
    this.isGroupDropdownOpen = false;
    this.isEditGroupActive = false;
  }

  public onSaveBtnClicked(): void {
    let newReportConfig = {
      userId: this.selectedUser.id,
      groupId: this.data.groupSelectItem.id,
      cycle: this.sendMailInterval,
      type: this.sendDataType
    } as ReportConfiguration
    if (this.data.congfig) {
      this.reportConfigService.editReportConiguration(newReportConfig, this.data.congfig.id).subscribe(() => {
        this.alertService.alert('Bericht Einstellung bearbeitet', AlertTypes.info);
        this.dialogRef.close(true);
      });
    } else {
      if (!this.reportConfigs.find((rc) => rc.userId === newReportConfig.userId && rc.groupId === newReportConfig.groupId)) {
        this.reportConfigService.createReportConfiguration(newReportConfig).subscribe(() => {
          this.alertService.alert("Bericht Einstellung gespeichert", AlertTypes.success);
          this.dialogRef.close();
        });
      } else {
        this.alertService.alert('Es existiert bereits eine Einstellung für den User: ' + this.selectedUser.name + ' und der Gruppe: ' + this.data.groupSelectItem.name, AlertTypes.warning, 5000);
      }
    }
  }

  private fillSelectedUser(userData: UserDataResponse): void {
    if (this.data.congfig) { return; }
    this.selectedUser.name = userData?.firstName + " " + userData?.lastName;
    this.selectedUser.id = userData?.id
  }

  private fillUserSelectInput(users: UserDataResponse[]): void {
    for (let user of users) {
      let selectInput = {
        name: user.firstName + " " + user.lastName,
        id: user.id
      }
      this.userSelectInput.push(selectInput);
    }
  }

  private fillGroupSelectInput(groups: ProjectGroup[]): void {
    for (let group of groups) {
      let selectInput = {
        name: group.name,
        id: group.id
      }
      this.groupSelectInput.push(selectInput);
      this.groupSelectInputBackUp.push(selectInput);
    }
  }

  public setGroup(group: SelectInput) {
    this.data.groupSelectItem = { name: group.name, id: group.id };
    this.searchFilter = '';
  }

  private getData(): void {
    this.reportConfigService.getReportConfigurations().subscribe(reportConfigs => {
      this.reportConfigs = reportConfigs;
    });
    this.groupService.getAllProjectGroups().subscribe(groups => {
      this.fillGroupSelectInput(groups);
      if (this.data.congfig) {
        let groupName = groups.find((g) => g.id === this.data.groupSelectItem.id)?.name;
        this.data.groupSelectItem.name = groupName ? groupName : "";
      }
    });
    this.userService.getAllUsers().subscribe(users => {
      this.fillUserSelectInput(users);
      if (this.data.congfig) {
        let user = users.find((u) => u.id === this.data.congfig?.userId);
        this.selectedUser.name = user?.firstName + " " + user?.lastName;
        this.selectedUser.id = user?.id;
      }
    });
  }

  private fillDataForEditConfig(): void {
    if (!this.data.congfig) { return; }
    this.sendMailInterval = this.data.congfig.cycle;
    this.sendDataType = this.data.congfig.type;
  }
}
