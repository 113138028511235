<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">
        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/clinical_note.svg"
                alt="question-circle Icon"> Projekt
        </h1>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Informaionen - Karte </h4> <button (click)="onShowProjektMap()"> <img
                            *ngIf="!isShowProjektMap" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowProjektMap" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowProjektMap"> Wenn Sie auf „Karte“ klicken, öffnet sich eine
                Weltkarte mit den öffentlichen Fahrspuren wo ihr aktives Projekt zusehen ist. <br>
                Das Projekt wird mit einem Roten Punkt gekennzeichnet, (siehe Abbildung 6)und (Abbildung 5)
                <br>
                <br>
                <img style="height: 50px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 6.png"
                    alt="Referenz zu Abbildung 6">
                <img style="height: 450px" class="me-8 ms-20 mb-4 " src="assets/FAQ/Abbildung 5...png"
                    alt="Referenz zu Abbildung 5">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Informaionen - Stationsübersicht </h4> <button (click)="onShowProjektStations()">
                        <img *ngIf="!isShowProjektStations" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowProjektStations" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowProjektStations"> Wenn Sie auf „Stationsübersicht“ klicken“ und kein
                Projekt zugewiesen ist,
                bekommen sie die Meldung „Keine Gruppe ausgewählt“ zurück.<br>
                Wenn sie auf „Stationsübersicht “klicken“ und ein Projekt zugewiesen ist,
                (siehe Abbildung 7) können Sie innerhalb des Projektes eine „Fahrtrichtung “ aussuchen und Sie
                bekommen ihre zugewiesene Stationsübersicht zu sehen.
                <br>
                Anschließend bekommen Sie eine oder mehrere Übersichten von Schilden, siehe Abbildung 8.
                Hier sehen Sie die Seriennummer, Position, Sam ID, Akkustand, Onlinestatus und Empfangsstatus sieht.
                <br>
                <br>
                <img style="height: 350px" class="me-8 ms-20 mb-4 " src="assets/FAQ/Abbildung 7...png"
                    alt="Referenz zu Abbildung 7">
                <img style="height: 350px" class="me-8 ms-40 mb-4 " src="assets/FAQ/Abbildung 8..png"
                    alt="Referenz zu Abbildung 8">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Informaionen - Schema </h4> <button (click)="onShowProjektPlan()"> <img
                            *ngIf="!isShowProjektPlan" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowProjektPlan" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowProjektPlan"> Wenn sie auf "Schema" klicken und in Keinem Projekt
                zugewiesen sind,
                wird die Meldung "Keine Gruppe ausgewählt" angezeigt.<br>
                Wenn Sie auf „Schema“ klicken und einem Projekt zugewiesen ist, werden die Meldungen Schematisch
                visualisiert siehe Abbildung 9.<br>
                Anzeigen im Randstreifen werden unterhalb der Fahrbahn angezeigt, Anzeigen im Mittelstreifen
                oberhalb. Die Fahrtrichtung ist immer von links nach rechts.
                <br>
                <br>
                <img style="height: 350px" class="me-8 ms-40 mb-4 " src="assets/FAQ/Abbildung 9....png"
                    alt="Referenz zu Abbildung 9">
            </div>
        </div>
    </div>
</div>