import {NgModule} from "@angular/core";
import {CompanyOverviewComponent} from "./company/company-overview/company-overview.component";
import {CompanySettingsComponent} from "./company/company-settings/company-settings.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {RouterLink, RouterOutlet} from "@angular/router";
import { CompanyComponent } from './company/company.component';

@NgModule({
    declarations: [
        CompanyOverviewComponent,
        CompanySettingsComponent,
        CompanyComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterLink,
        RouterOutlet
    ]
})
export class CompanyModule { }