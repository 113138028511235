<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Hinweise zur Zuweisung</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseModalClicked()">
    </div>
    <div *ngIf="data" class="mt-1">
        <div class="row">
            <div class="col-2 fw-bold">SAM ID</div>
            <div class="col-1 fw-bold">AQ</div>
            <div class="col-1 fw-bold">MQ</div>
            <div class="col-1 fw-bold">Flasher</div>
            
            <div class="col-1 fw-bold">RouteStation</div>
            <div class="col-1 fw-bold">SWA</div>
            <div class="col fw-bold">Fehlerhafte Channel</div>
        </div>

        <div *ngFor="let errorObject of errorObjects">
            <div class="row">
                <div class="col-2">{{ errorObject.samId }}</div>
                <div class="col-1"><img *ngIf="errorObject.AQerror"
                        src="../../../../assets/icons/check2.svg">
                    <img *ngIf="!errorObject.AQerror" src="../../../../assets/icons/ban.svg">
                </div>
                <div class="col-1"><img *ngIf="errorObject.MQerror"
                        src="../../../../assets/icons/check2.svg">
                    <img *ngIf="!errorObject.MQerror" src="../../../../assets/icons/ban.svg">
                </div>
                <div class="col-1"><img *ngIf="errorObject.Flasherror"
                        src="../../../../assets/icons/check2.svg">
                    <img *ngIf="!errorObject.Flasherror" src="../../../../assets/icons/ban.svg">
                </div>
                
                <div class="col-1"><img *ngIf="errorObject.Routestationerror"
                        src="../../../../assets/icons/check2.svg">
                    <img *ngIf="!errorObject.Routestationerror" src="../../../../assets/icons/ban.svg">
                </div>
                <div class="col-1"><img *ngIf="errorObject.Swaerror"
                        src="../../../../assets/icons/check2.svg">
                    <img *ngIf="!errorObject.Swaerror" src="../../../../assets/icons/ban.svg">
                </div>
                <div class="col">{{ errorObject.ErrorsByChannelId }}</div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-5 border-top pt-3">
        <button class="btn btn-sm btn-outline-success rounded-0" (click)="onCloseModalClicked()">
            Zur Kenntnis genommen
        </button>
    </div>
</mat-dialog-content>