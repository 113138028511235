<div [ngClass]="isSmallDropdown ? 'avs-small-select-card'  : 'avs-select-card'" class="card shadow rounded-0">
  <div *ngIf="isHeaderActive" class="card-header d-flex justify-content-between">
    <span class="avs-select-card-titel">{{title}}</span>
    <img *ngIf="isCloseButtonActive" class="avs-select-close-icon" alt="select close icon"
    src="../../../assets/icons/x.svg" (click)="onCloseButtonClicked()">
  </div>
  <ul *ngFor="let input of selectInput" class="list-group list-group-flush"
    (click)="onListItemClicked(input)">
    <li class="list-group-item avs-select-nav-link text-start " [routerLink]='input.routerLink' routerLinkActive="active"
        [ngClass]="!activeItem ? '' : (activeItem.name === input.name && activeItem.id === input.id && activeItem.samId === input.samId) ? 'avs-active-item' : ''">
      <div class="d-flex justify-content-between">
        <span [class]="input.class">{{input.name}}</span>
        <span *ngIf="input.samId" class="ms-3 text-secondary">Sam ID: {{input.samId}}</span>
        <span *ngIf="input.projectName" class="ms-3 text-secondary text-end ">{{input.projectName}}</span>
      </div>
    </li>
  </ul>
</div>
