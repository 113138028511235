<div style="width: 100vw; display: flex; justify-content: end; " class="me-5 mt-5 mb-5  ">

    <div class="d-flex" style="width: 100%; margin-right: 16px;"> <!--  max-width: calc(100vw - 500px); -->
        <div class="d-flex justify-content-center align-items-center flex-wrap w-100">

            <div class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px;  width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1 " style=" width: 400px; background-color: white;">
                    <div class="mt-1 me-1 ">
                        <div class="ms-3 d-flex justify-content-start   align-items-center">
                            <img class="ms-2 me-2 " style=" height: 26px " src="assets/icons/person-circle-black.svg"
                                alt="question-circle Icon">
                            <span class="me-5 text-secondary faq-header">Account</span>
                        </div>
                        <div class="ms-3 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                            <span class="ms-2 me-2 mt-2"> • Wie logge ich mich ein?</span>
                            <span class="ms-2 me-2"> • Wie verwalte ich meine Daten?</span>
                            <span class="ms-2 me-2 mb-2"> • Wie logge ich mich aus?</span>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../account">Alles ansehen</a>
                </div>
            </div>

            <div class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px;  width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1 " style="width: 400px; align-content: center;">
                    <div class="mt-1 me-1">
                        <div class="ms-2 d-flex justify-content-start   align-items-center">
                            <img class="ms-2 me-2 " style="height: 26px" src="assets/icons/donut_large.svg"
                                alt="question-circle Icon">
                            <span class="me-5 text-secondary faq-header">Übersicht</span>
                        </div>
                        <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                            <span class="ms-2 me-2 mt-2"> • Was versteht man unter Zuweisung?</span>
                            <span class="ms-2 me-2"> • Was versteht man unter Auslastung?</span>
                            <span class="ms-2 me-2  mb-2"> • Was versteht man unter Fehlerquote?</span>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../overview">Alles ansehen</a>
                </div>
            </div>

            <div class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px;  width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1 " style="width: 400px;">
                    <div class="mt-1 me-1">
                        <div class="ms-2 d-flex justify-content-start  align-items-center ">
                            <img class="ms-2 me-2  " style="height: 26px" src="assets/icons/clinical_note.svg"
                                alt="question-circle Icon">
                            <span class="me-5 text-secondary faq-header">Projekt</span>
                        </div>
                        <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                            <span class="ms-2 me-2 mt-2"> • Information - Karte</span>
                            <span class="ms-2 me-2"> • Information - Stationsübersicht</span>
                            <span class="ms-2 me-2 mb-2"> • Information - Schema</span>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../project">Alles ansehen</a>
                </div>
            </div>
            <div class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px; width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1 " style="width: 400px;">
                    <div class="mt-1 me-1">
                        <div class="ms-2 d-flex justify-content-start align-items-center">
                            <img class="ms-2 me-2 " style="height: 26px" src="assets/icons/problem.svg"
                                alt="question-circle Icon">
                            <span class="me-5 text-secondary faq-header">Bericht</span>
                        </div>
                        <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                            <span class="ms-2 me-2 mt-2"> • Wieso?</span>
                            <span class="ms-2 me-2"> • Weshalb?</span>
                            <span class="ms-2 me-2 mb-2"> • Warum?</span>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../report">Alles ansehen</a>
                </div>
            </div>

            <div *canAccess="[1,5]" class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px;  width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1  " style=" width: 400px;">
                    <div class="ms-2 d-flex justify-content-start  align-items-center">
                        <img class="ms-2 me-2 " style="height: 26px" src="assets/icons/card-image.svg"
                            alt="question-circle Icon">
                        <span class="me-5 text-secondary faq-header">Bilder</span>
                    </div>
                    <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                        <span class="ms-2 me-2 mt-2"> • Wie Öffne ich einen Dialog mit den Stationen? </span>
                        <span class="ms-2 me-2"> • Wie sehe ich die Routestations?</span>
                        <span class="ms-2 me-2"> • Wie kann ich die Bilder hochladen ?</span>
                        <span class="ms-2 me-2 mb-2"> • Wie kann ich meinen Kanal Synchroniesieren ?</span>
                    </div>

                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../bilder">Alles ansehen</a>
                </div>
            </div>

            <div *canAccess="[1,5]" class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px;  width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1  " style="width: 400px;">
                    <div class="ms-2 d-flex justify-content-start align-items-center">
                        <img class="ms-2 me-2 " style="height: 26px" src="assets/icons/exclamation-octagon.svg"
                            alt="question-circle Icon">
                        <span class="me-5 text-secondary faq-header">Swa/Stauwarnanlage</span>
                    </div>
                    <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                        <span class="ms-2 me-2 mt-2"> • Wie Öffne ich einen Dialog mit den Stationen?</span>
                        <span class="ms-2 me-2"> • Erstellung eines SWA </span>
                        <span class="ms-2 me-2"> • Variante 2 zu erstellung eines SWA's</span>
                        <span class="ms-2 me-2 mb-2"> • Was sind die Besonderheiten?</span>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../swa">Alles ansehen</a>
                </div>
            </div>

            <div *canAccess="[1,5]" class="card shadow rounded-0 position-relative"
                style="border: 1px solid #adb5bd; height:240px; width: 420px; margin: 5px; padding: 4px; margin-bottom: 8px;">
                <div class="mx-1 mt-1 " style=" width: 400px; background-color: white;">
                    <div class="mt-1 me-1">
                        <div class="ms-2 d-flex justify-content-start   align-items-center">
                            <img class="ms-2 me-2 " style=" height: 26px " src="assets/icons/calendar-week-fill.svg"
                                alt="question-circle Icon">
                            <span class="me-5 text-secondary faq-header">Zeitschaltuhr</span>
                        </div>
                        <div class="ms-2 d-flex mt-1 mb-2 d-flex flex-column" style="border: 1px solid #dee2e6;">
                            <span class="ms-2 me-2 mt-2"> • Wie erstelle ich eine Zeitschaltuhr ?</span>
                            <span class="ms-2 me-2"> • AQ Einstellungen</span>
                            <span class="ms-2 me-2"> • MQ Einstellungen</span>
                            <span class="ms-2 me-2"> • Blinker Einstellungen </span>
                            <span class="ms-2 me-2 mb-2"> • Tabelle Zeitschaltuhr</span>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; bottom: 7px; right: 7px">
                    <a class="view-all" routerLinkActive="active" routerLink="../timer-switch">Alles ansehen</a>
                </div>
            </div>

        </div>
    </div>
</div>