<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">
        <h1 class="ms-4 mb-4"><img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/donut_large.svg" alt="question-circle Icon">
            Übersicht
        </h1>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Was versteht man unter Zuweisung ? </h4> <button
                        (click)="onShowDashboardAllocation()"> <img *ngIf="!isShowDashboardAllocation"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon"><img *ngIf="isShowDashboardAllocation" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowDashboardAllocation"> Die drei Ringdiagramme links daneben geben
                einen
                Überblick darüber,
                wie viele der selektierten „online“ Anlagen einem Projekt zugewiesen sind (Zuweisung) (Abbildung 2).
                <br>
                <br>
                <img style="height: 550px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 2..png"
                    alt="Referenz zu Abbildung 2">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Was versteht man unter Auslastung ? </h4> <button
                        (click)="onShowDashboardWorkload()"> <img *ngIf="!isShowDashboardWorkload" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowDashboardWorkload"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowDashboardWorkload"> Die drei Ringdiagramme links daneben geben einen
                Überblick darüber,
                wie viele unserer selektierten Systeme derzeit insgesamt online sind (Auslastung)(Abbildung 2).
                <br>
                <br>
                <img style="height: 550px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 2..png"
                    alt="Referenz zu Abbildung 2">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-space-start align-items-center border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Was versteht man unter Fehlerquote ? </h4> <button
                        (click)="onShowDashboardErrorRate()"> <img *ngIf="!isShowDashboardErrorRate"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowDashboardErrorRate"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowDashboardErrorRate"> Die drei Ringdiagramme links daneben geben
                einen
                Überblick darüber,
                wie hoch die aktuelle Quote an selektierten Anlagen mit Fehlermeldungen ist (Fehlerquote)(Abbildung
                2).
                <br>
                <br>
                <img style="height: 550px" class="me-4 ms-12 mb-4 " src="assets/FAQ/Abbildung 2..png"
                    alt="Referenz zu Abbildung 2">
            </div>
        </div>
    </div>
</div>