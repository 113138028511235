<ng-container *ngIf="deviceParameter">
    <h6 class="text-secondary">Smart Micro anlernen</h6>
    <span *ngIf="selectKanalInput.length < 1" class="text-danger ms-2">Keine Sensor gefunden!</span>
    <div class="row">
        <div class="col-6">
            <div class="avs-test-area-additional-settings-w-2 mt-2 position-relative">
                <p class="mb-0 mt-3 text-secondary">Funktion</p>
                <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onFunctionDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                    <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                    (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isFunctionDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
        <div *ngIf="isFunctionSelected" class="col-6 h-100 d-flex justify-content-between align-content-center  ">

            <button class="btn btn-outline-success rounded-0 mt-5  avs-test-area-additional-settings-w-2"
                (click)="onSaveBtnClicked()">Speichern</button>
        </div>
    </div>
</ng-container>