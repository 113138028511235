<div class="d-flex justify-content-center mb-5">
  <div class="avs-user-settings shadow">
    <div class="table-responsive position-relative">
        <input placeholder="Suche" style="top: 24px; left: 135px;" class="position-absolute form-control rounded-0 " #search type="text" (keyup)="filterResults(search.value)">
      <table class="table mb-0">
        <thead>
          <tr>
            <th class="bg-light ps-4" scope="col">Name</th>
            <th class="bg-light ps-0" scope="col">Status</th>
            <th class="bg-light ps-0" scope="col">Rolle</th>
            <th class="bg-light ps-0" scope="col" colspan="2">Institution</th>
            <th class="bg-light ps-0" scope="col" colspan="2">Projekte</th>
            <th class="bg-light text-end" >
              <button type="button" class="btn h-100 text-white avs-user-button rounded-0 border-0 shadow"
                (click)="onAddBtnClicked()">
                <span class="d-xl-block d-none text-nowrap">Benutzer hinzufügen</span>
                <div class="d-xl-none fw-bold pb-1">+</div>
              </button>
            </th>
          </tr>
        </thead>
        
        <tbody>
          <tr *ngFor="let user of filteredUserData; let i = index">
            <td class="ps-1 ps-md-2 ps-xl-4" scope="row">
              <div class="d-flex align-items-center">
                <div>
                  <img class="avs-user" src="../../../assets/icons/person-circle.svg" alt="account circle">
                </div>
                <div>
                  {{ user.title }} {{ user.firstName }} {{ user.lastName }}
                  <div class="avs-user-attributes">{{ user.email }}</div>
                </div>
              </div>
            </td>
            <td>
              <div>
                <ng-container *ngIf="user.isVerified; else notVerified">
                  <span class="avs-state-success p-1">Verifiziert</span>
                </ng-container>
                <ng-template #notVerified>
                  <span class="avs-state-danger p-1">Nicht Verifiziert</span>
                </ng-template>

              </div>
            </td>
            <td>{{ getRoleTypesString(user.role) }}</td>
            <td colspan="2">{{ getCompanyName(user.company) }}</td>
            <td colspan="2">
              <ul *ngIf="user.userProjects?.length! > 0">
                <li *ngFor="let project of user.userProjects"> {{ getProjectName(project.projectId!) }}</li>
              </ul>
            </td>
            <td class="pe-0 pe-lg-2"  >
              <div class="d-flex justify-content-end">
                <div style="width: 74px;">
                  <img class="avs-icon-delete p-2" src="assets/icons/trash.svg" alt="icon-delete-group"
                    (click)="onDeleteBtnClicked(user.id)">
                  <img class="avs-icon-edit p-2" src="assets/icons/pencil.svg" alt="avs-icon-edit"
                    (click)="onEditBtnClicked(user.id)">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>