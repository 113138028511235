import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectDataService } from "../shared/services/project-data.service";
import { ProjectRouteStation } from "../shared/interfaces/project-route-station.interface";
import { DeviceParameter } from "../../device/shared/interfaces/device-parameter.interface";
import { DataEndChannel } from "../shared/interfaces/data-end-channel.interface";
import { DeviceService } from "../../device/shared/services/device.service";
import { Subscription } from 'rxjs';
@Component({
  selector: 'avs-project-test-area',
  templateUrl: './project-test-area.component.html',
  styleUrls: ['./project-test-area.component.css']
})
export class ProjectTestAreaComponent implements OnInit, OnDestroy {
  public activeStation?: ProjectRouteStation;
  public deviceParameter?: DeviceParameter;
  public dataEndChannels: DataEndChannel[] = [];

  public testareaSubscriptionCollection: Subscription[] = [];
  public activeRouteStationSubscription?: Subscription;
  public dataEndChannelsSubscription?: Subscription;

  constructor(private readonly projectDataService: ProjectDataService,
    private readonly deviceService: DeviceService) {
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(station => {
      this.activeStation = station;
      if (station) {
        this.getDeviceParameter(station);
      }
    });
    this.dataEndChannelsSubscription = projectDataService.dataEndChannels.subscribe(endChannels => {
      this.dataEndChannels = endChannels;
    });
  }

  public ngOnInit(): void {
    this.testareaSubscriptionCollection.push(this.activeRouteStationSubscription!);
    this.testareaSubscriptionCollection.push(this.dataEndChannelsSubscription!);
  }

  public ngOnDestroy(): void {
    this.testareaSubscriptionCollection.forEach(sub => sub.unsubscribe());
   
  }

  public getDeviceParameter(station: ProjectRouteStation): void {
    this.deviceService.getDeviceParameter(station.samId).subscribe(deviceParam => {
      this.deviceParameter = deviceParam;
    });
  }
}
