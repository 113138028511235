import {Injectable} from "@angular/core";
import {HttpService} from "../../../core/shared/http.service";
import {Observable} from "rxjs";
import {ReportConfiguration} from "../interfaces/report-configuration.interface";

@Injectable({
    providedIn: 'root'
})
export class ReportConfigurationService {

    constructor(private readonly httpService: HttpService) {}

    public getReportConfigurations(): Observable<ReportConfiguration[]>{
        return this.httpService.get<ReportConfiguration[]>('ReportConfiguration');
    }

    public createReportConfiguration(reportConfig: ReportConfiguration): Observable<ReportConfiguration> {
        return this.httpService.post<ReportConfiguration>('ReportConfiguration', reportConfig);
    }

    public editReportConiguration(reportConfig: ReportConfiguration, id: number): Observable<any> {
        return this.httpService.put('ReportConfiguration/' + id, reportConfig);
    }

    public deleteReportConfiguration(id: number): Observable<any> {
        return this.httpService.delete('ReportConfiguration/' + id);
    }
}