import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceDataService } from "../shared/services/device-data.service";
import { ProjectRouteStation } from "../../project/shared/interfaces/project-route-station.interface";
import { DeviceParameter } from "../shared/interfaces/device-parameter.interface";
import { DataEndChannel } from "../../project/shared/interfaces/data-end-channel.interface";
import { RouteStationService } from "../../project/shared/services/route-station.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-device-test-area',
  templateUrl: './device-test-area.component.html',
  styleUrls: ['./device-test-area.component.css']
})
export class DeviceTestAreaComponent implements OnInit, OnDestroy {
  public activeStation?: ProjectRouteStation;
  public deviceParameter?: DeviceParameter;
  public dataEndChannels: DataEndChannel[] = [];

  private subscriptionCollection: Subscription[] = [];
  private activeStationSubscription?: Subscription;
  private deviceParameterSubscription?: Subscription;

  constructor(private deviceDataService: DeviceDataService,
    private stationService: RouteStationService) {
    this.activeStationSubscription = deviceDataService.activeRouteStation.subscribe(station => {
      this.activeStation = station;
      if (station) {
        this.getDataEndChannels(station)
      }
    });
    this.deviceParameterSubscription = deviceDataService.deviceParameter.subscribe(deviceParameter => {
      this.deviceParameter = deviceParameter;
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeStationSubscription!);
    this.subscriptionCollection.push(this.deviceParameterSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }



  private getDataEndChannels(activeStation: ProjectRouteStation): void {
    this.stationService.getDataEndChannels(activeStation.id).subscribe(endChannels => {
      this.dataEndChannels = endChannels;
    });
  }
}
