import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getPrioritySelect } from '../../shared/enum/priority.enum';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'avs-add-flasher-time-switch',
  templateUrl: './add-flasher-time-switch.component.html',
  styleUrls: ['./add-flasher-time-switch.component.css']
})
export class AddFlasherTimeSwitchComponent implements OnInit{
 
  @Output() refIsOneTime: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refIsTouched: EventEmitter<boolean> = new EventEmitter<boolean>();
  public selectedImage?: ProjectImageData;
  public allImages: ProjectImageData[] = [];
  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }, { name: 'Blinkend', id: 2 }];
  public selectedFunction: SelectInput = { name: "Funktion" };
  public isPriorityDropdownOpen: boolean = false;
  public prioritySelect: SelectInput[] = getPrioritySelect();
  public selectedPriority: SelectInput = { name: "Priorität" };
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00";
  public isOneTime: boolean = false;
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public form: FormGroup;
  public untouched: boolean = false;

  constructor(private rootFormGroup: FormGroupDirective) {
    this.form = this.rootFormGroup.control.get("timerSwitchForm") as FormGroup;
  }

  public ngOnInit(): void {
    this.onTouchedChange();
    this.form.patchValue({ tlS_Code: 61 })
    this.onFunctionItemClicked({ name: "An", id: 1 });
    this.onPriorityItemClicked({ name: "Mittel", id: 2 });
  }
  

  public onEnterStartTimeChange(newValue: string): void {
    this.starttime = newValue;
    this.form.patchValue({ starttime: this.starttime })
  }

  public onEnterEndTimeChange(newValue: string): void {
    this.endtime = newValue;
    this.form.patchValue({ endtime: this.endtime })
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    let funcId = item.id as number
    this.form.patchValue({ tlS_Function: funcId })
    if (funcId !== 2) {

      this.form.patchValue({ tlS_Time: 0 })
    }
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onPriorityItemClicked(item: SelectInput): void {
    this.isPriorityDropdownOpen = false;
    this.selectedPriority.name = item.name
    this.selectedPriority.id = item.id
    let prioId = item.id as number
    this.form.patchValue({ priority: prioId })
  }

  public onPriorityDropdownClicked(): void {
    this.isPriorityDropdownOpen = !this.isPriorityDropdownOpen;
  }

  public onOneTimeChange(): void {
    this.isOneTime = !this.isOneTime
    this.refIsOneTime.emit(this.isOneTime)
  }
  public onTouchedChange(): void {
    this.refIsTouched.emit(true)
  }

  public onStartDateSelect(newValue: NgbDate) {
    this.startDate = newValue;
    this.form.patchValue({ startdate: { year: newValue.year, month: newValue.month, day: newValue.day } })
  }

  public onEndDateSelect(newValue: NgbDate) {
    this.endDate = newValue;
    this.form.patchValue({ enddate: { year: newValue.year, month: newValue.month, day: newValue.day } })
  }
}



