import { Component, inject, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'ngbd-datepicker-range-popup',
  standalone: true,
  imports: [NgbDatepickerModule, FormsModule, JsonPipe],
  templateUrl: './popup-range-picker.component.html',
  styleUrls: ['./popup-range-picker.component.css']
})
export class PopupRangePickerComponent implements OnInit {

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  fromDate: NgbDate | null = this.calendar.getToday();
  toDate: NgbDate | null = this.calendar.getNext(this.calendar.getToday(), 'd', 10);

  @Output() startDate = new EventEmitter<NgbDate>()
  @Output() endDate = new EventEmitter<NgbDate>()

  @Input() loadedFromDate?: NgbDate; 
  @Input() loadedToDate?: NgbDate;
  hoveredDate: NgbDate | null = null;

  ngOnInit(): void {
    if (this.loadedFromDate) {
      this.fromDate = this.loadedFromDate;
    }
    if (this.loadedToDate) { this.toDate = this.loadedToDate }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;

    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.startDate.emit(this.fromDate);
      this.endDate.emit(this.toDate)
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string, isFromDate: boolean): NgbDate | null {
    const parsed = this.formatter.parse(input);
    if (isFromDate) {
      let fromDate = NgbDate.from(parsed);
      this.startDate.emit(fromDate!);
    }
    if (!isFromDate) {
      let toDate = NgbDate.from(parsed);
      this.endDate.emit(toDate!);
    }
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
