import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Radar } from 'src/app/device/shared/interfaces/radar.interface';
import { DeviceService } from 'src/app/device/shared/services/device.service';

@Component({
  selector: 'avs-radar-data-chart',
  templateUrl: './radar-data-chart.component.html',
  styleUrls: ['./radar-data-chart.component.css']
})

export class RadarDataChartComponent implements OnInit {
  public radare: Radar[] = [];
  public countdown: number = 60;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<RadarDataChartComponent>) {
  }

  ngOnInit(): void {
    this.radare = this.data.radar
    this.startCountdown();
  }

  public ngOnDestroy(): void {
    this.stopTimer();
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  private stopTimer() {
    if (this.timer)
      clearInterval(this.timer)
  }
  public timer: any;

  private startCountdown() {
    this.stopTimer();
    this.timer = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {

        this.deviceService.getDeviceParameter(this.data.samId).subscribe(element => { this.radare = []; this.radare = element.radar })

        this.countdown = 60;

      }
    }, 1000);
  }
}
