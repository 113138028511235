<ng-container *ngIf="deviceParameter">
    <div class="d-flex justify-content-between">
        <h6 class="text-secondary">Bildeinstellungen</h6>
        <span *ngIf="selectKanalInput.length < 1" class="text-danger ms-2">Keine Kanäle gefunden!</span>
    </div>
    <div class="row">
        <div class="col-md-6 d-flex mb-4 mt-4">
            <p class="mb-0 mt-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Stellcode:</p>
            <avs-text-input class="avs-test-area-input ms-2" type="number" [value]="deviceParameter.led.stellcode"
                (valueChange)="onSettingCodeInputValueChange(+$event)" [disabled]="selectKanalInput.length < 1">
            </avs-text-input>
        </div>
        <div class="col-md-6 d-flex mt-4 ">
            <p class="mb-0 mt-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Kanal:</p>
            <div class="avs-test-area-dropdown-container ms-2">
                <div [ngClass]="isDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                    role="button" (click)="onDropdownClicked()"> 
                    <div class="ms-2 p-1" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">
                        {{ selectedKanal? selectedKanal.name : 'N/A' }}
                    </div>
                    <img *ngIf="!isDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="selectKanalInput" [activeItem]="selectedKanal"
                    (onItemClicked)="onSelectKanalItemClicked($event)" [isHeaderActive]="false" *ngIf="isDropdownOpen"
                    class="position-absolute avs-test-area-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-6 mb-4">
            <p class="" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Endpunkte:</p>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1"
                    [disabled]="selectKanalInput.length < 1" [checked]="imageSettings.endpoint === EndpointTypes.led"
                    (change)="onEndpointOptionsRadioBtnValueChange(EndpointTypes.led)" style="cursor: pointer;">
                <label class="form-check-label px-1" style="cursor: pointer;" for="flexRadioDefault1">
                    Anzeigetafel
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2"
                    [disabled]="selectKanalInput.length < 1" [checked]="imageSettings.endpoint === EndpointTypes.pw"
                    (change)="onEndpointOptionsRadioBtnValueChange(EndpointTypes.pw)" style="cursor: pointer;">
                <label class="form-check-label px-1" for="flexRadioDefault2" style="cursor: pointer;">
                    Prismenwender
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault3"
                    [disabled]="selectKanalInput.length < 1"
                    [checked]="imageSettings.endpoint === EndpointTypes.blinker"
                    (change)="onEndpointOptionsRadioBtnValueChange(EndpointTypes.blinker)" style="cursor: pointer;">
                <label class="form-check-label px-1" for="flexRadioDefault3" style="cursor: pointer;">
                    Blinker
                </label>
            </div>
        </div>
        <div class="col-md-6 mb-4">
            <p class="" [ngClass]="selectKanalInput.length < 1 ? 'text-secondary' : ''">Status:</p>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault4"
                    [disabled]="selectKanalInput.length < 1" [checked]="imageSettings.state === StateTypes.on"
                    (change)="onStatusRadioBtnValueChange(StateTypes.on)" style="cursor: pointer;">
                <label class="form-check-label px-1" for="flexRadioDefault4" style="cursor: pointer;">
                    An
                </label>
            </div>
            <div class="form-check ">
                <input class="form-check-input" type="radio" name="flexRadioDefault5" id="flexRadioDefault5" checked
                    [disabled]="selectKanalInput.length < 1" [checked]="imageSettings.state === StateTypes.off"
                    (change)="onStatusRadioBtnValueChange(StateTypes.off)" style="cursor: pointer;">
                <label class="form-check-label px-1" for="flexRadioDefault5" style="cursor: pointer;">
                    Aus
                </label>
            </div>
            <div class="form-check ">
                <input class="form-check-input" type="radio" name="flexRadioDefault6" id="flexRadioDefault6" checked
                    [disabled]="selectKanalInput.length < 1" [checked]="imageSettings.state === StateTypes.flashing"
                    (change)="onStatusRadioBtnValueChange(StateTypes.flashing)" style="cursor: pointer;">
                <label class="form-check-label px-1" for="flexRadioDefault6" style="cursor: pointer;">
                    Blinkend
                </label>
            </div>
        </div>
        <ng-container *ngIf="imageSettings.state === StateTypes.flashing">
            <div class="flex-column align-items-start mb-3 mt-4">
                <div class="d-flex align-items-center mb-2">
                    <p class="mb-0">Blinkgeschwindigkeit:</p>
                    <avs-text-input type="number" class="avs-test-area-input mx-3" [value]="imageSettings.interval"
                        (valueChange)="imageSettings.interval = +$event"></avs-text-input>
                    <p class="mb-0">(x 200ms)</p>
                </div>
                <div class="">
                    <input type="range" [(ngModel)]="imageSettings.interval" min="0" max="100"
                        class="form-range w-100 p-3">
                </div>
            </div>
        </ng-container>
    </div>
    <div class="text-end" *ngIf="isAnyValueChanged">
        <button class="btn btn-outline-success rounded-0 mt-4" type="button"
            (click)="onSaveBtnClicked()">Speichern</button>
    </div>
</ng-container>