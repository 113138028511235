<ng-container *ngIf="deviceParameter">
    <h6 class="text-secondary">Zusätzliche Einstellungen</h6>
    <div class="row">
        <div class="col-6">
            <button class="btn btn-outline-danger rounded-0 mt-4 avs-test-area-save-btn" (click)="onResetEvaClicked()">Reset
                Eva</button>
            <p [ngClass]="router ? 'text-success' : 'text-danger'" class="mt-2">{{router ? 'Router Online' : 'Router Offline'}}</p>
            <div>
                <p class="mb-0 mt-3 text-secondary">Eva Command SMS</p>
                <div class="avs-test-area-additional-settings-w-2 mt-2 position-relative">
                    <div [ngClass]="isEvaCommandDropdownOpen ? 'border-danger' : ''"
                        class="border d-flex justify-content-between" role="button"
                        (click)="isEvaCommandDropdownOpen = !isEvaCommandDropdownOpen">
                        <div class="ms-2 p-1 text-nowrap">{{ selectedEvaCommand.name }}</div>
                        <img *ngIf="!isEvaCommandDropdownOpen" class="me-2 " src="../../../../assets/icons/caret-down.svg"
                            alt="caret-down open select">
                        <img *ngIf="isEvaCommandDropdownOpen" class="me-2 " src="../../../../assets/icons/caret-up.svg"
                            alt="caret-up close select">
                    </div>
                    <avs-dropdown [selectInput]="evaCommandSelect" [activeItem]="selectedEvaCommand"
                        (onItemClicked)="onevaCommandSStellcodeValueChange($event)" [isHeaderActive]="false"
                        *ngIf="isEvaCommandDropdownOpen" class="position-absolute avs-test-area-additional-settings-w-2">
                    </avs-dropdown>
                </div>
                <button *ngIf="selectedEvaCommand.id !== 0"
                class="btn btn-outline-success rounded-0 mt-2 avs-test-area-additional-settings-w-2"
                (click)="onEvaCommandBtnClicked(selectedEvaCommand.id)">Ausführen</button>
            </div>
        </div>
        <div class="col-6">
            <button class="btn btn-secondary rounded-0 mt-4 avs-test-area-additional-settings-w-2 me-2"
                (click)="onUploadImagesClicked()">Bilder hochladen</button>
            <button class="btn btn-secondary rounded-0 mt-4 avs-test-area-additional-settings-w-2"
                (click)="onRefreshSolardataClicked()">Daten aktualisieren</button>
            <p class="mb-0 mt-3 text-secondary">Stellcode synchronisieren</p>
            <div class="avs-test-area-additional-settings-w-2 mt-2 position-relative">
                <div [ngClass]="isSyncStellcodeOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button"
                    (click)="isSyncStellcodeOpen = !isSyncStellcodeOpen">
                    <div class="ms-2 p-1 text-nowrap">{{ selectedSyncStellcode.name }}</div>
                    <img *ngIf="!isSyncStellcodeOpen" class="me-2 " src="../../../../assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isSyncStellcodeOpen" class="me-2 " src="../../../../assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="channelSyncStellcodeSettings" [activeItem]="selectedSyncStellcode"
                    (onItemClicked)="onChannelDropdownSyncStellcodeValueChange($event)" [isHeaderActive]="false"
                    *ngIf="isSyncStellcodeOpen" class="position-absolute avs-test-area-additional-settings-w-2">
                </avs-dropdown>
            </div>
            <button *ngIf="selectedSyncStellcode.id !== 0"
                class="btn btn-outline-success rounded-0 mt-2 avs-test-area-additional-settings-w-2"
                (click)="onSyncStellcodeBtnClicked()">Sync Kanal</button>
        </div>
    </div>
</ng-container>