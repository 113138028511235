import { Component } from '@angular/core';

@Component({
  selector: 'avs-faq-menu',
  templateUrl: './faq-menu.component.html',
  styleUrls: ['./faq-menu.component.css']
})
export class FaqMenuComponent {

}
