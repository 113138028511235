import { Component } from '@angular/core';

@Component({
  selector: 'avs-changelog-aq-engine',
  templateUrl: './changelog-aq-engine.component.html',
  styleUrls: ['./changelog-aq-engine.component.css']
})
export class ChangelogAqEngineComponent {

}
