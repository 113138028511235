import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { RouteStationService } from "../../shared/services/route-station.service";
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'avs-route-station-add-modal',
  templateUrl: './route-station-add-modal.component.html',
  styleUrls: ['./route-station-add-modal.component.css']
})
export class RouteStationAddModalComponent implements OnInit {
  public routeStations: ProjectRouteStation[] = [];
  public filter: string = "";
  public swap: boolean = false;

  constructor(private dialogRef: MatDialogRef<RouteStationAddModalComponent>,
    private stationService: RouteStationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public ngOnInit() {
    if (this.data) { this.swap = this.data.swap; }

    this.stationService.getUnassignedRouteStations().subscribe(allStations => {
      this.routeStations = allStations;
    }, error => {
      this.dialogRef.close();
    });
  }

  public onCloseBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSearchValueChange(value: string): void {
    this.filter = value;
  }

  public getFilteredInput(): ProjectRouteStation[] {
    return this.routeStations.filter(
      s => s.name.toLowerCase().includes(this.filter.toLowerCase()) ||
        s.samId.toString().includes(this.filter.toString()));
  }

  public onItemClicked(item: any): void {
    this.dialogRef.close(item);
  }
}
