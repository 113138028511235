<mat-dialog-content>
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Brennstoffzellendetails </h5>
        <div class="d-flex">
            <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
                (click)="onCloseClicked()">
        </div>
    </div>
    <div class="d-flex flex-column ">


        <div class="w-100 d-flex justify-content-between"> <span> Systemzustand: </span> <span>{{
                detailObj?.systemzustand}} </span>
        </div>
        <div class="w-100 d-flex justify-content-between"> <span> Ladezustand Batterie:</span> <span> {{
                detailObj?.ladezustandBatterie}} </span></div>
        <div class="w-100 d-flex justify-content-between"> <span> Batteriespannung:</span> <span> {{
                detailObj?.batteriespannung}} V</span>
        </div>
        <div class="w-100 d-flex justify-content-between"> <span> Generatorleistung:</span> <span> {{
                detailObj?.generatorleistung}} W</span>
        </div>
        <div class="w-100 d-flex justify-content-between"> <span> Generatorspannung: </span> <span>{{
                detailObj?.generatorspannung}} V</span></div>
        <div class="w-100 d-flex justify-content-between"> <span> Generatorstrom:</span> <span> {{
                detailObj?.generatorstrom }} A</span></div>

        <div class="w-100 d-flex justify-content-between"> <span> Tanksensor:</span> <span> {{
                detailObj?.meldungExternTankSensor }}</span></div>

        <div class="w-100 d-flex justify-content-between"> <span> Aktive Patrone:</span> <span> {{
                detailObj?.nummerAktiverPatrone }}</span></div>

        <div class="w-100 d-flex justify-content-between"> <span> Füllstand Patrone 1:</span> <span> {{
                detailObj?.fuellgradPatrone1 }}</span></div>

        <div class="w-100 d-flex justify-content-between"> <span> Füllstand Patrone 2:</span> <span> {{
                detailObj?.fuellgradPatrone2 }}</span></div>
    </div>

</mat-dialog-content>