import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrennstoffZelle } from 'src/app/device/shared/interfaces/brennstoff-zelle';
import { DeviceService } from 'src/app/device/shared/services/device.service';

@Component({
  selector: 'avs-brennstoffzellen-details',
  templateUrl: './brennstoffzellen-details.component.html',
  styleUrls: ['./brennstoffzellen-details.component.css']
})
export class BrennstoffzellenDetailsComponent {
  public samId?: number;
  public detailObj?: BrennstoffZelle;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<BrennstoffzellenDetailsComponent>
  ) { }

  public ngOnInit(): void {
    this.samId = this.data.data.routeStation.samId;
    if (this.samId) {
      this.deviceService.getDeviceParameter(this.samId).subscribe(element => {
        this.detailObj = element.brennstoffZelle;
      })
    }
  }

  lastSunday(year: number, month: number) {
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);
    return date;
  }

  isCEST(d: Date): boolean {

    let yearString = d as unknown as number;
    let startCET: Date = this.lastSunday(yearString, 3);
    let endCET: Date = this.lastSunday(yearString, 10);

    return !(startCET < d && d < endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }
  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}
