import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-report',
  templateUrl: './faq-report.component.html',
  styleUrls: ['./faq-report.component.css']
})
export class FaqReportComponent {

  public isShowReport: boolean = false;
  public isShowReportForWhatEverReason: boolean = false;
  public isShowReportWhy: boolean = false;

  constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}

  public onShowReport() {
    this.isShowReport = !this.isShowReport
  }

  public onShowReportForWhatEverReason(){
    this.isShowReportForWhatEverReason =! this.isShowReportForWhatEverReason
  }

  public onShowReportWhy(){
    this.isShowReportWhy =! this.isShowReportWhy
  }
}
