<div class="d-flex justify-content-between position-relative w-100 h-100">
    <div *ngIf="parsedSettings; else noConfig">
        <div *ngIf="parsedSettings?.AQ !== null">
            <div *ngIf="parsedSettings?.AQ?.Code !== 61" class="d-flex">
                <div class="me-2 ps-2 pt-2 pb-2 position-relative" >
                    <img  [src]="environment.apiAddress + getPathByStellcodeId(parsedSettings.AQ?.Code!)" style="max-width: 50px;">
                    <img *ngIf="checkIfStellcodeIsAnimated(parsedSettings.AQ?.Code!)"
                        src="assets/icons/shuffle.svg" alt="animatedImage"
                        class="position-absolute bg-white rounded-5 p-1 " style="top: 9px; right: 9px; height: 14px">
                </div>

                <div class="d-flex flex-column ">
                    <span class="mt-4 "> Funktion:<span class="fw-semibold  "> {{
                            getFunctionString(parsedSettings.AQ?.Function!) }} </span>
                    </span>
                    <!-- TODO: function return method an aus blinkend-->
                    <!--    <span> Active:<span class="fw-semibold  "> {{ parsedSettings.AQ?.Active}}</span></span> -->
                    <span *ngIf="parsedSettings?.AQ?.Function === 2"> Time:<span class="fw-semibold  "> {{
                            parsedSettings.AQ?.Time}}</span></span>
                    <span> Priorität: <span class="fw-semibold  "> {{ manualControl?.highPriority ? 'hoch' :
                            'niedrig'}}</span></span>
                </div>
            </div>
            <div *ngIf="parsedSettings?.AQ?.Code === 61" class="d-flex">
                <div class="me-2 p-2 mt-4 ">
                    <div *ngIf="parsedSettings.AQ?.Function !== 0" class="d-flex" style="width: 60px;">
                        <div class="p-3 rounded-5 border border-black"
                            style="background-color: yellow; height: 25px; width: 25px; margin-right: 4px;">
                        </div>
                        <div class="p-3 rounded-5 border border-black"
                            style="background-color: yellow; height: 25px; width: 25px">
                        </div>
                    </div>
                    <div *ngIf="parsedSettings?.AQ?.Function === 0" class="d-flex" style="width: 60px;">
                        <div class="p-3 rounded-5 border border-black"
                            style="background-color: white; height: 25px; width: 25px; margin-right: 4px;">
                        </div>
                        <div class="p-3 rounded-5 border border-black"
                            style="background-color: white; height: 25px; width: 25px">
                        </div>
                    </div>

                </div>
                <div class="d-flex flex-column mt-4">
                    <span> Funktion: <span class="fw-semibold  "> {{ parsedSettings.AQ?.Function}}</span></span>
                    <!--    <span> Active: <span class="fw-semibold  "> {{ parsedSettings.AQ?.Active}}</span></span> -->
                    <span *ngIf="parsedSettings.AQ?.Function === 2"> Time: <span class="fw-semibold  "> {{
                            parsedSettings.AQ?.Time}}</span></span>
                    <span> Priorität: <span class="fw-semibold  "> {{ manualControl?.highPriority ? 'hoch' :
                            'niedrig'}}</span></span>
                </div>

            </div>
        </div>

        <div *ngIf="parsedSettings?.MQ !== null" class="d-flex flex-column " matTooltipPosition="right"
            [matTooltip]="'VS: ' + getverkehrszustandString(parsedSettings.MQ?.VS!)+ ' - ' + '   Traffic: ' + getVerkehrsstufeString(parsedSettings.MQ?.Traffic!)">

            <span> VS: <span class="fw-semibold  ">{{ parsedSettings.MQ?.VS}}</span></span>
            <span> Traffic: <span class="fw-semibold  "> {{ parsedSettings.MQ?.Traffic}}</span></span>
            <!-- <span> Active: <span class="fw-semibold  ">{{ parsedSettings.MQ?.Active}}</span></span> -->
            <span> Priorität: <span class="fw-semibold  "> {{ manualControl?.highPriority ? 'hoch' :
                    'niedrig'}}</span></span>
        </div>
        <div *ngIf="parsedSettings?.AQ !== null || parsedSettings?.MQ !== null">
            <div *ngIf="parsedSettings?.AQ?.Code !== 61 && parsedSettings?.MQ === null">
                <button *canAccess="[1]" mat-icon-button #tooltip="matTooltip"
                    [matTooltip]="'Erstellt am: ' + createdAtFormatted + ' Bearbeitet am: ' + lastChangedAtFormatted + ' Bearbeitet von : ' + username "
                    matTooltipPosition="right"
                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
                    (click)="tooltip.toggle()" class="position-absolute" style="top: -21px;
        right: -22px;">
                    <img height="16" src="assets/icons/info-circle.svg">
                </button>
            </div>
            <div *ngIf="parsedSettings?.MQ !== null">
                <button *canAccess="[1]" mat-icon-button #tooltip="matTooltip"
                    [matTooltip]="'Erstellt am: ' + createdAtFormatted + ' Bearbeitet am: ' + lastChangedAtFormatted + ' Bearbeitet von : ' + username "
                    matTooltipPosition="right"
                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
                    (click)="tooltip.toggle()" class="position-absolute" style="top: -21px;
        right: -11px;">
                    <img height="16" src="assets/icons/info-circle.svg">
                </button>
            </div>
            <div *ngIf="parsedSettings?.AQ?.Code === 61  && parsedSettings?.MQ === null">
                <button *canAccess="[1]" mat-icon-button #matTooltipButton="matTooltip"
                    [matTooltip]="'Erstellt am: ' + createdAtFormatted + ' Bearbeitet am: ' + lastChangedAtFormatted + ' Bearbeitet von : ' + username "
                    matTooltipPosition="right"
                    aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
                    (click)="matTooltipButton.toggle()" class="position-absolute" style="top: -21px;
        right: -11px;">
                    <img height="16" src="assets/icons/info-circle.svg">
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #noConfig>
    <div class="d-flex w-100 h-100">
        <div class="d-flex h-100 w-100 justify-content-end align-content-center mt-2 me-2 fw-semibold ">
            nicht konfiguriert
        </div>
    </div>
</ng-template>