import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { DataEndChannelTypeEnum, getDataEndChannelTypeString } from "../../shared/enums/data-end-channel-type.enum";
import { ModalService } from "../../../shared/modal/shared/modal.service";
import { DataEndChannelService } from "../../shared/services/data-end-channel.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { MatDialog } from "@angular/material/dialog";
import { DataEndChannelEditModalComponent } from "../data-end-channel-edit-modal/data-end-channel-edit-modal.component";
import { DataEndChannelError } from "../../shared/interfaces/data-end-channel-error.interface";
import { DataEndChannelSettingsDialogComponent } from '../shared/data-end-channel-settings-dialog/data-end-channel-settings-dialog.component';
import { getLocalBusTypeString } from './local-bus-type.enum';
import { FallbackImageSettingsComponent } from '../shared/fallback-image-settings/fallback-image-settings.component';
import { Fallbacksettings } from '../shared/fallbacksettings';
import { DeviceService } from 'src/app/device/shared/services/device.service';
@Component({
  selector: 'avs-data-end-channel-table',
  templateUrl: './data-end-channel-table.component.html',
  styleUrls: ['./data-end-channel-table.component.css']
})
export class DataEndChannelTableComponent {
  @Input() dataEndChannels: DataEndChannel[] = [];
  @Input() dataEndChannelErrors: DataEndChannelError[] = [];
  @Input() routeStationGuid?: string;
  @Input() projectId?: number;
  @Input() channelAdded: boolean = false;
  @Output() dataEndChannelErrorsRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataEndChannelRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  public detecedChanges: boolean = false;
  protected readonly getDataEndChannelTypeString = getDataEndChannelTypeString;
  protected readonly getLocalBusTypeString = getLocalBusTypeString;
  constructor(private dataEndChannelService: DataEndChannelService,
    private alertService: AlertService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private deviceService: DeviceService) { }


  /* keine routeStations on reload, müssten nochmal manuell geladen werden */

  public onEditBtnClicked(dataEndChannel: DataEndChannel): void {
    const dialogRef = this.dialog.open(DataEndChannelEditModalComponent, { data: dataEndChannel });
    dialogRef.afterClosed().subscribe(() => {
      this.dataEndChannelRefresh.emit(true);
    });
  }
  public onDeleteBtnClicked(dataEndChannel: DataEndChannel): void {
    this.modalService.openDialog('Datenendkanal löschen?', 'Nein', 'Ja').subscribe(() => {
      this.dataEndChannelService.deleteDataEndChannel(dataEndChannel.id).subscribe(() => {
        this.alertService.alert('Datenendkanal wurde erfolgreich entfernt', AlertTypes.info);
        this.detecedChanges = true;
        this.dataEndChannelRefresh.emit(true);
      });
    });
  }

  public onRemoveTimerAssignment(deKanalId: number): void {
    this.modalService.openDialog('Zeitschaltuhr Zuweisung entfernen?', 'Nein', 'Ja').subscribe(() => {
      this.dataEndChannelService.deleteDataEndChannelTimerAssignment(deKanalId).subscribe(() => {
        this.alertService.alert('Zeitschaltuhr Zuweisung wurde erfolgreich entfernt', AlertTypes.info);
        this.detecedChanges = true;
        this.dataEndChannelRefresh.emit(true);
      });
    });
  }

  public onResetEvaClicked(): void {
    if (!this.routeStationGuid) { return; }
    this.deviceService.resetDevice(this.routeStationGuid, 0).subscribe(() => {
      this.alertService.alert("Eva Reset wurde ausgeführt", AlertTypes.info);
    });
  }

  public showFGSettings(dataEndChannel: DataEndChannel): void {
    let data;
    let fg: number = 0;
    let type = getDataEndChannelTypeString(dataEndChannel.dataEndChannelType);
    if (dataEndChannel.fG01_Settings.length > 2) { data = dataEndChannel.fG01_Settings; fg = 1; type = type }
    if (dataEndChannel.fG04_Settings.length > 2) { data = dataEndChannel.fG04_Settings; fg = 4; type = type }
    if (dataEndChannel.fG06_Settings.length > 2) { data = dataEndChannel.fG06_Settings; fg = 6; type = type }
    const dialogRef = this.dialog.open(DataEndChannelSettingsDialogComponent, { data: { data: data, fg: fg, guid: this.routeStationGuid, type: type, projectId: this.projectId } })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let engineParameter = '{"EngineParameter": ' + JSON.stringify(result.settings) + '}';
        if (fg === 1) {
          dataEndChannel.fG01_Settings = engineParameter;
        }
        if (fg === 4) {
          dataEndChannel.fG04_Settings = engineParameter;
        }
        if (fg === 6) {
          dataEndChannel.fG06_Settings = engineParameter;
        }
        this.dataEndChannelService.editDataEndChannel(dataEndChannel.id, dataEndChannel).subscribe((x) => { this.alertService.alert('Einstellungen wurden erfolgreich gespeichert', AlertTypes.info); this.dataEndChannelRefresh.emit(true); this.detecedChanges = true; });
      }

    })
  }

  public createFallbackSettings(dataEndChannel: DataEndChannel): void {
    const dialogRef = this.dialog.open(FallbackImageSettingsComponent, { data: { data: dataEndChannel, projectId: this.projectId } });
    dialogRef.afterClosed().subscribe((result: Fallbacksettings) => {
      if (!result) { return; }
      this.dataEndChannelService.setFallbackSettings(dataEndChannel.id, dataEndChannel.fg, dataEndChannel.de, { anzeigeprinzip: result.anzeigeprinzip, stellcode: result.stellcode, funktions: result.funktions }).subscribe(() => {
        this.alertService.alert('Fallback Einstellungen wurden erfolgreich gespeichert', AlertTypes.info);
        /*       this.dataEndChannelRefresh.emit(true); */
      });
    });
  }


  public getTypeIcon(type: DataEndChannelTypeEnum): string {
    switch (type) {
      case DataEndChannelTypeEnum.PW:
        return "assets/icons/data-end-channel/unknown settings.svg";
      case DataEndChannelTypeEnum.BLINKER:
        return "assets/icons/data-end-channel/blinker.svg";
      case DataEndChannelTypeEnum.CLUSTER:
        return "assets/icons/data-end-channel/unknown settings.svg";
      case DataEndChannelTypeEnum.LED:
        return "assets/icons/data-end-channel/led.svg";
      case DataEndChannelTypeEnum.RADAR:
        return "assets/icons/data-end-channel/radar.svg";
      case DataEndChannelTypeEnum.Unknown:
        return "assets/icons/data-end-channel/unknown settings.svg";
      default:
        return "assets/icons/data-end-channel/unknown settings.svg";
    }
  }

  /*  färbung muss mit antwort Not Found zurückgesetzt werden, damit der aktuelle stand angezeigt wird. Und nicht irgendein gemischter,

id vom dechannel mitgeben, find(), diesen dann slicen() und dann ohne id prüfen ob die daten iwo identisch sind?

  eine möglichkeit wäre, jeden channel den man erhält von dem array zu slicen und dann mit include prüfen?
  wenn das true zurück gibt, muss als duplicate angezeigt werden und dann SCHWARZZ
  
  */



  public isDuplicate(dataEndChannel: DataEndChannel): boolean {
    let dataEndChannels = this.dataEndChannels.filter(x => x.id !== dataEndChannel.id);
    for (let channel of dataEndChannels) {
      if (channel.de === dataEndChannel.de && channel.fg === dataEndChannel.fg && channel.id !== dataEndChannel.id) {
        return true;
      }
    }
    return false;
  }


  public getChannelErrorString(dataEndChannel: DataEndChannel): string {

    /* wenn keine geladen werden, weil gerät offline stehen alle auf unbekannt */
    let isDuplicate = this.isDuplicate(dataEndChannel);
    if (!this.dataEndChannelErrors) { return 'Unbekannt'; }
    for (let error of this.dataEndChannelErrors) {
      if (error.de === dataEndChannel.de && error.fg === dataEndChannel.fg) {
        if (error.error) {
          return 'Fehler';
        }


        else {
          return 'keine Fehler';
        }
      }
    } if (isDuplicate) {
      return 'Duplikat';
    }
    else
      return 'Unbekannt';
  }

  public getChannelStateClassString(dataEndChannel: DataEndChannel): string {
    let isDuplicate = this.isDuplicate(dataEndChannel);
    if (!this.dataEndChannelErrors) { return 'avs-state-secondary'; }
    for (let error of this.dataEndChannelErrors) {
      if (error.de === dataEndChannel.de && error.fg === dataEndChannel.fg) {
        if (error.error) {
          return 'avs-state-danger';
        } else {
          return 'avs-state-success';
        }
      }
    } if (isDuplicate) {
      return 'avs-state-error';
    } else
      return 'avs-state-secondary';
  }
}
