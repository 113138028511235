import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { ProjectGroupMetaInformation } from '../interfaces/project-group-meta-information';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetaInformationService {

  constructor(private readonly httpService: HttpService) { }

  public postLineDescription(groupId: number, x: number, y: number, description: string, descriptionType: number): Observable<ProjectGroupMetaInformation> {
    let body = { groupId: groupId, x: x, y: y, description: description, descriptionType: descriptionType }
    return this.httpService.post<ProjectGroupMetaInformation>('MetaInformation', body);
  }

  public editProjectGroup(id: number, groupId: number, x: number, y: number, description: string): Observable<ProjectGroupMetaInformation> {
    let body = { id: id, groupId: groupId, x: x, y: y, description: description }
    return this.httpService.put<ProjectGroupMetaInformation>('MetaInformation/' + id, body);
  }

  public deleteMetaInformationById(id: number): Observable<any> {
    return this.httpService.delete('MetaInformation/' + id);
  }

  public getMetaInformationByGroupId(groupId: number): Observable<any> {
    return this.httpService.get('Metainformation/' + groupId)
  }
}
