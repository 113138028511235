import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'avs-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css']
})
export class TimeInputComponent {
  @Input() label: string | undefined;
  @Input() value: string | number = "";
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() isDateString: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()
  
  public onValueChange(event: any): void {
    this.valueChange.emit(event.target.value);
  }
}
