import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ProjectMetaData, ProjectMetaDataTypes} from "../shared/interfaces/project-meta.interface";
import {ProjectPositionData} from "../shared/interfaces/project-position.interface";
import {AlertService} from "../../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../../shared/alert/shared/alert-types";
import {ProjectService} from "../../shared/services/project.service";
import {Project} from "../../shared/interfaces/project.interface";

@Component({
  selector: 'avs-project-settings-create-modal',
  templateUrl: './project-settings-create-modal.component.html',
  styleUrls: ['./project-settings-create-modal.component.css']
})
export class ProjectSettingsCreateModalComponent {
  protected readonly ProjectMetaDataTypes = ProjectMetaDataTypes;
  public projectMetaData: ProjectMetaData =
    {name: "", timeSlotFrom: "", timeSlotTo: "", notes: "", isActiveChange: true};
  public projectPositionData: ProjectPositionData = {latitude: undefined, longitude: undefined};
  public modalPage: number = 1;

  constructor(public dialogRef: MatDialogRef<ProjectSettingsCreateModalComponent>,
              private alertService: AlertService,
              private projectService: ProjectService) {}

  public onMetaValueChange(value: any, type: ProjectMetaDataTypes): void {
    switch (type) {
      case ProjectMetaDataTypes.name:
        this.projectMetaData.name = value;
        break;
      case ProjectMetaDataTypes.timeSlotFrom:
        this.projectMetaData.timeSlotFrom = value;
        break;
      case ProjectMetaDataTypes.timeSlotTo:
        this.projectMetaData.timeSlotTo = value;
        break;
      case ProjectMetaDataTypes.notes:
        this.projectMetaData.notes = value;
        break;
      case ProjectMetaDataTypes.isActive:
        this.projectMetaData.isActiveChange = value.checked;
        break;
    }
  }

  public onPositionValueChange(value: string, isLatitude: boolean):void {
    if (isLatitude) {
      this.projectPositionData.latitude = +value;
    } else {
      this.projectPositionData.longitude = +value;
    }
  }

  public onCancelBtnClicked(): void {
    this.dialogRef.close();
  }

  public onFurtherBtnClicked(): void {
    if (!this.projectMetaData.name) {
      this.alertService.alert('Projekt Name ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    if (!this.projectMetaData.timeSlotFrom) {
      this.alertService.alert('Datum von ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    } else  {
      if (! /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/.test(this.projectMetaData.timeSlotFrom)) {
        this.alertService.alert('Datum von nicht korrekt (dd.mm.yyyy)', AlertTypes.warning, 3000);
        return;
      }
    }
    if (!this.projectMetaData.timeSlotTo) {
      this.alertService.alert('Datum bis ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    } else {
      if (! /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/.test(this.projectMetaData.timeSlotTo)) {
        this.alertService.alert('Datum bis nicht korrekt (dd.mm.yyyy)', AlertTypes.warning, 3000);
        return;
      }
    }
    this.modalPage ++;
  }

  public onBackBtnClicked(): void {
    this.modalPage --;
  }

  public onSaveBtnClicked(): void {
    if (!this.projectPositionData.latitude) {
      this.alertService.alert('Latitude ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    if (!this.projectPositionData.longitude) {
      this.alertService.alert('Longitude ist ein Pflichtfeld', AlertTypes.warning, 3000);
      return;
    }
    this.createProject();
  }

  private createProject(): void {
    let newProject = {
      name: this.projectMetaData.name,
      from: new Date(this.projectMetaData.timeSlotFrom.slice(3, 5) + '/' +
          this.projectMetaData.timeSlotFrom.slice(0, 2) + '/' +
          this.projectMetaData.timeSlotFrom.slice(6)).toISOString(),
      to: new Date(this.projectMetaData.timeSlotTo.slice(3, 5) + '/' +
          this.projectMetaData.timeSlotTo.slice(0, 2) + '/' +
          this.projectMetaData.timeSlotTo.slice(6)).toISOString(),
      notes: this.projectMetaData.notes,
      isActive: this.projectMetaData.isActiveChange,
      latitude: this.projectPositionData.latitude,
      longitude: this.projectPositionData.longitude,
        isDeleted: false
    } as Project
    this.projectService.createNewProject(newProject).subscribe(newProject => {
      this.alertService.alert('neues Projekt "' + newProject.name + '" erstellt.', AlertTypes.success);
      this.dialogRef.close(true);
    });
  }
}
