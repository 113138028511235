<ng-container>
    <div *ngIf="routeStation">
        <div class="d-flex flex-column m-2">
            <span class="text-bg-danger ps-2">Name: {{ routeStation.routeStation.name}} </span>
            <span class="text-center "> SAM ID: {{ routeStation.routeStation.samId}} </span>
        </div>

        <div *ngFor="let kanal of routeStation?.routeStation?.dataEndChannels">
            <div *ngIf="kanal.fg === 4 && kanal.localbusAddress === 6 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">
                <!-- Blinker -->
                <div class="d-flex bg-light m-2 border border-2 border-secondary" style="cursor: pointer;">
                    <ng-container>
                        <div class="d-flex justify-content-between bg-light m-2">
                            Blinker
                        </div>
                    </ng-container>

                    <avs-manual-control-result class="w-100" [scenarioManual]="getProperScenarioManual(kanal.id)"
                        [users]="users"></avs-manual-control-result>
                </div>
            </div>

            <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->
            <div *ngIf="kanal.fg === 1 && kanal.localbusAddress === 11 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)"> <!-- Sensoren -->
                <div class="d-flex justify-content-between bg-light m-2 border border-2 border-secondary"
                    style="cursor: pointer;">
                    <div class="d-flex flex-column p-1" style="width: 95px; white-space: nowrap;">
                        <span class="">Smart Micro</span>
                        <span>{{ kanal.de === 1 ? 'Hauptfahrspur' : (kanal.de-1 + ' .Überholspur')}}</span>
                    </div>

                    <avs-manual-control-result [scenarioManual]="getProperScenarioManual(kanal.id)"
                        [users]="users"></avs-manual-control-result>
                </div>
            </div>
            <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->

            <div *ngIf="kanal.fg === 1 && kanal.localbusAddress === 3 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">

                <div class="d-flex bg-light m-2 border border-2 border-secondary justify-content-between"
                    style="cursor: pointer;">
                    <div class="d-flex flex-column p-1" style="width: 95px; white-space: nowrap;">
                        <div *ngIf="kanal.de === 1 || kanal.de === 2" class="d-flex flex-column">
                            <span>VDE-Sensor</span>
                            <span>{{ kanal.de === 1 ? 'Hauptfahrspur' : (kanal.de-1 + ' .Überholspur')}}</span>
                        </div>
                        <div *ngIf="kanal.de > 159" class="d-flex flex-column">
                            <span>ASIM</span>
                            <span>ADEC</span>
                            <span>{{ kanal.de === 160 ? 'Hauptfahrspur' : (kanal.de-160 + ' .Überholspur')}}</span>
                        </div>
                    </div>


                    <avs-manual-control-result [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users">
                    </avs-manual-control-result>
                </div>
            </div>

            <div *ngIf="kanal.fg === 4 &&( kanal.localbusAddress === 2 || kanal.localbusAddress === 1 )&& kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)">
                <div class="d-flex  bg-light m-2 border border-2 border-secondary"
                    style="cursor: pointer;">
                    <div class="p-2  position-relative">
                        Anzeige
                    </div>
                    <avs-manual-control-result [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users"
                        [images]="images"></avs-manual-control-result>
                </div>
            </div>

            <div *ngIf="kanal.fg === 4 && kanal.localbusAddress === 8 && kanal.de < 190"
                (click)="onOpenComponentClicked(kanal)" style="cursor: pointer;">
                <div class="d-flex justify-content-between  bg-light m-2 border border-2 border-secondary"
                    style="cursor: pointer;">
                    <div class="p-2">
                        Anzeige
                    </div>
                    <avs-manual-control-result style="margin-right: 13px;"
                        [scenarioManual]="getProperScenarioManual(kanal.id)" [users]="users"
                        [images]="images"></avs-manual-control-result>
                </div>
            </div>
        </div>
    </div>
</ng-container>