<div *ngIf="projectGroup !== undefined">
  <div *ngIf="projectRouteStations[0]; else noRouteStationsAssigned" class="row m-0">
    <ng-container *ngFor="let routeStation of projectRouteStations">
      <div class="card rounded-0 me-3 mt-4 shadow p-3 avs-project-station-card d-flex justify-content-center">
        <avs-project-route-station [routeStation]="routeStation" [images]="projectImages"></avs-project-route-station>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="projectGroup === undefined">
  <p class="mt-3 fw-semibold text-secondary">Keine Gruppe ausgewählt!</p>
</div>
<ng-template #noRouteStationsAssigned>
  <p class="mt-3 fw-semibold text-secondary">Dieser Gruppe wurden keine RouteStations zugewiesen!</p>
</ng-template>