import { Component } from '@angular/core';

@Component({
  selector: 'avs-report-overview-new',
  templateUrl: './report-overview-new.component.html',
  styleUrls: ['./report-overview-new.component.css']
})

export class ReportOverviewNewComponent {

}
