import { Component } from '@angular/core';

@Component({
  selector: 'avs-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent {

}
