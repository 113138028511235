import { Injectable } from "@angular/core";
import { ProjectGroup } from "../interfaces/project-group.interface";
import { Observable } from "rxjs";
import { ProjectRouteStation } from "../interfaces/project-route-station.interface";
import { HttpService } from "../../../core/shared/http.service";
import { AnimatedImages } from "../interfaces/animated-images";
import { AnimatedImageErrorMessage } from "../../project-images/shared/animated-image-error-message";

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    constructor(private readonly httpService: HttpService) { }
    public createNewProjectGroup(group: ProjectGroup): Observable<ProjectGroup> {
        return this.httpService.post('Group', group);
    }

    public getProjectGroup(groupId: number): Observable<ProjectGroup> {
        return this.httpService.get<ProjectGroup>('Group/' + groupId);
    }

    public getAllProjectGroups(): Observable<ProjectGroup[]> {
        return this.httpService.get<ProjectGroup[]>('Group/');
    }

    public editProjectGroup(groupId: number, group: ProjectGroup): Observable<ProjectGroup> {
        return this.httpService.put('Group/' + groupId, group);
    }

    public deleteProjectGroup(groupId: number): Observable<any> {
        return this.httpService.delete('Group/' + groupId);
    }

    public getProjectRouteStations(groupId: number): Observable<ProjectRouteStation[]> {
        return this.httpService.get<ProjectRouteStation[]>('Group/' + groupId + '/RouteStation');
    }

    public editProjectRouteStations(groupId: number, addStationIds: string[], deleteStationIds: string[]): Observable<[]> {
        return this.httpService.patch<[]>('Group/' + groupId + '/RouteStation', { 'add': addStationIds, 'delete': deleteStationIds });
    }

    public getProjectGroupsDataEndChannels(groupId: number): Observable<any> {
        return this.httpService.get('Group/' + groupId + '/DataEndChannel');
    }

    public generateAnimatedImage(groupId: number, images: AnimatedImages[], stellcode: number): Observable<any> {
        return this.httpService.post('Group/' + groupId + '/AnimatedImages?stellcode=' + stellcode, images);
    }

    public deleteStellcodeByGroupId(groupId: number, stellcode: number): Observable<any> {
        return this.httpService.delete('Group/' + groupId + '/RemoveStellcode?stellcode=' + stellcode);
    }

    public createManualControl(groupId: number, z: number, vs: number, name: string, highPriority: boolean): Observable<any> {
        if (vs === 0) {
            return this.httpService.post('Group/' + groupId + '/ManualControl?z=' + z + '&vs=' + 'null' +'&name=' + name + '&highPriority=' + highPriority, null);
        }
        else {
            return this.httpService.post('Group/' + groupId + '/ManualControl?z=' + z + '&vs=' + vs + '&name=' + name + '&highPriority=' + highPriority, null);



        }
    }
}
