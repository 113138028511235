import { Component, OnDestroy, OnInit } from '@angular/core';
import { Project } from "../shared/interfaces/project.interface";
import { ProjectDataService } from "../shared/services/project-data.service";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadModalComponent } from './shared/file-upload-modal/file-upload-modal.component';
import { AttachmentService } from '../shared/services/attachment.service';
import { ProjectService } from '../shared/services/project.service';
/* import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; */
import { environment } from "../../../environments/environment";
import { Attachment } from './shared/interface/attachment.interface';
@Component({
  selector: 'avs-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.css']
})
export class ProjectDocumentsComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public attachments: Attachment[] = [];
  private subscriptionCollection: Subscription[] = [];
  private activeProjectSubscription?: Subscription;
  protected readonly environment = environment;
  constructor(private projectDataService: ProjectDataService,
    private dialog: MatDialog,
    private attachmentService: AttachmentService,
    private projectService: ProjectService
  ) {
    this.activeProjectSubscription = this.projectDataService.activeProject.subscribe(project => {
      this.activeProject = project;
      if (this.activeProject !== undefined) this.getAllAttachments()
    });
  }

  public onUploadFileClicked(): void {
    const dialogRef = this.dialog.open(FileUploadModalComponent, {
      data: this.activeProject,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllAttachments();
      }

    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeProjectSubscription!);
  }
  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  private getAllAttachments(): void {
    this.projectService.getProjectsAttachments(this.activeProject?.id!).subscribe(attachments => {
      this.attachments = attachments;
      this.activeProject!.attachments = attachments;
    })
  }

  public getBlob(attachment: Attachment) {
    this.attachmentService.getBlob(attachment.id).subscribe((res: any) => {
      const blob = new Blob([res], { type: attachment.mimeType });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = attachment.name; /* name übernehmen */
      document.body.appendChild(a);
      a.click();
    })
  }

  public onDeleteAttachment(attachment: Attachment) {
    this.attachmentService.deleteAttachment(attachment.id).subscribe(() => {
      this.getAllAttachments();
    })
  }
}
