import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectTimeSwitchErrorMessageInterface } from '../shared/interfaces/project-time-switch-error-messages.interface';
import { Project } from '../../shared/interfaces/project.interface';
import { ProjectGroup } from '../../shared/interfaces/project-group.interface';
import { ProjectDataService } from '../../shared/services/project-data.service';
import { ProjectRouteStation } from '../../shared/interfaces/project-route-station.interface';

@Component({
  selector: 'avs-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public routeStation?: ProjectRouteStation
  public errorObjects: ProjectTimeSwitchErrorMessageInterface[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    private projectDataService: ProjectDataService) {
    this.projectDataService.activeProject.subscribe((project) => {
      this.activeProject = project;
    });
    this.projectDataService.activeGroup.subscribe((group) => {
      this.activeGroup = group;
    });
  }

  public ngOnInit(): void {
    this.convertErrorMessage()
  }

  private convertErrorMessage(): void {
    let guids = Object.keys(this.data)
    for (let index = 0; index < guids.length; index++) {
      let guid = Object.keys(this.data)[index]
      let error = { guids: Object.keys(this.data)[index], AQerror: this.data[guid].AQerror, MQerror: this.data[guid].MQerror, Flasherror: this.data[guid].Flasherror, Routestationerror: this.data[guid].Routestationerror, Swaerror: this.data[guid].Swaerror, samId: this.getSamIdbyGuid(guid) }
      this.errorObjects.push(error)
    }
  }
  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  private getSamIdbyGuid(guid: string) {
    this.routeStation = this.activeGroup?.routeStations.find(x => x.id === guid)
    return this.routeStation?.samId
  }
}
