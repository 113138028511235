<div class="position-relative " style=" height: 24px">
    <img *ngIf="description === '' && editActive" src="assets/icons/plus.svg" alt="add-line-description"
        (click)="changeActiveStatus()" class="avs-line-add">
    <div *ngIf="description === ''">
        <p (click)="changeActiveStatus()" *ngIf="!componentActive && y === 1"
            style="margin: unset; padding: unset; height: 24px"> Linie {{x}}</p>
    </div>
    <div *ngIf="description !== ''">{{ description}}</div>
    <input *ngIf="componentActive" type="text" (keyup.enter)="onEnter(description)" class="form-control rounded-0"
        [(ngModel)]="description"> <img *ngIf="componentActive" (click)="stopEditing()"
        class="text-danger position-absolute avs-line-close " style="top: 0; right: 4px" src="assets/icons/x.svg">
    <button *ngIf="description !== '' && componentActive" (click)="onEnter(description)" type="button"
        class="btn btn-sm btn-outline-success position-absolute" style="top: 28px; right: 5px"> Ok</button>
    <img *ngIf="id && editActive" (click)="deleteDescription()" class="text-danger position-absolute avs-line-close "
        style="top: 0; right: 4px" src="assets/icons/x.svg">

    <div *ngIf="!isBlocked" class="position-absolute w-100 " style="top: 61px; left: 0px;">

        <div *ngIf="description !== '' && componentActive"
            class="w-100 avs-route-station-settings-dropdown-container justify-content-between bg-white shadow mb-2">
            <div [ngClass]="isDescriptionTypeDropdownOpen ? 'border-danger' : ''"
                class="border d-flex justify-content-between" role="button"
                (click)="onDescriptionTypeDropdownClicked()">
                <div class="ms-2 p-1">{{ selectedDescriptionType.name }}</div>
                <img *ngIf="!isDescriptionTypeDropdownOpen" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isDescriptionTypeDropdownOpen" src="assets/icons/caret-up.svg" alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="metaInformationTypeSelect" [activeItem]="selectedDescriptionType"
                (onItemClicked)="onDescriptionTypeClicked($event)" [isHeaderActive]="false"
                *ngIf="isDescriptionTypeDropdownOpen" class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>

    <div class="position-absolute w-100" style="top: 145px;">
        <img *ngIf="descriptionTypeId === 4" src="assets/piktogramme/ausfahrt_autobahn.svg" style="height: 120px">
        <img *ngIf="descriptionTypeId === 5"src="assets/piktogramme/ausfahrt_bundesstrasse.svg" style="height: 120px">
        <img *ngIf="descriptionTypeId === 6"src="assets/piktogramme/autobahnkreuz.svg" style="height: 120px">
        <img *ngIf="descriptionTypeId === 1"src="assets/piktogramme/parkplatz.svg" style="height: 120px">
    </div>
</div>