<h5 class="text-secondary p-2 ms-1 me-1 border-2 border-bottom text-center">Gruppe erstellen</h5>
<div *ngIf="modalPage === 1" class="m-2">
    <div class="d-flex">
        <avs-text-input class="me-1" label="Name" [value]="metaData.name ? metaData.name : ''"
            (valueChange)="onMetaValueChange($event, GroupMetaDataTypes.name)" [required]="true"
            placeholder="Gruppen Name...">
        </avs-text-input>
        <avs-text-input class="ms-1" label="Straße"
            (valueChange)="onMetaValueChange($event, GroupMetaDataTypes.routeName)"
            [value]="metaData.routeName ? metaData.routeName : ''" placeholder="Straße...">
        </avs-text-input>
    </div>

    <div class="d-flex mt-3">
        <div class="w-100">
            <div class="ms-2 mb-1 me-1">Gruppentyp</div>
            <div [ngClass]="isGroupTypesSelectOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onGroupTypeSelectClicked()">
                <div [ngClass]="metaData.groupType === undefined ? 'ms-2 p-1 text-secondary' : 'ms-2 p-1'">
                    {{metaData.groupType === undefined ? 'auswählen...' : getGroupTypeString(metaData.groupType ?
                    metaData.groupType : 0)}}
                </div>
                <img *ngIf="!isGroupTypesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isGroupTypesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown *ngIf="isGroupTypesSelectOpen" [isHeaderActive]="false" [activeItem]="metaData.groupType? {name: getGroupTypeString(metaData.groupType), id: metaData.groupType} :
                                metaData.groupType === 0 ? {name: getGroupTypeString(0), id: 0} : {name: ''}"
                (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.groupType)"
                [selectInput]="groupTypeSelect">
            </avs-dropdown>
        </div>
        <div class="w-100 ms-1">
            <div class="ms-2 mb-1">Diagrammtyp</div>
            <div [ngClass]="isDiagramTypesSelectOpen ? 'border-danger' : ''"
                class="border d-flex justify-content-between" role="button" (click)="onDiagramTypeSelectClicked()">
                <div [ngClass]="metaData.diagramType === undefined ? 'ms-2 p-1 text-secondary' : 'ms-2 p-1'">
                    {{metaData.diagramType === undefined ? 'auswählen...' : getDiagramTypeString(metaData.diagramType ?
                    metaData.diagramType : 0)}}
                </div>
                <img *ngIf="!isDiagramTypesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isDiagramTypesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown *ngIf="isDiagramTypesSelectOpen"
                (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.diagramType)" [isHeaderActive]="false"
                [activeItem]="metaData.diagramType ? {name: getDiagramTypeString(metaData.diagramType), id: metaData.diagramType} :
                                metaData.diagramType === 0 ? {name: getDiagramTypeString(0), id: 0} : {name: ''}"
                [selectInput]="diagramTypeSelect">
            </avs-dropdown>
        </div>
    </div>

    <div class="d-flex mt-3">
        <div class="w-100">
            <div class="ms-2 mb-1 me-1">Fahrbahn Regeln</div>
            <div [ngClass]="isTrafficRulesSelectOpen ? 'border-danger' : ''"
                class="border d-flex justify-content-between" role="button" (click)="onTrafficRulesSelectClicked()">
                <div [ngClass]="metaData.trafficRules === undefined ? 'ms-2 p-1 text-secondary' : 'ms-2 p-1'">
                    {{metaData.trafficRules === undefined ? 'auswählen...' : getTrafficRulesString(metaData.trafficRules
                    ? metaData.trafficRules : 0)}}
                </div>
                <img *ngIf="!isTrafficRulesSelectOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isTrafficRulesSelectOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown *ngIf="isTrafficRulesSelectOpen"
                (onItemClicked)="onMetaValueChange($event, GroupMetaDataTypes.trafficRules)" [isHeaderActive]="false"
                [activeItem]="metaData.trafficRules ? {name: getTrafficRulesString(metaData.trafficRules), id: metaData.trafficRules} :
                                metaData.trafficRules === 0 ? {name: getTrafficRulesString(0), id: 0} : {name: ''}"
                [selectInput]="trafficRulesSelect">
            </avs-dropdown>
        </div>
        <div class="w-100 me-2">
            <div class="ms-2 mb-1 ">Fahrbahn Spuren (1-10)</div>
            <input type="number" style="height: 34px; border: 1px solid rgb(222, 226, 230);" class="ms-2 w-100" min="1" max="10" [(ngModel)]="trafficLanes">
        </div>
    </div>
    <div class="d-flex mt-3">
        <mat-slide-toggle class="mt-auto me-1" color="primary" [checked]="metaData.isActive ? metaData.isActive : false"
            (change)="onMetaValueChange($event, GroupMetaDataTypes.isActive)">
            Aktiv
        </mat-slide-toggle>
    </div>
</div>
<div *ngIf="modalPage === 2" class="m-2">
    <div class="d-flex">
        <avs-text-input class="me-1" label="Latitude" [value]="positionData.latitude ? positionData.latitude.toLocaleString(
                    'en-EN', {minimumFractionDigits: 14}) : ''" (valueChange)="onPositionValueChange($event, true)"
            [required]="true" placeholder="breitengrad...">
        </avs-text-input>
        <avs-text-input class="ms-1" label="Longitude" [value]="positionData.longitude ? positionData.longitude.toLocaleString(
                    'en-EN', {minimumFractionDigits: 14}) : ''" (valueChange)="onPositionValueChange($event, false)"
            [required]="true" placeholder="längengrad...">
        </avs-text-input>
    </div>
</div>
<div class="p-2 mt-3 ms-1 me-1 border-2 border-top">
    <p *ngIf="modalPage === 1" class="text-center text-secondary">Gruppen Meta Daten</p>
    <p *ngIf="modalPage === 2" class="text-center text-secondary">Gruppen Positions Daten</p>
    <div class="d-flex justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" type="button"
            (click)="onCancelBtnClicked()">Abbrechen</button>
        <button *ngIf="modalPage === 1" class="btn btn-sm btn-outline-success rounded-0" type="button"
            (click)="onFurtherBtnClicked()">Weiter</button>
        <div *ngIf="modalPage === 2">
            <button class="btn btn-sm btn-outline-secondary me-2 rounded-0" type="button"
                (click)="onBackBtnClicked()">Zurück</button>
            <button class="btn btn-sm btn-outline-success rounded-0" type="button"
                (click)="onSaveBtnClicked()">Speichern</button>
        </div>
    </div>
</div>