import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectRouteStation } from 'src/app/project/shared/interfaces/project-route-station.interface';

@Component({
  selector: 'avs-animated-images-error-dialog',
  templateUrl: './animated-images-error-dialog.component.html',
  styleUrls: ['./animated-images-error-dialog.component.css']
})
export class AnimatedImagesErrorDialogComponent {

  public errors: ProjectRouteStation[] = [];
  public successes: ProjectRouteStation[] = [];

  constructor(private dialogRef: MatDialogRef<AnimatedImagesErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.errors = data.errors;
    this.successes = data.successes;
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

}
