import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'avs-new-map-jsonp-loading',
  templateUrl: './new-map-jsonp-loading.component.html',
  styleUrls: ['./new-map-jsonp-loading.component.css']
})
export class NewMapJsonpLoadingComponent {
  apiLoaded: Observable<boolean>;


/*   importieren HttpClientJsonpModule ? */

  constructor(httpClient: HttpClient) {
    this.apiLoaded = httpClient
      .get( 
        'https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY', /* wieder anpassen, siehe stackblitz  && apikey */
        { responseType: 'text' }
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public ngOnInit(): void {
/*     this.apiLoaded = httpClient
    .get(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBcos3aEaS6CkhPm_2YPTrbv3f1XUH6Hf0',
      'callback'
    )
    .pipe(
      map(() => true),
      catchError(() => of(false))
    ); */
  }

  isLoaded(){
  }

  options: google.maps.MapOptions = {
    center: {
      lat: 51.508742,
      lng: -0.12085,
    },
    zoom: 4,

    // Before intial load, options complains  "google is not defined"
    // Uncomment out the options 
    /* 
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.LARGE,
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    */

    streetViewControl: false,
    mapTypeControl: false,
  };

  /* 
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };
  */
  markerPositions: google.maps.LatLngLiteral[] = [];

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions.push(event.latLng!.toJSON());
  }
}
