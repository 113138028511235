import { NgModule } from "@angular/core";
import { DeviceOverviewComponent } from './device-overview/device-overview.component';
import {MatButtonModule} from "@angular/material/button";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {ProjectModule} from "../project/project.module";
import { DeviceMenuComponent } from './device-menu/device-menu.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { DeviceTestAreaComponent } from './device-test-area/device-test-area.component';
import { TestAreaBrightnessComponent } from '../project/project-test-area/test-area-brightness/test-area-brightness.component';
import {SharedModule} from "../shared/shared.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { TestAreaImageSettingsComponent } from '../project/project-test-area/test-area-image-settings/test-area-image-settings.component';
import { TestAreaAdditionalSettingsComponent } from '../project/project-test-area/test-area-additional-settings/test-area-additional-settings.component';
import { TestAreaDigitalOutputsComponent } from '../project/project-test-area/test-area-digital-outputs/test-area-digital-outputs.component';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { DeviceRouteStationComponent } from './device-route-station/device-route-station.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { DeviceNotesComponent } from './device-notes/device-notes.component';
import { DatePipe } from "@angular/common";

@NgModule({

  declarations: [
      DeviceOverviewComponent,
      DeviceMenuComponent,
      DeviceTestAreaComponent,
      DeviceSettingsComponent,
      DeviceRouteStationComponent,
      DeviceNotesComponent
  ],
    imports: [
        MatButtonModule,
        NgIf,
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        ProjectModule,
        NgForOf,
        ReactiveFormsModule,
        FormsModule,
        NgClass,
        SharedModule,
        MatSlideToggleModule,
        MatTooltipModule,
        DatePipe
    ],
  exports: [],
  providers: [],
})
export class DeviceModule { }
