import {Injectable} from "@angular/core";
import {AlertTypes} from "./alert-types";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AlertComponent} from "../alert.component";

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private matAlert: MatSnackBar,) {
    }
    public alert(message: string, type: AlertTypes, duration: number = 2000) {
        this.matAlert.openFromComponent(AlertComponent, {
            duration: duration,
            data: {'message': message, 'type': type}
        });
    }
}
