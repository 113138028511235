import { Injectable } from '@angular/core';
import {HttpService} from "../../../core/shared/http.service";
import {UserDataResponse} from "../../../user/shared/interfaces/login-data-response.interface";
import {Observable} from "rxjs";
import {CompanyData} from "../interfaces/company-data.interface";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpService: HttpService) {}

  public createCompany(newCompanyData: CompanyData): Observable<CompanyData> {
    return this.httpService.post<CompanyData>("Company", newCompanyData)
  }

  public getCompanys(): Observable<CompanyData[]> {
    return this.httpService.get<CompanyData[]>("Company")
  }

  public getCompany(companyId: number): Observable<CompanyData> {
    return this.httpService.get<CompanyData>("Company/" + companyId)
  }

  public deleteCompany(companyId: number): Observable<any> {
    return this.httpService.delete<any>("Company/" + companyId)
  }

  public updateCompany(companyId: number, newCompanyData: CompanyData): Observable<CompanyData> {
    return this.httpService.put<CompanyData>("Company/" + companyId, newCompanyData)
  }

}
