import { Component } from '@angular/core';

@Component({
  selector: 'avs-faq-overview',
  templateUrl: './faq-overview.component.html',
  styleUrls: ['./faq-overview.component.css']
})
export class FaqOverviewComponent {

}
