<div class="p-4 d-flex">
    <avs-text-input placeholder="Suchen" (valueChange)="onSearchValueChange($event)"></avs-text-input>
    <img class="avs-select-close-icon ms-3" alt="select close icon"
         src="assets/icons/x.svg" (click)="onCloseBtnClicked()">
</div>
<p class="text-danger ms-2" *ngIf="!getFilteredInput()[0] && selectInput[0]">keine Station gefunden.</p>
<avs-dropdown (onItemClicked)="onItemClicked($event)"
              [selectInput]="getFilteredInput()"
              [isHeaderActive]="false">
</avs-dropdown>
