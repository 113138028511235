import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CheckboxDropdownItem} from "./shared/checkbox-dropdown-item.interface";

@Component({
  selector: 'avs-checkbox-dropdown',
  templateUrl: './checkbox-dropdown.component.html',
  styleUrls: ['./checkbox-dropdown.component.css']
})
export class CheckboxDropdownComponent implements OnChanges{
  @Input() isSelectionAsTitle: boolean = false;
  @Input() isMultiSelect: boolean = true;
  @Input() title?: string
  @Input() checkboxDropdownItems: CheckboxDropdownItem[] = [];
  @Input() styleTitleDiv: string = '';
  @Input() styleItemsDiv: string = '';
  @Input() isMultiSelectChecked: boolean = false;
  @Output() valueChange: EventEmitter<CheckboxDropdownItem[]> = new EventEmitter<CheckboxDropdownItem[]>();
  @Output() isDropdownOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isDropdownOpen: boolean = false;

  constructor(private readonly cdr: ChangeDetectorRef) {
  }
  public ngOnChanges(): void {
    this.cdr.detectChanges();
    this.isMultiSelectChecked = false;
  }

  public onSelectDropdownClicked(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.isDropdownOpenChanged.emit(this.isDropdownOpen);
  }

  public onMultiSelectChanged(): void {
    for (let item of this.checkboxDropdownItems) {
      item.checked = this.isMultiSelectChecked;
    }
    this.valueChange.emit(this.checkboxDropdownItems);
  }

  public onItemSelectChanged(): void {
    this.valueChange.emit(this.checkboxDropdownItems);
    this.isMultiSelectChecked = false;
  }
}
