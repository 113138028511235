<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Stellcode: {{data.stellcode}}</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg"
             alt="close-modal-icon" (click)="onCloseModalClicked()">
    </div>
    <div class="d-flex justify-content-around p-4">
        <div class="p-3">
            <div class="d-flex justify-content-center">
              <img [src]="environment.apiAddress + data.path">
            </div>
        </div>
        <div class="p-3" *ngIf="deviceParameter?.online">
            <div [ngClass]="isKanalSelectOpen ? 'border-danger' : ''"
                 class="border d-flex justify-content-between avs-project-image-kanal-select"
                 role="button" (click)="onKanalSelectClicked()">
                <div class="ms-2 p-1 text-black">{{selectedKanal ? selectedKanal.name : 'Kanal auswählen'}}</div>
                <img *ngIf="!isKanalSelectOpen" class="me-2" src="assets/icons/caret-down.svg" alt="caret-down open select">
                <img *ngIf="isKanalSelectOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
            </div>
            <avs-dropdown *ngIf="isKanalSelectOpen"
                          class="position-absolute avs-project-image-kanal-select"
                          [isHeaderActive]="false"
                          [activeItem]="selectedKanal"
                          (onItemClicked)="onKanalSelectValueChange($event)"
                          [selectInput]="kanalSelectInput">
            </avs-dropdown>
        </div>
    </div>
    <div class="d-flex justify-content-between mt-5 justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseModalClicked()">Abbrechen</button>
        <button class="btn btn-sm btn-secondary rounded-0" (click)="onDeleteImageClicked()">Bild entfernen</button>
        <button *ngIf="deviceParameter?.online" class="btn btn-sm btn-outline-success rounded-0" (click)="onSetImageClicked()">Bild Setzen</button>
    </div>
</mat-dialog-content>
