<mat-dialog-content class="overflow-hidden ">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Digital Input 1 und 2 von SAM ID:{{ samId }}</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseAddTimeSwitchClicked()">
    </div>
    <div class="d-flex justify-content-center align-items-center mb-4">
        Von vor
        <input class="ms-1 me-1 form-control rounded-0 form-control-sm" style="width: 70px" type="number"
            [(ngModel)]="fromDate" (ngModelChange)="setFromDate(fromDate)" min="1" max="14"> Tagen
        Bis zu
        <input class="ms-1 me-1 form-control rounded-0 form-control-sm" style="width: 70px" type="number"
            [(ngModel)]="toDate" (ngModelChange)="setToDate(toDate)" min="0" max="13"> Tagen
        <button (click)="removeData(chart)" class="btn rounded-0 btn-outline-success ms-5   ms-sm-5 btn-sm"> Update
        </button>
    </div>
    <div class="d-flex w-100 h-100 justify-content-center align-content-center ">
        <div class="chart-container d-flex align-items-center "
            style="width: 72vw; height: 60vh;padding-bottom: 100px;">
            <canvas id="MyChart">{{ chart }}</canvas>
            <span *ngIf="noData1"> Keine Daten vorhanden</span>
        </div>
        <div class="chart-container d-flex align-items-center "
            style="width: 72vw; height: 60vh;padding-bottom: 100px;">
            <canvas id="MyChart2">{{ chart2 }}</canvas>
            <span *ngIf="noData2"> Keine Daten vorhanden</span>
        </div>
    </div>

    <div *ngIf="noData1 && noData2" class="d-flex w-100 h-100 justify-content-center align-content-center ">
        Keine Daten vorhanden
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-end me-5">
    <button (click)="onCloseAddTimeSwitchClicked()" class="btn rounded-0 btn-outline-danger ms-sm-5 btn-sm mt-4 ">
        Schließen
    </button>
</div>