import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-bilder',
  templateUrl: './faq-bilder.component.html',
  styleUrls: ['./faq-bilder.component.css']
})
export class FaqBilderComponent {

public  isShowPicturesInProjekt :boolean = false;
public isShowDialog :boolean = false;
public isShowStellcodeAuswahl : boolean = false;

public isShowSchieldsPicture: boolean = false;
public isShowRouteStationPicture: boolean = false;
public isShowSchilderpicture: boolean = false;
public isShowResetEVA: boolean = false;


constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}

public onShowPicturesInProjekt() {
  this.isShowPicturesInProjekt = !this.isShowPicturesInProjekt
}

public onShowDialog() {
  this.isShowDialog = !this.isShowDialog
}

public onShowStellcodeAuswahl() {
  this.isShowStellcodeAuswahl = !this.isShowStellcodeAuswahl
}

public onShowSchieldsPicture() {
  this.isShowSchieldsPicture = !this.isShowSchieldsPicture
}

public onShowRouteStationPicture(){
  this.isShowRouteStationPicture =! this.isShowRouteStationPicture
}

public onShowSchilderpicture(){
  this.isShowSchilderpicture =! this.isShowSchilderpicture
}

public onShowResetEVA(){
  this.isShowResetEVA =! this.isShowResetEVA
}

}

 
