import { Injectable } from '@angular/core';
import {HttpService} from "../../../core/shared/http.service";
import {UserDataResponse} from "../interfaces/login-data-response.interface";
import {Observable} from "rxjs";
import {PasswordData} from "../../user-data-settings/shared/password-data.interface";
import {EditUserPassword} from "../../user-data-settings/shared/edit-user-password.interface";
import {CreateUser} from "../interfaces/create-user.interface";
import {EditUser} from "../interfaces/edit-user.interface";
import {UserProjects} from "../interfaces/user-projects.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  public editUserData(userId: number | undefined, userData: EditUser): Observable<UserDataResponse> {
    return this.httpService.put<UserDataResponse>("User/" + userId, userData);
  }

  public editPasswordData(userId: number, passwordData: EditUserPassword): Observable<EditUserPassword> {
    return this.httpService.put<EditUserPassword>("User/" + userId, passwordData);
  }

  public getAllUsers(): Observable<UserDataResponse[]> {
    return this.httpService.get<UserDataResponse[]>("User");
  }

  public deleteUser(userId: number): Observable<any> {
    return this.httpService.delete<any>("User/" + userId);
  }

  public createUser(newUserData: CreateUser): Observable<UserDataResponse> {
    return this.httpService.post<UserDataResponse>("User/CreateUser", newUserData);
  }

  public getUserById(userId: number): Observable<UserDataResponse> {
    return this.httpService.get<UserDataResponse>("User/" + userId);
  }

  public getProjectsAssignedToUser(userId: number | undefined): Observable<UserProjects[]> {
    return this.httpService.get<UserProjects[]>("User/" + userId + "/project");
  }

  public editUserProject(userId: number, addProjectIds: number[], deleteProjectIds: number[]): Observable<[]> {
    return this.httpService.patch<[]>('user/' + userId + '/project', {'add': addProjectIds, 'delete': deleteProjectIds});
  }

  public assignRoleToUser(userId: number, roleId: number): Observable<any> {
    return this.httpService.post<any>("User/" + userId + "/role/" + roleId, {});
  }
}


