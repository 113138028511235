<div style="width: 100vw; display: flex; justify-content: center;">
    <div class="mt-4" style="width: 100vw;">

        <h1 class="ms-4 mb-4"> <img (click)="onBackButtonKlick()" class="ms-2 mb-1 back-button"
                src="assets/icons/arrow-left.svg" alt="Backbutton">
            <img class="ms-4 me-2 " style="height: 32px" src="assets/icons/exclamation-octagon.svg"
                alt="question-circle Icon"> SWA Einstellungen
        </h1>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Wie Öffne ich einen Dialog mit den Stationen? </h4> <button
                        (click)="onShowSwaSettings()"> <img *ngIf="!isShowSwaSettings" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowSwaSettings" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon(back-button)"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowSwaSettings">
                Wenn Sie im oberen Reiter auf "Projekt" klicken, öffnet sich in der oberen rechten Ecke ein weiterer
                Reiter, auf dem Sie zu "SWA" navigieren können, indem Sie darauf klicken. Danach erscheinen zwei
                Fenster: einmal die "Stauwarnanlagen Einstellungen" und "Stauwarnanlagen erstellen" (siehe
                Abbildung). Hier können Sie die geöffneten Stauwarnanlagen Einstellungen einsehen und
                Stauwarnanlagen erstellen.
                <br>
                <br>
                <img style="height: 451px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild1..png"
                    alt="Referenz zu dem Bild 7">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Erstellung eines SWA </h4> <button (click)="onShowSwaCreate()"> <img
                            *ngIf="!isShowSwaCreate" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowSwaCreate" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowSwaCreate">
                Wenn Sie im oberen Menü auf "Projekte" klicken, öffnet sich in der oberen rechten Ecke ein
                weiterer Reiter, auf dem Sie zur SWA-Navigation gelangen, indem Sie darauf klicken. Dort finden
                Sie die Option "Stauwarnanlagen erstellen" als Toggle. Wenn Sie den Toggle aktiv lassen, wird
                durch einen Klick auf den Button automatisch eine einfache Stauwarnanlage erstellt. Diese
                basiert auf den Koordinaten der RouteStations gemäß dem Schema und unter Berücksichtigung
                weiterer Parameter.
                <br>
                <img style="height: 100px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild2.png"
                    alt="Referenz zu dem  SWA Bild 2">

                <div *ngIf="isShowSwaCreate"> Wird der Toggle umgestellt, ändert sich die Darstellung wie folgt:
                    <br>
                    <img style="height: 130px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild3..png"
                        alt="Referenz zu dem  SWA Bild 3">

                    <div *ngIf="isShowSwaCreate"> Mit Betätigung des Toggles bietet das System weitere Optionen
                        zur Erstellung einer Stauwarnanlage an.<br>
                        Entweder können die Parameter in den Eingabefeldern angepasst werden (Felder sind mit
                        Standardwerten vorausgefüllt.)<br>
                        Sind diese Parameter gesetzt, kann man per Knopfdruck diese Konfiguration zur Erstellung
                        der SWA nutzen.
                        <br>
                        <br>
                        <img style="height: 464px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild4..png"
                            alt="Referenz zu dem  SWA Bild 4">
                        <br>
                        <br>
                        <img style="height: 70px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild5.png"
                            alt="Referenz zu dem  SWA Bild 5">
                    </div>
                </div>
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Variante 2 zu erstellung eines SWA's </h4> <button
                        (click)="onShowSwaCreateOption2()"> <img *ngIf="!isShowSwaCreateOption2" style="height: 24px"
                            class="me-4 back-button" src="assets/icons/caret-down.svg"
                            alt="open-faq-answer-icon/back-button"><img *ngIf="isShowSwaCreateOption2"
                            style="height: 24px" class="me-4 back-button" src="assets/icons/caret-up.svg"
                            alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowSwaCreateOption2">
                Wählt man die Chekbox „JSON einfügen“ aus, erhält man die Option, eine Konfiguration als Text im
                JSON-Format einzufügen,
                welches die Parameter der Konfiguration beinhaltet.<br>
                Anschließend ist auch hier, die „Konfigurierte SWA erstellen“ Taste zu drücken, um die
                Erstellung der Stauwarnanlage abzuschließen.
                <br>
                <br>
                <img style="height: 379px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild6..png"
                    alt="Referenz zu dem Bild 7">
            </div>
        </div>

        <div class="card shadow rounded-0 p-3  ms-4 me-4 mb-4 ">
            <div class="ms-3 d-flex justify-content-start border-bottom w-100 ">
                <div class="d-flex justify-content-between w-100 ">
                    <h4 class="me-5"> Was sind die Besonderheiten? </h4> <button (click)="onShowSpezcialFeatures()">
                        <img *ngIf="!isShowSpezcialFeatures" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-down.svg" alt="open-faq-answer-icon/back-button"><img
                            *ngIf="isShowSpezcialFeatures" style="height: 24px" class="me-4 back-button"
                            src="assets/icons/caret-up.svg" alt="close-faq-answer-icon/back-button"></button>
                </div>
            </div>
            <div class="ms-4 mt-2" *ngIf="isShowSpezcialFeatures">
                Besteht eine SWA, muss diese erst gelöscht werden, wenn man eine neue erstellen möchte. Dies
                ist an 2 Merkmalen zu erkennen.<br>
                Merkmal 1. Der Toggle (siehe Abbildung Beispiel: Eingeschaltete SWA) wird ersetzt, mit der
                Information „Keine SWA vorhanden“.<br>
                Merkmal 2. Der Button Bestehende SWA löschen wird ebenfalls nur angezeigt, wenn eine SWA
                besteht. Siehe unten
                <br>
                <br>
                <img style="height: 90px" class="me-4 ms-12 mb-4 " src="assets/FAQ/swaBild7.png"
                    alt="Referenz zu dem swa Bild 7">
            </div>
        </div>

        
    </div>
</div>