import { Component } from '@angular/core';
import { right } from '@popperjs/core';
import { Location } from '@angular/common';

@Component({
  selector: 'avs-faq-project',
  templateUrl: './faq-project.component.html',
  styleUrls: ['./faq-project.component.css']
})
export class FaqProjectComponent {

  public isShowProjektMap: boolean = false;
  public isShowProjektStations: boolean = false;
  public isShowProjektPlan: boolean = false;

  constructor(private location:Location){}

public onBackButtonKlick(){
  this.location.back();
}

public onShowProjektMap() {
  this.isShowProjektMap = !this.isShowProjektMap
}

public onShowProjektStations(){
  this.isShowProjektStations =! this.isShowProjektStations
}
 
public onShowProjektPlan(){
  this.isShowProjektPlan =! this.isShowProjektPlan
}
}
