import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ProjectRouteStation} from "../../../project/shared/interfaces/project-route-station.interface";
import {DeviceParameter} from "../interfaces/device-parameter.interface";
import {DeviceService} from "./device.service";
import {DeviceDataSessionStorageService} from "./device-data-session-storage.service";
import {DataEndChannel} from "../../../project/shared/interfaces/data-end-channel.interface";
import {RouteStationService} from "../../../project/shared/services/route-station.service";
import {DataEndChannelError} from "../../../project/shared/interfaces/data-end-channel-error.interface";
import {ProjectRouteStationWithParam} from "../../../project/shared/interfaces/project-route-station.class";

@Injectable({
    providedIn: 'root'
})
export class DeviceDataService {
    private routeStationsSubject$ = new BehaviorSubject<ProjectRouteStation[]>([]);
    public routeStations = this.routeStationsSubject$.asObservable();
    private activeRouteStationSubject$ = new BehaviorSubject<ProjectRouteStation | undefined>(undefined);
    public activeRouteStation = this.activeRouteStationSubject$.asObservable();
    private deviceParameterSubject$ = new BehaviorSubject<DeviceParameter | undefined>(undefined);
    public deviceParameter = this.deviceParameterSubject$.asObservable();
    private dataEndChannelDeviceSubject$ = new BehaviorSubject<DataEndChannel[]>([]);
    public dataEndChannelDevice = this.dataEndChannelDeviceSubject$.asObservable();
    private dataEndChannelErrorsSubject$ = new BehaviorSubject<DataEndChannelError[]>([]);
    public dataEndChannelErrors = this.dataEndChannelErrorsSubject$.asObservable();
    private routeStationWithDeviceParamSubject$ = new BehaviorSubject<ProjectRouteStationWithParam | undefined>(undefined);
    public routeStationWithDeviceParam = this.routeStationWithDeviceParamSubject$.asObservable();

    constructor(private readonly deviceService: DeviceService,
                private readonly deviceSessionService: DeviceDataSessionStorageService,
                private readonly stationService: RouteStationService) {}

    public updateRouteStations(routeStations: ProjectRouteStation[]): void {
        this.routeStationsSubject$.next(routeStations);
        if (this.deviceSessionService.getActiveRouteStation()) {
            this.updateActiveRouteStation(routeStations.find(
                r  => r.id === this.deviceSessionService.getActiveRouteStation()
            ));
        }
    }

    public updateActiveRouteStation(activeRouteStation: ProjectRouteStation | undefined): void {
        this.activeRouteStationSubject$.next(activeRouteStation);
        if (activeRouteStation) {
            this.getDeviceParameter(activeRouteStation)
            this.deviceSessionService.setActiveRouteStation(activeRouteStation.id);
            this.getDataEndChannels(activeRouteStation);
            this.getDataEndChannelErrors(activeRouteStation.samId);
        } else {
            this.updateDeviceParameter(undefined);
            this.updateDataEndChannelDevice([]);
            this.deviceSessionService.deleteActiveRouteStation();
            this.updateRouteStationWithParam(undefined);
        }
    }

    public updateDeviceParameter(deviceParameter: DeviceParameter | undefined): void {
        this.deviceParameterSubject$.next(deviceParameter);
    }

    public updateDataEndChannelDevice(dataEndChannels: DataEndChannel[]): void {
        this.dataEndChannelDeviceSubject$.next(dataEndChannels);
    }

    public updateDataEndChannelErrorDevice(dataEndChannelErrors: DataEndChannelError[]): void {
        this.dataEndChannelErrorsSubject$.next(dataEndChannelErrors);
    }

    private getDeviceParameter(activeRouteStation: ProjectRouteStation): void {
        this.deviceService.getDeviceParameter(activeRouteStation.samId).subscribe(deviceParameter => {
           this.updateDeviceParameter(deviceParameter);
           this.mapStaionWithDeviceParam(activeRouteStation, deviceParameter);
        });
    }

    private getDataEndChannels(activeRouteStation: ProjectRouteStation): void {
        this.stationService.getDataEndChannels(activeRouteStation.id).subscribe(dataEndChannels => {
            if (!dataEndChannels) {dataEndChannels = [];}
            else activeRouteStation.dataEndChannels = dataEndChannels;
           this.updateDataEndChannelDevice(dataEndChannels);
        });
    }

    private getDataEndChannelErrors(samId: number): void {
        this.deviceService.getAllChannelErrors(samId).subscribe(channelErrors => {
           this.updateDataEndChannelErrorDevice(channelErrors);
        });
    }

    private mapStaionWithDeviceParam(routeStation: ProjectRouteStation, deviceParameter: DeviceParameter): void {
        this.updateRouteStationWithParam({routeStation, deviceParameter});
    }

    private updateRouteStationWithParam(routeStationWithParam: ProjectRouteStationWithParam | undefined): void {
        this.routeStationWithDeviceParamSubject$.next(routeStationWithParam);
    }
}