import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { APP_BASE_HREF } from '@angular/common';
import { ProjectModule } from './project/project.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DeviceModule } from './device/device.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from "./user/user.module";
import { CompanyModule } from "./company/company.module";
import { ReportModule } from "./report/report-module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeFlatOverviewComponent } from './report/shared/components/tree-flat-overview/tree-flat-overview.component';
import { ChangelogAqEngineComponent } from './report/shared/components/changelog-aq-engine/changelog-aq-engine.component';




@NgModule({
    declarations: [
        AppComponent,
        TreeFlatOverviewComponent,
    
        

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        DashboardModule,
        DeviceModule,
        SharedModule,
        BrowserAnimationsModule,
        ProjectModule,
        UserModule,
        CompanyModule,
        ReportModule,
        NgbModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/avs-portal/' }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
