import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Scenario } from '../../shared/interfaces/scenario.interface';
import { ScenarioService } from '../../shared/services/scenario.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getVerkehrsstufeSelect } from '../../shared/enums/verkehrsstufe';
import { getVerkehrszustandSelect } from '../../shared/enums/verkehrszustand';
import { GroupService } from '../../shared/services/group.service';

@Component({
  selector: 'avs-scenario-select-dialog',
  templateUrl: './scenario-select-dialog.component.html',
  styleUrls: ['./scenario-select-dialog.component.css']
})
export class ScenarioSelectDialogComponent {

  public scenarios: Scenario[] = [];
  public objectIsTouched: boolean = false;
  public highPriority: boolean = false;
  public name: string = 'Manuelle Steuerung';
  public isTrafficDropdownOpen: boolean = false;
  public trafficSelect: SelectInput[] = getVerkehrsstufeSelect();
  public selectedTraffic: SelectInput = { name: "freier Verkehr", id: 1 };

  public isVerkehrssituationDropdownOpen: boolean = false;
  public verkehrssituationSelect: SelectInput[] = getVerkehrszustandSelect();
  public selectedVerkehrssituation: SelectInput = { name: "Verkehrssituation ", id: 0 };

  constructor(public dialogRef: MatDialogRef<ScenarioSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scenarioService: ScenarioService,
    private groupService: GroupService) {
    this.scenarios = data.scenarios;
  }

  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }

  public onScenarioEnabled(senario: Scenario): void {
    if (senario.enabled) {
      this.scenarioService.disableScenario(senario.id!).subscribe((x) => { console.log(x) });
      this.dialogRef.close({ result: 'changed' });
    }
    else {
      this.scenarioService.enableScenario(senario.id!).subscribe((x) => { console.log(x) });
      this.dialogRef.close({ result: 'changed' });
    }
  }

  public onNameChange(newValue: string): void {
    this.name = newValue;
  }

  public onVerkehrssituationItemClicked(item: SelectInput): void {
    this.isVerkehrssituationDropdownOpen = false;
    this.selectedVerkehrssituation.name = item.name
    this.selectedVerkehrssituation.id = item.id
    let vsId = item.id as number
    this.objectIsTouched = true;
  }
  public onVerkehrssituationDropdownClicked() {
    this.isVerkehrssituationDropdownOpen = !this.isVerkehrssituationDropdownOpen;
  }

  public onTrafficItemClicked(item: SelectInput): void {
    this.isTrafficDropdownOpen = false;
    this.selectedTraffic.name = item.name
    this.selectedTraffic.id = item.id
    let trafficId = item.id as number
    if (item.id === 3 || item.id === 4) {
      this.selectedVerkehrssituation.id = 1;
      this.objectIsTouched = true;
    }

  }

  public onTrafficDropdownClicked() {
    this.isTrafficDropdownOpen = !this.isTrafficDropdownOpen;
  }

  public onSubmitBtnClicked(): void {
    this.groupService.createManualControl(this.data.groupId, Number(this.selectedTraffic.id), Number(this.selectedVerkehrssituation.id), this.name, this.highPriority).subscribe((x) => { this.dialogRef.close({ result: 'changed' }); });
  }
}