import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { LoginService } from "./services/login.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  /* RolqGuard und LoginGuard müssen überabeitet werden -> https://angular.io/api/router/CanActivateFn */
  constructor(private loginService: LoginService,
              private router: Router) {
  }

  public canActivate(routeSnapshot: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot): boolean | UrlTree {
    if (!this.loginService.isLoggedIn()) {
      const url = encodeURI(routerSnapshot.url);
      return this.router.createUrlTree(['login'], {queryParams: {redirect: url}});
    }
    return true;
  }
}
