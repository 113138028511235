import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginOverviewComponent } from "./login-overview/login-overview.component";
import { FormsModule } from "@angular/forms";
import { UserDataSettingsComponent } from "./user-data-settings/user-data-settings.component";
import { SharedModule } from "../shared/shared.module";
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { UserProjectAddModalComponent } from './user-project-add-modal/user-project-add-modal.component';
import { RouterLink, RouterOutlet } from "@angular/router";
import { UserComponent } from './user/user.component';
import { CanAccessDirective } from "./shared/can-access.directive";



@NgModule({
  declarations: [
    LoginOverviewComponent,
    UserDataSettingsComponent,
    UserOverviewComponent,
    UserSettingsComponent,
    UserProjectAddModalComponent,
    UserComponent,
    CanAccessDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterLink,
    RouterOutlet
  ],
  exports: [
    LoginOverviewComponent,
    CanAccessDirective
  ]
})
export class UserModule { }
