<div class="bg-white "> <!-- shadow auskommentiert, falls das wo anders komisch aussehen sollte, einfach shadow auf den parent knallen -->
    <div [ngClass]="isDropdownOpen ? 'border-danger' : ''" [style]="styleTitleDiv"
         class="border d-flex justify-content-between" role="button"
         (click)="onSelectDropdownClicked()">
        <div class="ms-2 p-1">{{ title }}</div>
        <img *ngIf="!isDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
             alt="caret-down open select">
        <img *ngIf="isDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
             alt="caret-up close select">
    </div>
    <div *ngIf="isDropdownOpen" class="position-absolute z-1 bg-white shadow p-2" [style]="styleItemsDiv">
        <div *ngIf="isMultiSelect" class="form-check avs-checkbox">
            <label class="form-check-label">alles auswählen</label>
            <input class="form-check-input rounded-0"
                   [checked]="isMultiSelectChecked"
                   [(ngModel)]="isMultiSelectChecked"
                   (change)="onMultiSelectChanged()"
                   type="checkbox">
        </div>
        <ng-container *ngFor="let item of checkboxDropdownItems">
            <div class="form-check avs-checkbox" [ngClass]="isMultiSelect ? 'ms-2' : ''">
                <label class="form-check-label">{{item.name}}</label>
                <input class="form-check-input rounded-0"
                       [checked]="item.checked"
                       [(ngModel)]="item.checked"
                       (change)="onItemSelectChanged()"
                       type="checkbox">
            </div>
        </ng-container>
    </div>
</div>
