import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'avs-report-time-area-dropdown',
  templateUrl: './report-time-area-dropdown.component.html',
  styleUrls: ['./report-time-area-dropdown.component.css']
})
export class ReportTimeAreaDropdownComponent implements OnInit{
  @Input() isSelectOpen: boolean = false;
  @Input() title: string = '';
  @Input() styleTitleDiv: string = '';
  @Input() styleItemsDiv: string = '';
  @Output() isSelectOpenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getFromDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() getToDate: EventEmitter<string> = new EventEmitter<string>();
  public isThisMonth: boolean = true;
  public isThisYear: boolean = false;
  public fromDate: string = '';
  public toDate: string = '';

  public ngOnInit(): void {
    this.getThisMonth();
  }

  public onSelectDropdownClicked(): void {
    this.isSelectOpen = !this.isSelectOpen;
    this.isSelectOpenChanged.emit(this.isSelectOpen);
  }

  public onFromDateChanged(newDate: string): void {
    this.isThisMonth = false;
    this.isThisYear = false;
    this.fromDate = newDate;
    this.getFromDate.emit(newDate);
  }

  public onToDateChanged(newDate: string): void {
    this.isThisMonth = false;
    this.isThisYear = false;
    this.toDate = newDate;
    this.getToDate.emit(newDate);
  }

  public isThisMonthChanged(checked: boolean): void {
    this.isThisMonth = checked;
    if (checked) {
      this.isThisYear = false;
      this.getThisMonth();
    }
  }

  public isThisYearChanged(checked: boolean): void {
    this.isThisYear = checked;
    if (checked) {
      this.isThisMonth = false;
      this.getThisYear();
    }
  }

  private getThisMonth(): void {
    this.fromDate = `${new Date().getFullYear()}-` +
        `${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`}-01`;
    this.toDate = `${new Date().getFullYear()}-` +
        `${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`}-` +
        `${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : `${new Date().getDate()}`}`;
    this.getFromDate.emit(this.fromDate);
    this.getToDate.emit(this.toDate);
  }

  private getThisYear(): void {
    this.fromDate = `${new Date().getFullYear()}-` + `01-01`;
    this.toDate = `${new Date().getFullYear()}-` +
        `${(new Date().getMonth() + 1) < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`}-` +
        `${(new Date().getDate()) < 10 ? `0${new Date().getDate()}` : `${new Date().getDate()}`}`;
    this.getFromDate.emit(this.fromDate);
    this.getToDate.emit(this.toDate);
  }
}
