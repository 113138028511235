import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStation } from "../../project/shared/interfaces/project-route-station.interface";
import { DeviceDataService } from "../shared/services/device-data.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'avs-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.css']
})
export class DeviceOverviewComponent implements OnInit, OnDestroy {
  public routeStations: ProjectRouteStation[] | undefined
  public activeRouteStation: ProjectRouteStation | undefined;
  public isMenuOpen: boolean = false;

  private subscriptionCollection: Subscription[] = [];
  private routeStationsSubscription?: Subscription;
  private activeRouteStationSubscription?: Subscription;


  constructor(private deviceDataService: DeviceDataService) {
    this.routeStationsSubscription = deviceDataService.routeStations.subscribe(stations => {
      this.routeStations = stations;
    });
    this.activeRouteStationSubscription = deviceDataService.activeRouteStation.subscribe(activeStation => {
      this.activeRouteStation = activeStation;
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.routeStationsSubscription!);
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onMenuClicked(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
