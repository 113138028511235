export enum RoleTypes {
    none,
    admin,
    user,
    extern,
    streetworker,
    adminReadOnly,
    trackerRegistor, /* nicht fürs Frontend */
    userReadOnly
}

export function getRoleTypesString(roleType: RoleTypes): string {
    switch (roleType) {
        case RoleTypes.admin:
            return 'Admin';
        case RoleTypes.adminReadOnly:
            return 'AdminReadOnly';
        case RoleTypes.extern:
            return 'Extern';
        case RoleTypes.none:
            return 'None';
        case RoleTypes.streetworker:
            return 'Monteur';
        case RoleTypes.user:
            return 'User';
        case RoleTypes.userReadOnly:
            return 'userReadOnly';
        case RoleTypes.trackerRegistor:
            return 'TrackerRegistor'
    }
}