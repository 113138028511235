import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'avs-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent {
@Input() label: string | undefined;
@Input() value: string | number = "";
@Input() placeholder: string = "";
@Input() required: boolean = false;
@Input() isDateString: boolean = false;
@Input() type: string = "text";
@Input() disabled: boolean = false;
@Input() classItem: string = "";
@Input() classInputLabel: string = "";
@Input() classInputOnly: string = "";
@Input() measurement: string = "";
@Output() valueChange: EventEmitter<string> = new EventEmitter<string>()
@Input() min?: number;
@Input() max?: number;

public onValueChange(event: any): void {
  this.valueChange.emit(event.target.value);
}
}
