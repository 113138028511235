import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from '../../shared/services/project.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getPrioritySelect, getPriorityString } from '../shared/enum/priority.enum';
import { ProjectTimeSwitchInterface } from '../shared/interfaces/project-time-switch.interface';
import { ProjectImageData } from '../../shared/interfaces/project-image-data.interface';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { getVerkehrsstufeSelect, getVerkehrsstufeString } from '../../shared/enums/verkehrsstufe';
import { getVerkehrszustandSelect, getVerkehrszustandString } from '../../shared/enums/verkehrszustand';
import { ImageDataService } from '../../shared/services/image-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'avs-project-time-switch-edit-modal-wrapper',
  templateUrl: './project-time-switch-edit-modal-wrapper.component.html',
  styleUrls: ['./project-time-switch-edit-modal-wrapper.component.css']
})

export class ProjectTimeSwitchEditModalWrapperComponent implements OnInit {
  public images: ProjectImageData[] = [];
  public allImages: ProjectImageData[] = [];
  public selectedImage?: ProjectImageData;
  public isPriorityDropdownOpen: boolean = false;
  public prioritySelect: SelectInput[] = getPrioritySelect();
  public selectedPriority: SelectInput = { name: 'Mittel', id: 2 }
  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }, { name: 'Blinkend', id: 2 }];
  public selectedFunction: SelectInput = { name: "An", id: 1 };
  public isOneTime: boolean = false;
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00";
  public monday: boolean = false;
  public tuesday: boolean = false;
  public wednesday: boolean = false;
  public thursday: boolean = false;
  public friday: boolean = false;
  public saturday: boolean = false;
  public sunday: boolean = false;
  public isTrafficDropdownOpen: boolean = false;
  public selectedTraffic: SelectInput = { name: "freier Verkehr", id: 1 };
  public trafficSelect: SelectInput[] = getVerkehrsstufeSelect();
  protected readonly environment = environment;
  public isVerkehrszustandDropdownOpen: boolean = false;
  public verkehrszustandSelect: SelectInput[] = getVerkehrszustandSelect();
  public selectedVerkehrszustand: SelectInput = { name: "Verkehrssituation " };

  public inputStart?: NgbDate;
  public inputEnd?: NgbDate;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProjectTimeSwitchEditModalWrapperComponent>,
    private projectService: ProjectService,
    public imageDataService: ImageDataService) {
    imageDataService.projectImages.subscribe(images => {
      this.images = images;
      this.allImages = images;
    });
  }

  public ngOnInit() {
    this.setData();
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name;
    this.selectedFunction.id = item.id;
    let funcId = item.id as number;
    this.data.data.Funktion = funcId;
    this.data.data.tlS_Function = funcId;
    if (funcId !== 2) {

      this.data.data.tlS_Time = 0;
    }
  }

  public onTrafficDropdownClicked() {
    this.isTrafficDropdownOpen = !this.isTrafficDropdownOpen;
  }


  public onVerkehrszustandItemClicked(item: SelectInput): void {
    this.isVerkehrszustandDropdownOpen = false;
    this.selectedVerkehrszustand.name = item.name
    this.selectedVerkehrszustand.id = item.id
    let vsId = item.id as number
    this.data.data.vs = vsId
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onEnterStartTimeChange(newValue: string): void {
    this.starttime = newValue;
  }

  public onEnterEndTimeChange(newValue: string): void {
    this.endtime = newValue;
  }

  public onPriorityItemClicked(item: SelectInput): void {
    this.isPriorityDropdownOpen = false;
    this.selectedPriority.name = item.name;
    this.selectedPriority.id = item.id;
    let prioId = item.id as number;
    this.data.data.priority = prioId;
  }

  public onPriorityDropdownClicked(): void {
    this.isPriorityDropdownOpen = !this.isPriorityDropdownOpen;
  }

  public onCloseEditTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  public onProjectImageClicked(image: ProjectImageData) {
    if (this.images.length > 1) { this.allImages = this.images }
    if (this.images) {
      if (this.images.length > 1) {
        this.selectedImage = image
        this.data.data.tlS_Code = this.selectedImage.stellcode
        this.images = [];
        this.images.push(image)
      }
      else if (this.images.length === 1) {
        this.images = this.allImages
      }
    }
  }

  public onTrafficItemClicked(item: SelectInput): void {
    this.isTrafficDropdownOpen = false;
    this.selectedTraffic.name = item.name
    this.selectedTraffic.id = item.id
    let trafficId = item.id as number
    this.data.data.trafic = trafficId
  }

  public onVerkehrszustandDropdownClicked() {
    this.isVerkehrszustandDropdownOpen = !this.isVerkehrszustandDropdownOpen;
  }

  public onStartDateSelect(newValue: NgbDate) {
    this.startDate = newValue;
    this.inputStart = newValue
  }

  public onEndDateSelect(newValue: NgbDate) {
    this.endDate = newValue;
    this.inputEnd = newValue
  }

  public onOneTimeChange(): void {
    this.data.data.one_time = !this.data.data.one_time
  }
  public onSubmitBtnClicked(): void {
    let startTimeParts = this.starttime.split(":", 3);
    let endTimeParts = this.endtime.split(":", 3);
    if (this.data.type === 'aq') {
      this.closeAsAQ(startTimeParts, endTimeParts)
    }
    if (this.data.type === 'mq') {
      this.closeAsMQ(startTimeParts, endTimeParts)
    }
    if (this.data.type === 'flasher') {
      this.closeAsFlasher(startTimeParts, endTimeParts)
    }
  }

  private closeAsFlasher(startTimeParts: string[], endTimeParts: string[]) {
    if (this.data.data.one_time) {
      this.dialogRef.close({
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        yearOn: this.startDate?.year,
        monthOn: this.startDate?.month,
        dayOn: this.startDate?.day,
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        yearOff: this.endDate?.year,
        monthOff: this.endDate?.month,
        dayOff: this.endDate?.day,
        priority: this.data.data.priority,
        tlS_Code: 61,
        tlS_Function: this.data.data.tlS_Function,
        tlS_Time: 0,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id
      });
    }
    else {
      this.dialogRef.close({
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        monday: this.data.data.monday,
        tuesday: this.data.data.tuesday,
        wednesday: this.data.data.wednesday,
        thursday: this.data.data.thursday,
        friday: this.data.data.friday,
        saturday: this.data.data.saturday,
        sunday: this.data.data.sunday,
        priority: this.data.data.priority,
        tlS_Code: 61,
        tlS_Function: this.data.data.tlS_Function,
        tlS_Time: 0,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id
      });
    }
  }

  private closeAsAQ(startTimeParts: string[], endTimeParts: string[]) {
    if (this.data.data.one_time) {
      this.dialogRef.close({
        aq: true,
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        yearOn: this.startDate?.year,
        monthOn: this.startDate?.month,
        dayOn: this.startDate?.day,
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        yearOff: this.endDate?.year,
        monthOff: this.endDate?.month,
        dayOff: this.endDate?.day,
        priority: this.data.data.priority,
        tlS_Code: this.data.data.tlS_Code,
        tlS_Function: this.data.data.tlS_Function,
        tlS_Time: this.data.data.tlS_Time,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id
      });
    }
    else {
      this.dialogRef.close({
        aq: true,
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        monday: this.data.data.monday,
        tuesday: this.data.data.tuesday,
        wednesday: this.data.data.wednesday,
        thursday: this.data.data.thursday,
        friday: this.data.data.friday,
        saturday: this.data.data.saturday,
        sunday: this.data.data.sunday,
        priority: this.data.data.priority,
        tlS_Code: this.data.data.tlS_Code,
        tlS_Function: this.data.data.tlS_Function,
        tlS_Time: this.data.data.tlS_Time,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id
      });
    }
  }

  private closeAsMQ(startTimeParts: string[], endTimeParts: string[]) {
    if (this.data.data.one_time) {
      this.dialogRef.close({
        aq: false,
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        yearOn: this.startDate?.year,
        monthOn: this.startDate?.month,
        dayOn: this.startDate?.day,
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        yearOff: this.endDate?.year,
        monthOff: this.endDate?.month,
        dayOff: this.endDate?.day,
        priority: this.data.data.priority,
        trafic: this.data.data.trafic,
        vs: this.data.data.vs,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id,
      });
    }
    else {
      this.dialogRef.close({
        aq: false,
        one_time: this.data.data.one_time,
        hourOn: startTimeParts[0],
        minuteOn: startTimeParts[1],
        secondOn: startTimeParts[2],
        hourOff: endTimeParts[0],
        minuteOff: endTimeParts[1],
        secondOff: endTimeParts[2],
        monday: this.data.data.monday,
        tuesday: this.data.data.tuesday,
        wednesday: this.data.data.wednesday,
        thursday: this.data.data.thursday,
        friday: this.data.data.friday,
        saturday: this.data.data.saturday,
        sunday: this.data.data.sunday,
        priority: this.data.data.priority,
        trafic: this.data.data.trafic,
        vs: this.data.data.vs,
        timerSwitchId: this.data.data.timerSwitchId,
        projectId: this.data.data.projectId,
        id: this.data.data.id
      });
    }
  }

  private setData() {
    let funktionSelect = this.functionSelect.find(element => element.id === this.data.data.tlS_Function)
    this.onPriorityItemClicked({ name: getPriorityString(this.data.data.priority), id: this.data.data.priority });
    if (this.data.type === 'aq') {
      this.selectedImage = this.allImages.find(image => image.stellcode === this.data.data.tlS_Code)
      if (this.selectedImage) this.onProjectImageClicked(this.selectedImage)

      this.onFunctionItemClicked({ name: funktionSelect!.name, id: funktionSelect!.id })
    }
    if (this.data.type === 'mq') {
      this.onTrafficItemClicked({ name: getVerkehrsstufeString(this.data.data.trafic), id: this.data.data.trafic })
      this.onVerkehrszustandItemClicked({ name: getVerkehrszustandString(this.data.data.vs), id: this.data.data.vs })
    }
    if (this.data.type === 'flasher') {
      this.onFunctionItemClicked({ name: funktionSelect!.name, id: funktionSelect!.id })
    }
    if (this.data.data.one_time) {
      let startTest = new NgbDate(this.data.data.yearOn, this.data.data.monthOn, this.data.data.dayOn)
      let endTest = new NgbDate(this.data.data.yearOff, this.data.data.monthOff, this.data.data.dayOff)
      this.onStartDateSelect(startTest);
      this.onEndDateSelect(endTest);
    }

    let starttime = this.twoDigits(this.data.data.hourOn) + ":" + this.twoDigits(this.data.data.minuteOn) + ":" + this.twoDigits(this.data.data.secondOn) /* minuten und stunden vor eingabe bearbeiten, damit 2 stellen eingetragen werden */
    let endtime = this.twoDigits(this.data.data.hourOff) + ":" + this.twoDigits(this.data.data.minuteOff) + ":" + this.twoDigits(this.data.data.secondOff)
    this.onEnterStartTimeChange(starttime);
    this.onEnterEndTimeChange(endtime);
  }

  private twoDigits(number: number) {
    /* TODO: in globale utils bauen? und dann ebenfalls an anderen stellen einsetzen **** */
    if (number < 10) {
      let string = number.toString().padStart(2, '0')
      return string
    }
    else return number
  }
}
