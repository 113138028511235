import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ReportConfigurationService} from "../shared/services/report-configuration.service";
import {ReportConfiguration} from "../shared/interfaces/report-configuration.interface";
import {ModalService} from "../../shared/modal/shared/modal.service";
import {AlertService} from "../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../shared/alert/shared/alert-types";
import {
  ChangelogConfigurationModalComponent
} from "../changelog-configuration-modal/changelog-configuration-modal.component";
import {SelectInput} from "../../shared/dropdown/shared/select-input.class";

@Component({
  selector: 'avs-changelog-config-overview-modal',
  templateUrl: './changelog-config-overview-modal.component.html',
  styleUrls: ['./changelog-config-overview-modal.component.css']
})
export class ChangelogConfigOverviewModalComponent implements OnInit {
  public reportConfigs: ReportConfiguration[] = [];

  constructor(private readonly dialogRef: MatDialogRef<ChangelogConfigOverviewModalComponent>,
              private readonly reportConfigService: ReportConfigurationService,
              private readonly modalService: ModalService,
              private readonly alertService: AlertService,
              private readonly dialog: MatDialog) {}

  public ngOnInit(): void {
    this.getData();
  }

  public onCloseModalClicked(): void {
    this.dialogRef.close();
  }

  public onEditReportConfigBtnClicked(reportConfig: ReportConfiguration): void {
    this.dialog.open(ChangelogConfigurationModalComponent,
        {data: {groupSelectItem: {name: "", id: reportConfig.groupId} as SelectInput, congfig: reportConfig}}).afterClosed().subscribe(result => {
          if (result) {
            this.getData();
          }
    });
  }

  public onDeleteReportConfigBtnClicked(reportConfig: ReportConfiguration): void {
    this.modalService.openDialog("Bericht Einstellung löschen?", "Nein", "Ja").subscribe(result => {
      if (result) {
        this.reportConfigService.deleteReportConfiguration(reportConfig.id).subscribe(result => {
          this.alertService.alert("Bericht Einstellung gelöscht", AlertTypes.info);
          this.getData();
        });
      }
    });
  }

  private getData(): void {
    this.reportConfigService.getReportConfigurations().subscribe(configs => {
      this.reportConfigs = configs;
    });
  }
}
