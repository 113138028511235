<div class="d-flex justify-content-center mb-5">
  <div class="avs-company-overview shadow">
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
        <tr>
          <th class="bg-light ps-4" scope="col">Name</th>
          <th class="bg-light ps-0"  scope="col">Besitzer</th>
          <th class="bg-light ps-0"  scope="col">Notrufnummer</th>
          <th class="bg-light ps-0"  scope="col">Addresse</th>
          <th class="bg-light text-end" scope="col">
            <button type="button" class="btn ms-0 avs-company-button rounded-0 border-0 shadow"
                    (click)="onAddBtnClicked()" >
              <span class="text-white">Firma hinzufügen</span>
            </button>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let company of companyData; let i = index">
          <td class="ps-1 ps-md-2 ps-lg-4" scope="row">
            <div class="d-flex align-items-center">
              <div>
                <img class="avs-company-user" src="assets/icons/building.svg" alt="building Icon for company">
              </div>
              <div>
                {{ company.companyName }}
                <div class="avs-company-attributes">{{ company.phone }}</div>
              </div>
            </div>
          </td>
          <td>{{ company.firstname }} {{ company.lastname }}</td>
          <td>{{ company.emergencyPhone }}</td>
          <td>{{ company.street }} {{ company.streetNo }}, {{ company.zip }} {{ company.city }}, {{ company.country }}</td>
          <td class="pe-1 pe-md-2 pe-lg-4">
            <div class="d-flex justify-content-end">
              <img class="avs-icon-delete p-2" src="assets/icons/trash.svg" alt="icon-delete-group" (click)="onDeleteBtnClicked(company.id)">
              <img class="avs-icon-edit p-2" src="assets/icons/pencil.svg" alt="avs-icon-edit" (click)="onEditBtnClicked(company.id)">
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
  </div>
</div>
