<div *ngIf="activeProject; else noActiveProject" class="m-0">
  <div class="card rounded-0 shadow mt-4 p-2">
    <div class="d-flex w-100 justify-content-between ">
      <div class="fw-semibold">Dokumente zu: <span class="text-danger">{{activeProject.name}}</span></div>
      <img (click)="onUploadFileClicked()" class="avs-icon-add mb-3" src="assets/icons/plus.svg" alt="Datei hochladen">
    </div>
    <div class="p-3">
      <em>{{activeProject.attachments.length > 0 ? '' : 'Keine Dokumente...'}}</em>
      <div *ngFor="let attachment of attachments">
        <div class="d-flex justify-content-between">
          <button class="btn btn-sm btn-outline-danger rounded-0 mt-1 "
            (click)="getBlob(attachment)">{{attachment.name}}</button>
          <img class="avs-icon-delete ms-4 p-2" src="assets/icons/trash.svg" alt="icon-delete-ts"
            (click)="onDeleteAttachment(attachment)" [matTooltip]="attachment.name + ' löschen'">
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noActiveProject>
  <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!</p>
</ng-template>