<div>
    <label *ngIf="label" class="form-label ms-2 mb-1">
      {{label}}
      
    </label>
    <input [placeholder]="placeholder" class="form-control rounded-0 input-text" type="time" step="1"
           (change)="onValueChange($event)" [required]="required" 
           #input="ngModel" [ngModel]="isDateString ? (value | date:'dd.MM.YYYY') : value">
    <span *ngIf="!input.control.valid && input.touched" class="text-danger">

    </span>
  </div>
  