import { Component, Input } from '@angular/core';
import { ProjectGroup } from "../../shared/interfaces/project-group.interface";
import { ProjectMetaData, ProjectMetaDataTypes } from "../shared/interfaces/project-meta.interface";
import { ProjectPositionData } from "../shared/interfaces/project-position.interface";
import { ProjectImageData } from "../../shared/interfaces/project-image-data.interface";
import { ProjectService } from "../../shared/services/project.service";
import { ProjectDataService } from "../../shared/services/project-data.service";
import { ModalService } from "../../../shared/modal/shared/modal.service";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { Project } from "../../shared/interfaces/project.interface";
import { MatDialog } from "@angular/material/dialog";
import { GroupSettingsCreateModalComponent } from "../group-settings-create-modal/group-settings-create-modal.component";
import { GroupService } from "../../shared/services/group.service";
import { PositionGmapService } from '../shared/services/position-gmap.service';
import { ImageDataService } from "../../shared/services/image-data.service";
import { environment } from "../../../../environments/environment";
import { UploadImageModalComponent } from '../../project-images/upload-image-modal/upload-image-modal.component';
import { ImageService } from '../../shared/services/image.service';
import { EditUser } from 'src/app/user/shared/interfaces/edit-user.interface';
import { UserService } from 'src/app/user/shared/services/user.service';
import { AnimatedImageModalComponent } from './animated-image-modal/animated-image-modal.component';
import { AnimatedImageErrorMessage } from '../../project-images/shared/animated-image-error-message';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { AnimatedImagesErrorDialogComponent } from './animated-images-error-dialog/animated-images-error-dialog.component';
import { ProjectRouteStation } from '../../shared/interfaces/project-route-station.interface';
import { group } from '@angular/animations';
import { GroupStellcodeDeleteDialogComponent } from './group-stellcode-delete-dialog/group-stellcode-delete-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'avs-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.css']
})
export class ProjectSettingsComponent {
  @Input() activeProject: Project | undefined;
  @Input() projectGroups: ProjectGroup[] = [];
  @Input() activeProjectUsers: EditUser[] = [];
  public projectMetaData: ProjectMetaData | undefined;
  public projectPositionData: ProjectPositionData | undefined;
  public images: ProjectImageData[] | undefined;
  public filteredImages: ProjectImageData[] = [];
  protected readonly ProjectMetaDataTypes = ProjectMetaDataTypes;
  public projectRouteStations: ProjectRouteStation[] = []
  protected readonly environment = environment;
  public openImageId: number = 0;
  public assignedImages: ProjectImageData[] | undefined;
  public unique: string[] = [];
  constructor(private projectService: ProjectService,
    private groupService: GroupService,
    private projectDataService: ProjectDataService,
    private modalService: ModalService,
    private alertService: AlertService,
    private positionGmapService: PositionGmapService,
    private dialog: MatDialog,
    private imageDataService: ImageDataService,
    private userService: UserService,
    private router: Router) {
    imageDataService.projectUnassignedImages.subscribe(images => {
      this.images = images;
    })
    imageDataService.projectImages.subscribe(images => {
      this.assignedImages = images
    });
  }

  public onProjectMetaChange(newValue: string, valueType: ProjectMetaDataTypes): void {
    if (!this.projectMetaData) {
      this.projectMetaData = { name: "", timeSlotFrom: "", timeSlotTo: "", notes: "", isActiveChange: false };
    }
    switch (valueType) {
      case ProjectMetaDataTypes.name:
        this.projectMetaData.name = newValue;
        break;
      case ProjectMetaDataTypes.timeSlotFrom:
        this.projectMetaData.timeSlotFrom = newValue.slice(3, 5) + '/' + newValue.slice(0, 2) + '/' + newValue.slice(6);
        break;
      case ProjectMetaDataTypes.timeSlotTo:
        this.projectMetaData.timeSlotTo = newValue.slice(3, 5) + '/' + newValue.slice(0, 2) + '/' + newValue.slice(6);
        break;
      case ProjectMetaDataTypes.notes:
        this.projectMetaData.notes = newValue;
        break;
    }
  }

  public onProjectPositionChange(newValue: string, isLatitude: boolean): void {
    if (!this.projectPositionData) {
      this.projectPositionData = { latitude: undefined, longitude: undefined };
    }
    if (isLatitude) {
      this.projectPositionData.latitude = +newValue;
    } else {
      this.projectPositionData.longitude = +newValue;
    }
  }

  public onSaveMetaDataClicked(): void {
    if (this.activeProject) {
      this.activeProject.name = this.projectMetaData?.name ? this.projectMetaData.name : this.activeProject.name;
      this.activeProject.from = this.projectMetaData?.timeSlotFrom ?
        new Date(this.projectMetaData.timeSlotFrom).toISOString() : this.activeProject.from;
      this.activeProject.to = this.projectMetaData?.timeSlotTo ?
        new Date(this.projectMetaData.timeSlotTo).toISOString() : this.activeProject.to;
      this.activeProject.notes = this.projectMetaData?.notes ? this.projectMetaData.notes : this.activeProject.notes;
      this.projectService.editProject(this.activeProject.id, this.activeProject).subscribe(() => {
        this.alertService.alert('Projekt Metadaten wurden geändert', AlertTypes.info);
        this.projectMetaData = undefined;
      });
    }
  }
  public onEditBtnClicked(userId: number): void {
  this.router.navigate(['user/edit/', userId]);
  }
  
  public onSavePositionDataClicked(): void {
    if (this.activeProject) {
      this.activeProject.latitude = this.projectPositionData?.latitude ?
        this.projectPositionData?.latitude : this.activeProject.latitude;
      this.activeProject.longitude = this.projectPositionData?.longitude ?
        this.projectPositionData.longitude : this.activeProject.longitude;
      this.projectService.editProject(this.activeProject.id, this.activeProject).subscribe(() => {
        this.alertService.alert('Projekt Positionsdaten wurden geändert', AlertTypes.info);
        this.projectPositionData = undefined;
      });
    }
  }

  public onAddGroupBtnClicked(): void {
    if (this.activeProject) {
      const dialogRef = this.dialog.open(GroupSettingsCreateModalComponent, { disableClose: true, data: this.activeProject.id });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.activeProject) {
            this.projectService.getProjectGroups(this.activeProject?.id).subscribe(groups => {
              this.projectDataService.updateProjectGroups(groups);
            });
          }
        }
      });
    }
  }

  public getPathByImageId(imageId: number) {

    if (this.images) {
      let imageObject = this.images.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }

  public onGroupClicked(projectGroup: ProjectGroup): void {
    this.projectDataService.updateActiveGroup(projectGroup);
  }

  public onDeleteGroupClicked(projectGroup: ProjectGroup): void {
    this.modalService.openDialog('Gruppe Löschen?', 'Nein', 'Ja').subscribe(
      btnYes => {
        if (btnYes) {
          this.deleteGroup(projectGroup);
        }
      }
    );
  }

  public onDeleteUsersAssignmentClicked(user: EditUser): void {
    this.modalService.openDialog('Nutzer Zuweisung entfernen?', 'Nein', 'Ja').subscribe(
      btnYes => {
        if (btnYes) {
          this.deleteUserAssignment(user);
        }
      }
    );
  }

  public onCopyCoordinatesLinkBtnClick(): void {
    let latitude = this.activeProject?.latitude;
    let longitude = this.activeProject?.longitude;

    this.positionGmapService.copyCoordinatesLink(latitude as number, longitude as number)
  }

  public onAddCustomImgClicked(): void {
    this.filterNextOpenImageId()
    const dialogRef = this.dialog.open(UploadImageModalComponent, { data: { data: this.activeProject, openImageId: this.openImageId, notAssignedImages: this.filteredImages, allImages: this.assignedImages } });
    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined && this.activeProject) {
        result.file.path = result.file.name;
        this.projectService.uploadCustomImageForProject(this.activeProject.id, result.imageId, result.file).subscribe(
          () => {
            this.alertService.alert('Bild erfolgreich hochgeladen', AlertTypes.success);

            this.filteredImages = this.filteredImages.filter(item => item.imageId != result.imageId)
            this.images = this.images!.filter(item => item.imageId != result.imageId)

            this.reloadImages();
          })
      }
    })
  }

  public reloadImages(): void {
    this.projectService.getAllUnassignedRouteStationImages(this.activeProject?.id!).subscribe(images => {
      this.images = images;
    });
    this.projectService.getAllRouteStationImages(this.activeProject?.id!).subscribe(images => {
      this.assignedImages = images;
    });
  }


  errorObjects: ProjectRouteStation[] = [];

  errorRouteStation?: ProjectRouteStation
  successObjects: ProjectRouteStation[] = [];

  succesRouteStation?: ProjectRouteStation
  public onGenerateAnimatedImageClicked(): void {
    this.errorObjects = [];
    this.projectRouteStations = [];
    this.filterNextOpenImageId();

    const dialogRef = this.dialog.open(AnimatedImageModalComponent, { data: { data: this.activeProject, openImageId: this.openImageId, notAssignedImages: this.filteredImages, projectImages: this.images, assignedImages: this.assignedImages } });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined) return;
      this.groupService.generateAnimatedImage(result.groupId, result.animatedImages, result.stellcode).subscribe((errorMessages) => {
        this.alertService.alert('Bild erfolgreich erstellt', AlertTypes.success);

        let guidList: string[] = [];
        let errorList: string[] = []; /* Gibt mir alle Fehler zurück, die vor dem setzen passieren   und somit scheitern werden */
        let successList: string[] = [];

        errorMessages.forEach((message: AnimatedImageErrorMessage) => {
          if (message.deKanal) { guidList.push(message.guid); }
          if (message.deKanal === 0 && message.hasFailed && message.errorMessage !== 'Stellcode is already in use') { errorList.push(message.guid); }
          this.unique = guidList.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
        })
        successList = this.unique.filter(item => !errorList.includes(item))
        this.projectGroups.forEach(group => { group.routeStations.forEach(station => this.projectRouteStations.push(station)) })
        errorList.forEach((guid) => {
          this.errorRouteStation = this.projectRouteStations?.find(x => x.id === guid)

          this.errorObjects.push(this.errorRouteStation!)
        })
        successList.forEach((guid) => {
          this.succesRouteStation = this.projectRouteStations?.find(x => x.id === guid)

          this.successObjects.push(this.succesRouteStation!)
        })


        this.dialog.open(AnimatedImagesErrorDialogComponent, { data: { errors: this.errorObjects, successes: this.successObjects } }) /* erfolgreiche einfach weglassen? */
      })
    })
  }

  public onDeleteStellcodeForGroup() {
    const dialogRef = this.dialog.open(GroupStellcodeDeleteDialogComponent, { data: { data: this.activeProject, openImageId: this.openImageId, notAssignedImages: this.filteredImages, projectImages: this.images, assignedImages: this.assignedImages } });
    dialogRef.afterClosed().subscribe(result => {

      if (result === undefined) return;

      this.groupService.deleteStellcodeByGroupId(result.group.id, result.image.stellcode).subscribe((x) => {
      })
    });
  }


  public onActiveProjeckChange(): void {
    if (this.activeProject) {
      this.activeProject.isActive = !this.activeProject.isActive;
      if (!this.projectMetaData) {
        this.projectMetaData = { name: "", timeSlotFrom: "", timeSlotTo: "", notes: "", isActiveChange: true };
      }
    }
  }

  public onProjectImageClicked(image: ProjectImageData): void {
    this.modalService.openDialog('Bild für alle Stationen hinzufügen?', 'Abbrechen', 'Bestätigen', image).subscribe(() => {
      if (!this.activeProject) { return; }
      this.projectService.setImageForAllRouteStations(this.activeProject?.id, image.imageId).subscribe(() => {
        this.projectDataService.updateActiveProject(this.activeProject);
      });
    });
  }

  private filterNextOpenImageId() {
    this.filteredImages = [];
    this.openImageId = 0;
    if (this.images) {
      this.images.forEach(image => { if (image.imageId > 121) { this.filteredImages.push(image) } })
    }
    this.openImageId = Math.min.apply(Math, this.filteredImages.map(o => o.imageId));
  }

  private deleteGroup(group: ProjectGroup): void {
    this.groupService.deleteProjectGroup(group.id).subscribe(() => {
      this.alertService.alert('Gruppe wurde gelöscht', AlertTypes.info);
      this.projectService.getProjectGroups(group.projectId).subscribe(groups => {
        this.projectDataService.updateProjectGroups(groups);
      })
    });
  }

  private deleteUserAssignment(user: EditUser): void {
    this.modalService.openDialog('Das Projekt vom Benutzer entfernen?', 'Nein', 'Ja')
      .subscribe(() => {
        if (user) {
          this.userService.editUserProject(user.id, [], [this.activeProject?.id!])
            .subscribe(() => {
              this.alertService.alert('Zuweisung wurde etnfernt', AlertTypes.info);
              this.activeProjectUsers = this.activeProjectUsers.filter(x => x.id !== user.id)
            })
        }
      });
  }
}
