<div class="mat-elevation-z8 table-container position-relative" style="z-index: 0;">
  <div class="position-absolute" style=" top: 0px; right: 0px; z-index: 99999;">

    <mat-menu #menuDownload="matMenu" xPosition="before">
      <button mat-menu-item (click)="downloadMQReport('csv')">
        <mat-icon>download</mat-icon>
        <span>CSV</span>
      </button>
      <button mat-menu-item (click)="downloadMQReport('ascii')">
        <mat-icon>download</mat-icon>
        <span>ASCII</span>
      </button>
      <button mat-menu-item (click)="downloadMQReport('pdf')">
        <mat-icon>download</mat-icon>
        <span>PDF</span>
      </button>
    </mat-menu>
  </div>
  <table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8">


    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef> Zeitpunkt </th>
      <td mat-cell *matCellDef="let element"> {{element.time | date: 'dd.MM.yyyy - H:mm':
        offset(element.measuredAt)}} </td>
    </ng-container>

    <ng-container matColumnDef="deviceId">
      <th mat-header-cell *matHeaderCellDef> SAM ID </th>
      <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
    </ng-container>

    <ng-container matColumnDef="projectId">
      <th mat-header-cell *matHeaderCellDef> projectId </th>
      <td mat-cell *matCellDef="let element"> {{element.projectId}} </td>
    </ng-container>

    <ng-container matColumnDef="fg">
      <th mat-header-cell *matHeaderCellDef> fg</th>
      <td mat-cell *matCellDef="let element"> {{element.fg}} </td>
    </ng-container>

    <ng-container matColumnDef="detyp">
      <th mat-header-cell *matHeaderCellDef> detyp </th>
      <td mat-cell *matCellDef="let element"> {{element.detyp}} </td>
    </ng-container>
    <ng-container matColumnDef="deKanal">
      <th mat-header-cell *matHeaderCellDef> deKanal </th>
      <td mat-cell *matCellDef="let element"> {{element.deKanal}} </td>
    </ng-container>
    <ng-container matColumnDef="jobId">
      <th mat-header-cell *matHeaderCellDef> jobId </th>
      <td mat-cell *matCellDef="let element"> {{element.jobId}} </td>
    </ng-container>
    <ng-container matColumnDef="lat">
      <th mat-header-cell *matHeaderCellDef> lat </th>
      <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
    </ng-container>
    <ng-container matColumnDef="lng">
      <th mat-header-cell *matHeaderCellDef> lng </th>
      <td mat-cell *matCellDef="let element"> {{element.lng}} </td>
    </ng-container>
    <ng-container matColumnDef="rawdata">
      <th mat-header-cell *matHeaderCellDef> rawdata </th>
      <td mat-cell *matCellDef="let element"> {{element.rawdata}} </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button [matMenuTriggerFor]="menuDownload" aria-label="Download menu">
          <mat-icon>download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element"> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!--     <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr> -->
  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
</div>