<div *ngIf="activeProject; else noActiveProject" class="m-0">

  <!-- ProjectNotesCard -->

  <div class="card rounded-0 shadow mt-4 p-2">
    <div class="fw-semibold d-flex justify-content-between ">
      <div>Projekt Notizen zu: <span class="text-danger">{{activeProject.name}}</span></div>
      <div>
        <img (click)="onShowProjectNotes()" *ngIf="!showProjectNotes" style="height: 36px" class="p-1 me-4 hide-button"
          src="assets/icons/caret-down.svg" alt="hide-project-notes">
        <img (click)="onShowProjectNotes()" *ngIf="showProjectNotes" style="height: 36px" class="me-4  hide-button p-1 "
          src="assets/icons/caret-up.svg" alt="hide-project-notes">
      </div>
    </div>

    <div class="p-3" *ngIf="showProjectNotes">
      <textarea [(ngModel)]="activeProject.notes" class="avs-textarea-notes w-100 h-auto form-control rounded-0"
        oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' placeholder="Keine Notizen..."
        (keydown)="onKeyDown($event)">

      </textarea>
    </div>
    <div class="actionbar">
      <button class="btn btn-outline-success rounded-0 me-3" type="button" (click)="onSaveNotesBtnClicked()"
        *ngIf="isNoteChanged">Speichern</button>
    </div>
  </div>


  <!-- activeRouteStationNotesCard  CREATE CARD-->

  <ng-container *ngIf="routeStation; else noActiveRouteStation">

    <div *ngIf="routeStation.routeStationNotes.length === 0">
      <div class="card rounded-0 shadow mt-4 p-2">
        <div class="fw-semibold">RouteStation Notizen zu: <span class="text-danger">{{routeStation.name}}</span></div>
        <div class="p-3">
          <textarea [(ngModel)]="note" class="avs-textarea-notes w-100 h-auto form-control rounded-0"
            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' placeholder="Keine Notizen..."
            (keydown)="onKeyDownCreateSingleNote($event)">
        </textarea>
        </div>
        <div class="actionbar">
          <button class="btn btn-outline-success rounded-0 me-3" type="button" (click)="onSaveSingleNotes()"
            *ngIf="note !== ''">Speichern</button>
        </div>
      </div>
    </div>
  </ng-container>




  <!-- latestNoteField of activeRouteStations EDIT CARD-->
  <ng-container *ngIf="latestNote && routeStation ">
    <div class="card rounded-0 shadow mt-4 p-2">
      <div class="d-flex justify-content-between">
        <div class="fw-semibold">RouteStation Notizen zu: <span class="text-danger">{{routeStation.name}}</span></div>
        <div class="d-flex justify-content-end  ">
          <span *ngIf="latestNote.createdAt === latestNote.lastChangedAt">
            Erstellt am: {{ latestNote.createdAt | date: 'dd.MM.yyyy - H:mm'}}</span>
          <span *ngIf="latestNote.createdAt !== latestNote.lastChangedAt">
            Bearbeitet am: {{latestNote.lastChangedAt | date: 'dd.MM.yyyy - H:mm'}}</span>
        </div>
      </div>
      <div class="p-3">
        <textarea [ngModel]="latestNote.note" class="avs-textarea-notes w-100 h-auto form-control rounded-0"
          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' placeholder="Keine Notizen..."
          (ngModelChange)="onChangeLatestNote($event, latestNote)">

      </textarea>
      </div>
      <div class="actionbar">
        <button *ngIf="latestNote.noteChanged" class="btn btn-outline-success rounded-0 me-3" type="button"
          (click)="onEditSingleNote(latestNote.id!)">Bearbeiten</button>
      </div>


    </div>
  </ng-container>

  <!-- otherGroupsRouteStationsNotesCard -->
  <ng-container *ngFor="let stations of projectsActiveRouteStation">
    <div *ngIf="stations.samId !== routeStation?.samId">
      <div class="card rounded-0 shadow mt-4 p-2" (click)="changeActiveRouteStation(stations)">
        <div class="d-flex justify-content-between">
          <div class="fw-semibold">RouteStation Notizen zu: <span class="text-danger">{{stations?.name}}</span>
          </div>
          <div class="d-flex justify-content-end  ">
            <div *ngIf="stations.routeStationNotes[0]">
              <span *ngIf="stations.routeStationNotes[0].createdAt === stations.routeStationNotes[0].lastChangedAt">
                Erstellt am: {{ stations.routeStationNotes[0].createdAt | date: 'dd.MM.yyyy - H:mm'}}</span>
              <span *ngIf="stations.routeStationNotes[0].createdAt !== stations.routeStationNotes[0].lastChangedAt">
                Bearbeitet am: {{stations.routeStationNotes[0].lastChangedAt | date: 'dd.MM.yyyy - H:mm'}}</span>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div>
            <span> {{stations.routeStationNotes.length !== 0 ? stations.routeStationNotes[0].note : 'Keine Notizen...'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-template #noActiveRouteStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
  </ng-template>
</div>



<ng-template #noActiveProject>
  <p class="mt-3 fw-semibold text-secondary">Kein Projekt ausgewählt!</p>
</ng-template>