import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectInput } from './shared/select-input.class';

@Component({
  selector: 'avs-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @Input() title: string = "";
  @Input() isCloseButtonActive: boolean = true;
  @Input() isHeaderActive: boolean = true;
  @Input() selectInput: SelectInput[] = [];
  @Input() activeItem: SelectInput | undefined;
  @Input() isSmallDropdown: boolean = false;
  @Output() onCloseBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onItemClicked: EventEmitter<SelectInput> = new EventEmitter<SelectInput>();

  public onCloseButtonClicked(): void {
    this.onCloseBtnClicked.emit(true);
  }

  public onListItemClicked(item: SelectInput): void {
    this.onItemClicked.emit(item);
  }
}
