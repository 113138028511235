<div class="m-2" style="min-width: 600px;">
    <div class="d-flex justify-content-between border-bottom">
        <h5 class="fw-semibold">Berichteinstellungen Übersicht</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg"
             alt="close-modal-icon" (click)="onCloseModalClicked()">
    </div>
    <div class="m-2">
        <div *ngIf="reportConfigs.length > 0">
            <div class="row border-bottom border-secondary">
                <div class="col-2 text-danger fw-semibold border-end">User ID</div>
                <div class="col-2 text-danger fw-semibold border-end">Group ID</div>
                <div class="col-2 text-danger fw-semibold border-end">Datei Format</div>
                <div class="col-2 text-danger fw-semibold border-end">Datum</div>
                <div class="col-2 text-danger fw-semibold border-end">Interval</div>
                <div class="col-2 text-danger fw-semibold">Aktion</div>
            </div>
            <div *ngFor="let reportConfig of reportConfigs" class="row border-bottom avs-report-config-row">
                <div class="col-2 border-end">{{reportConfig.userId}}</div>
                <div class="col-2 border-end">{{reportConfig.groupId}}</div>
                <div class="col-2 border-end">{{reportConfig.type}}</div>
                <div class="col-2 border-end">{{reportConfig.modifiedAt | date:'dd.MM.YYYY'}}</div>
                <div class="col-2 border-end">{{reportConfig.cycle === 1 ? 'wöchentlich' : 'monatlich'}}</div>
                <div class="col-2 d-flex justify-content-end">
                    <img class="p-1 avs-report-config-action-icon" src="assets/icons/pencil.svg"
                         role="button" (click)="onEditReportConfigBtnClicked(reportConfig)" alt="edit Icon">
                    <img class="p-1 ms-1 avs-report-config-action-icon" src="assets/icons/trash.svg"
                         role="button" (click)="onDeleteReportConfigBtnClicked(reportConfig)" alt="delete Icon">
                </div>
            </div>
        </div>
        <div class="text-danger" *ngIf="reportConfigs.length === 0">Keine Berichteinstellungen angelegt!</div>
    </div>
</div>
