import { ProjectGroup } from '../shared/interfaces/project-group.interface';
import { Project } from '../shared/interfaces/project.interface';
import { ProjectService } from '../shared/services/project.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStation } from '../shared/interfaces/project-route-station.interface';
import { ProjectDataService } from '../shared/services/project-data.service';
import { ProjectDataSessionStorageService } from "../shared/services/project-data-session-storage.service";
import { ProjectSessionStorageData } from "../shared/interfaces/project-session-storage-data.interface";
import { GroupService } from "../shared/services/group.service";
import { MapHandlerService } from '../project-map/shared/map-handler.service';
import { SelectInput } from "../../shared/dropdown/shared/select-input.class";
import { checkAccessAuthorization } from "../../user/shared/role.guard";
import { UserDataService } from "../../user/shared/services/user-data.service";
import { UserDataResponse } from "../../user/shared/interfaces/login-data-response.interface";
import { ProjectMenuData } from "../project-menu/shared/project-menu-data.interface";
import { Subscription } from 'rxjs';


@Component({
  selector: 'avs-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  public activeProject: Project | undefined;
  public activeGroup: ProjectGroup | undefined;
  public activeRouteStation: ProjectRouteStation | undefined;
  public isSmallMenu: boolean = false;
  public isProjectMenuOpen: boolean = false;
  public isProjectSelectOpen: boolean = false;
  public projectMenuSelectInput: SelectInput[] = [];
  public user: any;
  public subscriptionCollection: Subscription[] = [];
  public activeRouteStationSubscription?: Subscription;
  public activeGroupSubscription?: Subscription;
  public activeProjectSubscription?: Subscription;
  public userDataSubscription?: Subscription;


  constructor(private projectService: ProjectService,
    private groupService: GroupService,
    private projectDataService: ProjectDataService,
    private projectDataSessionStorageService: ProjectDataSessionStorageService,
    private mapHandler: MapHandlerService,
    private userDataService: UserDataService
  ) {
    this.activeProjectSubscription = projectDataService.activeProject.subscribe(activeProject => { this.activeProject = activeProject });
    this.activeGroupSubscription = projectDataService.activeGroup.subscribe(activeGroup => { this.activeGroup = activeGroup });
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(routeStation => { this.activeRouteStation = routeStation });
    this.userDataSubscription = userDataService.userData.subscribe(user => { if (user) { this.user = user; this.fillMenuSelectInput(user); } });

  }

  public ngOnInit(): void {
    this.getProjectData();
    this.subscriptionCollection.push(this.activeProjectSubscription!);
    this.subscriptionCollection.push(this.activeGroupSubscription!);
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
    this.subscriptionCollection.push(this.userDataSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public onHideMenuBtnClick(): void {
    this.isSmallMenu = !this.isSmallMenu;
    this.resizeMap();
  }

  private resizeMap(): void {
    this.mapHandler.initResize();
  }

  public onProjectMenuClicked(): void {
    this.isProjectMenuOpen = !this.isProjectMenuOpen;
  }

  private getProjectData(): void {
    this.projectService.getProjects().subscribe((projects: Project[]) => {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionStorageService.getProjectSessionData();
      this.setProjectDataFromSession(activeProjectData, projects);
    });
  }

  private setProjectDataFromSession(activeProjectData: ProjectSessionStorageData,
    projects: Project[]): void {
    let sessionData: ProjectMenuData = {
      projects: projects, groups: [], stations: [], activeProject: undefined, activeGroup: undefined, activeStation: undefined
    };
    if (activeProjectData.activeProjectId) {
      sessionData.activeProject = projects.find(
        (p: Project) => p.id.toLocaleString() === activeProjectData.activeProjectId);
      this.projectService.getProjectGroups((+activeProjectData.activeProjectId)).subscribe(
        (groups: ProjectGroup[]) => {
          sessionData.groups = groups;
          if (activeProjectData.activeProjectGroupId) {
            sessionData.activeGroup = groups.find(
              (g: ProjectGroup) => g.id.toLocaleString() === activeProjectData.activeProjectGroupId);
            this.groupService.getProjectRouteStations(+(activeProjectData.activeProjectGroupId)).subscribe(stations => {
              sessionData.stations = stations;
              if (activeProjectData.activeProjectRouteStation) {
                sessionData.activeStation = stations.find(rs =>
                  rs.id === activeProjectData.activeProjectRouteStation);
                this.projectDataService.updateProjectDataFromSession(sessionData);
              } else {
                this.projectDataService.updateProjectDataFromSession(sessionData);
              }
            });
          } else {
            this.projectDataService.updateProjectDataFromSession(sessionData);
          }
        });
    } else {
      this.projectDataService.updateProjectDataFromSession(sessionData);
    }
  }

  private fillMenuSelectInput(user: UserDataResponse): void {
    this.projectMenuSelectInput.push(
      { name: 'Karte', routerLink: 'map' },
      { name: 'Stationsübersicht', routerLink: 'station-overview' },
      { name: 'Schema', routerLink: 'schema' },
      { name: 'Sonderprogramme', routerLink: 'scenario' }
    );
    if (checkAccessAuthorization([1], user.role)) {
      this.projectMenuSelectInput.push(
        { name: 'Testbereich', routerLink: 'test-area' },
        { name: 'Einstellungen', routerLink: 'settings' },
        { name: 'Bilder', routerLink: 'images' },
        { name: 'Dokumente', routerLink: 'documents' },
        { name: 'Notizen', routerLink: 'notes' },
        { name: 'SWA', routerLink: 'swa-config' },
        { name: 'Zeitschaltuhr', routerLink: 'time-switch' },

      )
    }
  }
}
