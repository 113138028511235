import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardOverviewComponent } from "./dashboard-overview/dashboard-overview.component";
import { BatterieDiagrammComponent } from "../report/shared/components/batterie-diagramm/batterie-diagramm.component";
import { MatCardModule } from "@angular/material/card";
import { SharedModule } from "../shared/shared.module";

@NgModule({

  declarations: [DashboardOverviewComponent, BatterieDiagrammComponent],
  imports: [CommonModule, MatCardModule, SharedModule],
  exports: [],
  providers: [],
})
export class DashboardModule { }
