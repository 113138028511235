<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Sonderprogramm auswählen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>

    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        
        <mat-tab label="Programmkatalog">

            <div *ngFor="let scenario of scenarios" class="row w-100">
                <span> {{ scenario.name }}</span> <mat-slide-toggle class="pb-2"
                    [checked]="scenario.enabled" color="primary" (change)="onScenarioEnabled(scenario)">
                    <span class="text-secondary">{{ scenario.enabled ? 'Ausschalten' : 'Einschalten'}}</span>
                </mat-slide-toggle>
            </div>
        </mat-tab>
<mat-tab label="Programm erstellen">
            <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-center mt-1">
                    <avs-text-input label="Name" (valueChange)="onNameChange($event)" [value]="name"
                        style="width: 240px !important"> </avs-text-input>
                </div>
                <div class="w-100 d-flex justify-content-center mb-2">
                    <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                        <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                            <span class="ms-1 text-secondary">Verkehrsstufe</span>
                            <div [ngClass]="isTrafficDropdownOpen ? 'border-danger' : ''"
                                class="border d-flex justify-content-between" role="button"
                                (click)="onTrafficDropdownClicked()">
                                <div class="ms-2 p-1">{{ selectedTraffic.name }}</div>
                                <img *ngIf="!isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                    alt="caret-down open select">
                                <img *ngIf="isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                    alt="caret-up close select">
                            </div>
                            <avs-dropdown [selectInput]="trafficSelect" [activeItem]="selectedTraffic"
                                (onItemClicked)="onTrafficItemClicked($event)" [isHeaderActive]="false"
                                *ngIf="isTrafficDropdownOpen" class="avs-route-station-settings-dropdown-container">
                            </avs-dropdown>
                        </div>
                    </div>
                </div>

                <div class="w-100 d-flex justify-content-center mb-2" *ngIf="selectedTraffic.id === 1 || selectedTraffic.id === 2">
                    <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                        <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                            <span class="ms-1 text-secondary">Verkehrssituation</span>
                            <div [ngClass]="isVerkehrssituationDropdownOpen ? 'border-danger' : ''"
                                class="border d-flex justify-content-between" role="button"
                                (click)="onVerkehrssituationDropdownClicked()">
                                <div class="ms-2 p-1">{{ selectedVerkehrssituation.name }}</div>
                                <img *ngIf="!isVerkehrssituationDropdownOpen" class="me-2"
                                    src="assets/icons/caret-down.svg" alt="caret-down open select">
                                <img *ngIf="isVerkehrssituationDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                    alt="caret-up close select">
                            </div>
                            <avs-dropdown [selectInput]="verkehrssituationSelect" [activeItem]="selectedVerkehrssituation"
                                (onItemClicked)="onVerkehrssituationItemClicked($event)" [isHeaderActive]="false"
                                *ngIf="isVerkehrssituationDropdownOpen"
                                class="avs-route-station-settings-dropdown-container">
                            </avs-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <mat-checkbox class="d-flex justify-content-center me-2" [(ngModel)]="highPriority">Hohe
                Priorität</mat-checkbox>


            <div class="d-flex justify-content-center">
                <button [disabled]="!objectIsTouched" class="btn btn-sm btn-outline-success rounded-0"
                    (click)="onSubmitBtnClicked()">
                    Programm schalten
                </button>
            </div>
        </mat-tab>
    </mat-tab-group>









    <div class="d-flex justify-content-end mt-5 border-top pt-3">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseDialogClicked()">
            Schließen
        </button>
    </div>
</mat-dialog-content>