import { JwtTokenService } from '../../../core/shared/jwt-token.service';
import { HttpService } from '../../../core/shared/http.service';
import { Injectable } from "@angular/core";
import { LoginData } from "../interfaces/login-data.interface";
import { Observable } from "rxjs";
import { UserDataResponse } from "../interfaces/login-data-response.interface";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpService: HttpService,
              private jwtTokenService: JwtTokenService,
              private router: Router) {
  }

  public login(loginData: LoginData): Observable<UserDataResponse> {
    return this.httpService.post<UserDataResponse>('User/authenticate', loginData, false);
  }

  public isLoggedIn(): boolean {
    let token = this.jwtTokenService.getJwtToken();
    return !!token;
  }

  public logout(): void {
    this.jwtTokenService.removeJwtToken();
    this.router.navigateByUrl('login');
  }

  public getUserViaJwtToken(): Observable<UserDataResponse> {
    return this.httpService.get<UserDataResponse>('User/self');
  }

  public forgotPassword(email: string): Observable<any> {
    const emailData = {
      "email": email};
    return this.httpService.post<any>('User/forgot-password', emailData, false);
  }
}
